import styled from "styled-components/macro";
import { Color } from "../../styles/color";
import { indents } from "styles/indents";

export const Title = styled.div`
  font-family: "Montserrat", "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${Color.DARK900};
  margin-top: ${indents.indent5};
`;

export const Links = styled.span`
  color: ${Color.BLUE300};
  cursor: pointer;
`;
