import styled from "styled-components/macro";
import { Color } from "styles/color";

export const Wrapper = styled.div`
  width: 160px;
  position: relative;
`;

export const CalendarIconStyled = styled.div<{ filled: boolean }>`
  width: 12px;
  height: 12px;

  position: absolute;
  right: 10px;
  top: 11px;

  pointer-events: none;

  svg path {
    fill: ${(p) => (p.filled ? Color.BLUE300 : Color.LIGHT50)};
  }
`;
