import * as yup from "yup";

export const PRIVATE_INFO_HELP = "Private information for your company";

export const CONTRACT_DURATION = [
  "1 month",
  "2 months",
  "3 months",
  "4 months",
  "5 months",
  "6 months",
  "7 months",
  "8 months",
  "9 months",
  "10 months",
  "11 months",
  "12 months",
];

// схема необязательного поля ввода чисел
export const numberSchema = yup.lazy((v) =>
  v === ""
    ? yup.string()
    : yup
        .number()
        .min(0, "The value can be between 0 and 1 000 000")
        .max(1000000, "The value can be between 0 and 1 000 000")
);

const REQUIRED_ERROR = "Fill in the required field";
const MAX_CHARS_ERROR = "Only 1000 characters allowed.";
export const GENERAL_INFO_SCHEMA = yup.object({
  rank: yup.string().required(REQUIRED_ERROR),
  ship_type: yup.string().required(REQUIRED_ERROR),
  ship_dwt: numberSchema,
  net_salary: yup.object().shape({
    from: yup.string().required(REQUIRED_ERROR),
    to: yup.string(),
  }),
  contract_duration: yup.string().required(REQUIRED_ERROR),
  has_dates_interval: yup.boolean(),
  date_of_boarding: yup.string().required(REQUIRED_ERROR),
  crew_on_board: yup.array().nullable(),
  additional_info: yup.string().max(1000, MAX_CHARS_ERROR),
  private_info: yup.string().max(1000, MAX_CHARS_ERROR),
});
