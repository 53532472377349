import { IResponse, StatusResponse } from "api/responses/types";

const getStatus = (response: IResponse): StatusResponse => {
  if (response.archived) return StatusResponse.archived;
  if (response.rejected) return StatusResponse.rejected;
  if (response.hiring_confirmed) return StatusResponse.hiring_confirmed;
  if (response.hired) return StatusResponse.hired;
  if (response.contacted) return StatusResponse.contacted;
  if (response.selected) return StatusResponse.selected;
  if (response.viewed) return StatusResponse.viewed;
  return StatusResponse.sent;
};

export const modifyResponses = (responses: IResponse[]): IResponse[] => {
  return responses.map((item) => {
    const status = getStatus(item);
    return { ...item, fr_status: status };
  });
};
