export type ICvData = {
  availability_status: StatusAvailable;
  availability_status_updated_at: string;
  birthday?: string;
  certificates: CertificatesType[];
  citizenship?: string;
  city_of_residence?: string;
  second_citizenship?: string;
  contract_duration?: number;
  created_at: string;
  updated_at: string;
  last_activity_at: string;
  user_id: string;
  cv_id: string;
  date_of_availability: string;
  vaccine?: string;
  date_of_last_injection?: string;
  diplomas: DiplomaType[];
  email?: string;
  email_is_verified?: boolean;
  phone_is_verified?: boolean;
  preferred_messenger?: string;
  fill_step: number;
  first_name?: string;
  last_name?: string;
  middle_name?: string;
  foreign_languages: LanguageType[];
  gender?: Gender;
  general_remarks?: string;
  is_deleted: boolean;
  is_public: boolean;
  matching_percent_to_vacancy?: number;
  medical_certificates: MedicalCertificatesType[];
  moderation_status: ModerationStatus;
  net_salary_from?: string;
  net_salary_to?: string;
  height?: number;
  weight?: number;
  overall_size?: string;
  shoe_size?: number;
  body_mass_index?: number;
  no_experience?: boolean;
  work_experience: WorkExperience[];
  total_work_experience_in_months?: number;
  total_work_experience?: string;
  visas: VisaType[];
  travel_passports: TravelPassportType[];
  phone?: string;
  photo?: string;
  first_rank?: string;
  responded_to_vacancy_at: string;
  secondary_rank?: string;
  ship_types?: Array<string>;
  shared_date?: string;
  views_count: number;
  sb_number?: string;
  sb_country_of_issue?: string;
  sb_file_path?: string;
  sb_status?: Status;
  sb_valid_until?: string;
  sb_is_perpetual?: boolean;
  utm_compaign?: string;
  utm_medium?: string;
  utm_source?: string;
  paid?: boolean;
  dip_get_later?: boolean;
  dip_later_date?: string;
  dip_not_shared?: boolean;
  paid_assessment?: boolean;
  last_admin_editor?: string;
  is_responded_to_vacancy?: boolean;
  contacted_email?: SeamanContactStatus;
  contacted_phone?: SeamanContactStatus;
  contacted_whatsapp?: SeamanContactStatus;
};

export type Gender = "male" | "female";
type Visa = "travel" | "work" | "maritime";
type Status = "exist" | "later" | "not_exist";

export enum StatusAvailable {
  available = "available",
  postponed = "postponed",
  unavailable = "unavailable",
}

export enum ModerationStatus {
  moderated = "moderated",
  banned = "banned",
}

export type LanguageType = {
  language: string;
  level: string;
};

export type TravelPassportType = {
  country_of_issue?: string;
  file_path?: string;
  is_perpetual?: boolean;
  passport_number?: string;
  valid_until: string;
};

export type VisaType = {
  file_path: string;
  valid_until: string;
  visa_to: string;
  visa_type: Visa;
};

export type CertificatesType = {
  certificate_number: string;
  certificate_type: string;
  country_of_issue: string;
  file_path: string;
  place_of_issue: string;
  valid_until: string;
};

export type MedicalCertificatesType = {
  certificate_type: string;
  country_of_issue: string;
  file_path: string;
  place_of_issue: string;
  valid_until: string;
};

export type DiplomaType = {
  cert_type: string;
  proficiency: string;
  endorsement: string;
  competency: string;
  country_of_issue: string;
  file_path: string;
  license_number: string;
  valid_until: string;
};

export type WorkExperience = {
  duration_human: string;
  duration_months: number;
  rank: string;
  ship_crewing_agency: string;
  ship_flag: string;
  ship_dwt: number | string;
  ship_grt: number | string;
  ship_kw: number | string;
  ship_imo: string;
  ship_me_type: string;
  ship_name: string;
  ship_nrt: string;
  ship_owner: string;
  ship_type: string;
  sign_off_date: string;
  sign_on_date: string;
  year_of_build?: string;
  still_employed?: boolean;
  fuel_type: string;
};

// ------------------------------
export interface IUpdateCv {
  seaman_contact_status?: SeamanContactStatus;
  contact_type?: ContactType;
  availability_status?: StatusAvailable;
  available_from?: string;
  first_rank?: string;
  secondary_rank?: string;
}

export enum SeamanContactStatus {
  in_dialog = "in_dialog",
  contacted = "contacted",
  no_reply = "no_reply",
}

export enum ContactType {
  contacted_email = "contacted_email",
  contacted_whatsapp = "contacted_whatsapp",
  contacted_phone = "contacted_phone",
}
