import styled from "styled-components/macro";
import { Link as DefaultLink } from "react-router-dom";
import { Color } from "../../styles/color";

export const ButtonsWrapper = styled.div`
  display: flex;
`;

export const Link = styled(DefaultLink)`
  color: ${Color.BLUE300};
  text-decoration: none;
`;

export const Text = styled.p<{ color?: string }>`
  color: ${(props) => props.color || "#061938"};
`;
