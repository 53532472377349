import map from "lodash/fp/map";
import dayjs from "dayjs";
import { formatDateAPI } from "utils/dateTime";

const DURATION = [
  "1 month",
  "2 months",
  "3 months",
  "4 months",
  "5 months",
  "6 months",
  "7 months",
  "8 months",
  "9 months",
  "10 months",
  "11 months",
  "12 months",
  "13 months",
  "14 months",
  "15 months",
];

export const DURATION_OPTIONS = map(
  (duration) => ({
    label: duration,
    value: String(parseInt(duration)),
  }),
  DURATION
);

export const getToDate = (date: string, month: string) =>
  formatDateAPI(dayjs(date).add(Number(month), "month").toString());

export const getDiffMonth = (from: string, to: string) => {
  return dayjs(to).diff(from, "month").toString();
};
