import React from "react";
import * as Consts from "./forms/forms.const";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Styled from "./AddVacancy.styled";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import Controls from "./components/Controls/Controls";
import { useSubmit } from "./useSubmit";
import { IInitialValues } from "./forms/forms.const";
import { useParams } from "react-router-dom";
import { PersonalInformation } from "./forms/PersonalInformation/PersonalInformation";
import { useGetDictionary } from "./useGetDictionary";
import { IPersonalProps } from "./forms/PersonalInformation/PersonalInformation.typed";
import { Documents } from "./forms/Documents/Documents";
import { IDocumentsProps } from "./forms/Documents/Documents.typed";
import { WorkExperience } from "./forms/WorkExperience/WorkExperience";
import { IWorkProps } from "./forms/WorkExperience/WorkExperience.typed";
import GeneralInfo from "./forms/GeneralInfo/GeneralInfo";
import { IVacancyName } from "./forms/GeneralInfo/GeneralInfo.typed";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton";
import ConfigInfo from "./forms/ConfigInfo/ConfigInfo";

interface IProps {
  defaultValues?: IInitialValues;
  vacancyId?: string;
  status?: string;
  titleCompany?: string;
}

const AddVacancy: React.FC<IProps> = ({
  defaultValues,
  vacancyId,
  status,
  titleCompany,
}) => {
  const params = useParams() as { id: string };
  const {
    formState: { errors },
    watch,
    getValues,
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(Consts.scheme),
    defaultValues: defaultValues || Consts.initialValues,
    mode: "onBlur",
  });

  useGetDictionary();

  const { onDraftClick, onPublicClick, error } = useSubmit({
    vacancyId,
    titleCompany,
  });

  const formValues = watch();

  const onPublishClick = () => {
    handleSubmit(onPublicClick)();
  };

  const onSaveToDraftClick = () => {
    onDraftClick(getValues());
  };

  return (
    <PageLayout>
      <GoBackButton text="Back to company" link={`/company/${params.id}`} />
      <Styled.Wrapper>
        <Styled.Aside>
          <Controls
            error={error}
            formValues={formValues}
            vacancyId={vacancyId}
            status={status}
            onPublishClick={onPublishClick}
            onSaveToDraftClick={onSaveToDraftClick}
          />
        </Styled.Aside>
        <Styled.Main>
          <Styled.Title>
            {vacancyId ? "Edit vacancy" : "Add new vacancy"}
          </Styled.Title>
          <ConfigInfo
            control={control}
            defaultValues={
              defaultValues?.config_info || Consts.initialValues.config_info
            }
          />
          <GeneralInfo
            control={control}
            defaultValues={
              defaultValues?.general_info || Consts.initialValues.general_info
            }
            errors={errors.general_info as IVacancyName["errors"]}
          />
          <Styled.Requirements>
            <PersonalInformation
              control={control}
              defaultValues={
                defaultValues?.personal_information ||
                Consts.initialValues.personal_information
              }
              errors={errors.personal_information as IPersonalProps["errors"]}
            />
            <WorkExperience
              control={control}
              defaultValues={
                defaultValues?.work_experience ||
                Consts.initialValues.work_experience
              }
              errors={errors.work_experience as IWorkProps["errors"]}
            />
            <Documents
              control={control}
              defaultValues={
                defaultValues?.documents || Consts.initialValues.documents
              }
              errors={errors.documents as IDocumentsProps["errors"]}
            />
          </Styled.Requirements>
        </Styled.Main>
      </Styled.Wrapper>
    </PageLayout>
  );
};

export { AddVacancy };
