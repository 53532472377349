import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";
import { StatusAvailable } from "api/cvs/types";

export const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 144px;
  position: relative;
`;

export const Avatar = styled.div`
  position: relative;
  width: 104px;
  height: 104px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 11px;
  margin-left: ${indents.indent6};
  margin-right: ${indents.indent5};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    border: 1px solid ${Color.BLUE300};
    transition: opacity 0.3s;
  }

  &:hover::after {
    opacity: 1;
  }
`;

const getBackground = (status: StatusAvailable | undefined) => {
  if (status === "available") return Color.GREEN400;
  if (status === "postponed") return Color.YELLOW300;
  return Color.RED300;
};

export const Status = styled.div<{ status: StatusAvailable | undefined }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: ${indents.indent4} ${indents.indent5};
  max-width: 133px;
  color: ${Color.MID400};
  font-size: 11px;
  line-height: 14px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  background-color: ${Color.LIGHT10};
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  border-radius: 0 4px 4px 0;
`;

export const StatusIndicator = styled.div<{
  status: StatusAvailable | undefined;
}>`
  min-width: 9px;
  width: 9px;
  min-height: 9px;
  height: 9px;
  margin-top: 2px;
  border-radius: 50%;
  background: ${(props) => getBackground(props.status)};
  margin-right: ${indents.indent2};
`;
