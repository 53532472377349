import React, { useEffect, useState } from "react";
import { WrapperPage } from "./CompanyPage.styled";
import PageLayout from "components/layout/PageLayout/PageLayout";
import { Information } from "./components/Information";
import { Vacancies } from "./components/Vacancies";
import {
  fetchCompanyInfo,
  fetchCompanyManagers,
  fetchCompanyOpeningCv,
} from "../../api/companies/companies";
import { fetchResponses } from "api/responses/responses";
import { fetchVacancies } from "../../api/vacancies/vacancies";
import { useParams } from "react-router-dom";
import { IVacancy } from "api/vacancies/types";
import { ICompany, ICompanyManager, IOpeningCv } from "api/companies/types";
import { IResponse } from "api/responses/types";
import { Managers } from "./components/Managers";
import { OpeningsCv } from "./components/OpeningsCv";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton";
import { modifyResponses } from "./utils";
import { Applicants } from "./components/Applicants";

const CompanyPage = () => {
  const [info, setInfo] = useState<ICompany | null>(null);
  const [loader, setLoader] = useState(true);
  const [managers, setManagers] = useState<ICompanyManager[]>([]);
  const [vacancies, setVacancies] = useState<IVacancy[]>([]);
  const [opening, setOpening] = useState<IOpeningCv[]>([]);
  const [responses, setResponses] = useState<IResponse[]>([]);
  const params = useParams() as { id: string };
  const query = `company_id=${params.id}`;

  useEffect(() => {
    fetchCompany();
    fetchCompanyManagers(params.id).then((res) => setManagers(res.data));
    fetchVacancies(query).then((res) => setVacancies(res.data.vacancies));
    fetchResponses(query).then((res) => {
      setResponses(modifyResponses(res.data.responses));
    });
    fetchCompanyOpeningCv(params.id).then((res) => {
      const sort = res.data.sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
      setOpening(sort);
    });
  }, []);

  const fetchCompany = () => {
    setLoader(true);
    fetchCompanyInfo(params.id)
      .then((res) => {
        setInfo(res.data);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <PageLayout>
      <WrapperPage>
        <GoBackButton text="Back to list" link="/companies" />
        <Information
          info={info}
          loader={loader}
          managers={managers.length}
          fetchCompany={fetchCompany}
        />
        <Managers managers={managers} setManagers={setManagers} />
        <Vacancies vacancies={vacancies} setVacancies={setVacancies} />
        <OpeningsCv opening={opening} />
        <Applicants applicants={responses} />
      </WrapperPage>
    </PageLayout>
  );
};

export { CompanyPage };
