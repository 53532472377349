import React from "react";

import * as Styled from "./Button.styled";
import { Color, IButton, Variant } from "./Button.typed";
import { Link } from "react-router-dom";

/**
 * Компонент кнопки
 */
const Button = ({
  children,
  href,
  variant = Variant.CONTAINED,
  color = Color.PRIMARY,
  ...buttonProps
}: IButton) => {
  return (
    <Styled.Button
      as={href ? Link : "button"}
      variant={variant}
      color={color}
      {...buttonProps}
    >
      {children}
    </Styled.Button>
  );
};

export default Button;
