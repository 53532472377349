// идея в том, что бы описать разрешенные символы и
// впоследствии инвертировать их, чтобы получить запрещенные и отфильтровать
export const RegexPattern = {
  ENGLISH_STRING: /[а-я]/gi,
  NUMBER: /[^0-9]/gi,
  EMAIL_ALLOWED_CHARS: /[^A-Za-z0-9@!#$%&'*+-/=?^_`{|}~.]/gi,
  LETTER: /[^a-z]/gi,
  LETTER_AND_NUMBER: /[^a-z0-9]/gi,
  LETTER_AND_NUMBER_WITH_SPACE: /[^a-z0-9\s]/gi,
  NAME: /[^a-z0-9\s-]/gi,
  DATE: /^([0-9]{4})\/?(1[0-2]|0[1-9])\/?(3[01]|0[1-9]|[12][0-9])$/,
};
