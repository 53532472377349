import React, { useMemo } from "react";
import { Link, WrapperTooltip } from "../../../Support/Support.styled";
import { ICvData } from "api/cvs/types";
import { getRenderStatus } from "utils/getRenderStatusSeaman";
import { getBaseUrl } from "utils/helpers";
import { getTime } from "utils/dateTime";
import { Variant } from "components/ui/Button/Button.typed";
import Button from "components/ui/Button/Button";
import { sendInvitation } from "api/vacancies/vacancies";
import { getApiError } from "utils/helpers";
import { AxiosResponse } from "axios";

export const COLUMNS = [
  {
    Header: "Applied at ↓",
    accessor: "col1",
    minWidth: 110,
    maxWidth: 110,
    width: 110,
  },
  {
    Header: "Seafarer’s Name",
    accessor: "col2",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Contact Details",
    accessor: "col3",
    minWidth: 145,
    maxWidth: 145,
    width: 145,
  },
  {
    Header: "Rank",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Matching %",
    accessor: "col5",
    minWidth: 110,
    maxWidth: 110,
    width: 110,
  },
  {
    Header: "Status",
    accessor: "col6",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Last Login",
    accessor: "col7",
    minWidth: 110,
    maxWidth: 110,
    width: 110,
  },
  {
    Header: "",
    accessor: "col8",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
];

const prepareRowData = (
  cv: ICvData,
  vacId: string,
  setOpenChangeRank: (val: ICvData) => void,
  setOpenChangeStatus: (val: ICvData) => void
) => {
  const appliedAt = getTime(cv.responded_to_vacancy_at, true);
  const lastLogin = getTime(cv.last_activity_at, true);
  const handleClickSeaman = () => {
    window.open(`${getBaseUrl()}/cv/${cv.cv_id}`, "_blank");
  };

  const onChangeRank = () => setOpenChangeRank(cv);
  const onChangeStatus = () => setOpenChangeStatus(cv);

  const handleSendInvitation = async () => {
    try {
      await sendInvitation(vacId, cv.user_id);
      alert(`Send invite to ${cv.first_name} ${cv.last_name}`);
    } catch (e) {
      alert(getApiError(e as AxiosResponse));
    }
  };

  return {
    col1: appliedAt === "-" ? "Didn’t Apply" : appliedAt,
    col2: (
      <Link onClick={handleClickSeaman}>
        {cv.first_name || ""} {cv.middle_name || ""} {cv.last_name || ""}
      </Link>
    ),
    col3: `${cv.phone || "-"}\n${cv.email}`,
    col4: (
      <WrapperTooltip id={`ranks_${cv.cv_id}`}>
        {cv.first_rank || "-"} / {cv.secondary_rank || "-"}
        <Link onClick={onChangeRank}>Change</Link>
      </WrapperTooltip>
    ),
    col5: `${cv.matching_percent_to_vacancy}%`,
    col6: (
      <WrapperTooltip id={`available_${cv.cv_id}`}>
        {getRenderStatus(cv.availability_status, cv.date_of_availability)}
        <Link onClick={onChangeStatus}>Change</Link>
      </WrapperTooltip>
    ),
    col7: lastLogin === "-" ? "Never" : lastLogin,
    col8: (
      <>
        <Button variant={Variant.TEXT} onClick={handleSendInvitation}>
          Send Invitation
        </Button>
      </>
    ),
  };
};

export const useMatchingData = (
  cvs: ICvData[],
  vacId: string,
  setOpenChangeRank: (val: ICvData) => void,
  setOpenChangeStatus: (val: ICvData) => void
) => {
  return useMemo(() => {
    if (!cvs || !cvs.length) return [];

    return cvs.map((item) =>
      prepareRowData(item, vacId, setOpenChangeRank, setOpenChangeStatus)
    );
  }, [cvs]);
};
