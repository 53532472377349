import styled, { css } from "styled-components/macro";
import { Variant } from "./Button.typed";
import { ColorSet } from "./Button.const";

export const Button = styled.button<{
  variant?: Variant;
  color: string;
}>`
  display: inline-block;
  vertical-align: center;
  cursor: pointer;

  background: transparent;
  border: 1px solid transparent;
  color: #ffffff;

  font-size: 14px;
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 4px;
  margin: 0;

  transition: 200ms ease-in-out;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:disabled,
  &:disabled:hover {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${(props) => {
    const colorSet = ColorSet[props.color.toUpperCase()];

    switch (props.variant) {
      case Variant.CONTAINED: {
        return css`
          background: ${colorSet.default};
          border-color: ${colorSet.default};

          &:hover {
            background-color: ${colorSet.hover};
          }

          &:active {
            background: ${colorSet.focus};
            border-color: ${colorSet.focus};
          }
        `;
      }
      case Variant.OUTLINED: {
        return css`
          background: transparent;
          color: ${colorSet.default};
          border-color: ${colorSet.default};

          &:hover {
            background: ${colorSet.default};
            color: #ffffff;
          }

          &:focus {
          }
        `;
      }
      case Variant.TEXT: {
        return css`
          color: ${colorSet.default};

          &:hover {
            background: ${colorSet.pale};
          }

          &:focus {
          }
        `;
      }
    }
  }};
`;
