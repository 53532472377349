import React from "react";

const PasswordIcon = () => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1569 0.32876C14.027 0.332544 13.9038 0.386714 13.8131 0.479801L9.34179 4.95116C9.34018 4.95045 9.33883 4.94926 9.33723 4.94855L4.94856 9.33657C4.94934 9.33835 4.95037 9.34 4.95116 9.34178L1.47981 12.8131C1.43182 12.8592 1.39351 12.9144 1.36712 12.9755C1.34073 13.0365 1.32678 13.1022 1.32611 13.1688C1.32543 13.2353 1.33803 13.3013 1.36318 13.3628C1.38832 13.4244 1.4255 13.4804 1.47254 13.5274C1.51958 13.5745 1.57553 13.6116 1.63712 13.6368C1.69871 13.6619 1.7647 13.6745 1.83122 13.6739C1.89774 13.6732 1.96345 13.6592 2.02452 13.6329C2.08558 13.6065 2.14076 13.5682 2.18684 13.5202L5.50455 10.2025C6.11523 10.8946 7.00632 11.3333 7.99999 11.3333C9.83799 11.3333 11.3333 9.83798 11.3333 7.99998C11.3333 7.00651 10.8945 6.11531 10.2025 5.50454L11.6842 4.02277C13.3015 4.96969 14.5563 6.49893 15.0169 8.2897C15.0736 8.51636 15.2767 8.66665 15.5 8.66665C15.54 8.66665 15.583 8.66306 15.623 8.64972C15.893 8.58306 16.0531 8.31027 15.9831 8.0436C15.4698 6.04273 14.1537 4.35768 12.4258 3.28123L14.5202 1.18683C14.5923 1.11658 14.6416 1.02619 14.6615 0.927487C14.6814 0.828785 14.671 0.726371 14.6318 0.633649C14.5925 0.540926 14.5262 0.462214 14.4414 0.407814C14.3567 0.353414 14.2575 0.325861 14.1569 0.32876ZM7.99673 1.99998C4.2734 1.99998 0.916915 4.54027 0.016915 8.0436C-0.053085 8.31027 0.106941 8.58306 0.376941 8.64972C0.643608 8.71972 0.916394 8.5597 0.983061 8.2897C1.75973 5.27303 4.77673 2.99998 7.99673 2.99998C8.56673 2.99998 9.12983 3.0697 9.67317 3.20636L10.4935 2.3867C9.69681 2.13337 8.85673 1.99998 7.99673 1.99998ZM7.99999 4.66665C6.16199 4.66665 4.66665 6.16198 4.66665 7.99998C4.66665 8.06565 4.67242 8.1303 4.67642 8.1953L8.1953 4.67642C8.1303 4.67242 8.06565 4.66665 7.99999 4.66665ZM8.54426 7.16274C8.81813 7.34112 8.99999 7.64874 8.99999 7.99998C8.99999 8.55232 8.55232 8.99998 7.99999 8.99998C7.64874 8.99998 7.34113 8.81812 7.16275 8.54425L8.54426 7.16274Z"
        fill="#7A869A"
      />
    </svg>
  );
};
export { PasswordIcon };
