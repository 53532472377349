import React from "react";
import * as Styled from "../../Seamen.styled";
import Button from "components/ui/Button/Button";

interface IProps {
  handleReset: () => void;
}

const NotFound: React.FC<IProps> = ({ handleReset }) => {
  return (
    <Styled.NotFoundWrapper>
      <Styled.NotFoundTitle>:-( Oooooops. Not found.</Styled.NotFoundTitle>
      <Styled.NotFoundText>
        We could not find any seafarers fitting your specifications. Try
        changing the filters or reset them
      </Styled.NotFoundText>
      <Button onClick={handleReset}>Reset filters</Button>
    </Styled.NotFoundWrapper>
  );
};

export { NotFound };
