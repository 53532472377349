import styled, { css } from "styled-components/macro";
import { Input as TextInput } from "components/ui/TextField/TextField.styled";
import { Wrapper as ErrorComponent } from "components/ErrorMessage/ErrorMessage";
import { Color } from "styles/color";
import { indents } from "styles/indents";

interface IInputWrapper {
  value: string | number;
  disabled: boolean;
  error: boolean;
}

export const Container = styled.div`
  &:focus-within {
    ${ErrorComponent} {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Label = styled.label`
  position: absolute;
  left: 12px;
  top: 6px;
  z-index: 100;
  font-size: 14px;
  line-height: 20px;
  background: transparent;
  color: ${Color.DARK900};
`;

export const InputWrapper = styled.div<IInputWrapper>`
  position: relative;
  max-width: 164px;
  width: 100%;

  ${(props) =>
    props.value &&
    css`
      ${Label} {
        color: ${Color.BLUE500};
      }
    `};

  ${(props) =>
    (props.disabled || props.error) &&
    css`
      ${Label} {
        color: ${Color.MID80};
      }
    `};

  &:first-child {
    margin-right: ${indents.indent15};
    ${TextInput} {
      padding-left: 50px;
    }
  }
  &:last-child {
    ${TextInput} {
      padding-left: 32px;
    }
  }
`;
