import styled, { css } from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "../../styles/color";

export const Wrapper = styled.div`
  padding: 4px 8px;
  margin-bottom: ${indents.indent6};
  background-color: ${Color.LIGHT30};
  display: flex;
  gap: 4px;
  border-radius: 8px;
  width: fit-content;
`;

export const Name = styled.span`
  font-family: Lato, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.DARK900};
  padding-right: 6px;
`;

export const Count = styled.div`
  padding: 4px 7px;
  border-radius: 4px;
  background-color: ${Color.BLUE300};
  color: ${Color.LIGHT10};
`;

export const Item = styled.div<{ isActive: boolean }>`
  min-width: 127px;
  min-height: 39px;
  padding: 6px 16px;
  background: ${Color.LIGHT00};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${(props) =>
    !props.isActive &&
    css`
      background: transparent;
      ${Name} {
        color: ${Color.MID80};
      }

      ${Count} {
        background-color: ${Color.MID80};
      }
    `}
`;
