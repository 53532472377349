import styled, { css } from "styled-components/macro";

export const Wrapper = styled.div<{ backgroundImage?: string }>`
  padding-top: 4px;
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  ${(props) => css`
    background-image: url("${props.backgroundImage}");
  `};
`;

export const Main = styled.main`
  flex-grow: 1;
  width: 100%;
  margin: 24px auto 0;
  max-width: 1376px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
`;
