import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Color } from "../../../pages/Dashboard/Dashboard.const";
import * as Dashboard from "../../../pages/Dashboard/Dashboard.styled";
import Loader from "../../utils/Loader/Loader";
import * as Styled from "./SeamenCard.styled";
import { fetchSeamenCount } from "../../../api/stats/stats";
import { IStatsSeamenCount } from "../../../api/stats/types";

const SeamenCard = () => {
  const [stats, setStats] = useState<IStatsSeamenCount | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res = await fetchSeamenCount();
      setStats(res.data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const titleJsx = (
    <Dashboard.Title white>
      <Dashboard.TitleText>Total seamen: </Dashboard.TitleText>
      {loading ? <Loader color="#fff" inline /> : stats?.all}
    </Dashboard.Title>
  );

  return (
    <Link to="/seamen">
      <Styled.SeamenCard hover>
        {titleJsx}
        <Styled.SeamenContent>
          <Dashboard.StatusesList>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.GREY} />
              <Dashboard.Text white>
                Registered: {stats?.registered ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.BLUE} />
              <Dashboard.Text white>
                Signed in: {stats?.signed_in ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.GREEN} />
              <Dashboard.Text white>
                Created CV: {stats?.created_cvs ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.YELLOW} />
              <Dashboard.Text white>
                Banned: {stats?.banned ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.RED} />
              <Dashboard.Text white>
                Archive: {stats?.archived ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
          </Dashboard.StatusesList>
          <Dashboard.StatusesList>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.GREEN} />
              <Dashboard.Text white>
                Seamen: {stats?.seaman ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.YELLOW} />
              <Dashboard.Text white>
                Going to be: {stats?.going_to_be_seaman ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon color={Color.RED} />
              <Dashboard.Text white>
                Not seaman: {stats?.not_seaman ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
            <Dashboard.StatusItem>
              <Dashboard.LiIcon />
              <Dashboard.Text white>
                No status: {stats?.no_status ?? "..."}
              </Dashboard.Text>
            </Dashboard.StatusItem>
          </Dashboard.StatusesList>
        </Styled.SeamenContent>
      </Styled.SeamenCard>
    </Link>
  );
};

export default SeamenCard;
