import styled from "styled-components/macro";
import {
  Wrapper as Date,
  CalendarIconStyled,
} from "components/ui/DatePicker/DatePicker.styled";
import {
  Container as TextContainer,
  Input as TextInput,
} from "components/ui/TextField/TextField.styled";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const Wrapper = styled.div`
  display: flex;
  & > span {
    font-size: 14px;
    line-height: 20px;
    color: ${Color.MID300};
    padding: 6px ${indents.indent4} 0 ${indents.indent4};
  }

  ${Date} {
    width: 300px;
    ${TextContainer},
    ${TextInput} {
      max-width: 300px;
      width: 100%;
    }

    ${TextInput} {
      text-align: center;
    }
  }

  ${CalendarIconStyled} {
    display: none;
  }
`;
