import { ICountries, IDC } from "api/dc/types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchDc } from "api/dc/dc";
import { RootState } from "../store";

export interface IInitialStateDC {
  vaccines: IDC;
  ranks: IDC;
  shipTypes: IDC;
  languages: IDC;
  langLevels: IDC;
  clothesSizes: IDC;
  certificates: IDC;
  medicalCertificates: IDC;
  meTypes: IDC;
  countries: ICountries;
  endorsement: IDC;
  coc: IDC;
  cop: IDC;
  certificatesType: IDC;
  fuelTypes: IDC;
}

const initialState: IInitialStateDC = {
  vaccines: [],
  ranks: [],
  shipTypes: [],
  languages: [],
  langLevels: [],
  clothesSizes: [],
  certificates: [],
  medicalCertificates: [],
  meTypes: [],
  countries: [],
  endorsement: [],
  coc: [],
  cop: [],
  certificatesType: [],
  fuelTypes: [],
};

export const getVaccines = createAsyncThunk<IDC>("dc/vaccines", () =>
  fetchDc.vaccines().then(({ data }) => data)
);

export const getCountries = createAsyncThunk<ICountries>("dc/countries", () =>
  fetchDc.countries().then(({ data }) => data)
);

export const getLangLevels = createAsyncThunk<IDC>("dc/lang-levels", () =>
  fetchDc.langLevels().then(({ data }) => data)
);

export const getLanguages = createAsyncThunk<IDC>("dc/languages", () =>
  fetchDc.languages().then(({ data }) => data)
);

export const getCertificates = createAsyncThunk<IDC>("dc/certificates", () =>
  fetchDc.certificates().then(({ data }) => data)
);

export const getMedicalCertificates = createAsyncThunk<IDC>(
  "dc/medical-certificates",
  () => fetchDc.medicalCertificates().then(({ data }) => data)
);

export const getShipTypes = createAsyncThunk<IDC>("dc/ship-types", () =>
  fetchDc.shipTypes().then(({ data }) => data)
);

export const getRanks = createAsyncThunk<IDC>("dc/ranks", () =>
  fetchDc.ranks().then(({ data }) => data)
);

export const getMETypes = createAsyncThunk<IDC>("dc/me-types", () =>
  fetchDc.meTypes().then(({ data }) => data)
);

export const getFuelTypes = createAsyncThunk<IDC>("dc/fuel-type", () =>
  fetchDc.fuelType().then(({ data }) => data)
);

export const getEndorsement = createAsyncThunk<IDC>("dc/endorsement", () =>
  fetchDc.endorsement().then(({ data }) => data)
);

export const getCoc = createAsyncThunk<IDC>("dc/coc", () =>
  fetchDc.coc().then(({ data }) => data)
);

export const getCop = createAsyncThunk<IDC>("dc/cop", () =>
  fetchDc.cop().then(({ data }) => data)
);

export const dictionaries = createSlice({
  name: "dictionaries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload;
    });
    builder.addCase(getLangLevels.fulfilled, (state, action) => {
      state.langLevels = action.payload;
    });
    builder.addCase(getVaccines.fulfilled, (state, action) => {
      state.vaccines = action.payload;
    });
    builder.addCase(getLanguages.fulfilled, (state, action) => {
      state.languages = action.payload;
    });
    builder.addCase(getCertificates.fulfilled, (state, action) => {
      state.certificates = action.payload;
    });
    builder.addCase(getMedicalCertificates.fulfilled, (state, action) => {
      state.medicalCertificates = action.payload;
    });
    builder.addCase(getShipTypes.fulfilled, (state, action) => {
      state.shipTypes = action.payload;
    });
    builder.addCase(getRanks.fulfilled, (state, action) => {
      state.ranks = action.payload;
    });
    builder.addCase(getMETypes.fulfilled, (state, action) => {
      state.meTypes = action.payload;
    });
    builder.addCase(getFuelTypes.fulfilled, (state, action) => {
      state.fuelTypes = action.payload;
    });
    builder.addCase(getEndorsement.fulfilled, (state, action) => {
      state.endorsement = action.payload;
    });
    builder.addCase(getCoc.fulfilled, (state, action) => {
      state.coc = action.payload;
    });
    builder.addCase(getCop.fulfilled, (state, action) => {
      state.cop = action.payload;
    });
  },
});

export const selectDC = (state: RootState) => {
  return state.dc;
};

const { reducer } = dictionaries;

export default reducer;
