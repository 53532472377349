import React, { useEffect, useState } from "react";
import * as Style from "./MatchingVacancy.styled";
import PageLayout from "components/layout/PageLayout/PageLayout";
import MatchingSeamen, {
  FiltersMatching,
} from "./components/MatchinSeamen/MatchingSeamen";
import { GoBackButton } from "components/GoBackButton/GoBackButton";
import { useLocation, useParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { getVacancy } from "api/vacancies/vacancies";
import { IVacancy } from "api/vacancies/types";
import { getBaseUrl } from "utils/helpers";

const MatchingVacancy = () => {
  const params = useParams() as { vacId: string };
  const location = useLocation();
  const baseUrl = getBaseUrl();

  const urlSearchParams = new URLSearchParams(location.search);
  const query = Object.fromEntries(urlSearchParams.entries());
  const [vacancy, setVacancy] = useState<IVacancy>();

  useEffect(() => {
    getDataVacancy(params.vacId);
  }, []);

  const getDataVacancy = async (id: string) => {
    try {
      const res = await getVacancy(id);
      setVacancy(res.data);
    } catch (e) {
      const error = e as AxiosResponse;
      alert(error.data.err);
    }
  };

  const handleClickVacancy = () => {
    window.open(`${baseUrl}/vacancy/${vacancy?.vac_id}`, "_blank");
  };

  const handleClickCompany = () => {
    window.open(
      `https://cp.krew.team/company/${vacancy?.company_id}`,
      "_blank"
    );
  };

  return (
    <PageLayout>
      <GoBackButton text="Back to Vacancies Matching Results" link="/support" />
      <Style.Title>
        Matching results for the vacancy:{" "}
        <Style.Links onClick={handleClickVacancy}>{vacancy?.title}</Style.Links>{" "}
        <Style.Links onClick={handleClickCompany}>
          ({vacancy?.company_title})
        </Style.Links>
      </Style.Title>
      <div style={{ marginTop: "16px" }}>
        <MatchingSeamen
          vacId={params.vacId}
          defaultFilter={query.filter as keyof typeof FiltersMatching}
        />
      </div>
    </PageLayout>
  );
};

export default MatchingVacancy;
