export const COLUMNS = [
  {
    Header: "Company name",
    accessor: "col1",
    minWidth: 156,
    maxWidth: 156,
    width: 156,
  },
  {
    Header: "Phone number\nE-mail",
    accessor: "col2",
    minWidth: 171,
    maxWidth: 171,
    width: 171,
  },
  {
    Header: "Location",
    accessor: "col3",
    minWidth: 144,
    maxWidth: 144,
    width: 144,
  },
  {
    Header: "Working\narea",
    accessor: "col4",
    minWidth: 90,
    maxWidth: 90,
    width: 90,
  },
  {
    Header: "Verification",
    accessor: "col5",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
  },
  {
    Header: "Managers",
    accessor: "col6",
    minWidth: 95,
    maxWidth: 95,
    width: 95,
  },
  {
    Header: "Vacancies\npublished",
    accessor: "col7",
    minWidth: 95,
    maxWidth: 95,
    width: 95,
  },
  {
    Header: "Date of last \nentry",
    accessor: "col8",
  },
  {
    Header: "",
    accessor: "col9",
    minWidth: 230,
    maxWidth: 230,
    width: 230,
  },
];
