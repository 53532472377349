import React, { useMemo } from "react";
import { IVacancy } from "api/vacancies/types";
import { Link, Subtext } from "../../Support.styled";
import { getTime } from "utils/dateTime";
import { getBaseAdminUrl } from "utils/helpers";

export const COLUMNS = [
  {
    Header: "Created at \n/ DOB ↓",
    accessor: "col1",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
  },
  {
    Header: "Vacancy name",
    accessor: "col2",
    minWidth: 170,
    maxWidth: 170,
    width: 170,
  },
  {
    Header: "Company name",
    accessor: "col3",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Candidates",
    accessor: "col4",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "90-100%",
    accessor: "col5",
    minWidth: 90,
    maxWidth: 90,
    width: 90,
  },
  {
    Header: "50-89%",
    accessor: "col6",
    minWidth: 90,
    maxWidth: 90,
    width: 90,
  },
  {
    Header: "10-49%",
    accessor: "col7",
    minWidth: 90,
    maxWidth: 90,
    width: 90,
  },
  {
    Header: "Total\nviews",
    accessor: "col8",
    minWidth: 90,
    maxWidth: 90,
    width: 90,
  },
  {
    Header: "Views by\nCandidates",
    accessor: "col9",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Total\nresponses",
    accessor: "col10",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Moderated\nresponses",
    accessor: "col11",
    minWidth: 150,
    maxWidth: 200,
    width: 150,
  },
];

const prepareRowData = (vacancy: IVacancy) => {
  const matchingUrl = `${getBaseAdminUrl()}/support/matching/${vacancy.vac_id}`;

  const handleClickPerfectMatching = () => {
    window.open(`${matchingUrl}?filter=high`, "_blank");
  };

  const handleClickDecentMatching = () => {
    window.open(`${matchingUrl}?filter=middle`, "_blank");
  };

  const handleClickNiceMatching = () => {
    window.open(`${matchingUrl}?filter=low`, "_blank");
  };

  const handleClickVacancy = () => {
    window.open(`${matchingUrl}`, "_blank");
  };

  const handleClickCompany = () => {
    window.open(`${getBaseAdminUrl()}/company/${vacancy.company_id}`, "_blank");
  };

  return {
    col1: (
      <>
        {getTime(vacancy.created_at, true)}
        <br />
        <Subtext>{getTime(vacancy.date_of_boarding, true)}</Subtext>
      </>
    ),
    col2: <Link onClick={handleClickVacancy}>{vacancy.title}</Link>,
    col3: <Link onClick={handleClickCompany}>{vacancy.company_title}</Link>,
    col4: (
      <Link onClick={handleClickVacancy}>{vacancy.matching_seafarers}</Link>
    ),
    col5: (
      <>
        <Link onClick={handleClickPerfectMatching}>
          {vacancy.total_perfect_match}
        </Link>
        {vacancy.total_perfect_match ? (
          <Subtext>({vacancy.perfect_match_percent}%)</Subtext>
        ) : null}
      </>
    ),
    col6: (
      <>
        <Link onClick={handleClickDecentMatching}>
          {vacancy.total_decent_match}
        </Link>
        {vacancy.decent_match_percent ? (
          <Subtext>({vacancy.decent_match_percent}%)</Subtext>
        ) : null}
      </>
    ),
    col7: (
      <>
        <Link onClick={handleClickNiceMatching}>
          {vacancy.total_nice_match}
        </Link>
        {vacancy.decent_match_percent ? (
          <Subtext>({vacancy.nice_match_percent}%)</Subtext>
        ) : null}
      </>
    ),
    col8: vacancy.seaman_views,
    col9: (
      <>
        {vacancy.matched_views}
        {vacancy.matched_views ? (
          <Subtext>({vacancy.matched_views_percent}%)</Subtext>
        ) : null}
      </>
    ),
    col10: vacancy.total_responses,
    col11: (
      <>
        {vacancy.moderated_responses}
        {vacancy.moderated_responses ? (
          <Subtext>({vacancy.moderated_responses_percent}%)</Subtext>
        ) : null}
      </>
    ),
  };
};

export const useVacanciesData = (vacancies: IVacancy[]) => {
  return useMemo(() => {
    if (!vacancies || !vacancies.length) return [];

    return vacancies.map((vacancy) => prepareRowData(vacancy));
  }, [vacancies]);
};
