export enum StatusEmail {
  pending = "pending",
  canceled = "canceled",
  sent = "sent",
}

export interface IEmail {
  email_id: string;
  user_id: string;
  email: string;
  body_html: string;
  body_txt: string;
  subject: string;
  status: StatusEmail;
  comment: string;
  category: string;
  send_at: string;
  expired_at: string;
  created_at: string;
  updated_at: string;
}
