import { IVacancy } from "api/vacancies/types";
import Button from "components/ui/Button/Button";
import { Color, Variant } from "components/ui/Button/Button.typed";
import { Color as Colors } from "styles/color";
import React, { useMemo } from "react";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { convertUTCDateToLocalDate } from "../../../utils/dateTime";

export const COLUMNS = [
  {
    Header: "Vacancy name",
    accessor: "col1",
    minWidth: 220,
    maxWidth: 220,
    width: 220,
  },
  {
    Header: "Date of Publication",
    accessor: "col2",
    minWidth: 170,
    maxWidth: 170,
    width: 170,
  },
  {
    Header: "Views",
    accessor: "col3",
    minWidth: 60,
    maxWidth: 60,
    width: 60,
  },
  {
    Header: "Invited\n(Matching)",
    accessor: "col4",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Unlogin\nviews",
    accessor: "col5",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Applicants",
    accessor: "col6",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Suitable seaman",
    accessor: "col7",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Status",
    accessor: "col8",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "",
    accessor: "col9",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

const RowControls = ({
  vacancy,
  handleDelete,
}: {
  vacancy: IVacancy;
  handleDelete: (vacId: string) => void;
}) => {
  const history = useHistory();
  const params = useParams() as { id: string };
  const handleEdit = () => {
    history.push(`/company/${params.id}/edit_vacancy/${vacancy.vac_id}`);
  };

  const handleArchive = () => {
    handleDelete(vacancy.vac_id);
  };

  return (
    <>
      <Button variant={Variant.TEXT} onClick={handleEdit}>
        Edit
      </Button>
      {vacancy.status !== "archived" && (
        <Button
          color={Color.SECONDARY}
          variant={Variant.TEXT}
          onClick={handleArchive}
        >
          Archive
        </Button>
      )}
    </>
  );
};

const getStatus = (status: string) => {
  if (status === "published")
    return <span style={{ color: Colors.GREEN300 }}>Published</span>;
  if (status === "draft")
    return <span style={{ color: Colors.MID400 }}>Draft</span>;
  return <span style={{ color: Colors.RED400 }}>Archived</span>;
};

const prepareRowData = (
  vacancy: IVacancy,
  handleDelete: (vacId: string) => void
) => {
  const TimeUTC = convertUTCDateToLocalDate(vacancy.published_at);

  const publishTime = vacancy.published_at
    ? `${dayjs(TimeUTC).format("HH:mm")}\n${dayjs(TimeUTC).format(
        "YYYY / MM / D"
      )}`
    : "-";

  return {
    col1: vacancy.title,
    col2: publishTime,
    col3: vacancy.all_views,
    col4: `${vacancy.matched_views_percent}%`,
    col5: vacancy.unauthorized_views,
    col6: vacancy.total_responses,
    col7: "Скоро",
    col8: getStatus(vacancy.status),
    col9: <RowControls vacancy={vacancy} handleDelete={handleDelete} />,
  };
};

export const useVacanciesData = (
  vacancies: IVacancy[],
  handleDelete: (vacId: string) => void
) => {
  return useMemo(() => {
    if (!vacancies || !vacancies.length) return [];

    return vacancies.map((item) => prepareRowData(item, handleDelete));
  }, [vacancies]);
};
