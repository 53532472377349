import React, { SyntheticEvent, useState } from "react";
import ReactPhoneInput from "react-phone-input-2";
import isEmpty from "lodash/fp/isEmpty";
import "react-phone-input-2/lib/style.css";

import {
  GlobalPhoneInputStyled,
  ContainerStyled,
  InputStyled,
  InputFilledStyled,
  ButtonStyled,
  DropdownStyled,
  SearchStyled,
  Wrapper,
} from "./PhoneInput.styled";
import { ErrorMessageText } from "../../ErrorMessage/ErrorMessage";

type Props = {
  name?: string;
  value?: string;
  country?: string;
  errors?: string | boolean;
  disabled?: boolean;
  autofocus?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (event: SyntheticEvent) => void;
  onEnterKeyPress?: () => void;
};

export const PhoneInput = ({
  value = "",
  country = "",
  errors,
  disabled,
  autofocus: autoFocus,
  onChange,
  onBlur,
  onEnterKeyPress,
}: Props): JSX.Element => {
  const [focused, setFocused] = useState(autoFocus);

  const handleFocus = () => {
    setFocused(true);
  };

  const handleBlur = (event: SyntheticEvent) => {
    setFocused(false);
    onBlur && onBlur(event);
  };

  const filled = !!(value && isEmpty(errors));
  const inputClass = filled
    ? InputFilledStyled.styledComponentId
    : InputStyled.styledComponentId;

  return (
    <Wrapper>
      <GlobalPhoneInputStyled />
      <ReactPhoneInput
        country={country}
        disabled={disabled}
        inputProps={{ autoFocus }}
        value={value}
        enableSearch
        disableSearchIcon
        searchPlaceholder="Choose country"
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        isValid={() => isEmpty(errors)}
        containerClass={ContainerStyled.styledComponentId}
        inputClass={inputClass}
        buttonClass={ButtonStyled.styledComponentId}
        dropdownClass={DropdownStyled.styledComponentId}
        searchClass={SearchStyled.styledComponentId}
        onEnterKeyPress={onEnterKeyPress}
      />
      {!focused && errors && typeof errors === "string" && (
        <ErrorMessageText text={errors} />
      )}
    </Wrapper>
  );
};
