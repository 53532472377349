import { api } from "../axiosConfig";
import { saveAs } from "file-saver";
import { ICvData, IUpdateCv } from "./types";

interface IModerateCV {
  cv_id: string;
  moderation_status: string;
}

export const fetchCvs = (query: string, signal?: AbortSignal) => {
  const config = {} as { signal: AbortSignal };
  if (signal) config.signal = signal;
  return api.get<{ cvs: ICvData[]; total: number }>(`/cvs?${query}`, config);
};

export const downloadCV = async (cvId: string) => {
  const response = await api.get(`/cv/${cvId}/pdf?download=1`, {
    responseType: "blob",
  });
  const blob = new Blob([response.data]);
  saveAs(blob, `CV_${cvId}.pdf`);
};

export const changeCv = (id: string, payload: IUpdateCv) =>
  api.patch(`/cvs/${id}/support`, payload);

export const moderateCv = (payload: IModerateCV) =>
  api.patch(`/cvs/moderation`, payload);

export const fetchMatchingCvs = (query: string, signal: AbortSignal) =>
  api.get<{
    cvs: ICvData[];
    total: number;
  }>(`/matching/cvs?${query}`, { signal: signal });

export const fetchMatchingStats = (id: string, signal: AbortSignal) =>
  api.get<{
    total_match_results: number;
    total_perfect: number;
    total_decent: number;
    total_nice: number;
  }>(`/matching/stats/${id}`, { signal: signal });

export const getCvById = (id: string) => api.get<ICvData>(`cvs/${id}`);
