import { api } from "../axiosConfig";
import { IResponse } from "api/responses/types";

export const fetchResponses = (query: string) => {
  return api.get<{
    total: number;
    total_declined: number;
    total_pending: number;
    total_validated: number;
    responses: IResponse[];
  }>(`/responses?${query}`);
};

export const validationResponses = (id: string, validate: boolean) => {
  return api.patch(`/responses/${id}/moderation`, {
    response_validated: validate,
  });
};

export const createResponse = (vacId: string, cvId: string) => {
  return api.post("/responses", { vac_id: vacId, cv_id: cvId });
};
