import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Wrapper = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.DARK900};
  border-collapse: collapse;
  margin-bottom: ${indents.indent8};

  td {
    vertical-align: top;
    padding: 0 12px;
  }

  span {
    color: ${Color.LIGHT50};
  }
`;
export const TableHead = styled.thead`
  color: ${Color.MID200};
  background-color: ${Color.LIGHT10};

  td {
    height: 72px;
    padding: 12px;
  }
`;

export const TableBody = styled.tbody`
  tr {
    :hover {
      background-color: ${Color.LIGHT10};
    }
  }

  td {
    padding: ${indents.indent6} 12px;
  }
`;

export const BlueText = styled.span`
  color: ${Color.BLUE300};
`;
