import React, { useEffect, useMemo, useState } from "react";
import { count } from "../../utils/helpers";
import * as Styled from "./PaginationControls.styled";
import { useIsMount } from "../../hooks/useIsMount";
import { countPaginationRange } from "./PaginationControl.utils";

interface IPaginationControls {
  totalElements: number;
  elementsOnPage?: number;
  changePage: (val: number) => void;
}

export const PAGINATION_SIDE = 10; // для расчета кол-ва кнопок пагинации

const PaginationControls = ({
  totalElements,
  changePage,
  elementsOnPage = 10,
}: IPaginationControls) => {
  const [currentPage, setCurrentPage] = useState(1);

  const isMount = useIsMount();

  useEffect(() => {
    if (isMount) {
      changePage(currentPage - 1);
    }
  }, [currentPage]);

  const totalPages = useMemo(() => {
    return Math.ceil(totalElements / elementsOnPage);
  }, [totalElements, elementsOnPage]);

  const onPrevButtonClick = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const onNextButtonClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const onPageNumberClick = (val: number) => {
    setCurrentPage(val);
  };

  return (
    <Styled.PageControls>
      <Styled.PrevButton
        onClick={onPrevButtonClick}
        disabled={currentPage === 1}
      >
        Prev
      </Styled.PrevButton>
      <Styled.NextButton
        onClick={onNextButtonClick}
        disabled={currentPage === totalPages}
      >
        Next
      </Styled.NextButton>
      <Styled.PaginationContainer>
        {count(totalPages, 1)
          .slice(...countPaginationRange(currentPage, totalPages))
          .map((item) => (
            <Styled.PaginationButton
              key={`pagination-btn-${item}`}
              active={item === currentPage}
              onClick={() => onPageNumberClick(item)}
            >
              {item}
            </Styled.PaginationButton>
          ))}
        {currentPage + PAGINATION_SIDE < totalPages && (
          <>
            <Styled.Dot>...</Styled.Dot>
            <Styled.PaginationButton
              active={totalPages === currentPage}
              onClick={() => onPageNumberClick(totalPages)}
            >
              {totalPages}
            </Styled.PaginationButton>
          </>
        )}
      </Styled.PaginationContainer>
    </Styled.PageControls>
  );
};

export default PaginationControls;
