import React, { useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import * as Styled from "../Filters.styled";
import { Accordion } from "../Accordion/Accordion";
import { Select } from "components/ui/Select/Select";
import { makeOption, makeOptionCountries } from "components/ui/Select/utils";
import { ICountries } from "api//dc/types";
import { DateWithMonth } from "../DateWithMonths/DateWithMonth";
import { findItemAndUnshiftCountries } from "utils/changeArray";
import { Names } from "../Filter.utils";
import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  selectDC,
  getCertificates,
  getMedicalCertificates,
  getEndorsement,
} from "services/slices/dictionaries";

interface IProps {
  control: Control;
  coc: Array<string>;
  countries: ICountries;
  changePopupContainer: (name: string) => void;
}

const Documents = React.memo(
  ({ control, countries, coc, changePopupContainer }: IProps) => {
    const dispatch = useAppDispatch();
    const { certificates, medicalCertificates, endorsement } =
      useAppSelector(selectDC);

    useEffect(() => {
      if (!certificates?.length) dispatch(getCertificates());
      if (!medicalCertificates?.length) dispatch(getMedicalCertificates());
      if (!endorsement?.length) dispatch(getEndorsement());
    }, []);

    const onChangeField = (name: string) => {
      changePopupContainer(name);
    };

    return (
      <>
        <Styled.Subtitle isPaddingTop>Documents</Styled.Subtitle>
        <div className="FilterWrapper" id={Names.visas}>
          <Accordion title="Visas" defaultOpened>
            <Controller
              name={Names.visas}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={[
                    {
                      label: "Schengen",
                      value: "Schengen",
                    },
                    ...findItemAndUnshiftCountries(
                      countries,
                      "United States of America"
                    ).map(makeOptionCountries),
                  ]}
                  placeholder="Choose visas"
                  name={name}
                  maxValuesLength={5}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.certificates}>
          <Accordion title="Certificates" defaultOpened>
            <Controller
              name={Names.certificates}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={certificates.map(makeOption)}
                  placeholder="Choose certificates"
                  name={name}
                  maxValuesLength={10}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.medical_certificates}>
          <Accordion title="Medical Certificates" defaultOpened>
            <Controller
              name={Names.medical_certificates}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={medicalCertificates.map(makeOption)}
                  placeholder="Choose medical certificates"
                  name={name}
                  maxValuesLength={10}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.seaman_documents}>
          <Accordion title="Seaman’s Document">
            <Controller
              name={Names.seaman_documents}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <DateWithMonth
                  name={name}
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.travel_passport}>
          <Accordion title="Travel Passport">
            <Controller
              name={Names.travel_passport}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <DateWithMonth
                  name={name}
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.endorsements}>
          <Accordion title="Endorsements" defaultOpened>
            <Controller
              name={Names.endorsements}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={endorsement.map(makeOption)}
                  placeholder="Choose endorsements"
                  name={name}
                  maxValuesLength={5}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.endorsements_competency}>
          <Accordion title="Endorsement Competency" defaultOpened>
            <Controller
              name={Names.endorsements_competency}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={coc.map(makeOption)}
                  placeholder="Choose endorsements"
                  name={name}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.endorsements_issued}>
          <Accordion title="Endorsement Issued by" defaultOpened>
            <Controller
              name={Names.endorsements_issued}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={countries.map(makeOptionCountries)}
                  placeholder="Choose country"
                  name={name}
                />
              )}
            />
          </Accordion>
        </div>
      </>
    );
  }
);

export { Documents };
