import React, { useMemo } from "react";
import { WrapperTooltip, Link } from "../../Support.styled";
import { ICvData } from "api/cvs/types";
import { getRenderStatus } from "utils/getRenderStatusSeaman";
import { getBaseAdminUrl } from "utils/helpers";
import { getTime } from "utils/dateTime";
import { Variant } from "components/ui/Button/Button.typed";
import Button from "components/ui/Button/Button";
import { TooltipType } from "./Seafarers";

export const COLUMNS = [
  {
    Header: "Login ↓",
    accessor: "col1",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Seafarer’s Name",
    accessor: "col2",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
  },
  {
    Header: "Contact Details",
    accessor: "col3",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
  },
  {
    Header: "Rank",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Availability Status",
    accessor: "col5",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "",
    accessor: "col6",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

const prepareRowData = (
  cv: ICvData,
  setOpenNotifyId: (val: string) => void,
  openTooltip: (type: TooltipType, cv: ICvData) => void
) => {
  const handleClickSeaman = () => {
    window.open(`${getBaseAdminUrl()}/cv/${cv.cv_id}`, "_blank");
  };

  const onChangeRanks = () => {
    openTooltip(TooltipType.ranks, cv);
  };

  const onChangeStatus = () => {
    openTooltip(TooltipType.statusAvailable, cv);
  };

  return {
    col1: getTime(cv.last_activity_at, true),
    col2: (
      <Link onClick={handleClickSeaman}>
        {cv.first_name || ""} {cv.middle_name || ""} {cv.last_name || ""}
      </Link>
    ),
    col3: `${cv.phone || "-"}\n${cv.email}`,
    col4: (
      <WrapperTooltip id={`ranks_${cv.cv_id}`}>
        <span>
          {cv.first_rank || "-"} / {cv.secondary_rank || "-"}
        </span>
        <Link onClick={onChangeRanks}>Change</Link>
      </WrapperTooltip>
    ),
    col5: (
      <WrapperTooltip id={`available_${cv.cv_id}`}>
        {getRenderStatus(cv.availability_status, cv.date_of_availability)}
        <Link onClick={onChangeStatus}>Change</Link>
      </WrapperTooltip>
    ),
    col6: (
      <>
        <Button
          variant={Variant.TEXT}
          onClick={() => setOpenNotifyId(cv.user_id)}
        >
          Edit
        </Button>
      </>
    ),
  };
};

export const useSeafarers = (
  cvs: ICvData[],
  setOpenNotifyId: (val: string) => void,
  openTooltip: (type: TooltipType, cv: ICvData) => void
) => {
  return useMemo(() => {
    if (!cvs || !cvs.length) return [];

    return cvs.map((item) =>
      prepareRowData(item, setOpenNotifyId, openTooltip)
    );
  }, [cvs]);
};
