import React, { useMemo } from "react";
import { Name, Rank } from "./GeneralInfo.styled";
import { joinArray } from "utils/helpers";
import { ICvData } from "api/cvs/types";

interface IProps {
  cvData: ICvData | undefined;
}

const RankAndName: React.FC<IProps> = ({ cvData }) => {
  const getName = useMemo(() => {
    let name = "";
    if (cvData?.first_name) name += `${cvData?.first_name}`;
    if (cvData?.middle_name) name += ` ${cvData?.middle_name}`;
    if (cvData?.last_name) name += ` ${cvData?.last_name}`;
    return name.trim();
  }, [cvData]);

  return (
    <>
      <Name>{getName}</Name>
      <Rank>{joinArray([cvData?.first_rank, cvData?.secondary_rank])}</Rank>
    </>
  );
};

export { RankAndName };
