import React, { useEffect, useState } from "react";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import { getEmails } from "../../api/emails/emails";
import { COLUMNS, getSearchString, useEmailsData } from "./Emails.utils";
import Table from "../../components/Table/Table";
import { IEmail } from "api/emails/types";
import { Filters } from "./conponents/Filters";
import Loader from "../../components/utils/Loader/Loader";
import PaginationControls from "../../components/PaginationControls/PaginationControls";

const Emails = () => {
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    getApiData();
  }, [currentPage, filters]);

  const getApiData = async () => {
    setLoading(true);
    const query = getSearchString(filters, currentPage);
    const data = await getEmails(query);
    setEmails(data.data.emails);
    setTotal(data.data.total);
    setLoading(false);
  };

  const changeFilters = (values: Record<string, string | Array<string>>) => {
    setFilters(values);
  };

  const changePage = (val: number) => {
    setCurrentPage(val);
  };

  const data = useEmailsData(emails);

  return (
    <PageLayout>
      <Filters getApiData={changeFilters} />
      {loading ? <Loader /> : <Table columns={COLUMNS} data={data} />}
      <PaginationControls totalElements={total} changePage={changePage} />
    </PageLayout>
  );
};

export { Emails };
