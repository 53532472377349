import { useCallback, useEffect, useRef, useState } from "react";

const ANIMATION_DURATION = 300; // мс

export const useEscKeydownHandler = (onClose: () => void) => {
  useEffect(() => {
    const handleEscKeydown = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") {
        onClose();
      }
      return null;
    };

    document.addEventListener("keydown", handleEscKeydown);
    return () => document.removeEventListener("keydown", handleEscKeydown);
  }, [onClose]);
};

export const useModalSetup = (open: boolean, onClose: () => void) => {
  useEscKeydownHandler(onClose);

  // флаг, блокирующий вызов handleClose() при первом рендере
  const [hasInteraction, setHasInteraction] = useState(false);
  // флаг, позволяющий убивать дом, когда попап закрыт
  const [shouldDestroyDom, setShouldDestroyDom] = useState(true);
  // надстройка над open, чтобы срабатывала анимация
  const [actuallyOpen, setActuallyOpen] = useState(open);

  const timerId = useRef<NodeJS.Timeout | null>(null);

  const handleOpen = useCallback(() => {
    if (!hasInteraction) setHasInteraction(true);
    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    // рендерим дом
    setShouldDestroyDom(false);
    // подставляем флаг на следующий тик, чтобы сработала анимация
    setTimeout(() => setActuallyOpen(true));

    document.body.style.overflow = "hidden";
  }, [hasInteraction]);

  const handleClose = useCallback(() => {
    // если попап изначально закрыт, прерываем
    if (!hasInteraction) return;
    setActuallyOpen(false);

    timerId.current = setTimeout(() => {
      setShouldDestroyDom(true);
      document.body.style.overflow = "initial";
    }, ANIMATION_DURATION);
  }, [hasInteraction]);

  useEffect(() => {
    open ? handleOpen() : handleClose();
  }, [open, handleClose, handleOpen]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = "initial";
    };
  }, []);

  return { actuallyOpen, shouldDestroyDom };
};
