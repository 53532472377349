import styled, { createGlobalStyle } from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const ContainerStyled = styled.div``;

export const InputStyled = styled.div``;
export const InputFilledStyled = styled.div``;

export const ButtonStyled = styled.div``;

export const DropdownStyled = styled.div``;

export const SearchStyled = styled.div``;

export const Wrapper = styled.div``;

/**
 * Workaround for using styled-components with third-party phone input
 */
export const GlobalPhoneInputStyled = createGlobalStyle`
    ${Wrapper} {
        ${ContainerStyled} {
            max-width: 340px;

            ${InputStyled}:hover {
                border-color: ${Color.BLUE100};
            }
        }

        ${InputStyled},
        ${InputFilledStyled} {
            width: 100%;
            height: ${indents.indent7};
            border: 1px solid ${Color.LIGHT40};
            border-radius: ${indents.indent2};

            font-family: Lato, sans-serif;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: ${Color.DARK800};

            background-color: ${Color.LIGHT10};

            ::placeholder {
                color: ${Color.MID300};
            }

            :hover:not(:focus) {
                border-color: ${Color.BLUE100};
            }

            :disabled,
            :disabled:hover {
                border-color: ${Color.MID60};
                color: ${Color.LIGHT50};
            }

            :focus {
                border-color: ${Color.BLUE300} !important;
                background: ${Color.LIGHT00};
                box-shadow: 0px 0px 4px rgba(0, 101, 255, 0.24), inset 0px 0px 4px rgba(23, 43, 77, 0.5);
            }

            &.invalid-number,
            &.invalid-number:focus {
                border-color: ${Color.RED400};
                background-color: ${Color.LIGHT00};
            }
        }

        ${InputFilledStyled} {
            :not(:focus) {
                color: ${Color.BLUE500};
                border: 1px solid ${Color.BLUE300};
                background: ${Color.BLUE50};
            }

            :disabled,
            :disabled:hover {
                color: ${Color.MID80};
                border-color: ${Color.MID60};
                background-color: ${Color.LIGHT20};
                opacity: 1;
                -webkit-text-fill-color: ${Color.MID80};
            }
        }

        ${ButtonStyled} {
            border: none;
            background: none;

            &.open {
                background: none;
            }

            .selected-flag,
            .selected-flag.open {
                background: none;
            }
        }

        ${DropdownStyled} {
            border-radius: ${indents.indent2};

            .country {
                padding: 12px 20px;

                font-family: Lato, sans-serif;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: ${Color.DARK800};
            }

            .country.highlight,
            .country:hover {
                background-color: ${Color.BLUE50};
            }

            .no-entries-message {
                font-family: Lato, sans-serif;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: ${Color.DARK800};
            }
        }

        ${SearchStyled} {
            &.search {
                padding: ${indents.indent5};
            }

            &-box.search-box {
                height: ${indents.indent7};
                width: 268px;
                margin: 0;
                padding: ${indents.indent3} 12px;

                font-family: Lato, sans-serif;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                color: ${Color.DARK800};
            }
        }
    }
`;
