import styled, { css } from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";
import { Position } from "./components/NameAndRank/NameAndRank.styled";

export const MainWrapper = styled.div`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: ${Color.DARK900};
  margin-bottom: ${indents.indent5};
  text-decoration: none;
  width: 100%;
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  cursor: default;
  display: block;
  position: relative;

  &:visited ${Position} {
    color: ${Color.PURPLE300};
  }
`;

const getColorBorder = (fillStep: number) => {
  if (fillStep === 3) return Color.GREEN300;
  if (fillStep === 2) return Color.BLUE300;
  return "none";
};

export const Wrapper = styled.div<{ fill_step: number }>`
  width: 100%;
  display: flex;
  background-color: ${Color.LIGHT00};
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  border-radius: 8px;
  border: 1px solid ${(props) => getColorBorder(props.fill_step)};
  cursor: pointer;
  padding: ${indents.indent6} ${indents.indent6} ${indents.indent6} 0;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const PersonalInfo = styled.div`
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

export const InfoTitle = styled.div`
  font-weight: bold;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: ${Color.MID400};
  margin-bottom: ${indents.indent2};
  font-family: inherit;
`;
