import React, { useEffect, useMemo, useState } from "react";
import { EmptyData } from "components/EmptyData/EmptyData";
import Filter from "components/Filter/Filter";
import { ICvData } from "api/cvs/types";
import Loader from "components/utils/Loader/Loader";
import Table from "components/Table/Table";
import PaginationControls from "components/PaginationControls/PaginationControls";
import { fetchMatchingCvs, fetchMatchingStats } from "api/cvs/cvs";
import { useMatchingData, COLUMNS } from "./MatchingSeamen.utils";
import { NotificationModal } from "modals/Notification/Notification";
import { UpdateStatusAvailable } from "components/TooltipEditCv/UpdateStatusAvailable/UpdateStatusAvailable";
import { UpdateRanks } from "components/TooltipEditCv/UpdateRanks/UpdateRanks";

interface IProps {
  vacId: string;
  defaultFilter?: keyof typeof FiltersMatching;
}

export enum FiltersMatching {
  all = "All",
  high = "90-100% match",
  middle = "50-89% match",
  low = "10-49% match",
}

interface IItemsFilter {
  name: FiltersMatching;
  amount: number;
}

const defaultTabs = [
  { name: FiltersMatching.all, amount: 0 },
  { name: FiltersMatching.high, amount: 0 },
  { name: FiltersMatching.middle, amount: 0 },
  { name: FiltersMatching.low, amount: 0 },
];

const MatchingSeamen = ({ vacId, defaultFilter }: IProps) => {
  const [loading, setLoading] = useState(true);
  const [seamen, setSeamen] = useState<ICvData[]>([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [itemsFilter, setItemsFilter] = useState<IItemsFilter[]>(defaultTabs);
  const [filter, setFilter] = useState(
    defaultFilter ? FiltersMatching[defaultFilter] : FiltersMatching.all
  );

  const [openNotifyId, setOpenNotifyId] = useState<string | null>(null);
  const [openChangeRank, setOpenChangeRank] = useState<ICvData | null>(null);
  const [openChangeStatus, setOpenChangeStatus] = useState<ICvData | null>(
    null
  );
  useEffect(() => {
    const controller = new AbortController();
    getSeamen(controller);
    return () => {
      controller.abort();
    };
  }, [currentPage, filter]);

  const getSeamen = async (controller: AbortController) => {
    try {
      setLoading(true);
      const data = await fetchMatchingCvs(getQuery(), controller.signal);
      const stats = await fetchMatchingStats(vacId, controller.signal);
      const { cvs } = data.data;
      setSeamen(cvs);
      getTotal(stats.data);
      setLoading(false);
    } catch (e) {
      if (e) setLoading(false);
    }
  };

  const handleChangeFilter = (val: FiltersMatching) => {
    setFilter(val);
    setCurrentPage(0);
  };

  const getTotal = async (stats: {
    total_match_results: number;
    total_perfect: number;
    total_decent: number;
    total_nice: number;
  }) => {
    if (filter === FiltersMatching.all) setTotal(stats.total_match_results);
    if (filter === FiltersMatching.high) setTotal(stats.total_perfect);
    if (filter === FiltersMatching.middle) setTotal(stats.total_decent);
    if (filter === FiltersMatching.low) setTotal(stats.total_nice);
    setItemsFilter([
      { name: FiltersMatching.all, amount: stats.total_match_results },
      { name: FiltersMatching.high, amount: stats.total_perfect },
      { name: FiltersMatching.middle, amount: stats.total_decent },
      { name: FiltersMatching.low, amount: stats.total_nice },
    ]);
  };

  const getQuery = (): string => {
    let query = `limit=${10}&skip=${currentPage * 10}&vac_id=${vacId}`;
    if (filter === FiltersMatching.all) query += "&all_matched_cvs=true";
    if (filter === FiltersMatching.high) query += "&match_category=perfect";
    if (filter === FiltersMatching.middle) query += "&match_category=decent";
    if (filter === FiltersMatching.low) query += "&match_category=nice";
    return query;
  };

  const updateCv = (cv: ICvData) => {
    const arr = seamen.map((item) => {
      if (item.cv_id === cv.cv_id) {
        return cv;
      }
      return item;
    });
    setSeamen(arr);
  };

  const displayColumns = useMemo(() => {
    if (vacId) return COLUMNS;
    return COLUMNS.filter((item) => item.accessor !== "col5");
  }, [COLUMNS, vacId]);

  const data = useMatchingData(
    seamen,
    vacId,
    setOpenChangeRank,
    setOpenChangeStatus
  );

  const getRenderContent = () => {
    if (!loading && !seamen.length) {
      return <EmptyData />;
    }
    return (
      <>
        {loading ? <Loader /> : <Table columns={displayColumns} data={data} />}
        <PaginationControls totalElements={total} changePage={setCurrentPage} />
      </>
    );
  };

  return (
    <>
      <Filter
        items={itemsFilter}
        active={filter}
        setFilter={handleChangeFilter}
      />
      {getRenderContent()}
      <NotificationModal
        isOpen={!!openNotifyId}
        onClose={() => setOpenNotifyId(null)}
        userId={openNotifyId}
      />
      {openChangeRank && (
        <UpdateRanks
          cv={openChangeRank}
          onClose={() => setOpenChangeRank(null)}
          updateCv={updateCv}
        />
      )}
      {openChangeStatus && (
        <UpdateStatusAvailable
          cv={openChangeStatus}
          onClose={() => setOpenChangeStatus(null)}
          updateCv={updateCv}
        />
      )}
    </>
  );
};

export default MatchingSeamen;
