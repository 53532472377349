import React, { useEffect, useState } from "react";
import * as Styled from "./Filters.styled";
import { makeOption } from "components/ui/Select/utils";
import { Select, TSelectProps } from "components/ui/Select/Select";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { getRanks, selectDC } from "services/slices/dictionaries";
import { fetchCompanies } from "api/companies/companies";
import { fetchVacancies } from "api/vacancies/vacancies";
import { getBaseUrl } from "utils/helpers";

interface IProps {
  changeQuery: (val: Record<string, string>) => void;
}

interface IOption {
  label: string;
  value: string;
}

const Filters = ({ changeQuery }: IProps) => {
  const dispatch = useAppDispatch();
  const { ranks } = useAppSelector(selectDC);
  const [vacancies, setVacancies] = useState([] as IOption[]);
  const [companies, setCompanies] = useState([] as IOption[]);

  const [filter, setFilter] = useState({ rank: "", vacancy: "" });
  const [company, setCompany] = useState("");
  const [isNotVacancies, setIsNotVacancies] = useState(false);

  const handleChangeRank = (val: string) => {
    setFilter({ ...filter, rank: val });
    changeQuery({ ...filter, rank: val });
  };

  const handleChangeVacancy = (val: string) => {
    setFilter({ ...filter, vacancy: val });
    changeQuery({ ...filter, vacancy: val });
  };

  const handleChangeCompany = (val: string) => {
    setCompany(val);
    setFilter({ ...filter, vacancy: "" });
    setVacancies([]);
  };

  useEffect(() => {
    (async () => {
      if (!ranks.length) dispatch(getRanks());
      try {
        const res = await fetchCompanies();
        const options = res.data.map((item) => {
          return { label: item.title || "Not title", value: item.company_id };
        });
        setCompanies(options);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (company) {
          setIsNotVacancies(false);
          const res = await fetchVacancies(`company_id=${company}`);
          if (!res.data.vacancies.length) {
            setIsNotVacancies(true);
            return;
          }
          const options = res.data.vacancies.map((item) => {
            return { label: item.title || "Not title", value: item.vac_id };
          });
          setVacancies(options);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [company]);

  const handleClickVacancy = () => {
    window.open(`${getBaseUrl()}/vacancy/${filter.vacancy}`, "_blank");
  };

  return (
    <>
      <Styled.Wrapper>
        <Styled.Text>Option 1. Filter by vacancy:</Styled.Text>
        <Select
          isDisabled={!!filter.rank}
          width={300}
          value={
            companies.find((item) => item.value === company)
              ?.label as TSelectProps["value"]
          }
          onChange={(val) => handleChangeCompany(val as string)}
          options={companies}
          placeholder="1. Choose company"
          name="company"
        />
        {isNotVacancies && <span>Vacancies not found</span>}
        {vacancies.length ? (
          <Select
            width={300}
            value={
              vacancies.find((item) => item.value === filter.vacancy)
                ?.label as TSelectProps["value"]
            }
            onChange={(val) => handleChangeVacancy(val as string)}
            options={vacancies}
            placeholder="2. Choose vacancy"
            name="vacancy"
          />
        ) : null}
        {filter.vacancy && (
          <Styled.Link onClick={handleClickVacancy}>Open vacancy</Styled.Link>
        )}
      </Styled.Wrapper>
      <Styled.Wrapper>
        <Styled.Text>Option 2. Filter by rank:</Styled.Text>
        <Select
          isDisabled={!!company}
          width={300}
          value={filter.rank as TSelectProps["value"]}
          onChange={(val) => handleChangeRank(val as string)}
          options={ranks.map(makeOption)}
          placeholder="Filter by rank"
          name="rank"
        />
      </Styled.Wrapper>
    </>
  );
};

export { Filters };
