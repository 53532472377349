import React from "react";

const PasswordShowIcon = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99553 0C4.22232 0 0.897707 2.61531 0.0157082 6.04232C-0.0172712 6.17078 0.00213239 6.30709 0.0696504 6.42124C0.137168 6.5354 0.24727 6.61806 0.375734 6.65104C0.504198 6.68402 0.640502 6.66462 0.75466 6.5971C0.868818 6.52958 0.951479 6.41948 0.984458 6.29102C1.74446 3.33803 4.68873 1 7.99553 1C11.3023 1 14.2559 3.33885 15.0157 6.29102C15.0487 6.41948 15.1313 6.52958 15.2455 6.5971C15.3597 6.66462 15.496 6.68402 15.6244 6.65104C15.7529 6.61806 15.863 6.5354 15.9305 6.42124C15.998 6.30709 16.0174 6.17078 15.9845 6.04232C15.1022 2.61448 11.7687 0 7.99553 0ZM8.00073 2.66667C6.22738 2.66667 4.77938 4.11467 4.77938 5.88802C4.77938 7.66138 6.22738 9.11003 8.00073 9.11003C9.77409 9.11003 11.2227 7.66138 11.2227 5.88802C11.2227 4.11467 9.77409 2.66667 8.00073 2.66667Z"
        fill="black"
      />
    </svg>
  );
};
export { PasswordShowIcon };
