import { Wrapper as AccordionWrapper } from "./Accordion/Accordion.styled";
import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${indents.indent5} 30px;
  background-color: ${Color.LIGHT00};
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  border-radius: 8px;
  margin-right: 20px;

  ${AccordionWrapper} {
    margin-bottom: 17px;
  }

  .FilterWrapper {
    position: relative;
  }
`;

export const Subtitle = styled.h3<{ isPaddingTop?: boolean }>`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-bottom: ${indents.indent5};
  padding-top: ${(props) => (props.isPaddingTop ? "8px" : "0")};
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK900};

  &:first-child {
    margin-top: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Popup = styled.div<{ isReset: boolean }>`
  position: absolute;
  z-index: 4;
  left: calc(100% + 46px);
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid
    ${(props) => (props.isReset ? Color.YELLOW300 : Color.BLUE300)};
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background-color: ${Color.LIGHT00};
  border-radius: 4px;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 16px;
    background-color: ${(props) =>
      props.isReset ? Color.YELLOW300 : Color.BLUE300};
  }

  &::before {
    height: 1px;
    left: -16px;
    transform: translateY(-50%);
  }

  &::after {
    height: 16px;
    left: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;

export const PopupInfo = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  white-space: nowrap;
  margin-right: 8px;
`;

export const Highlght = styled.span<{ isReset: boolean }>`
  color: ${(props) => (props.isReset ? Color.YELLOW300 : Color.BLUE300)};
`;
