import React from "react";

import * as Styled from "./Checkbox.styled";
import * as Typed from "./Checkbox.typed";

class Checkbox extends React.PureComponent<Typed.IProps> {
  public render(): JSX.Element {
    const {
      checked = false,
      children,
      disabled = false,
      name,
      onChange,
      onFocus,
    } = this.props;

    return (
      <Styled.Wrapper>
        <Styled.NativeInput
          checked={checked}
          disabled={disabled}
          name={name}
          onChange={onChange}
          onFocus={onFocus}
        />

        <Styled.Element />

        {children && <Styled.Text>{children}</Styled.Text>}
      </Styled.Wrapper>
    );
  }
}

export { Checkbox };
