import styled from "styled-components/macro";

export const Title = styled.h1`
  margin-top: 50px;
  text-align: center;
  font-weight: 700;
`;

export const BackToMain = styled.p`
  margin-top: 20px;
  text-align: center;
  color: #0065ff;
`;
