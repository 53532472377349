import React, { useState } from "react";
import PageLayout from "components/layout/PageLayout/PageLayout";
import Filter from "components/Filter/Filter";
import Seafarers from "./components/Seafarers/Seafarers";
import Vacancies from "./components/Vacancies/Vacancies";

export enum FiltersSupport {
  Vacancies = "Vacancies",
  Seafarers = "Seafarers",
}

interface IItemsFilter {
  name: FiltersSupport;
  amount: number;
}

const defaultTabs: IItemsFilter[] = [
  {
    name: FiltersSupport.Vacancies,
    amount: 0,
  },
  {
    name: FiltersSupport.Seafarers,
    amount: 0,
  },
];

const Support: React.FC = () => {
  const [activeTab, setActiveTab] = useState(FiltersSupport.Vacancies);

  return (
    <PageLayout>
      <Filter setFilter={setActiveTab} items={defaultTabs} active={activeTab} />
      {activeTab === FiltersSupport.Vacancies && <Vacancies />}
      {activeTab === FiltersSupport.Seafarers && <Seafarers />}
    </PageLayout>
  );
};

export default Support;
