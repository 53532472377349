import React, { useMemo } from "react";
import * as Styled from "./WorkExperience.styled";
import { formatDate } from "utils/dateTime";
import { WorkExperience as TWorkExperience } from "api/cvs/types";
import ReactCountryFlag from "react-country-flag";
import { ICountries } from "api/dc/types";
import { useHistory } from "react-router-dom";
import Button from "components/ui/Button/Button";
import { Variant } from "components/ui/Button/Button.typed";

interface IProps {
  noExperience: boolean | undefined;
  total: string | undefined;
  workExperience: TWorkExperience[];
  id: string;
  countries: ICountries;
}

const WorkExperience: React.FC<IProps> = ({
  noExperience,
  total,
  workExperience = [],
  id,
  countries,
}) => {
  const history = useHistory();

  const totalExperience = useMemo(() => {
    if (noExperience) return "0";
    if (total) return total;
    return "no information";
  }, [total, noExperience]);

  const openCv = () => {
    history.push(`/cv/${id}`);
  };

  const buttonLable = `Show all ${workExperience.length} experience records`;

  return (
    <Styled.Experience onClick={(e) => e.preventDefault()}>
      <Styled.ExperienceTitle>
        Experience:
        <Styled.ExperienceTotal>
          Total – {totalExperience}
        </Styled.ExperienceTotal>
      </Styled.ExperienceTitle>
      {noExperience ? (
        <Styled.ExperienceNone>
          Respondent has no work experiences
        </Styled.ExperienceNone>
      ) : workExperience?.length > 0 ? (
        <Styled.ExperienceOuterWrapper>
          {workExperience.slice(0, 2).map((experience, index) => (
            <Styled.ExperienceDetails key={index}>
              <Styled.ExperiencePosition>
                {experience.rank ?? "—"}
              </Styled.ExperiencePosition>
              <div>
                <Styled.ExperienceInnerWrapper>
                  <div>
                    {experience.sign_on_date
                      ? formatDate(experience.sign_on_date)
                      : "—"}{" "}
                    –{" "}
                    {experience.sign_off_date
                      ? formatDate(experience.sign_off_date)
                      : "Still working"}
                  </div>
                  <div>{experience.duration_human}</div>
                </Styled.ExperienceInnerWrapper>
                <Styled.ExperienceInnerWrapper>
                  <Styled.DarkText>
                    {experience.ship_type ? (
                      <>
                        {experience.ship_flag && (
                          <ReactCountryFlag
                            countryCode={
                              countries.find(
                                (item) =>
                                  item.Name === experience.ship_flag ||
                                  item.Name.includes(experience.ship_flag)
                              )?.Code || ""
                            }
                            svg
                            style={{
                              width: "20px",
                              marginRight: "4px",
                              position: "relative",
                              top: "-2px",
                            }}
                            title={experience.ship_flag}
                          />
                        )}
                        {experience.ship_type}
                      </>
                    ) : (
                      "—"
                    )}
                  </Styled.DarkText>
                  <div>
                    DWT{" "}
                    <Styled.DarkText>
                      {experience.ship_dwt ?? "—"}
                    </Styled.DarkText>
                  </div>
                  <div>
                    GRT{" "}
                    <Styled.DarkText>
                      {experience.ship_grt ?? "—"}
                    </Styled.DarkText>
                  </div>
                </Styled.ExperienceInnerWrapper>
              </div>
            </Styled.ExperienceDetails>
          ))}
          {workExperience.length > 2 && (
            <Button variant={Variant.OUTLINED} onClick={openCv}>
              {buttonLable}
            </Button>
          )}
        </Styled.ExperienceOuterWrapper>
      ) : (
        <Styled.ExperienceNone>
          The respondent did not indicate work experience
        </Styled.ExperienceNone>
      )}
    </Styled.Experience>
  );
};

export { WorkExperience };
