import React, { useMemo } from "react";
import * as Styled from "./Companies.styled";
import dayjs from "dayjs";
import { ICompany } from "api/companies/types";
import { useHistory } from "react-router-dom";
import Button from "../../components/ui/Button/Button";
import { Color as Colors } from "../../styles/color";
import { Color, Variant } from "../../components/ui/Button/Button.typed";

const RowControls = ({
  company,
  handleVerify,
  handleDelete,
}: {
  company: ICompany;
  handleVerify: (id: string) => void;
  handleDelete: (id: string) => void;
}) => {
  const history = useHistory();

  const verifyClick = () => {
    handleVerify(company.company_id);
  };

  const deleteClick = () => {
    handleDelete(company.company_id);
  };

  const handleEdit = () => {
    history.push(`/company/edit/${company.company_id}`);
  };

  return (
    <Styled.ButtonsWrapper>
      <Button variant={Variant.TEXT} onClick={handleEdit}>
        Edit
      </Button>
      {!company.is_verified && (
        <Button
          variant={Variant.TEXT}
          color={Color.GREEN}
          onClick={verifyClick}
        >
          Verify
        </Button>
      )}
      <Button
        variant={Variant.TEXT}
        color={Color.SECONDARY}
        onClick={deleteClick}
      >
        Archive
      </Button>
    </Styled.ButtonsWrapper>
  );
};

const prepareRowData = (
  company: ICompany,
  handleVerify: (id: string) => void,
  handleDelete: (id: string) => void
) => {
  return {
    col1: (
      <Styled.Link to={`company/${company.company_id}`}>
        {company.title || `<no title>`}
      </Styled.Link>
    ),
    col2: `${company.phone || "–"}\n${company.email || "–"}`,
    col3: company.office_location,
    col4: "–",
    col5: company.is_verified ? (
      <Styled.Text color={Colors.GREEN300}>Verified</Styled.Text>
    ) : (
      <Styled.Text color={Colors.RED300}>Not Verified</Styled.Text>
    ),
    col6: company.total_managers,
    col7: company.total_vacancies,
    col8: company.last_activity
      ? dayjs(company.last_activity).format("YYYY/MM/DD")
      : "-",
    col9: (
      <RowControls
        company={company}
        handleVerify={handleVerify}
        handleDelete={handleDelete}
      />
    ),
  };
};

export const useCompaniesData = (
  companies: ICompany[],
  handleVerify: (id: string) => void,
  handleDelete: (id: string) => void
) => {
  return useMemo(() => {
    if (!companies || !companies.length) return [];

    return companies.map((company) => {
      return prepareRowData(company, handleVerify, handleDelete);
    });
  }, [companies]);
};
