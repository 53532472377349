import { api } from "../axiosConfig";
import { IDC } from "./types";
import { ICountries } from "./types";

export const fetchDc = {
  vaccines: () => api.get<IDC>("/dc/vaccines"),
  ranks: () => api.get<IDC>("/dc/ranks"),
  shipTypes: () => api.get<IDC>("/dc/ship-types"),
  languages: () => api.get<IDC>("/dc/langs"),
  langLevels: () => api.get<IDC>("/dc/lang-levels"),
  clothesSizes: () => api.get<IDC>("/dc/clothes_sizes"),
  certificates: () => api.get<IDC>("/dc/certificates"),
  medicalCertificates: () => api.get<IDC>("/dc/medical-certificates"),
  meTypes: () => api.get<IDC>("/dc/me-types"),
  workExperience: () => api.get<IDC>("dc/work_experience"),
  countries: () => api.get<ICountries>("dc/countries"),
  endorsement: () => api.get<IDC>("dc/endorsement"),
  coc: () => api.get<IDC>("dc/coc"),
  cop: () => api.get<IDC>("dc/cop"),
  fuelType: () => api.get<IDC>("dc/fuel-type"),
};
