import React, { useEffect, useState } from "react";
import { IProps } from "./AddManagersCompany";
import { AddManagersCompany } from "./AddManagersCompany";
import { useParams } from "react-router-dom";
import { getManagerInformation } from "../../api/companies/companies";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import Loader from "../../components/utils/Loader/Loader";

const EditManager = () => {
  const params = useParams() as { id: string; userId: string };
  const [manager, setManager] = useState<IProps["defaultValues"] | null>(null);

  useEffect(() => {
    (async () => {
      const res = await getManagerInformation(params.id, params.userId);
      setManager({
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        email: res.data.email,
        phone: res.data.phone,
      });
    })();
  }, []);

  if (!manager) {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    );
  }

  return <AddManagersCompany id={params.userId} defaultValues={manager} />;
};

export { EditManager };
