import React, { useEffect, useState } from "react";
import { AddVacancy } from "./AddVacancy";
import PageLayout from "components/layout/PageLayout/PageLayout";
import Loader from "components/utils/Loader/Loader";
import { IInitialValues, initialValues } from "./forms/forms.const";
import { useParams } from "react-router-dom";
import { IVacancy } from "api/vacancies/types";
import { getVacancy } from "../../api/vacancies/vacancies";

const adaptDataToClient = (backendData: IVacancy): IInitialValues => {
  const {
    config_info,
    general_info,
    work_experience,
    personal_information,
    documents,
  } = initialValues;
  return {
    config_info: {
      is_synt: config_info.is_synt,
    },
    general_info: {
      rank: backendData.rank || general_info.rank,
      ship_type: backendData.ship_type || general_info.ship_type,
      ship_dwt: backendData.ship_dwt || general_info.ship_dwt,
      net_salary: {
        from: backendData.net_salary_from || general_info.net_salary.from,
        to: backendData.net_salary_to || general_info.net_salary.to,
      },
      contract_duration: backendData.contract_duration
        ? `${backendData.contract_duration} months`
        : general_info.contract_duration,
      has_dates_interval:
        backendData.has_dates_interval || general_info.has_dates_interval,
      date_of_boarding:
        backendData.date_of_boarding || general_info.date_of_boarding,
      crew_on_board: backendData.crew_on_board || general_info.crew_on_board,
      private_info: backendData.private_info || general_info.private_info,
    },
    personal_information: {
      seaman_citizenship:
        backendData.seaman_citizenship ||
        personal_information.seaman_citizenship,
      any_citizenship_allowed:
        backendData.any_citizenship_allowed ||
        personal_information.any_citizenship_allowed,
      english_level:
        backendData.english_level || personal_information.english_level,
      age_limit: backendData.age_limit || personal_information.age_limit,
    },
    work_experience: {
      total_contracts:
        backendData.total_contracts || work_experience.total_contracts,
      contracts_rank:
        backendData.contracts_rank || work_experience.contracts_rank,
      contracts_ships:
        backendData.contracts_ships || work_experience.contracts_ships,
      ship_types: backendData.ship_types || work_experience.ship_types,
      dwt: {
        from: backendData.dwt_from || work_experience.dwt.from,
        to: backendData.dwt_to || work_experience.dwt.to,
      },
      kw: {
        from: backendData.kw_from || work_experience.kw.from,
        to: backendData.kw_to || work_experience.kw.to,
      },
      fuel_types: backendData.fuel_types || work_experience.fuel_types,
      ship_me_types: backendData.ship_me_types || work_experience.ship_me_types,
      no_experience_allowed:
        backendData.no_experience_allowed ||
        work_experience.no_experience_allowed,
    },
    documents: {
      visas: backendData.visas || documents.visas,
      certificates: backendData.certificates || documents.certificates,
      medical_certificates:
        backendData.medical_certificates || documents.medical_certificates,
      seaman_document: backendData.seaman_document || documents.seaman_document,
      travel_passport: backendData.travel_passport || documents.travel_passport,
      competency: backendData.competency || documents.competency,
      proficiency: backendData.proficiency || documents.proficiency,
      endorsement: backendData.endorsement || documents.endorsement,
      endorsement_competency:
        backendData.endorsement_competency || documents.endorsement_competency,
      endorsement_country:
        backendData.endorsement_country || documents.endorsement_country,
    },
  };
};

const EditVacancy = () => {
  const params = useParams() as { id: string; vac_id: string };
  const vacancyId = params.vac_id;

  const [data, setData] = useState<IInitialValues | null>(null);
  const [status, setStatus] = useState("");
  const [titleCompany, setTitleCompany] = useState("");

  useEffect(() => {
    (async () => {
      if (vacancyId) {
        const res = await getVacancy(vacancyId);
        setData(adaptDataToClient(res.data));
        setTitleCompany(res.data.company_title);
        setStatus(res.data.status);
      }
    })();
  }, [vacancyId]);

  if (!data) {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    );
  }

  return (
    <AddVacancy
      defaultValues={data}
      vacancyId={vacancyId}
      status={status}
      titleCompany={titleCompany}
    />
  );
};

export { EditVacancy };
