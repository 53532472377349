import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const CVPageLayout = styled.div`
  width: 100%;
  max-width: calc(1440px - 128px);
`;

export const ContentWrapper = styled.div`
  margin-top: ${indents.indent5};
  margin-bottom: ${indents.indent12};
  display: grid;
  grid-template-columns: 205px 1fr;
  grid-template-rows: auto 1fr;
  gap: ${indents.indent5};
`;

export const Profile = styled.div`
  position: sticky;
  top: ${indents.indent7};
  align-self: start;
  grid-column: 1 / 2;
  grid-row: 1 / 3;
`;

export const Rest = styled.div`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Color.LIGHT20};
  margin: ${indents.indent7} 0 ${indents.indent7};
`;

export const GeneralRemarks = styled.p`
  font-size: 14px;
  line-height: 24px;
  word-wrap: break-word;
`;

export const StubText = styled.div`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: ${indents.indent5};
  line-height: ${indents.indent6};
  color: ${Color.MID400};
`;

export const Date = styled.span`
  color: ${Color.BLUE200};
`;
