import React from "react";
import * as Styled from "./Delete.styled";
import Button from "../../components/ui/Button/Button";
import {
  Color as ButtonColor,
  Variant,
} from "../../components/ui/Button/Button.typed";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";
import Modal from "../../components/ui/Modal";
import { IProps } from "./Delete.types";
import { useClick } from "./Delete.utils";

const DeleteModal: React.FC<IProps> = ({
  open,
  onClose,
  type,
  payload,
  callback,
}) => {
  const { loading, error, name, handleClick } = useClick(
    type,
    payload,
    callback,
    onClose
  );

  return (
    <Modal
      title={"Delete"}
      open={open}
      style={{ width: "100%", maxWidth: 575 }}
      onClose={onClose}
    >
      <Styled.Text>
        Are you sure you want to archive{" "}
        <Styled.Highlight>{name}</Styled.Highlight>?
      </Styled.Text>
      <Button
        style={{ fontWeight: "bold", padding: "9px 36px", marginRight: 16 }}
        onClick={handleClick}
        disabled={loading}
      >
        Yes
      </Button>
      <Button
        style={{ padding: "9px 36px" }}
        variant={Variant.OUTLINED}
        color={ButtonColor.SECONDARY}
        onClick={onClose}
        disabled={loading}
      >
        No
      </Button>
      {error && <ErrorMessageText text="Something went wrong" />}
    </Modal>
  );
};

export { DeleteModal };
