import { Color as ColorPalette } from "../../../styles/color";
import { TColorSet } from "./Button.typed";

export const ColorSet: { [key: string]: TColorSet } = {
  PRIMARY: {
    default: ColorPalette.BLUE300,
    hover: ColorPalette.BLUE200,
    focus: ColorPalette.BLUE500,
    pale: ColorPalette.BLUE50,
  },
  SECONDARY: {
    default: ColorPalette.RED400,
    hover: ColorPalette.RED300,
    focus: ColorPalette.RED500,
    pale: ColorPalette.RED50,
  },
  YELLOW: {
    default: ColorPalette.YELLOW400,
    hover: ColorPalette.YELLOW300,
    focus: ColorPalette.YELLOW500,
    pale: ColorPalette.YELLOW50,
  },
  GREEN: {
    default: ColorPalette.GREEN300,
    hover: ColorPalette.GREEN200,
    focus: ColorPalette.GREEN400,
    pale: ColorPalette.GREEN50,
  },
};
