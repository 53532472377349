import styled from "styled-components/macro";
import { Wrapper as LoaderStyled } from "components/utils/Loader/Loader";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1376px;
`;

export const Aside = styled.aside`
  width: 320px;
  min-width: 320px;
  margin-right: 20px;
`;

export const Main = styled.main`
  flex: 1;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  & + ${LoaderStyled.Wrapper} {
    margin: 24px 0;
  }
`;
