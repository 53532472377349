import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const Wrapper = styled.div`
  max-width: 540px;
  background: ${Color.BLUE50};
  border-radius: 10px;
  border: 1px solid ${Color.BLUE50};
  box-sizing: border-box;
  margin-bottom: ${indents.indent6};
  position: relative;
  padding: ${indents.indent5} ${indents.indent6} 0 ${indents.indent6};
  animation: null;
  cursor: default;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.h3`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-right: ${indents.indent5};
`;

export const Percent = styled.span`
  font-family: Montserrat, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${Color.GREEN300};
`;

export const Fields = styled.div`
  font-size: 14px;
  line-height: 24px;
`;

export const Content = styled.div`
  padding-top: ${indents.indent16};
`;
