import React from "react";
import { Bio, Gender, Separate } from "./GeneralInfo.styled";
import dayjs from "dayjs";
import { ICvData } from "api/cvs/types";

interface IProps {
  cvData: ICvData | undefined;
}

const GenderAndBirthday: React.FC<IProps> = ({ cvData }) => {
  return (
    <Bio>
      <Gender>{cvData?.gender || ""}</Gender>{" "}
      {cvData?.gender && cvData?.birthday && <Separate>|</Separate>}{" "}
      {cvData?.birthday
        ? `${dayjs().diff(dayjs(cvData?.birthday), "year")} years`
        : ""}
    </Bio>
  );
};

export { GenderAndBirthday };
