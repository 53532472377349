import styled from "styled-components/macro";
import { Color } from "../../styles/color";

export const Links = styled.span`
  color: ${Color.BLUE300};
  cursor: pointer;
`;

export const ButtonsRow = styled.div`
  display: flex;
`;
