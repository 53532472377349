import { ICreateUser, IUpdateUser } from "./types";
import { api } from "../axiosConfig";

export const createUser = (userData: ICreateUser) =>
  api.post("/users", userData);

export const editUser = (id: string, userData: IUpdateUser) =>
  api.put(`/users/${id}`, userData);

export const generateAuthToken = (id: string) => {
  return api.get<{ token: string }>(`/users/${id}/auth-token`);
};

export const getOnlineUsers = () => {
  return api.get<{ total_users_online: number }>(`/users/online/total`);
};
