import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as Styled from "./AdditionalFilters.styled";
import Button from "components/ui/Button/Button";
import { Color } from "components/ui/Button/Button.typed";
import { indents } from "styles/indents";
import { Controller, useForm } from "react-hook-form";
import { FieldRow } from "components/FieldRow";
import TextField from "components/ui/TextField";
import { Checkbox } from "components/ui/Checkbox";
import { TFormattedParams } from "../Filters.typed";
import { getFormattedParams } from "../../../utils";

interface IProps {
  handleAdditionalFilterSubmit: (val: TFormattedParams) => void;
}

const AdditionalFilters = forwardRef((props: IProps, ref) => {
  const { handleAdditionalFilterSubmit } = props;
  const [isEmptyForm, setIsEmptyForm] = useState(true);
  const { handleSubmit, control, reset, watch } = useForm();
  const watchFields = watch();

  useEffect(() => {
    const isEmpty = !Object.values(watchFields).some((val) => val);
    setIsEmptyForm(isEmpty);
  }, [watchFields]);

  useImperativeHandle(ref, () => ({
    onReset() {
      resetFilters();
    },
  }));

  const submitHandler = (data: TFormattedParams) => {
    handleAdditionalFilterSubmit(getFormattedParams(data));
  };

  const resetFilters = () => {
    reset();
    handleAdditionalFilterSubmit({});
  };

  return (
    <Styled.Wrapper>
      <Styled.Subtitle>Additional filters</Styled.Subtitle>
      <Controller
        name="is_banned"
        control={control}
        render={({ field: { onChange, value, name } }) => (
          <Checkbox
            name={name}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              onChange(evt.target.checked)
            }
            checked={value}
          >
            is blocked
          </Checkbox>
        )}
      />
      <FieldRow label="Cv id">
        <Controller
          name="cv_id"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <TextField
              id={name}
              value={value}
              onChange={onChange}
              placeholder="Enter cv id"
              name={name}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="First name">
        <Controller
          name="first_name"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <TextField
              id={name}
              value={value}
              onChange={onChange}
              placeholder="Enter first name"
              name={name}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Last name">
        <Controller
          name="last_name"
          control={control}
          render={({ field: { onChange, value, name } }) => (
            <TextField
              id={name}
              value={value}
              onChange={onChange}
              placeholder="Enter last name"
              name={name}
            />
          )}
        />
      </FieldRow>
      <Styled.ButtonsWrapper>
        <Button onClick={handleSubmit(submitHandler)}>Apply</Button>
        <Button
          color={Color.SECONDARY}
          onClick={() => resetFilters()}
          disabled={isEmptyForm}
          style={{ marginLeft: indents.indent6 }}
        >
          Reset filters
        </Button>
      </Styled.ButtonsWrapper>
    </Styled.Wrapper>
  );
});

export { AdditionalFilters };
