import styled from "styled-components/macro";
import Button from "../ui/Button/Button";
import { Color } from "../../styles/color";

export const PrevButton = styled(Button)`
  font-size: 14px;
  line-height: 20px;
  padding: 9px 31px;
`;

export const NextButton = styled(Button)`
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.005em;
  padding: 8px 26px 9px;
`;

export const PageControls = styled.div`
  display: flex;
  margin-top: 10px;
  padding: 10px 0;
  margin-bottom: 55px;
  position: sticky;
  bottom: 50px;
  background: rgba(255, 255, 255, 1);
`;

export const PaginationContainer = styled.div`
  display: flex;
  margin-left: 24px;
`;

export const PaginationButton = styled.button<{ active?: boolean }>`
  background: none;
  border: none;
  padding: 5px;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  color: ${(props) => (props.active ? Color.BLUE300 : Color.DARK900)};
  width: min-content;
  transition: scale 200ms ease;

  &:hover {
    transform: scale(1.3);
  }
`;

export const Dot = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${Color.DARK900};
  align-self: center;
  margin-right: 5px;
`;
