import React from "react";
import { createPortal } from "react-dom";
import { usePortal } from "../../hooks/usePortal";

interface IPortal {
  id: string;
  children: React.ReactNode;
}

const Portal: React.FC<IPortal> = ({ id, children }) => {
  const target = usePortal(id);
  return target ? createPortal(children, target) : null;
};

export { Portal };
