import React, { useState } from "react";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import TextField, { RegexPattern } from "../../components/ui/TextField";
import * as Styled from "./CreateSeaman.styled";
import { FieldRow } from "../../components/FieldRow";
import { PhoneInput } from "../../components/ui/PhoneInput";
import { Select } from "../../components/ui/Select/Select";
import { AxiosResponse } from "axios";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";
import { getApiError } from "../../utils/helpers";
import { PasswordInput } from "../../components/ui/PasswordInput/PasswordInput";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TSelectProps } from "components/ui/Select/Select";
import { ICreateUser, UserType } from "../../api/users/types";
import { createUser } from "../../api/users/users";

const optionsTypeUser = [
  { label: "Admin", value: UserType.ADMIN },
  { label: "Seaman", value: UserType.SEAMAN },
  { label: "Crew officer", value: UserType.CREW_OFFICER },
];

export const REG_PASSWORD =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?|&/{}\\+=():_-])[A-Za-z\d@$!%*#?|&/{}\\+=():_-]{8,}$/;

const scheme = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  phone: yup.string(),
  email: yup.string().required().email(),
  user_type: yup.string().required(),
  password: yup
    .string()
    .required()
    .matches(
      REG_PASSWORD,
      "Incorrect password. Use 8 or more characters with a mix of letters, numbers & symbols"
    ),
});

const CreateSeaman = () => {
  const [errorApi, setErrorApi] = useState("");

  const { control, formState, handleSubmit } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(scheme),
    defaultValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      user_type: UserType.SEAMAN,
      password: "",
    },
  });

  const { errors } = formState;

  const onSubmit = async (data: ICreateUser) => {
    setErrorApi("");
    try {
      const res = await createUser(data);
      if (res.status === 201) {
        alert("Пользователь создан");
      }
    } catch (e) {
      const error = e as AxiosResponse;
      setErrorApi(getApiError(error));
    }
  };

  return (
    <PageLayout>
      <Styled.Root>
        <Styled.Title>Create user</Styled.Title>
        <Styled.Form>
          <FieldRow label="First name">
            <Controller
              name="first_name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  placeholder="First name"
                  id="first_name"
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Last name">
            <Controller
              name="last_name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  placeholder="Last name"
                  id="last_name"
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Phone">
            <Controller
              name="phone"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <PhoneInput
                  country="rus"
                  name={name}
                  value={value}
                  onChange={onChange}
                  errors={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Email" required>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  pattern={RegexPattern.EMAIL_ALLOWED_CHARS}
                  placeholder="Enter email"
                  id="email"
                  name={name}
                  value={value}
                  onChange={onChange}
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Password" required>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <PasswordInput
                  id="password"
                  name={name}
                  placeholder="Enter password"
                  value={value}
                  help="Use 8 or more characters with a mix of letters, numbers & symbols"
                  onChange={onChange}
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="User type" required>
            <Controller
              name="user_type"
              control={control}
              defaultValue={UserType.SEAMAN}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  name={name}
                  id="user_type"
                  value={value as TSelectProps["value"]}
                  onChange={onChange}
                  placeholder="Choose user type"
                  errors={errors[name]?.message || !!errors[name]}
                  options={optionsTypeUser}
                />
              )}
            />
          </FieldRow>
          <Styled.SubmitButton onClick={handleSubmit(onSubmit)}>
            Create user
          </Styled.SubmitButton>
          {errorApi && <ErrorMessageText text={errorApi} />}
        </Styled.Form>
      </Styled.Root>
    </PageLayout>
  );
};

export default CreateSeaman;
