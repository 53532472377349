import React from "react";
import { Controller } from "react-hook-form";
import { IConfig } from "./ConfigInfo.typed";
import { Checkbox } from "components/ui/Checkbox";
import { FormWrapper } from "./ConfigInfo.styled";

const ConfigInfo = ({ control, defaultValues }: IConfig) => {
  return (
    <FormWrapper>
      <Controller
        name="config_info.is_synt"
        control={control}
        defaultValue={defaultValues?.is_synt ?? false}
        render={({ field: { onChange, name, value } }) => (
          <Checkbox
            name={name}
            onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
              onChange(evt.target.checked)
            }
            checked={value}
          >
            Fake vacancy
          </Checkbox>
        )}
      />
    </FormWrapper>
  );
};

export default ConfigInfo;
