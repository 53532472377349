import React from "react";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import relativeTime from "dayjs/plugin/relativeTime";
import * as Styled from "./Card.styled";
import { WorkExperience } from "./components/WorkExperience/WorkExperience";
import { ICvData } from "api/cvs/types";
import { Avatar } from "./components/Avatar/Avatar";
import { NameAndRank } from "./components/NameAndRank/NameAndRank";
import { BaseInfo } from "./components/BaseInfo/BaseInfo";
import { CitizenshipAndLanguages } from "./components/CitizenshipAndLanguage/CitizenshipAndLanguages";
import { Expectations } from "./components/Expectations/Expectations";
import { ICountries } from "api/dc/types";
import { Buttons } from "./components/Buttons/Buttons";
import { IOpenModeration } from "pages/Seamen/Seamen";

dayjs.extend(isSameOrBefore);
dayjs.extend(relativeTime);

interface IProps {
  cvData: ICvData;
  vacancyId: string | undefined;
  countries: ICountries;
  setOpenModal: (atributes: { isOpen: boolean; id: string }) => void;
  setOpenModeration: (value: IOpenModeration) => void;
}

const Card: React.FC<IProps> = ({
  cvData,
  countries,
  setOpenModal,
  vacancyId,
  setOpenModeration,
}) => {
  const englishLevel = cvData.foreign_languages?.find(
    (language) => language.language === "English"
  )?.level;

  return (
    <Styled.MainWrapper>
      <Styled.Wrapper fill_step={cvData.fill_step}>
        <Avatar
          photo={cvData?.photo}
          date={cvData.date_of_availability}
          status={cvData.availability_status}
          moderation={cvData.moderation_status}
          fill_step={cvData?.fill_step}
        />
        <Styled.PersonalInfo>
          <NameAndRank
            firstName={cvData?.first_name}
            lastName={cvData?.last_name}
            ranks={[cvData?.first_rank, cvData?.secondary_rank]}
          />
          <BaseInfo
            birthday={cvData?.birthday}
            residence={cvData?.city_of_residence}
          />
          <CitizenshipAndLanguages
            countries={countries}
            citizenship={cvData?.citizenship}
            englishLevel={englishLevel}
          />
          <Expectations
            netSalaryFrom={cvData?.net_salary_from}
            netSalaryTo={cvData?.net_salary_to}
            contractDuration={cvData?.contract_duration}
            shipTypes={cvData?.ship_types}
          />
          <Buttons
            cvData={cvData}
            vacancyId={vacancyId}
            setOpenModal={setOpenModal}
            setOpenModeration={setOpenModeration}
          />
        </Styled.PersonalInfo>
        <WorkExperience
          countries={countries}
          total={cvData.total_work_experience}
          noExperience={cvData.no_experience}
          workExperience={cvData.work_experience}
          id={cvData.cv_id}
        />
      </Styled.Wrapper>
    </Styled.MainWrapper>
  );
};

export { Card };
