import styled from "styled-components/macro";
import { Color } from "styles/color";

export const Root = styled.div`
  width: 315px;
  padding: 16px;
  position: sticky;
  top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
`;

export const Title = styled.h3`
  font-family: Montserrat, Verdana, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  color: ${Color.DARK900};
  margin-bottom: 16px;
`;
