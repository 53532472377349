import React from "react";
import { ICvData } from "api/cvs/types";
import { Block, BlockTitle } from "./GeneralInfo.styled";
import { Entries } from "../Entries/Entries";
import { joinArray } from "utils/helpers";
import { pluralizeMonth } from "utils/helpers";

interface IProps {
  cvData: ICvData;
}

const Expactations: React.FC<IProps> = ({ cvData }) => {
  const netSalary = [cvData?.net_salary_from, cvData?.net_salary_to]
    .filter((item) => !!item)
    .join(" - ");

  const contractDuration = cvData.contract_duration
    ? `${cvData.contract_duration} ${pluralizeMonth(
        Number(cvData.contract_duration)
      )}`
    : "-";

  return (
    <Block>
      <BlockTitle>Expectations</BlockTitle>

      <Entries
        entries={[
          {
            label: "Net salary",
            value: netSalary,
          },
          {
            label: "Contract duration",
            value: contractDuration || "-",
          },
          {
            label: "Vessel type",
            value: joinArray(cvData.ship_types) || "-",
          },
        ]}
      />
    </Block>
  );
};

export { Expactations };
