import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const Name = styled.h2`
  font-size: 24px;
  line-height: 26px;
  color: ${Color.DARK800};
  padding-bottom: ${indents.indent15};
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${indents.indent6};
  margin-bottom: ${indents.indent5};
  background-color: ${Color.LIGHT10};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: ${indents.indent5};
`;

export const SalaryWrapper = styled.div`
  display: flex;
  position: relative;
  min-height: 38px;
  margin-bottom: ${indents.indent5};
`;

export const Salary = styled.div`
  font-family: Montserrat, sans-serif;
  margin-right: ${indents.indent5};
  background: ${Color.LIGHT20};
  min-height: 38px;
  border-radius: 4px;
  padding: ${indents.indent3};
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  color: ${Color.DARK900};
`;

export const CompanyName = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  color: ${Color.DARK500};
  margin: ${indents.indent1} 0 ${indents.indent4} 0;
`;

export const BoardingDataWrapper = styled.div`
  display: flex;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: ${indents.indent4};
`;

export const BoardingDate = styled.span`
  display: flex;
  font-size: 16px;
  line-height: 16px;
  color: ${Color.MID400};
  gap: 5px;
  &:first-child {
    margin-right: ${indents.indent5};
  }
  & > img {
    margin-right: ${indents.indent3};
  }
`;

export const BoardingDataItem = styled.span``;

export const BoardingDataTitle = styled.span`
  color: ${Color.BLUE300};
`;
export const BoardingData = styled.span`
  color: ${Color.MID400};
`;
