import React, { useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import * as Styled from "../Filters.styled";
import { Accordion } from "../Accordion/Accordion";
import { Select } from "components/ui/Select/Select";
import { makeOption, makeOptionCountries } from "components/ui/Select/utils";
import { Range } from "components/ui//Range";
import dayjs from "dayjs";
import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  selectDC,
  getLangLevels,
  getLanguages,
} from "services/slices/dictionaries";
import { Names } from "../Filter.utils";
import { ICountries } from "api/dc/types";
import { findItemsAndUnshiftCountries } from "utils/changeArray";

interface IProps {
  control: Control;
  countries: ICountries;
  changePopupContainer: (name: string) => void;
}

const Personal = React.memo(
  ({ control, countries, changePopupContainer }: IProps) => {
    const dispatch = useAppDispatch();
    const { langLevels, languages } = useAppSelector(selectDC);

    useEffect(() => {
      if (!languages?.length) dispatch(getLanguages());
      if (!langLevels?.length) dispatch(getLangLevels());
    }, []);

    const onChangeField = (name: string) => {
      changePopupContainer(name);
    };

    return (
      <>
        <Styled.Subtitle isPaddingTop>Personal info</Styled.Subtitle>
        <div className="FilterWrapper" id={Names.citizenship}>
          <Accordion title="Citizenship" defaultOpened>
            <Controller
              name={Names.citizenship}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={findItemsAndUnshiftCountries(countries, [
                    "Ukraine",
                    "Russia",
                    "United States of America",
                    "India",
                    "Philippines",
                  ]).map(makeOptionCountries)}
                  placeholder="Choose citizenships"
                  name={name}
                  maxValuesLength={5}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.country_current_residence}>
          <Accordion title="Country of Current Location">
            <Controller
              name={Names.country_current_residence}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={countries.map(makeOptionCountries)}
                  placeholder="Choose current residence"
                  name={name}
                  maxValuesLength={5}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.age}>
          <Accordion title="Age" defaultOpened>
            <Controller
              name={Names.age}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Range
                  minValue={18}
                  maxValue={80}
                  maxLength={2}
                  value={value}
                  onChange={(from: number, to: number): void => {
                    onChangeField(name);
                    onChange({ from, to });
                  }}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.net_salary}>
          <Accordion title="Expected Salary" defaultOpened>
            <Controller
              name={Names.net_salary}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Range
                  minValue={0}
                  maxValue={30000}
                  placeholderMin={"$ 0"}
                  placeholderMax={"$ 30 000"}
                  maxLength={5}
                  onChange={(from: number, to: number): void => {
                    onChangeField(name);
                    onChange({ from, to });
                  }}
                  value={value}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.expected_duration}>
          <Accordion title="Expected COE Duration (months)" defaultOpened>
            <Controller
              name={Names.expected_duration}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Range
                  minValue={1}
                  maxValue={12}
                  maxLength={2}
                  value={value}
                  onChange={(from: number, to: number): void => {
                    onChangeField(name);
                    onChange({ from, to });
                  }}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.doa}>
          <Accordion title="Availability" defaultOpened>
            <Controller
              name={Names.doa}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Range.Date
                  withoutRange={true}
                  minValue={dayjs().format("YYYY-MM-DD")}
                  maxValue={dayjs().add(2, "year").format("YYYY-MM-DD")}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  value={value}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.english_level}>
          <Accordion title="English Level" defaultOpened>
            <Controller
              name={Names.english_level}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={langLevels.map(makeOption)}
                  placeholder="Choose level"
                  name={name}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.langs}>
          <Accordion title="Additional Languages">
            <Controller
              name={Names.langs}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={languages.map(makeOption)}
                  placeholder="Choose languages"
                  name={name}
                  maxValuesLength={4}
                />
              )}
            />
          </Accordion>
        </div>
      </>
    );
  }
);

export { Personal };
