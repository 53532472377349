import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../api";
import { RootState } from "../store";
import { ICheckTokenSuccess } from "../../api/auth/types";

interface IAuthState {
  tokenSuccess: boolean;
  tokenLoading: boolean;
  tokenError: string;
  isAuthenticated: boolean;
  userId: string | null;
}

const initialState: IAuthState = {
  tokenSuccess: false,
  tokenLoading: false,
  tokenError: "",
  userId: null,
  isAuthenticated: false,
};

export const checkToken = createAsyncThunk<
  ICheckTokenSuccess,
  void,
  { rejectValue: string }
>("auth/check-token", (_, { rejectWithValue }) =>
  authApi.checkToken().catch((err) => {
    const errorMsg = err.response.data.msg;
    return rejectWithValue(errorMsg as string);
  })
);

export const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    /** Проверяет, есть ли в куках токен авторизации. */
    setIsAuthenticated: (state) => {
      state.isAuthenticated = true;
    },
    signout: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkToken.pending, (state) => {
      state.tokenLoading = true;
    });
    builder.addCase(checkToken.fulfilled, (state, action) => {
      state.userId = action.payload.user_id;
      state.tokenLoading = false;
      state.tokenError = "";
      state.isAuthenticated = true;
    });
    builder.addCase(checkToken.rejected, (state, action) => {
      state.tokenError = action.payload || "Unexpected Error";
      state.tokenLoading = false;
    });
  },
});

export const selectTokenLoading = (state: RootState) => {
  return state.user.tokenLoading;
};

export const selectIsAuthenticated = (state: RootState) => {
  return state.user.isAuthenticated;
};

export const selectUserId = (state: RootState) => {
  return state.user.userId;
};

const { actions, reducer } = user;

export const { signout, setIsAuthenticated } = actions;

export default reducer;
