import React from "react";
import { IModal } from "./Modal.typed";
import { useModalSetup } from "./Modal.utils";
import * as Styled from "./Modal.styled";
import { Portal } from "../../Portal/Portal";

const Modal = ({
  open,
  onClose,
  className,
  children,
  title,
  showCloseButton = true,
  error = false,
  style,
}: IModal) => {
  const { actuallyOpen, shouldDestroyDom } = useModalSetup(open, onClose);

  if (shouldDestroyDom) {
    return null;
  }

  return (
    <Portal id="modals">
      <Styled.Wrapper open={actuallyOpen} className={className}>
        <Styled.Layout onClick={onClose} />
        <Styled.ContentWrapper>
          <Styled.Content error={error} style={style}>
            {showCloseButton && <Styled.CloseButton onClick={onClose} />}
            {title && <Styled.Title>{title}</Styled.Title>}
            {children}
          </Styled.Content>
        </Styled.ContentWrapper>
      </Styled.Wrapper>
    </Portal>
  );
};

export default Modal;
