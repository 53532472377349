import { api } from "../axiosConfig";
import { IStatsSeamenCount, IGetSmsBalanceSuccess } from "./types";

export const fetchSeamenCount = () =>
  api.get<IStatsSeamenCount>("/admin/seamen/count");

export const fetchSmsBalance = () =>
  api.get<IGetSmsBalanceSuccess>("/admin/nexmo/sms/balance").then((res) => ({
    ...res,
    data: {
      success: res.data.success,
      smsBalance: res.data.sms_balance,
    },
  }));
