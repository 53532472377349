import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 ${indents.indent5};
  margin-bottom: ${indents.indent6};

  & > *:last-child {
    width: 100%;
  }
`;

export const InfoSubtitle = styled.span`
  margin-right: ${indents.indent2};
  color: ${Color.MID400};
`;
