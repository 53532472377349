import React, { useState, useEffect } from "react";
import { EmptyData } from "components/EmptyData/EmptyData";
import Table from "components/Table/Table";
import { COLUMNS, useVacanciesData } from "./Vacancies.utils";
import { fetchVacancies } from "api/vacancies/vacancies";
import { IVacancy } from "api/vacancies/types";
import PaginationControls from "components/PaginationControls/PaginationControls";
import Loader from "components/utils/Loader/Loader";

const Vacancies = () => {
  const [loading, setLoading] = useState(true);
  const [vacancies, setVacancies] = useState<IVacancy[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState<number>(0);

  useEffect(() => {
    const controller = new AbortController();
    getVacancies(controller);
    return () => {
      controller.abort();
    };
  }, [currentPage]);

  const getVacancies = async (controller: AbortController) => {
    try {
      setLoading(true);
      const vacancies = await fetchVacancies(
        `limit=10&skip=${currentPage * 10}`,
        controller.signal
      );
      setVacancies(vacancies.data.vacancies);
      setTotal(vacancies.data.total ? vacancies.data.total : 0);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const data = useVacanciesData(vacancies);

  if (!loading && !vacancies.length) {
    return <EmptyData />;
  }

  return (
    <>
      {loading ? <Loader /> : <Table columns={COLUMNS} data={data} />}
      <PaginationControls
        totalElements={total}
        changePage={(page) => setCurrentPage(page)}
      />
    </>
  );
};

export default Vacancies;
