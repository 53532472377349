import styled, { css } from "styled-components/macro";
import { Color } from "../../../styles/color";

export const Container = styled.div<{ vertical?: boolean; small?: boolean }>`
  font-family: Lato, Verdana, sans-serif;
  max-width: ${(props) => (props.small ? 296 : 490)}px;
  display: flex;
  flex-direction: ${(props) => (props.vertical ? "column" : "row")};
  justify-content: space-between;
`;

export const Label = styled.label<{ vertical?: boolean }>`
  font-size: 14px;
  line-height: 20px;
  color: #172b4d;
  margin-top: 7px;
  ${(props) =>
    props.vertical &&
    css`
      margin-top: 0;
      margin-bottom: 8px;
    `}
`;

export const InputContainer = styled.div`
  max-width: 340px;
  flex-grow: 1;
  position: relative;
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 4px;
`;

export const Input = styled.input<{
  error?: boolean;
  width?: number;
  icon?: string;
  textPosition?: string;
}>`
  width: ${(props) => (props.width ? props.width + "px" : "100%")};
  padding: 5px 12px;
  flex-grow: 1;
  background: #fafbfc;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #172b4d;
  transition: 200ms ease-in-out;
  text-align: ${(props) => props.textPosition};

  &::placeholder {
    color: #5e6c84;
    text-align: ${(props) => props.textPosition};
  }

  &:hover {
    border-color: #4c9aff;
  }

  &:focus {
    outline: none;
    border-color: #0065ff;
    box-shadow: 0 0 4px rgba(0, 101, 255, 0.24),
      inset 0 0 4px rgba(23, 43, 77, 0.5);
  }

  &:disabled {
    color: #97a0af;
    background: #f4f5f7;
    border: 1px solid #b3bac5;
    cursor: not-allowed;
  }

  ${(props) =>
    props.value &&
    !props.error &&
    css`
      color: #0747a6;
      border-color: #0065ff;
      background: #f2f7ff;
    `};

  ${(props) =>
    props.error &&
    css`
      color: ${Color.MID80};
      border-color: #de350b;
      background-color: #ffffff;

      &::placeholder {
        color: ${Color.MID80};
      }
    `};

  ${(props) =>
    props.icon &&
    css`
      background-image: url(${props.icon});
      background-repeat: no-repeat;
      background-position: calc(100% - 10px) 50%;
    `}
`;

export const Error = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #de350b;
`;

export const Help = styled.span`
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #97a0af;
`;

export const Button = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: #061938;
  cursor: pointer;
  transition: 200ms ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

export const BgText = styled.span`
  display: block;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  position: absolute;
  color: ${Color.MID80};
  font-size: 14px;
`;
