import styled from "styled-components/macro";
import {
  Wrapper as FieldRow,
  LabelContainer,
} from "components/FieldRow/FieldRow.styled";
import { indents } from "styles/indents";

export const SubField = styled.div`
  padding-left: ${indents.indent9};
  box-sizing: border-box;
  ${FieldRow} {
    flex-wrap: nowrap;
  }
  ${LabelContainer} {
    min-width: 150px;
  }
`;
