import React from "react";

const MiniLogo = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 927 1455"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M833.142 484.689L463.354 379.521V288.071L833.142 393.239V484.689Z"
        fill="#EC4432"
      />
      <path
        d="M93.5663 484.689L463.354 379.521V288.071L93.5663 393.239V484.689Z"
        fill="#F35544"
      />
      <path
        d="M628.199 258.347L463.354 212.622V0L628.199 116.599V258.347Z"
        fill="#EC4432"
      />
      <path
        d="M298.509 258.347L463.354 212.622V0L298.509 116.599V258.347Z"
        fill="#F35544"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.349 452.684L0 585.287V1021.96C0 1277.79 314.097 1280.31 449.051 1448.62C453.896 1450.46 458.664 1452.28 463.349 1454.07C468.033 1452.28 472.802 1450.46 477.646 1448.62C610.373 1289.45 926.697 1277.79 926.697 1021.96V585.287L463.349 452.684Z"
        fill="#EC4432"
      />
      <path
        d="M463.349 452.684L0 585.287V1021.96C0 1277.79 314.097 1280.31 449.051 1448.62C453.896 1450.46 458.664 1452.28 463.349 1454.07V452.684Z"
        fill="#F35544"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M463.352 1101.97C583.92 1101.97 681.66 1001.66 681.66 877.917C681.66 754.176 583.92 653.864 463.352 653.864C342.784 653.864 245.044 754.176 245.044 877.917C245.044 1001.66 342.784 1101.97 463.352 1101.97ZM463.352 1005.95C532.248 1005.95 588.1 948.626 588.1 877.917C588.1 807.208 532.248 749.887 463.352 749.887C394.456 749.887 338.605 807.208 338.605 877.917C338.605 948.626 394.456 1005.95 463.352 1005.95Z"
        fill="white"
      />
      <path
        d="M588.092 877.938C588.092 948.648 532.241 1005.97 463.344 1005.97C394.448 1005.97 338.597 948.648 338.597 877.938C338.597 807.229 394.448 749.908 463.344 749.908C532.241 749.908 588.092 807.229 588.092 877.938Z"
        fill="#A61E0F"
      />
      <path
        d="M295.025 980.996C314.164 961.354 345.194 961.354 364.333 980.996C383.472 1000.64 383.472 1032.49 364.333 1052.13L227.596 1192.46C208.457 1212.11 177.427 1212.11 158.288 1192.46C139.149 1172.82 139.149 1140.97 158.288 1121.33L295.025 980.996Z"
        fill="white"
      />
    </svg>
  );
};
export { MiniLogo };
