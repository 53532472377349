import styled from "styled-components/macro";
import { Color } from "../../styles/color";
import { Label, Wrapper as Field } from "../FieldRow";
import { indents } from "styles/indents";

export const Tooltip = styled.div`
  min-width: 320px;
  position: absolute;
  z-index: 99999;
  top: 25px;
  left: 60px;
  background: ${Color.LIGHT00};
  border-radius: 4px;
  padding: 16px;
  box-shadow: 0 5px 10px 0 rgba(23, 43, 77, 0.25);

  ${Label} {
    margin-top: 0;
  }

  ${Field} {
    margin-bottom: ${indents.indent4};
  }
`;
