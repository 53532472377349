import styled, { css } from "styled-components/macro";

export const Card = styled.div<{ hover?: boolean }>`
  border-radius: 20px;
  padding: 24px 32px;
  width: 100%;

  ${(props) =>
    props.hover &&
    css`
      transition: 300ms ease;

      &:hover {
        box-shadow: 0 4px 20px 0 rgba(23, 43, 77, 0.28);
        cursor: pointer;
      }
    `}
`;
export const Text = styled.p<{ white?: boolean }>`
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => (props.white ? "#fafbfc" : "#061938")};
`;
export const List = styled.ul`
  margin-top: 24px;
`;
export const CardsWrapper = styled.div`
  display: grid;
  margin-bottom: 24px;

  grid-template-columns: repeat(2, 1fr);
  gap: 18px;
`;
