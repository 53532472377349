import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Wrapper = styled.div`
  padding: ${indents.indent4} ${indents.indent5};
  background: ${Color.RED50};
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  border-radius: 0 4px 4px 0;
  margin-top: ${indents.indent4};
  max-width: 133px;
`;

export const Text = styled.span`
  position: relative;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
  display: inline-block;
  color: ${Color.MID400};
  padding-left: ${indents.indent5};

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: ${Color.RED300};
  }
`;
