import React, { useState } from "react";
import * as Styled from "./AddCompany.styled";
import PageLayout from "components/layout/PageLayout/PageLayout";
import Button from "../../components/ui/Button/Button";
import { Controller, useForm } from "react-hook-form";
import TextField from "../../components/ui/TextField";
import { FieldRow } from "../../components/FieldRow";
import { createNewCompany } from "../../api/companies/companies";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";
import { AxiosResponse } from "axios";
import { getApiError } from "../../utils/helpers";
import { useHistory } from "react-router-dom";
import { PasswordInput } from "../../components/ui/PasswordInput/PasswordInput";
import { createUser } from "../../api/users/users";
import { UserType } from "../../api/users/types";

interface IData {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  title: string;
}

const AddCompany: React.FC = () => {
  const history = useHistory();
  const [error, setError] = useState("");

  const { handleSubmit, control } = useForm({
    mode: "onSubmit",
    defaultValues: {
      title: "",
      email: "",
      first_name: "",
      last_name: "",
      password: "",
    },
  });

  const handleClick = async (data: IData) => {
    let userId = "";
    setError("");
    try {
      if (data.email) {
        const user = await createUser({
          email: data.email,
          phone: "",
          first_name: data.first_name,
          last_name: data.last_name,
          password: data.password,
          user_type: UserType.CREW_OFFICER,
        });
        userId = user.data.user_id;
      }
      const payload = {} as { user_id?: string; title?: string };
      if (userId) payload.user_id = userId;
      if (data.title) payload.title = data.title;
      const res = await createNewCompany(
        Object.keys(payload).length ? payload : null
      );
      if (res.status === 201) {
        alert(`Компания создалась c id ${res.data.company_id}`);
        history.push("/companies");
      }
    } catch (e) {
      const error = e as AxiosResponse;
      setError(getApiError(error));
    }
  };

  return (
    <PageLayout>
      <Styled.Wrapper>
        <Styled.Aside>
          <Styled.ButtonContainer>
            <Button onClick={handleSubmit(handleClick)}>Save</Button>
            {error && <ErrorMessageText text={error} />}
          </Styled.ButtonContainer>
        </Styled.Aside>
        <Styled.Main>
          <Styled.Title>{"Add company"}</Styled.Title>
          <Styled.HelpText>
            Внимание! Вы можете создать компанию не заполняя никаких полей.
            Просто нажмите кнопку save и компания создастся. Если вы хотите
            создать компанию и сразу привязать к ней пользователя, то
            пожалуйста, заполните email ниже:
          </Styled.HelpText>
          <Styled.SubTitle>User</Styled.SubTitle>
          <FieldRow label="First name">
            <Controller
              name="first_name"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  name={name}
                  id={name}
                  value={value}
                  onChange={onChange}
                  placeholder="First Name"
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Last name">
            <Controller
              name="last_name"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  name={name}
                  id={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Last Name"
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Email">
            <Controller
              name="email"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  name={name}
                  id={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Email"
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Password">
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <PasswordInput
                  id="password"
                  name={name}
                  placeholder="Enter password"
                  value={value}
                  help="Use 8 or more characters with a mix of letters, numbers & symbols"
                  onChange={onChange}
                />
              )}
            />
          </FieldRow>
          <Styled.SubTitle>Company</Styled.SubTitle>
          <FieldRow label="Title">
            <Controller
              name="title"
              control={control}
              defaultValue={""}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  help="Если заполнить это поле, то в сервисе страница регистрации компании отображаться не будет"
                  name={name}
                  id={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Title company"
                />
              )}
            />
          </FieldRow>
        </Styled.Main>
      </Styled.Wrapper>
    </PageLayout>
  );
};

export { AddCompany };
