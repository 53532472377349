import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const FormWrapper = styled.div`
  max-width: 540px;
`;
export const Form = styled.div`
  margin-bottom: 40px;
  background-color: ${Color.LIGHT20};
  border-radius: 10px;
  padding: 16px 24px 4px;
`;
export const FormTitle = styled.h2`
  font-family: Montserrat, Verdana, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;

  color: ${Color.DARK900};
`;
export const Subtitle = styled.h2`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #b8becc;
  margin-bottom: ${indents.indent5};
  margin-top: ${indents.indent7};
`;
export const HelpNote = styled.p`
  font-family: Lato, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: ${Color.MID80};
  margin-top: 4px;
  white-space: pre-wrap;
`;

export const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
