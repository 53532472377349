import smartcrop from "smartcrop";

export const cropPhoto = (
  file: File
): Promise<{ image: File | undefined; error?: string | undefined }> =>
  new Promise((resolve) => {
    const url = URL.createObjectURL(file);
    const img = new Image();

    img.onload = async () => {
      const { topCrop } = await smartcrop.crop(img, {
        width: 160,
        height: 160,
      });
      const canvas = document.createElement("canvas");

      canvas.height = topCrop.height;
      canvas.width = topCrop.width;
      canvas.getContext("2d")?.drawImage(img, -topCrop.x, -topCrop.y);

      const base64 = canvas.toDataURL("image/jpeg");
      const blob = await fetch(base64).then((res) => res.blob());

      const image = new File([blob], "userpic.jpeg", {
        type: "image/jpeg",
      });
      resolve({ image });
      URL.revokeObjectURL(url);
    };
    img.src = url;
  });
