import React, { useState } from "react";

import * as Styled from "./Accordion.styled";
import * as Typed from "./Accordion.typed";

const Accordion: React.FC<Typed.IProps> = ({
  title,
  defaultOpened = false,
  children,
}) => {
  const [opened, setOpened] = useState(defaultOpened);

  return (
    <Styled.Wrapper opened={opened}>
      <Styled.Title onClick={() => setOpened(!opened)}>{title}</Styled.Title>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

export { Accordion, Styled as AccordionStyled };
