import React, { useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import * as Styled from "../Filters.styled";
import { Accordion } from "../Accordion/Accordion";
import { Select } from "components/ui/Select/Select";
import { makeOption } from "components/ui/Select/utils";
import { Range } from "components/ui/Range";
import { Names } from "../Filter.utils";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { selectDC, getRanks, getCop } from "services/slices/dictionaries";
import TextField, { RegexPattern } from "components/ui/TextField";

interface IProps {
  control: Control;
  coc: Array<string>;
  changePopupContainer: (name: string) => void;
}

const Proficiency = React.memo(
  ({ control, coc, changePopupContainer }: IProps) => {
    const dispatch = useAppDispatch();
    const { ranks, cop } = useAppSelector(selectDC);

    useEffect(() => {
      if (!ranks?.length) dispatch(getRanks());
      if (!cop?.length) dispatch(getCop());
    }, []);

    const onChangeField = (name: string) => {
      changePopupContainer(name);
    };

    return (
      <>
        <Styled.Subtitle>Proficiency & Competency</Styled.Subtitle>
        <div className="FilterWrapper" id={Names.rank}>
          <Accordion title="Rank" defaultOpened>
            <Controller
              name={Names.rank}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={ranks.map(makeOption)}
                  placeholder="Choose ranks"
                  name={name}
                  maxValuesLength={5}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.experience_in_ranks}>
          <Accordion title="Rank Experience (contracts)" defaultOpened>
            <Controller
              name={Names.experience_in_ranks}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Range
                  minValue={0}
                  maxLength={2}
                  maxValue={30}
                  onChange={(from: number, to: number): void => {
                    onChangeField(name);
                    onChange({ from, to });
                  }}
                  value={value}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.proficiencies}>
          <Accordion title="Proficiencies" defaultOpened>
            <Controller
              name={Names.proficiencies}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={cop.map(makeOption)}
                  placeholder="Choose proficiencies"
                  name={name}
                  maxValuesLength={5}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.total_work_experience}>
          <Accordion title="Total Work Experience (contracts)" defaultOpened>
            <Controller
              name={Names.total_work_experience}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Range
                  minValue={0}
                  maxLength={2}
                  maxValue={50}
                  onChange={(from: number, to: number): void => {
                    onChangeField(name);
                    onChange({ from, to });
                  }}
                  value={value}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.competencies}>
          <Accordion title="Competencies" defaultOpened>
            <Controller
              name={Names.competencies}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Select
                  // @ts-ignore ~ react-select types error 👀
                  isMulti
                  value={value}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  options={coc.map(makeOption)}
                  placeholder="Choose competencies"
                  name={name}
                  maxValuesLength={5}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.time_at_sea}>
          <Accordion title="Time at Sea (years)">
            <Controller
              name={Names.time_at_sea}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <Range
                  minValue={0}
                  maxLength={2}
                  maxValue={30}
                  onChange={(from: number, to: number): void => {
                    onChangeField(name);
                    onChange({ from, to });
                  }}
                  value={value}
                />
              )}
            />
          </Accordion>
        </div>
        <div className="FilterWrapper" id={Names.time_off_board_limit}>
          <Accordion title="Longest time off board (months)">
            <Controller
              name={Names.time_off_board_limit}
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  id={name}
                  value={value}
                  pattern={RegexPattern.NUMBER}
                  onChange={(val) => {
                    onChangeField(name);
                    onChange(val);
                  }}
                  maxLength={3}
                  placeholder="Enter the number of months"
                  name={name}
                />
              )}
            />
          </Accordion>
        </div>
      </>
    );
  }
);

export { Proficiency };
