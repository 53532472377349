import React from "react";
import Dashboard from "./pages/Dashboard/Dashboard";
import Seamen from "./pages/Seamen/Seamen";
import CreateSeaman from "./pages/CreateSeaman/CreateSeaman";
import Applicants from "pages/Applicants/Applicants";
import Support from "pages/Support/Support";
import Companies from "./pages/Companies/Companies";
import { CompanyPage } from "./pages/CompanyPage/CompanyPage";
import NotFound from "./pages/NotFound/NotFound";
import { AddVacancy } from "./pages/AddVacancy/AddVacancy";
import { EditVacancy } from "./pages/AddVacancy/EditVacancy";
import { AddCompany } from "./pages/AddCompany/AddCompany";
import { EditCompany } from "./pages/AddCompany/EditCompany";
import { AddManagersCompany } from "./pages/AddManagersCompany/AddManagersCompany";
import { EditManager } from "./pages/AddManagersCompany/EditManager";
import { Emails } from "./pages/Emails/Emails";
import LoginPage from "./pages/Login/Login";
import MatchingVacancy from "./pages/MatchingVacancy/MatchingVacancy";
import { Cv } from "pages/Cv/Cv";

export const routes = [
  {
    exact: true,
    protect: true,
    path: "/",
    render: () => <Dashboard />,
  },
  {
    exact: true,
    protect: false,
    path: "/login",
    render: () => <LoginPage />,
  },
  {
    exact: true,
    protect: true,
    path: "/seamen",
    render: () => <Seamen />,
  },
  {
    exact: true,
    protect: true,
    path: "/seamen/create",
    render: () => <CreateSeaman />,
  },
  {
    exact: true,
    protect: true,
    path: "/cv/:id",
    render: () => <Cv />,
  },
  {
    exact: true,
    protect: true,
    path: "/applicants",
    render: () => <Applicants />,
  },
  {
    exact: true,
    protect: true,
    path: "/support",
    render: () => <Support />,
  },
  {
    exact: true,
    protect: true,
    path: "/support/matching/:vacId",
    render: () => <MatchingVacancy />,
  },
  {
    exact: true,
    protect: true,
    path: "/companies",
    render: () => <Companies />,
  },
  {
    exact: true,
    protect: true,
    path: "/company/add",
    render: () => <AddCompany />,
  },
  {
    exact: true,
    protect: true,
    path: "/company/edit/:id",
    render: () => <EditCompany />,
  },
  {
    exact: true,
    protect: true,
    path: "/company/:id",
    render: () => <CompanyPage />,
  },
  {
    exact: true,
    protect: true,
    path: "/company/:id/manager/add",
    render: () => <AddManagersCompany />,
  },
  {
    exact: true,
    protect: true,
    path: "/company/:id/manager/edit/:userId",
    render: () => <EditManager />,
  },
  {
    exact: true,
    protect: true,
    path: "/company/:id/:title/add_vacancy",
    render: () => <AddVacancy />,
  },
  {
    exact: true,
    protect: true,
    path: "/company/:id/edit_vacancy/:vac_id",
    render: () => <EditVacancy />,
  },
  {
    exact: true,
    protect: true,
    path: "/emails",
    render: () => <Emails />,
  },
  {
    exact: true,
    protect: false,
    path: "*",
    render: () => <NotFound />,
  },
];
