import React, { useMemo, useState } from "react";
import { ButtonsRow, Links } from "./Applicants.styled";
import { IResponse } from "api/responses/types";
import { getTime } from "../../utils/dateTime";
import Button from "../../components/ui/Button/Button";
import { Color, Variant } from "../../components/ui/Button/Button.typed";
import { validationResponses } from "../../api/responses/responses";
import { AxiosResponse } from "axios";
import { getApiError, getBaseUrl } from "../../utils/helpers";
import { FiltersApplicants } from "./Applicants";
import { getRenderStatus } from "../../utils/getRenderStatusSeaman";

export const COLUMNS = [
  {
    Header: "Date of\napplication  ↓",
    accessor: "col1",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
  },
  {
    Header: "Vacancy name",
    accessor: "col2",
    minWidth: 170,
    maxWidth: 170,
    width: 170,
  },
  {
    Header: "Company name",
    accessor: "col3",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Seaman name",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Phone / E-mail",
    accessor: "col5",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Status",
    accessor: "col6",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Matching",
    accessor: "col7",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "",
    accessor: "col8",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

const baseUrl = getBaseUrl();

const RowControls = ({
  response,
  filter,
  setOpenNotifyId,
}: {
  response: IResponse;
  filter: FiltersApplicants;
  setOpenNotifyId: (val: string) => void;
}) => {
  const [success, setSuccess] = useState<string | null>(null);

  const handleValidation = async (validate: boolean) => {
    try {
      await validationResponses(response.response_id, validate);
      setSuccess(`Response ${validate ? "validate" : "declined"}`);
    } catch (e) {
      const error = e as AxiosResponse;
      alert(getApiError(error));
    }
  };

  if (success) return <span>{success}</span>;

  return (
    <ButtonsRow>
      {filter !== FiltersApplicants.validated && (
        <Button
          color={Color.GREEN}
          variant={Variant.TEXT}
          onClick={() => handleValidation(true)}
        >
          Validate
        </Button>
      )}
      {filter === FiltersApplicants.pending && (
        <Button
          color={Color.SECONDARY}
          variant={Variant.TEXT}
          onClick={() => handleValidation(false)}
        >
          Decline
        </Button>
      )}
      <Button
        variant={Variant.TEXT}
        onClick={() => setOpenNotifyId(response.user_id)}
      >
        Edit
      </Button>
    </ButtonsRow>
  );
};

const prepareRowData = (
  response: IResponse,
  filter: FiltersApplicants,
  setOpenNotifyId: (val: string) => void
) => {
  const createdAt = getTime(response.created_at);

  const handleClickSeaman = () => {
    window.open(`${baseUrl}/cv/${response.cv_id}`, "_blank");
  };

  const handleClickVacancy = () => {
    window.open(`${baseUrl}/vacancy/${response.vac_id}`, "_blank");
  };

  const handleClickCompany = () => {
    window.open(
      `https://cp.krew.team/company/${response.company_id}`,
      "_blank"
    );
  };

  return {
    col1: createdAt,
    col2: <Links onClick={handleClickVacancy}>{response.title}</Links>,
    col3: <Links onClick={handleClickCompany}>{response.company_title}</Links>,
    col4: <Links onClick={handleClickSeaman}>{response.seaman_name}</Links>,
    col5: `${response.seaman_phone || "-"}\n${response.seaman_email}`,
    col6: getRenderStatus(
      response.seaman_availability,
      response.seaman_date_availability
    ),
    col7: `${response.match_percent_for_response}%`,
    col8: (
      <RowControls
        response={response}
        filter={filter}
        setOpenNotifyId={setOpenNotifyId}
      />
    ),
  };
};

export const useApplicantsData = (
  responses: IResponse[],
  filter: FiltersApplicants,
  setOpenNotifyId: (val: string) => void
) => {
  return useMemo(() => {
    if (!responses || !responses.length) return [];

    return responses.map((item) =>
      prepareRowData(item, filter, setOpenNotifyId)
    );
  }, [responses]);
};
