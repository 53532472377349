import styled from "styled-components/macro";

export const Wrapper = styled.div<{ type: "string" | "password" }>`
  position: relative;
  & > span {
    position: absolute;
    top: ${(props) => (props.type === "password" ? "9px" : "7px")};
    right: 12px;
    cursor: pointer;
  }
`;
