import React, { useMemo, useState } from "react";
import * as Styled from "./Buttons.styled";
import Button from "components/ui/Button/Button";
import { Color, Variant } from "components/ui/Button/Button.typed";
import { indents } from "styles/indents";
import { selectUserId } from "services/slices/auth";
import { useAppSelector } from "services/hooks";
import { getApiError } from "utils/helpers";
import { ErrorMessageText } from "components/ErrorMessage/ErrorMessage";
import { AxiosResponse } from "axios";
import { sendInvitation } from "api/vacancies/vacancies";
import { ICvData, ModerationStatus } from "api/cvs/types";
import { IOpenModeration } from "pages/Seamen/Seamen";

interface IProps {
  cvData: ICvData;
  setOpenModal: (attributes: { isOpen: boolean; id: string }) => void;
  setOpenModeration: (value: IOpenModeration) => void;
  vacancyId: string | undefined;
}

const Buttons = ({
  cvData,
  setOpenModal,
  vacancyId,
  setOpenModeration,
}: IProps) => {
  const adminId = useAppSelector(selectUserId);
  const [error, setError] = useState<string>("");

  const isRenderSend = useMemo(() => {
    return (
      !!cvData.email && cvData.moderation_status === ModerationStatus.moderated
    );
  }, [cvData]);

  const handleOpen = async () => {
    if (!adminId) return;
    const link = `cv/${cvData.cv_id}`;
    window.open(link, "_blank");
  };

  const handleEdit = () => {
    setOpenModal({ isOpen: true, id: cvData.user_id });
  };

  const handleBlock = () => {
    setOpenModeration({ type: ModerationStatus.banned, cv: cvData });
  };

  const handleApprove = () => {
    setOpenModeration({ type: ModerationStatus.moderated, cv: cvData });
  };

  const handleSendInvitation = async () => {
    if (!vacancyId) return;
    try {
      await sendInvitation(vacancyId, cvData.user_id);
      alert(`Send invite to ${cvData?.first_name} ${cvData?.last_name}`);
    } catch (e) {
      handleError(e as AxiosResponse);
    }
  };

  const handleError = (error: AxiosResponse) => {
    setError(getApiError(error));
  };

  const styles = {
    maxHeight: "32px",
    borderRadius: "6px",
    padding: "6px 12px",
  };

  return (
    <>
      <Styled.Wrapper>
        <Button
          onClick={handleOpen}
          variant={Variant.CONTAINED}
          style={{
            ...styles,
            marginRight: indents.indent5,
          }}
        >
          Open CV
        </Button>
        <Button
          onClick={handleEdit}
          variant={Variant.OUTLINED}
          style={{
            ...styles,
            marginRight: indents.indent5,
          }}
        >
          Edit CV
        </Button>
        {cvData.moderation_status === ModerationStatus.moderated && (
          <Button
            onClick={handleBlock}
            variant={Variant.OUTLINED}
            style={{
              ...styles,
              marginRight: indents.indent5,
            }}
          >
            Block
          </Button>
        )}
        {cvData.moderation_status === ModerationStatus.banned && (
          <Button
            onClick={handleApprove}
            color={Color.SECONDARY}
            variant={Variant.CONTAINED}
            style={{
              ...styles,
              marginRight: indents.indent5,
            }}
          >
            Release
          </Button>
        )}
        {isRenderSend && (
          <Button
            disabled={!vacancyId}
            onClick={handleSendInvitation}
            variant={Variant.OUTLINED}
            style={{ ...styles }}
          >
            Send invitation
          </Button>
        )}
      </Styled.Wrapper>
      {!!error && <ErrorMessageText text={error} />}
    </>
  );
};

export { Buttons };
