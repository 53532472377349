export const isTooLong = (
  value: string,
  maxLength: number,
  type: "string" | "number" | "money" | "password" = "string"
) => {
  if (type === "string") {
    return value.length > maxLength;
  }

  return value.replace(/\s+/g, "").length > maxLength;
};
