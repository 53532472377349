import styled from "styled-components/macro";

import * as FieldStyled from "../ui/TextField/TextField.styled";
import { Color } from "styles/color";
import { indents } from "styles/indents";
export const Field = styled.div`
  max-width: 340px;

  ${FieldStyled.Container} {
    width: 100%;
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${Color.DARK800};
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 20px;
  margin-top: ${indents.indent3};

  & span {
    margin-left: 3px;
    color: ${Color.RED300};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${indents.indent5};
`;
export const LabelContainer = styled.div`
  width: 150px;
`;
export const InputContainer = styled.div`
  width: 100%;
  max-width: 340px;
  min-width: 160px;
`;
