import styled, { css } from "styled-components/macro";

export const Root = styled.header`
  max-width: 1440px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 32px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px 0 32px;
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  border-radius: 8px;
  min-height: 57px; // высота хэдера со ссылками
`;

export const Logo = styled.img`
  display: block;
  margin-right: 16px;
`;

export const Links = styled.ul`
  display: flex;
`;

export const LinksItem = styled.li`
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const Link = styled.span<{ active?: boolean }>`
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #061938;
  padding-top: 18px;
  padding-bottom: 17px;
  border-bottom: 1px solid transparent;

  ${(props) =>
    props.active &&
    css`
      border-color: #2684ff;
    `};
`;
