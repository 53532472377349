import React, { useState } from "react";
import Modal, { IModal } from "../../components/ui/Modal";
import Button from "../../components/ui/Button/Button";
import {
  Variant,
  Color as ButtonColor,
} from "../../components/ui/Button/Button.typed";

import * as Styled from "./VerifyCompanyModal.styled";
import { verifyCompany } from "../../api/companies/companies";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";

interface IProps extends IModal {
  name: string;
  id: string;
  callback: () => void;
}

const VerifyCompanyModal = (props: IProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onVerifyClick = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await verifyCompany(props.id);
      if (res.status === 200) {
        props.callback();
        props.onClose();
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <Modal
      {...props}
      title={"Verification"}
      style={{ width: "100%", maxWidth: 575 }}
      onClose={props.onClose}
    >
      <Styled.Text>
        Are you sure you want to verify{" "}
        <Styled.Highlight>{props.name}</Styled.Highlight>?
      </Styled.Text>
      <Button
        style={{ fontWeight: "bold", padding: "9px 36px", marginRight: 16 }}
        onClick={onVerifyClick}
        disabled={loading}
      >
        Yes
      </Button>
      <Button
        style={{ padding: "9px 36px" }}
        variant={Variant.OUTLINED}
        color={ButtonColor.SECONDARY}
        onClick={props.onClose}
        disabled={loading}
      >
        No
      </Button>
      {error && <ErrorMessageText text="Something went wrong" />}
    </Modal>
  );
};

export default VerifyCompanyModal;
