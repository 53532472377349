import React from "react";
import { Entries } from "../Entries/Entries";
import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

type Props = {
  title?: string;
  entries: Array<{
    label: string | number | React.ReactElement;
    value: string | number | React.ReactElement;
  }>;
  text?: string[];
  footer?: React.ReactNode;
  filePath?: string;
};

export const Wrapper = styled.div`
  padding: 12px ${indents.indent5};
  border: 1px solid ${Color.LIGHT30};
  border-radius: ${indents.indent2};
  align-self: start;
  margin-right: ${indents.indent7};
  margin-bottom: ${indents.indent6};
  font-size: 14px;
  line-height: 20px;
`;

export const Title = styled.div`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: ${indents.indent5};
`;

export const BlueText = styled.span`
  color: #0065ff;
`;

export const Card = (props: Props) => {
  const { title, entries = [], text = [], footer } = props;

  if (footer) {
    return (
      <Wrapper>
        <Title>{title}</Title>
        {footer}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>{title}</Title>
      {text.length ? (
        <>
          {text[0]}
          <br /> <BlueText>{text[1]}</BlueText>
        </>
      ) : (
        <Entries entries={entries} />
      )}
    </Wrapper>
  );
};
