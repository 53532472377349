import styled from "styled-components/macro";
import { Color } from "styles/color";

const Element = styled.span`
  background-color: ${Color.LIGHT10};
  border: 1px solid ${Color.LIGHT50};
  border-radius: 3px;
  display: block;
  height: 16px;
  overflow: hidden;
  position: relative;
  transition: all 300ms;
  width: 16px;

  &::after {
    content: "";
    position: absolute;
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e")
      center center no-repeat;
    top: 2px;
    right: 2px;
    width: 10px;
    height: 10px;
  }
`;

const NativeInput = styled.input.attrs({ type: "checkbox" })`
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
  z-index: -1;
`;

const Text = styled.span`
  color: ${Color.DARK800};
  font-size: 14px;
  padding-left: 8px;
`;

const Wrapper = styled.label`
  align-items: stretch;
  display: inline-flex;
  user-select: none;

  &:hover ${NativeInput} {
    &:enabled {
      ~ ${Element} {
        box-shadow: 0 0 0 4px ${Color.LIGHT30} !important;
        cursor: pointer;
      }

      ~ ${Text} {
        cursor: pointer;
      }
    }
  }

  ${NativeInput} {
    &:focus {
      ~ ${Element} {
        background-color: ${Color.LIGHT00};
        border-color: ${Color.BLUE300};
        box-shadow: 0px 0px 4px rgba(0, 101, 255, 0.24);
      }
    }

    &:checked {
      ~ ${Element} {
        background-color: ${Color.BLUE200};
        border-color: ${Color.BLUE200};
        box-shadow: none;
      }
    }

    &:disabled {
      &:checked {
        ~ ${Element} {
          background-color: ${Color.MID60};
          border-color: ${Color.MID60};
        }
      }

      ~ ${Element} {
        background-color: ${Color.LIGHT30};
        border-color: ${Color.LIGHT50};
      }
    }
  }
`;

export { Element, NativeInput, Text, Wrapper };
