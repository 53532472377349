import React from "react";
import * as Styled from "./CardRequirements.styled";
import { IPropsCard } from "./CardRequirements.typed";

const CardRequirements = (props: IPropsCard) => {
  const { title, fields, percent, children, id } = props;

  const propsWrapper = {} as { id: string };

  if (id) propsWrapper.id = id;

  return (
    <Styled.Wrapper {...propsWrapper}>
      <Styled.Header>
        <div>
          <Styled.TitleWrapper>
            <Styled.Title>{title}</Styled.Title>
            <Styled.Percent>{percent}</Styled.Percent>
          </Styled.TitleWrapper>
          <Styled.Fields>{fields}</Styled.Fields>
        </div>
      </Styled.Header>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrapper>
  );
};

CardRequirements.displayName = "CardRequirements";

export { CardRequirements };
