import styled, { css } from "styled-components/macro";

export const Container = styled.div`
  background: url("../../images/bg-dashboard.png");
`;
export const Content = styled.div`
  height: 100%;
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(647px, 1fr));
  justify-items: center;
  gap: 18px;
`;
export const CardsColumn = styled.div`
  width: 100%;
  max-width: 700px;
  display: grid;
  grid-auto-rows: min-content;
  gap: 16px;
`;
export const Card = styled.div<{ hover?: boolean }>`
  border-radius: 20px;
  padding: 24px 32px;
  width: 100%;

  ${(props) =>
    props.hover &&
    css`
      transition: 300ms ease;

      &:hover {
        box-shadow: 0 4px 20px 0 rgba(23, 43, 77, 0.28);
        cursor: pointer;
      }
    `}
`;

export const Title = styled.h2<{ white?: boolean }>`
  display: flex;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: ${(props) => (props.white ? "#ffffff" : "#061938")};
`;
export const TitleText = styled.span`
  min-width: max-content;
  margin-right: 7px;
`;
export const Text = styled.p<{ white?: boolean }>`
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: ${(props) => (props.white ? "#fafbfc" : "#061938")};
`;
export const Highlight = styled.span`
  color: #0065ff;
`;

export const List = styled.ul`
  margin-top: 24px;
`;
export const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

export const StatusesList = styled(List)`
  // больше двух списков не будет
  &:nth-of-type(2) {
    margin-left: 128px;
    position: relative;
  }

  &:nth-of-type(2)::before {
    content: "";
    position: absolute;
    top: 0;
    left: -64px;
    height: 100%;
    width: 1px;
    background: url("../../images/bg-decor/thin-line.svg");
  }
`;
export const StatusItem = styled(ListItem)`
  display: flex;
  align-items: center;
`;
export const LiIcon = styled.span<{ color?: string }>`
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: ${(props) => props.color || "#fff"};
  margin-right: 3px;
`;
