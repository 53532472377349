import styled, { css } from "styled-components/macro";
import { Color } from "../../styles/color";

export const Link = styled.span`
  color: ${Color.BLUE300};
  cursor: pointer;
`;

export const Subtext = styled.span`
  color: rgba(0, 0, 0, 0.5);
`;

export const WrapperTooltip = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
`;

// кастомная таблица для создания вложенной таблицы. Плохое решение, но пока так,
// надо разбираться с компонентом таблицы и пагинации сначала

export const Table = styled.table<{ isVacancy?: boolean }>`
  border-collapse: collapse;
  width: 100%;
  ${(props) =>
    props.isVacancy &&
    css`
      border-bottom: 2px solid ${Color.BLUE300};
    `}
`;
export const THead = styled.thead`
  background: #fafbfc;
`;
export const TBody = styled.tbody``;
export const TRow = styled.tr<{ isVacancy?: boolean }>`
  ${(props) =>
    props.isVacancy &&
    css`
      background: rgba(0, 101, 255, 0.08);
    `}
`;
export const TH = styled.th`
  padding: 12px 12px 20px;
  font-family: Lato, sans-serif;
  text-align: left;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  color: #6b778c;
  white-space: pre;
`;

export const TD = styled.th`
  white-space: pre-wrap;
  padding: 16px 12px;
  font-family: Lato, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #061938;
  vertical-align: top;
  text-align: left;
`;
