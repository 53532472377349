import React, { useEffect, useState } from "react";
import * as Styled from "./CopyLinkWithToken.styled";
import Button from "../ui/Button/Button";
import { Variant } from "../ui/Button/Button.typed";
import Loader from "../utils/Loader/Loader";
import { generateAuthToken } from "../../api/users/users";
import { getBaseUrl } from "../../utils/helpers";

interface IProps {
  userId: string;
  textButton: string;
  redirect?: string;
}

const CopyLinkWithToken = ({ userId, textButton, redirect }: IProps) => {
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!loader && open) {
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open, loader]);

  const handleCopyToken = async () => {
    try {
      setLoader(true);
      setOpen(true);
      const res = await generateAuthToken(userId);
      let link = `${getBaseUrl()}/auth?token=${res.data.token}`;
      if (redirect) link += `&redirect=${redirect}`;
      window.navigator.clipboard.writeText(link);
      setLoader(false);
    } catch (e) {
      alert("Something went wrong");
    }
  };

  return (
    <Styled.Wrapper>
      <Button variant={Variant.TEXT} onClick={handleCopyToken}>
        {textButton}
      </Button>
      <Styled.CopyLink open={open}>
        {loader ? <Loader /> : "Link copied"}
      </Styled.CopyLink>
    </Styled.Wrapper>
  );
};

export { CopyLinkWithToken };
