import styled, { css } from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Root = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  margin-bottom: ${indents.indent15};
  padding: ${indents.indent2};
  background-color: ${Color.LIGHT30};
  display: flex;
  border-radius: 8px;
  justify-content: center;
`;

export const FilterName = styled.span`
  padding: ${indents.indent4} ${indents.indent1};
  display: flex;
  font-size: 14px;
  line-height: 14px;
  align-items: center;
  color: ${Color.MID80};
  justify-content: center;
`;

export const FilterItem = styled.button<{ active: boolean }>`
  border: none;
  white-space: nowrap;
  background-color: transparent;
  border-radius: 4px;
  transition: 200ms ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: auto;
  }

  ${(props) =>
    props.active &&
    css`
            background-color: ${Color.LIGHT00};
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.08), 0 2px 24px rgba(0, 0, 0, 0.08);
            ${FilterName} {
                color: ${Color.DARK900};
                font-weight: 600;
             ]
            }
        `}
`;

export const FilterAllCvs = styled(FilterItem)`
  width: 115px;
`;
export const FilterGoodCvs = styled(FilterItem)`
  width: 164px;
`;
