import { Color as Colors } from "../styles/color";
import React from "react";
import { StatusAvailable } from "../api/cvs/types";

export const getRenderStatus = (status: StatusAvailable, date?: string) => {
  const obj = {
    color: Colors.DARK900,
    name: "Unavailable",
  };
  if (status === StatusAvailable.available) {
    obj.color = Colors.GREEN500;
    obj.name = "Immediately available";
  }
  if (status === StatusAvailable.postponed) {
    obj.color = Colors.YELLOW300;
    obj.name = `Available from \n${date || "-"}`;
  }

  return <span style={{ color: obj.color }}>{obj.name}</span>;
};
