import { api } from "../axiosConfig";
import { IAuth, ICheckTokenSuccess } from "./types";
import { getCookie } from "utils/cookies";

export const auth = (email: string, password: string) => {
  return api.post<IAuth>("/auth/password/enter/admin", {
    email: email,
    password: password,
  });
};

export const checkToken = () => {
  const token = getCookie("auth-token");

  return api
    .get<ICheckTokenSuccess>(`/auth/tokens/check?token=${token}`)
    .then(({ data }) => {
      if (data.user_type !== "admin")
        throw new Error("Only admins may enter in control panel");
      if (!data.success) throw new Error("Unexpected Error");

      return data;
    });
};
