import React from "react";
import styled from "styled-components/macro";

import styles from "../DatePicker.module.css";
import { makeOption } from "../../Select/utils";
import { Select } from "../../Select/Select";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const SelectGroup = styled.div`
  display: flex;
`;

type Props = {
  /** date from react-day-picker */
  date: Date;
  /** setMonth from our DatePicker */
  onChange: (value: Date) => void;
  fromYear: number;
  toYear: number;
  /** show years in reverse order */
  reverseYears?: boolean;
};

export const YearMonthForm = ({
  date,
  onChange,
  fromYear,
  toYear,
  reverseYears = false,
}: Props) => {
  let years = [];

  for (let i = fromYear; i <= toYear; i += 1) {
    years.push(String(i));
  }

  if (reverseYears) years = years.reverse();

  const yearsOptions = years.map((year) => ({ label: year, value: year }));

  const monthsOptions = months.map((m: string, i: number) => ({
    label: m,
    value: String(i),
  }));

  const handleChange = (month: string, year: string) => {
    onChange(new Date(+year, +month));
  };

  return (
    <div className={styles.caption}>
      <SelectGroup>
        <Select
          name="month"
          onChange={(value) =>
            handleChange(value as string, String(date.getFullYear()))
          }
          value={{
            label: months[date.getMonth()],
            value: String(date.getMonth()),
          }}
          options={monthsOptions}
          placeholder="Month"
          groupLeft
          width={100}
          isClearable={false}
        />
        <Select
          name="year"
          onChange={(value) =>
            handleChange(String(date.getMonth()), value as string)
          }
          value={makeOption(String(date.getFullYear()))}
          options={yearsOptions}
          placeholder="Year"
          groupRight
          width={75}
          isClearable={false}
        />
      </SelectGroup>
    </div>
  );
};
