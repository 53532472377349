import { StatusAvailable } from "api/cvs/types";

export enum StatusResponse {
  sent = "sent",
  viewed = "viewed",
  selected = "selected",
  contacted = "contacted",
  hired = "hired",
  hiring_confirmed = "hiring_confirmed",
  rejected = "rejected",
  archived = "archived",
  employer_reminded = "employer_reminded",
}

export interface IResponse {
  company_id: string;
  company_title: string;
  contract_duration: number;
  date_of_boarding: string;
  has_dates_interval: boolean;
  net_salary: number;
  title: string;
  vac_code?: string;
  published_at?: string;
  response_id: string;
  vac_id: string;
  cv_id: string;
  is_deleted: boolean;
  emails_for_notification: string;
  status: string;
  hired: boolean;
  hired_updated_at: string;
  selected: boolean;
  selected_updated_at: string;
  contacted: boolean;
  contacted_updated_at: string;
  viewed: boolean;
  viewed_updated_at: string;
  rejected: boolean;
  rejected_updated_at: string;
  archived: boolean;
  archived_updated_at: string;
  employer_reminded: boolean;
  employer_reminded_updated_at: string;
  hiring_confirmed: boolean;
  hiring_confirmed_updated_at: string;
  not_reviewed_employer: boolean;
  not_reviewed_employer_updated_at: string;
  created_at: string;
  seaman_name: string;
  user_id: string;
  last_activity_at: string;
  seaman_email: string;
  seaman_phone: string;
  seaman_availability: StatusAvailable;
  seaman_date_availability: string;
  match_percent_for_response?: number;

  // custom keys only for frontend
  fr_status?: StatusResponse;
  fr_text?: boolean;
}
