import React, { useEffect } from "react";

import * as Dashboard from "../../../pages/Dashboard/Dashboard.styled";
import { useAppDispatch, useAppSelector } from "../../../services/hooks";
import Loader from "../../utils/Loader/Loader";
import * as Styled from "./SmsCard.styled";
import {
  getSmsBalance,
  selectSmsBalance,
  selectSmsBalanceLoading,
} from "../../../services/slices/other";

const SmsCard = () => {
  const dispatch = useAppDispatch();

  const smsBalance = useAppSelector(selectSmsBalance);
  const smsBalanceLoading = useAppSelector(selectSmsBalanceLoading);

  useEffect(() => {
    if (smsBalance === -1) {
      dispatch(getSmsBalance());
    }
  }, []);

  const titleJsx = (
    <Dashboard.Title>
      <Dashboard.TitleText>SMS Balance: </Dashboard.TitleText>
      {smsBalanceLoading ? (
        <Loader inline />
      ) : (
        "€" + Number(smsBalance).toFixed(2)
      )}
    </Dashboard.Title>
  );

  return <Styled.SmsCard>{titleJsx}</Styled.SmsCard>;
};

export default SmsCard;
