import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import InputRange, { Range as RangeTyped } from "react-input-range";
import debounce from "lodash/fp/debounce";

import { DatePicker } from "../../DatePicker/DatePicker";

import { DEBOUNCE_INTERVAL } from "../constants";
import * as Styled from "../Range.styled";
import * as Typed from "../Range.typed";

const DateRange: React.FC<Typed.IDateProps> = ({
  minValue,
  maxValue,
  onChange,
  value,
  withoutRange = false,
}) => {
  const minRangeValue = 0;
  const maxRangeValue = dayjs(maxValue).diff(dayjs(minValue), "day");

  const [rangeValue, setRangeValue] = useState<Typed.IRangeValue>({
    min: minRangeValue,
    max: maxRangeValue,
  });

  const [dateValue, setDateValue] = useState<Typed.IDateValue>({
    min: value?.from || minValue,
    max: value?.to || maxValue,
  });

  const [touched, setTouched] = useState(!!value);

  const handleValueChange = useCallback(
    debounce(DEBOUNCE_INTERVAL, (value: Typed.IDateValue) => {
      if (!value.min && !value.max) {
        onChange(undefined);
      } else {
        onChange({ from: value.min, to: value.max });
      }
    }),
    []
  );

  const handleDateChange = (date: string | Date, key: string): void => {
    setRangeValue({
      ...rangeValue,
      [key]: dayjs(date).diff(dayjs(minValue), "day"),
    });
    let dates;
    if (!date) {
      dates = { min: "", max: "" };
    } else {
      dates = { ...dateValue, [key]: dayjs(date).format("YYYY-MM-DD") };
    }
    setDateValue(dates);
    handleValueChange(dates);
    setTouched(true);
  };

  const handleRangeChange = (value: Typed.IRangeValue): void => {
    const cleanValue = { ...value };
    if (value.min < minRangeValue) {
      cleanValue.min = minRangeValue;
    }
    if (value.max > maxRangeValue) {
      cleanValue.max = maxRangeValue;
    }
    setRangeValue(value);
    const dates = {
      min: dayjs(minValue).add(cleanValue.min, "day").format("YYYY-MM-DD"),
      max: dayjs(minValue).add(cleanValue.max, "day").format("YYYY-MM-DD"),
    };
    setDateValue(dates);
    handleValueChange(dates);
    setTouched(true);
  };

  useEffect(() => {
    // for add
    if (!value) {
      setDateValue({ min: minValue, max: maxValue });
      setRangeValue({ min: minRangeValue, max: maxRangeValue });
      setTouched(false);
    } else {
      setDateValue({ min: value.from, max: value.to });
      setRangeValue({ min: minRangeValue, max: maxRangeValue });
      setTouched(true);
    }
  }, [value]);

  return (
    <Styled.Wrapper touched={touched}>
      {!withoutRange && (
        <InputRange
          minValue={0}
          maxValue={maxRangeValue}
          value={rangeValue}
          onChange={handleRangeChange as (value: number | RangeTyped) => void}
          allowSameValues
        />
      )}
      <Styled.FieldsContainer>
        <DatePicker
          name="min"
          value={dateValue.min ? new Date(dateValue.min) : undefined}
          onDayChange={(date: string | Date) => handleDateChange(date, "min")}
          fromDate={minValue}
          toDate={dateValue.max}
        />
        <DatePicker
          name="max"
          value={dateValue.max ? new Date(dateValue.max) : undefined}
          onDayChange={(date: string | Date) => handleDateChange(date, "max")}
          fromDate={dateValue.min}
          toDate={maxValue}
        />
      </Styled.FieldsContainer>
    </Styled.Wrapper>
  );
};

export { DateRange };
