import React from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { useAppSelector } from "../../../services/hooks";
import { selectIsAuthenticated } from "../../../services/slices/auth";

const ProtectedRoute = ({ ...routeProps }: RouteProps) => {
  const history = useHistory();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (isAuthenticated) {
    return <Route {...routeProps} />;
  }

  const { pathname, search } = history.location;

  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { prevPath: pathname + search },
      }}
    />
  );
};

export default ProtectedRoute;
