import styled from "styled-components/macro";
import { indents } from "styles/indents";

export const Wrapper = styled.div`
  display: flex;
  margin-bottom: ${indents.indent6};
  justify-content: space-between;
`;

export const Left = styled.div`
  display: flex;
  gap: ${indents.indent5};
`;

export const Selects = styled.div`
  width: 460px;
  display: flex;
  gap: ${indents.indent5};
`;

export const Dates = styled.div`
  display: flex;
  gap: ${indents.indent4};
`;
