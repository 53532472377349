import styled from "styled-components/macro";
import { Color } from "../../styles/color";

export const TableWrapper = styled.div`
  width: 100%;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

export const Thead = styled.thead`
  background: #fafbfc;
`;

export const Th = styled.th`
  padding: 12px 12px 20px;
  text-align: left;
  white-space: pre;
  font-family: Lato, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.0025em;
  color: #6b778c;
`;

export const Td = styled.td`
  white-space: pre-wrap;
  padding: 16px 12px;
  font-family: Lato, sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #061938;
  vertical-align: top;
`;

export const RowControls = styled.div<{ width?: number }>`
  width: ${(props) => (props.width ? props.width + "px" : "165px")};
  padding-right: 28px;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
`;

export const Tr = styled.tr`
  &:hover {
    background: ${Color.BLUE50};
  }
  &:hover ${RowControls} {
    opacity: 1;
  }
`;
