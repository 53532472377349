import React, { useEffect, useState } from "react";
import * as Styled from "./Controls.styled";
import { Skeleton } from "components/Skeleton/Skeleton";
import Button from "components/ui/Button/Button";
import { IInitialValues } from "../../forms/forms.const";
import { Color } from "../../../../components/ui/Button/Button.typed";
import { countFulfilledValues } from "../../AddVacancy.utils";
import { ErrorMessageText } from "../../../../components/ErrorMessage/ErrorMessage";

interface IControls {
  formValues: IInitialValues;
  vacancyId: string | undefined;
  status?: string;
  onPublishClick: () => void;
  onSaveToDraftClick: () => void;
  error: string;
}

const Controls: React.FC<IControls> = ({
  formValues,
  vacancyId,
  status,
  onPublishClick,
  onSaveToDraftClick,
  error,
}) => {
  const [disableDraftButton, setDisableDraftButton] = useState(true);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (vacancyId) {
      setTimeout(() => {
        setLoader(false);
      }, 1500);
    } else {
      setLoader(false);
    }
  }, [vacancyId]);

  useEffect(() => {
    const isEmpty = formValues.general_info
      ? countFulfilledValues(formValues.general_info)
      : true;

    setDisableDraftButton(!isEmpty);
  }, [formValues]);

  if (loader)
    return (
      <Styled.Root>
        <Skeleton height={26} />
        <Skeleton height={48} />
        <Skeleton height={40} bottom={8} />
        <Skeleton height={40} bottom={8} />
      </Styled.Root>
    );

  return (
    <Styled.Root>
      <Styled.Title>Save vacancy</Styled.Title>
      <Button color={Color.PRIMARY} onClick={onPublishClick}>
        Publish
      </Button>
      {status !== "published" && (
        <Button
          color={Color.SECONDARY}
          onClick={onSaveToDraftClick}
          disabled={disableDraftButton}
        >
          Save to draft
        </Button>
      )}
      {error && <ErrorMessageText text={error} />}
    </Styled.Root>
  );
};

export default Controls;
