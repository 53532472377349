import React, { useEffect, useState } from "react";
import * as Dashboard from "pages/Dashboard/Dashboard.styled";
import Loader from "../../utils/Loader/Loader";
import * as Styled from "./OnlineCard.styled";
import { getOnlineUsers } from "api/users/users";

const OnlineCard = () => {
  const [total, setTotal] = useState<number | null>(null);
  useEffect(() => {
    (async () => {
      const res = await getOnlineUsers();
      setTotal(res.data.total_users_online);
    })();
  }, []);

  const titleJsx = (
    <Dashboard.Title>
      <Dashboard.TitleText>Online: </Dashboard.TitleText>
      {total === null ? <Loader inline /> : total}
    </Dashboard.Title>
  );

  return <Styled.OnlineCard>{titleJsx}</Styled.OnlineCard>;
};

export default OnlineCard;
