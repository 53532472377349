import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const Container = styled.div<{ fill_step: number | undefined }>`
  display: flex;
  position: absolute;
  left: 24px;
  top: 82px;
  text-align: center;
  background: ${Color.LIGHT10};
  border-radius: 0px 4px 4px 4px;
  padding: ${indents.indent2} ${indents.indent5};
`;

export const Title = styled.span<{ fill_step: number | undefined }>`
  color: ${(props) =>
    props.fill_step === 3 ? `${Color.GREEN300}` : `${Color.BLUE300}`};
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 14px;
`;
