import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";
import { BaseInfo } from "../BaseInfo/BaseInfo.styled";

export const Experience = styled.div`
  border: 1px solid ${Color.LIGHT40};
  position: relative;
  box-sizing: border-box;
  border-radius: 5px;
  min-width: 400px;
  width: 400px;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${Color.LIGHT00};
  white-space: nowrap;
  cursor: default;

  @media (min-width: 1390px) {
    display: flex;
  }
`;

export const ExperienceTitle = styled.div`
  align-self: stretch;
  background-color: ${Color.DARK500};
  backdrop-filter: blur(24px);
  border-radius: 4px;
  color: ${Color.LIGHT40};
  padding: ${indents.indent4};
  box-sizing: border-box;
`;

export const ExperienceTotal = styled.span`
  color: ${Color.LIGHT00};
  font-weight: 700;
  margin-left: ${indents.indent4};
`;

export const ExperienceOuterWrapper = styled.div`
  max-width: 100%;
  padding: ${indents.indent4};
`;

export const ExperienceDetails = styled.div`
  margin-bottom: ${indents.indent4};
`;

export const ExperiencePosition = styled.div`
  line-height: 24px;
  font-weight: 700;
`;

export const ExperienceInnerWrapper = styled(BaseInfo)`
  &:first-child {
    margin-bottom: ${indents.indent2};
    line-height: 24px;
  }

  & > * {
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & > *:last-child {
    flex-shrink: 1;
  }
`;

export const DarkText = styled.span`
  color: ${Color.DARK900};
`;

export const ExperienceNone = styled.div`
  align-self: stretch;
  font-weight: bold;
  color: ${Color.MID90};
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
