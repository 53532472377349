import React from "react";
import { Block, BlockTitle } from "./GeneralInfo.styled";
import { Entries } from "../Entries/Entries";
import { ICvData } from "api/cvs/types";

interface IProps {
  cvData: ICvData;
}

const Location: React.FC<IProps> = ({ cvData }) => {
  const getCitizenshipString = () => {
    let str = cvData?.citizenship || "-";
    if (cvData?.second_citizenship) {
      str += `, ${cvData?.second_citizenship}`;
    }
    return str;
  };

  return (
    <>
      <Block>
        <BlockTitle>Location</BlockTitle>

        <Entries
          entries={[
            {
              label: "Cititzenship",
              value: getCitizenshipString(),
            },
            {
              label: "Current location",
              value: cvData?.city_of_residence || "-",
            },
          ]}
        />
      </Block>
    </>
  );
};

export { Location };
