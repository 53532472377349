import "react-input-range/lib/css/index.css";
import React, { useState, useEffect, useCallback } from "react";
import InputRange, { Range as RangeTyped } from "react-input-range";
import debounce from "lodash/fp/debounce";

import { DEBOUNCE_INTERVAL } from "./constants";
import { DateRange } from "./Date";
import * as Styled from "./Range.styled";
import * as Typed from "./Range.typed";
import TextField, { RegexPattern } from "../TextField";

const Range = ({
  minValue = 0,
  maxValue = 100,
  onChange,
  value,
  maxLength,
  placeholderMax,
  placeholderMin,
}: Typed.IProps) => {
  const [rangeValue, setRangeValue] = useState<Typed.IRangeValue>({
    min: value?.from || minValue,
    max: value?.to || maxValue,
  });

  const [touched, setTouched] = useState(!!value);

  const handleValueChange = useCallback(
    debounce(DEBOUNCE_INTERVAL, (value: Typed.IRangeValue) =>
      onChange(value.min, value.max)
    ),
    []
  );

  const handleChange = (value: Typed.IRangeValue): void => {
    const cleanValue = { ...value };
    if (value.min < minValue) {
      cleanValue.min = minValue;
    }
    if (value.min > maxValue) {
      cleanValue.min = maxValue;
    }
    if (value.max > maxValue) {
      cleanValue.max = maxValue;
    }
    if (value.max < minValue) {
      cleanValue.max = minValue;
    }
    setRangeValue(cleanValue);
    handleValueChange(cleanValue);
    setTouched(true);
  };

  useEffect(() => {
    // for add
    if (!value) {
      setRangeValue({ min: minValue, max: maxValue });
      setTouched(false);
    } else {
      setRangeValue({ min: value.from, max: value.to });
      setTouched(true);
    }
  }, [value]);

  return (
    <Styled.Wrapper touched={touched}>
      <InputRange
        minValue={minValue}
        maxValue={maxValue}
        value={rangeValue}
        onChange={handleChange as (value: number | RangeTyped) => void}
        allowSameValues
      />
      <Styled.FieldsContainer>
        <TextField
          style={{ margin: 0, maxWidth: "106px" }}
          id="min"
          name="min"
          placeholder={placeholderMin || String(minValue)}
          maxLength={maxLength}
          textPosition="center"
          value={touched ? String(rangeValue.min) : ""}
          pattern={RegexPattern.NUMBER}
          onChange={(value) =>
            handleChange({
              min: +value,
              max: rangeValue.max,
            })
          }
        />
        <TextField
          style={{ margin: 0, maxWidth: "106px" }}
          id="max"
          name="max"
          maxLength={maxLength}
          textPosition="center"
          placeholder={placeholderMax || String(maxValue)}
          value={touched ? String(rangeValue.max) : ""}
          pattern={RegexPattern.NUMBER}
          onChange={(value) =>
            handleChange({
              min: rangeValue.min,
              max: +value,
            })
          }
        />
      </Styled.FieldsContainer>
    </Styled.Wrapper>
  );
};

Range.Date = DateRange;

export { Range, Styled as RangeStyled, Typed as RangeTyped };
