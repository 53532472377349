import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import iconArrowBlue from "../../images/icon-arrow-blue.svg";

export const GoBackButton = styled(Link)`
  display: inline-block;
  align-self: flex-start;
  padding-left: 20px;
  font-size: 14px;
  line-height: 20px;
  color: #505f79;
  text-decoration: none;
  background: url(${iconArrowBlue}) no-repeat left;
  cursor: pointer;
`;
