import React from "react";
import {
  components,
  GroupTypeBase,
  IndicatorProps,
  OptionTypeBase,
} from "react-select";

import { StyledDropdownIndicator } from "./DropdownIndicator.styled";

export const DropdownIndicator = (
  props: IndicatorProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>
): JSX.Element => (
  <components.DropdownIndicator {...props}>
    <StyledDropdownIndicator
      isFocused={props.isFocused}
      hasValue={props.hasValue}
      isDisabled={props.isDisabled}
      width="10"
      height="5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.034 0a.4.4 0 01.283.683L5.424 4.576a.6.6 0 01-.848 0L.683.683A.4.4 0 01.966 0h8.068z" />
    </StyledDropdownIndicator>
  </components.DropdownIndicator>
);
