import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as Styled from "./Filters.styled";
import * as Typed from "./Filters.typed";
import { SearchTooltip } from "./SearchTooltip/SearchTooltip";
import { Proficiency } from "./blocks/Proficiency";
import { Experience } from "./blocks/Experience";
import { Personal } from "./blocks/Personal";
import { Documents } from "./blocks/Documents";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { getCoc, selectDC } from "services/slices/dictionaries";
import Button from "components/ui/Button/Button";
import { indents } from "styles/indents";
import { getFormattedParams } from "../../utils";
import { Color } from "components/ui/Button/Button.typed";
import { IParams } from "./Filters.typed";
import { useForm } from "react-hook-form";
import { adaptDataForFilter } from "./Filter.utils";

const Filters = forwardRef<Typed.IRefObject, Typed.IProps>(
  (props: Typed.IProps, ref) => {
    const { onSubmit, onlyGoodCvsFilter, vacancy } = props;

    const { handleSubmit, control, reset, watch, setValue } = useForm();
    const watchFields = watch();

    const [isEmptyForm, setIsEmptyForm] = useState(true);
    const [popupContainer, setPopupContainer] = useState<string | null>(null);

    const dispatch = useAppDispatch();
    const { coc } = useAppSelector(selectDC);

    useEffect(() => {
      if (vacancy) {
        const data = adaptDataForFilter(vacancy);
        Object.keys(data).forEach((item) => {
          setValue(item, data[item]);
        });
      }
    }, [vacancy]);

    useEffect(() => {
      if (!coc?.length) dispatch(getCoc());
    }, []);

    useEffect(() => {
      const isEmpty = !Object.values(watchFields).some((val) => val);
      setIsEmptyForm(isEmpty);
    }, [watchFields]);

    useImperativeHandle(ref, () => ({
      onReset() {
        resetFilters();
      },
    }));

    const submitHandler = (params: IParams) => {
      onSubmit(getFormattedParams(params));
      changePopupContainer(null);
    };

    const resetFilters = (field?: string) => {
      if (field) {
        setValue(field, "");
      } else {
        reset();
        onSubmit({});
      }
    };

    const changePopupContainer = (name: string | null) => {
      // setTimeout - костыль, который нужен для корректного сбрасывания тултипа при клике вне ее области
      setTimeout(() => {
        setPopupContainer(name);
      }, 0);
    };

    return (
      <Styled.Wrapper>
        {popupContainer && (
          <SearchTooltip
            watchFields={watchFields}
            popupContainer={popupContainer}
            setPopupContainer={setPopupContainer}
            onSubmit={handleSubmit(submitHandler)}
            onReset={resetFilters}
            onlyGoodCvsFilter={onlyGoodCvsFilter}
          />
        )}
        <div>
          <Proficiency
            control={control}
            coc={coc}
            changePopupContainer={changePopupContainer}
          />
          <Experience
            control={control}
            changePopupContainer={changePopupContainer}
          />
          <Personal
            control={control}
            countries={props.countries}
            changePopupContainer={changePopupContainer}
          />
          <Documents
            control={control}
            countries={props.countries}
            coc={coc}
            changePopupContainer={changePopupContainer}
          />
          <Styled.ButtonsWrapper>
            <Button
              onClick={handleSubmit(submitHandler)}
              disabled={isEmptyForm}
            >
              Apply
            </Button>
            <Button
              color={Color.SECONDARY}
              onClick={() => resetFilters()}
              disabled={isEmptyForm}
              style={{ marginLeft: indents.indent6 }}
            >
              Reset filters
            </Button>
          </Styled.ButtonsWrapper>
        </div>
      </Styled.Wrapper>
    );
  }
);

export { Filters };
