import React from "react";
import * as Styled from "./Radio.styled";
import { IProps } from "./Radio.typed";

const Radio = (props: IProps) => {
  const {
    checked = false,
    children,
    disabled = false,
    name,
    onChange,
    onFocus,
    value,
  } = props;

  return (
    <Styled.Wrapper>
      <Styled.NativeInput
        defaultChecked={checked}
        disabled={disabled}
        name={name}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
      />
      <Styled.Element />
      {children && <Styled.Text>{children}</Styled.Text>}
    </Styled.Wrapper>
  );
};

export { Radio };
