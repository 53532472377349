import { IOpeningCv } from "api/companies/types";
import React, { useMemo } from "react";
import dayjs from "dayjs";
import * as Styled from "../CompanyPage.styled";
import { getBaseUrl } from "utils/helpers";

export const COLUMNS = [
  {
    Header: "Rank",
    accessor: "col1",
    minWidth: 200,
    maxWidth: 200,
    width: 200,
  },
  {
    Header: "Name",
    accessor: "col2",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Date of opening",
    accessor: "col3",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Charged",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Status",
    accessor: "col5",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

const baseUrl = getBaseUrl();

const prepareRowData = (opening: IOpeningCv) => {
  const handleClick = () => {
    window.open(`${baseUrl}/cv/${opening.cv_id}`, "_blank");
  };

  return {
    col1: Array.isArray(opening.ranks) ? (
      <Styled.Link onClick={handleClick}>
        {opening.ranks.join(", ")}
      </Styled.Link>
    ) : (
      "-"
    ),
    col2: opening.name || "-",
    col3: dayjs(opening.created_at).format("YYYY/MM/DD"),
    col4: opening.price ? `-$${opening.price}` : "-",
    col5: opening.status,
  };
};

export const useOpeningData = (openings: IOpeningCv[]) => {
  return useMemo(() => {
    if (!openings || !openings.length) return [];

    return openings.map((item) => prepareRowData(item));
  }, [openings]);
};
