import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { statsApi } from "../../api";
import { RootState } from "../store";

interface IOtherState {
  smsBalance: number;
  smsBalanceLoading: boolean;
  smsBalanceError: boolean;
}

const initialState: IOtherState = {
  smsBalance: -1,
  smsBalanceLoading: false,
  smsBalanceError: false,
};

export const getSmsBalance = createAsyncThunk<number>("other/sms-balance", () =>
  statsApi.fetchSmsBalance().then(({ data }) => data.smsBalance)
);

export const other = createSlice({
  name: "other",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSmsBalance.pending, (state) => {
      state.smsBalanceLoading = true;
    });
    builder.addCase(getSmsBalance.fulfilled, (state, action) => {
      state.smsBalance = action.payload;
      state.smsBalanceLoading = false;
      state.smsBalanceError = false;
    });
    builder.addCase(getSmsBalance.rejected, (state) => {
      state.smsBalanceError = true;
    });
  },
});

export const selectSmsBalance = (state: RootState) => {
  return state.other.smsBalance;
};
export const selectSmsBalanceLoading = (state: RootState) => {
  return state.other.smsBalanceLoading;
};
export const selectSmsBalanceError = (state: RootState) => {
  return state.other.smsBalanceError;
};

const { reducer } = other;

export default reducer;
