import React from "react";
import ReactLoader from "react-loader-spinner";
import styled from "styled-components/macro";
import { Color } from "../../../styles/color";

export interface ILoader {
  size?: number;
  style?: React.CSSProperties;
  inline?: boolean;
  color?: string;
}

export const Size = {
  SMALL: 24,
  NORMAL: 48,
};

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = ({ size = Size.SMALL, style, inline, color }: ILoader) => {
  const inlineStyles = { marginLeft: 10, justifyContent: "flex-start" };

  let loaderStyles = style;
  if (inline) {
    loaderStyles = { ...loaderStyles, ...inlineStyles };
  }

  return (
    <Wrapper style={loaderStyles}>
      <ReactLoader
        type="TailSpin"
        color={color || Color.BLUE300}
        height={size}
        width={size}
      />
    </Wrapper>
  );
};

export default Loader;
