import { PAGINATION_SIDE } from "./PaginationControls";

export const countPaginationRange = (
  currentPage: number,
  totalPages: number
) => {
  if (PAGINATION_SIDE >= totalPages) {
    return [0, totalPages];
  }

  // если приближаемся к концу, оставлять PAGINATION_SIDE кнопок перед последней кнопкой
  if (currentPage + PAGINATION_SIDE >= totalPages) {
    return [totalPages - 2 * PAGINATION_SIDE, totalPages + 1];
  }

  return [
    currentPage < PAGINATION_SIDE ? 0 : currentPage - PAGINATION_SIDE || 0,
    currentPage < PAGINATION_SIDE
      ? 2 * PAGINATION_SIDE + 1
      : currentPage + PAGINATION_SIDE - 1, // убавляем единицу, чтобы с обеих сторон было поровну кнопок
  ];
};
