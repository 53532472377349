import styled from "styled-components/macro";
import { Color } from "styles/color";

type StyledDropdownIndicatorProps = {
  isFocused?: boolean;
  isDisabled?: boolean;
  hasValue?: boolean;
};

const getFill = (props: StyledDropdownIndicatorProps) => {
  if (props.isFocused || props.hasValue) {
    return Color.BLUE300;
  }

  if (props.isDisabled) {
    return Color.LIGHT50;
  }

  return Color.MID80;
};

export const StyledDropdownIndicator = styled.svg<StyledDropdownIndicatorProps>`
  transform: ${(p) => (p.isFocused ? "rotate(180deg)" : "unset")};
  transition: transform 0.2s ease-in;

  path {
    fill: ${getFill};
  }
`;
