import styled from "styled-components/macro";
import { Color } from "../../styles/color";

export const Text = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK800};
  margin-bottom: 32px;
`;
