import React from "react";
import * as Styled from "./ToggleCvs.styled";

interface IProps {
  onClick: (value: boolean) => void;
  onlyGoodCvsFilter: boolean;
}

const ToggleCvs = ({ onClick, onlyGoodCvsFilter }: IProps) => {
  return (
    <Styled.Root>
      <Styled.Content>
        <Styled.FilterGoodCvs
          onClick={() => onClick(true)}
          active={onlyGoodCvsFilter}
          disabled={onlyGoodCvsFilter}
        >
          <Styled.FilterName>Good CVs only</Styled.FilterName>
        </Styled.FilterGoodCvs>

        <Styled.FilterAllCvs
          onClick={() => onClick(false)}
          active={!onlyGoodCvsFilter}
          disabled={!onlyGoodCvsFilter}
        >
          <Styled.FilterName>All CVs</Styled.FilterName>
        </Styled.FilterAllCvs>
      </Styled.Content>
    </Styled.Root>
  );
};

export { ToggleCvs };
