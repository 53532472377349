import React, { useState, useEffect } from "react";
import * as Styled from "./FromTo.styled";
import TextField, { RegexPattern } from "components/ui/TextField";
import { IFromToProps } from "./FromTo.typed";
import { ErrorMessageText } from "components/ErrorMessage/ErrorMessage";

const FromTo: React.FC<IFromToProps> = ({
  name,
  maxLength,
  value,
  onChange,
  onBlur,
  disabled = false,
  placeholderFrom = "",
  placeholderTo = "",
  error,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name.split("-")[1];
    setCurrentValue({ ...currentValue, [key]: e.target.value });
    onChange({ ...currentValue, [key]: e.target.value });
  };

  return (
    <Styled.Container>
      <Styled.Wrapper>
        <Styled.InputWrapper
          value={currentValue?.from}
          disabled={disabled}
          error={!!error}
        >
          <Styled.Label>From:</Styled.Label>
          <TextField
            id={`${name}-from`}
            name={`${name}-from`}
            maxLength={maxLength}
            value={currentValue?.from || ""}
            pattern={RegexPattern.NUMBER}
            returnEvent
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholderFrom}
            error={!!error}
          />
        </Styled.InputWrapper>
        <Styled.InputWrapper
          value={currentValue?.to}
          disabled={disabled}
          error={!!error}
        >
          <Styled.Label>To:</Styled.Label>
          <TextField
            id={`${name}-from`}
            name={`${name}-to`}
            maxLength={maxLength}
            value={currentValue?.to || ""}
            pattern={RegexPattern.NUMBER}
            returnEvent
            onChange={handleChange}
            onBlur={onBlur}
            disabled={disabled}
            placeholder={placeholderTo}
            error={!!error}
          />
        </Styled.InputWrapper>
      </Styled.Wrapper>
      {error && <ErrorMessageText text={error} />}
    </Styled.Container>
  );
};

export { FromTo };
