import * as Styled from "../../Header.styled";
import { Link, useHistory } from "react-router-dom";
import React from "react";

type TLink = {
  id: number;
  href: string;
  title: string;
};

interface ILinks {
  links: TLink[];
}

const Links = (props: ILinks) => {
  const { location } = useHistory();

  return (
    <Styled.Links>
      {props.links.map((l) => (
        <Styled.LinksItem key={l.id}>
          <Link to={l.href}>
            <Styled.Link active={location.pathname === l.href}>
              {l.title}
            </Styled.Link>
          </Link>
        </Styled.LinksItem>
      ))}
    </Styled.Links>
  );
};

export default Links;
