import React, { useState } from "react";
import * as Styled from "./NotificationModeration.styled";
import Button from "../../components/ui/Button/Button";
import Modal from "../../components/ui/Modal";
import { ICvData, ModerationStatus } from "api/cvs/types";
import { Color, Variant } from "components/ui/Button/Button.typed";
import { moderateCv } from "api/cvs/cvs";
import { getApiError } from "utils/helpers";
import { AxiosResponse } from "axios";
import { ErrorMessageText } from "components/ErrorMessage/ErrorMessage";
import { usePrevious } from "hooks/usePrevious";

interface IProps {
  payload: { type: ModerationStatus | null; cv: ICvData | null };
  isOpen: boolean;
  onClose: () => void;
  updateCv: (cv: ICvData) => void;
}

const NotificationModerationModal = ({
  isOpen,
  onClose,
  payload,
  updateCv,
}: IProps) => {
  const { type, cv } = payload;
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const previousType = usePrevious(type);
  const currentType = previousType || type;

  const userName = `${cv?.first_name || ""} ${cv?.last_name || ""}`.trim();
  const previousName = usePrevious(userName);
  const currentName = previousName || userName;

  const handleModerateStatus = async () => {
    if (!cv || !type) return;
    try {
      setLoader(true);
      moderateCv({ cv_id: cv.cv_id, moderation_status: type });
      updateCv({
        ...cv,
        moderation_status: type,
      });
      setLoader(false);
      onClose();
    } catch (e) {
      const error = e as AxiosResponse;
      setError(getApiError(error));
    }
  };

  return (
    <Modal
      title={"NOTICE"}
      open={isOpen}
      style={{ width: "100%", maxWidth: 575, borderRadius: "8px" }}
      onClose={onClose}
    >
      <Styled.Text>
        Are you sure you want to
        <Styled.Status status={previousType || type}>
          {" "}
          {currentType === ModerationStatus.banned ? "block" : "approve"}{" "}
        </Styled.Status>
        this user {currentName}?
      </Styled.Text>
      <Button
        disabled={loader}
        style={{
          padding: "9px 36px",
          width: "100px",
          marginRight: "16px",
        }}
        onClick={handleModerateStatus}
        color={currentType ? Color.SECONDARY : Color.PRIMARY}
      >
        Yes
      </Button>
      <Button
        disabled={loader}
        style={{
          padding: "10px 26px",
          width: "70px",
        }}
        onClick={onClose}
        variant={Variant.OUTLINED}
      >
        No
      </Button>
      {error && <ErrorMessageText text={error} />}
    </Modal>
  );
};

export { NotificationModerationModal };
