import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Name = styled.div`
  margin-bottom: ${indents.indent2};
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.0015em;
  transition: color 0.3s;

  &:hover {
    color: ${Color.BLUE300};
  }
`;

export const Position = styled.div`
  margin-bottom: ${indents.indent2};
  color: ${Color.BLUE300};
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.0015em;
  transition: color 0.3s;

  &:hover {
    color: ${Color.BLUE400};
  }
`;
