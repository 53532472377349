export type Option<T> = { label: T; value: T };
type TCountry = { Name: string; Code: string };

export const makeOption = <T>(value: T): Option<T> => {
  return {
    label: value,
    value,
  };
};

export const makeOptionCountries = (value: TCountry): Option<string> => {
  return {
    label: value.Name,
    value: value.Name,
  };
};
