import React from "react";
import * as Styled from "./Avatar.styled";
import { formatDate } from "utils/dateTime";
import { ModerationStatus, StatusAvailable } from "api/cvs/types";
import { GoodPerfect } from "../GoodPerfect/GoodPerfect";
import { Moderation } from "../Moderation/Moderation";
interface IProps {
  photo?: string;
  date?: string;
  status?: StatusAvailable;
  fill_step?: number;
  moderation?: ModerationStatus;
}

const getText = (status: StatusAvailable | undefined, date?: string) => {
  if (status === "available") return "Immediately available";
  if (status === "postponed" && date)
    return `Available from ${formatDate(date)}`;
  return "Unavailable now";
};

const Avatar: React.FC<IProps> = ({
  photo,
  date,
  status,
  fill_step,
  moderation,
}) => {
  return (
    <>
      <Styled.AvatarWrapper>
        <Styled.Avatar>
          <img src={photo ?? "/empty-photo.png"} alt="avatar" />
        </Styled.Avatar>
        <GoodPerfect fill_step={fill_step} />
        <Styled.Status status={status}>
          <Styled.StatusIndicator status={status} />
          <span>{getText(status, date)}</span>
        </Styled.Status>
        <Moderation status={moderation} />
      </Styled.AvatarWrapper>
    </>
  );
};

export { Avatar };
