import React from "react";
import { CardRequirements } from "../../components/CardRequirements/CardRequirements";
import { IDocumentsProps } from "./Documents.typed";
import { Controller, useWatch } from "react-hook-form";
import * as Styled from "../forms.styled";
import { ICountry } from "api/dc/types";
import { FieldRow } from "components/FieldRow";
import { Select } from "components/ui/Select/Select";
import { makeOption, makeOptionCountries } from "components/ui/Select/utils";
import { useAppSelector } from "services/hooks";
import { selectDC } from "services/slices/dictionaries";
import { documents } from "../../useGetDictionary";
import { TSelectProps } from "components/ui/Select/Select";

const getErrors = (errors: IDocumentsProps["errors"]) => {
  return {
    err: {
      visas: errors?.visas?.message,
      certificates: errors?.certificates?.message,
      medical_certificates: errors?.medical_certificates?.message,
      competency: errors?.competency?.message,
      proficiency: errors?.proficiency?.message,
    },
  };
};

const Documents: React.FC<IDocumentsProps> = ({
  control,
  defaultValues,
  errors,
}) => {
  const {
    certificates,
    medicalCertificates,
    cop,
    coc,
    endorsement,
    countries,
  } = useAppSelector(selectDC);

  const { err } = getErrors(errors);
  const valueEndorsement: string = useWatch({
    control,
    name: "documents.endorsement",
  });

  const index = countries.findIndex(
    (item: ICountry) => item.Name === "United States"
  );

  const countriesModify = [...countries];
  countriesModify.unshift(...countriesModify.splice(index, 1));

  return (
    <CardRequirements
      title="Documents & Certificates Requirements"
      percent="+35%"
      fields="Visas, certificates, seaman's document, STCW Diploma..."
    >
      <FieldRow label="Visas">
        <Controller
          name="documents.visas"
          control={control}
          defaultValue={defaultValues?.visas ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={[
                { label: "Schengen", value: "Schengen" },
                ...countriesModify.map(makeOptionCountries),
              ]}
              placeholder="Choose visas"
              name={name}
              errors={err.visas}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Certificates">
        <Controller
          name="documents.certificates"
          control={control}
          defaultValue={defaultValues?.certificates ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={certificates.map(makeOption)}
              placeholder="Choose certificates"
              name={name}
              errors={err.certificates}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Medical certificates">
        <Controller
          name="documents.medical_certificates"
          control={control}
          defaultValue={defaultValues?.medical_certificates ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={medicalCertificates.map(makeOption)}
              placeholder="Choose medical certificates"
              name={name}
              errors={err.medical_certificates}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Seaman's document">
        <Controller
          name="documents.seaman_document"
          control={control}
          defaultValue={defaultValues?.seaman_document ?? ""}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={documents.map(makeOption)}
              placeholder="Choose the option"
              name={name}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Travel passport">
        <Controller
          name="documents.travel_passport"
          control={control}
          defaultValue={defaultValues?.travel_passport ?? ""}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={documents.map(makeOption)}
              placeholder="Choose the option"
              name={name}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Competency">
        <Controller
          name="documents.competency"
          control={control}
          defaultValue={defaultValues?.competency ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={coc.map(makeOption)}
              placeholder="Choose competencies"
              name={name}
              errors={err.competency}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Proficiency">
        <Controller
          name="documents.proficiency"
          control={control}
          defaultValue={defaultValues?.proficiency ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={cop.map(makeOption)}
              placeholder="Choose proficiencies"
              name={name}
              errors={err.proficiency}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Endorsement">
        <Controller
          name="documents.endorsement"
          control={control}
          defaultValue={defaultValues?.endorsement ?? ""}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={endorsement.map(makeOption)}
              placeholder="Choose endorsement"
              name={name}
            />
          )}
        />
      </FieldRow>
      {!!valueEndorsement && (
        <>
          <Styled.SubField>
            <FieldRow label="Country">
              <Controller
                name="documents.endorsement_country"
                control={control}
                defaultValue={defaultValues?.endorsement_country ?? ""}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Select
                    value={value as TSelectProps["value"]}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={countries.map(makeOptionCountries)}
                    placeholder="Choose country"
                    name={name}
                  />
                )}
              />
            </FieldRow>
          </Styled.SubField>
          <Styled.SubField>
            <FieldRow label="Competency">
              <Controller
                name="documents.endorsement_competency"
                control={control}
                defaultValue={defaultValues?.endorsement_competency ?? ""}
                render={({ field: { onChange, onBlur, value, name } }) => (
                  <Select
                    value={value as TSelectProps["value"]}
                    onChange={onChange}
                    onBlur={onBlur}
                    options={coc.map(makeOption)}
                    placeholder="Choose competency"
                    name={name}
                  />
                )}
              />
            </FieldRow>
          </Styled.SubField>
        </>
      )}
    </CardRequirements>
  );
};

export { Documents };
