import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const Links = styled.span`
  color: ${Color.BLUE300};
  cursor: pointer;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${indents.indent4};
`;

export const WrapperTooltip = styled.span`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Link = styled.span`
  color: ${Color.BLUE300};
  cursor: pointer;
`;
