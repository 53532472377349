import React from "react";
import styled from "styled-components/macro";

const Empty = styled.div`
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
`;

export const EmptyData = () => {
  return <Empty>We didn`t find any data. Try later.</Empty>;
};
