import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import logo from "../../../images/logo.svg";
import { useAppDispatch, useAppSelector } from "../../../services/hooks";
import { selectIsAuthenticated, signout } from "../../../services/slices/auth";
import { deleteCookie } from "utils/cookies";
import Button from "../../ui/Button/Button";
import { Variant } from "../../ui/Button/Button.typed";
import Links from "./components/Links/Links";

import * as Styled from "./Header.styled";

const LINKS = [
  { id: 0, href: "/", title: "Dashboard" },
  { id: 1, href: "/seamen", title: "Seafarers DB" },
  { id: 2, href: "/applicants", title: "Responses Moderation" },
  { id: 3, href: "/support", title: "Matching Results" },
  { id: 4, href: "/companies", title: "Companies" },
  { id: 5, href: "/emails", title: "E-mails" },
  { id: 6, href: "/seamen/create", title: "Create seaman" },
];

const Header: React.FC = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  const onSignoutClick = () => {
    deleteCookie("auth-token");
    dispatch(signout());
  };

  const linksAndButtonJsx = useMemo(
    () => (
      <>
        <Links links={LINKS} />
        <Button
          variant={Variant.OUTLINED}
          style={{ marginLeft: "auto" }}
          onClick={onSignoutClick}
        >
          Sign out
        </Button>
      </>
    ),
    []
  );

  return (
    <Styled.Root>
      <Styled.Container>
        <Link to="/">
          <Styled.Logo src={logo} alt="Логотип KrewGuru" />
        </Link>
        {isAuthenticated && linksAndButtonJsx}
      </Styled.Container>
    </Styled.Root>
  );
};

export default Header;
