import styled from "styled-components/macro";
import bgDecor from "../../../images/bg-decor/bg-decor.svg";
import { Card } from "../dashboard-cards.styled";

export const SeamenCard = styled(Card)`
  background: url(${bgDecor}) 238px no-repeat,
    linear-gradient(90deg, #000000 0%, #434343 100%);
`;

export const SeamenContent = styled.div`
  display: flex;
  margin-bottom: 11px;
`;
