import React, { useEffect, useState } from "react";

import PageLayout from "../../components/layout/PageLayout/PageLayout";
import SeamenCard from "../../components/dashboard-cards/SeamenCard/SeamenCard";
import CompaniesCard from "../../components/dashboard-cards/CompaniesCard/CompaniesCard";
import SmsCard from "../../components/dashboard-cards/SmsCard/SmsCard";

import bgImage from "../../images/bg-dashboard.png";
import * as Styled from "./Dashboard.styled";
import { ICompany } from "api/companies/types";
import { fetchCompanies } from "../../api/companies/companies";
import OnlineCard from "../../components/dashboard-cards/OnlineCard/OnlineCard";

const Dashboard: React.FC = () => {
  const [companies, setCompanies] = useState<ICompany[]>([]);

  useEffect(() => {
    fetchCompanies().then((res) => {
      setCompanies(res.data);
    });
  }, []);

  return (
    <PageLayout backgroundImage={bgImage}>
      <Styled.Content>
        <Styled.CardsContainer>
          <Styled.CardsColumn>
            <SeamenCard />
            <CompaniesCard
              companiesLength={companies.length}
              loading={!companies.length}
              companies={companies.slice(0, 4)}
            />
          </Styled.CardsColumn>
          <Styled.CardsColumn>
            <SmsCard />
            <OnlineCard />
          </Styled.CardsColumn>
        </Styled.CardsContainer>
      </Styled.Content>
    </PageLayout>
  );
};
export default Dashboard;
