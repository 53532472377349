import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";
import { StatusAvailable } from "api/cvs/types";

const Wrapper = styled.div`
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  border-radius: 0 0 5px 5px;
  padding: ${indents.indent16} ${indents.indent15};
  background: ${Color.LIGHT00};
  width: 204px;
`;

const AvailabilityText = styled.div`
  color: ${Color.DARK900};
  font-weight: bold;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const getBackground = (status: StatusAvailable) => {
  if (status === "available") return Color.GREEN400;
  if (status === "postponed") return Color.YELLOW300;
  return Color.RED300;
};

const Status = styled.div<{
  status: StatusAvailable;
}>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-top: -2px;
  background: ${(props) => getBackground(props.status)};
  margin-right: ${indents.indent3};
`;

export { Wrapper, AvailabilityText, Status };
