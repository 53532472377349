import React, { useEffect } from "react";
import { Controller, Control } from "react-hook-form";
import * as Styled from "../Filters.styled";
import { Accordion } from "../Accordion/Accordion";
import { Select } from "components/ui/Select/Select";
import { makeOption } from "components/ui/Select/utils";
import { Range } from "components/ui/Range";
import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  getFuelTypes,
  getMETypes,
  getShipTypes,
  selectDC,
} from "services/slices/dictionaries";
import { Names } from "../Filter.utils";

interface IProps {
  control: Control;
  changePopupContainer: (name: string) => void;
}

const Experience = React.memo(({ control, changePopupContainer }: IProps) => {
  const dispatch = useAppDispatch();
  const { shipTypes, meTypes, fuelTypes } = useAppSelector(selectDC);

  useEffect(() => {
    if (!shipTypes?.length) dispatch(getShipTypes());
    if (!meTypes?.length) dispatch(getMETypes());
    if (!fuelTypes?.length) dispatch(getFuelTypes());
  }, []);

  const onChangeField = (name: string) => {
    changePopupContainer(name);
  };

  return (
    <>
      <Styled.Subtitle isPaddingTop>Vessel experience</Styled.Subtitle>
      <div className="FilterWrapper" id={Names.ship_type}>
        <Accordion title="Vessel types" defaultOpened>
          <Controller
            name={Names.ship_type}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Select
                // @ts-ignore ~ react-select types error 👀
                isMulti
                value={value}
                onChange={(val) => {
                  onChangeField(name);
                  onChange(val);
                }}
                options={shipTypes.map(makeOption)}
                placeholder="Choose vessel types"
                name={name}
                maxValuesLength={10}
              />
            )}
          />
        </Accordion>
      </div>
      <div className="FilterWrapper" id={Names.experience_ship_types}>
        <Accordion title="Vessel experience" defaultOpened>
          <Controller
            name={Names.experience_ship_types}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Range
                minValue={0}
                maxLength={2}
                maxValue={30}
                onChange={(from: number, to: number): void => {
                  onChangeField(name);
                  onChange({ from, to });
                }}
                value={value}
              />
            )}
          />
        </Accordion>
      </div>
      <div className="FilterWrapper" id={Names.ship_me_type}>
        <Accordion title="Engine Types" defaultOpened>
          <Controller
            name={Names.ship_me_type}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Select
                // @ts-ignore ~ react-select types error 👀
                isMulti
                value={value}
                onChange={(val) => {
                  onChangeField(name);
                  onChange(val);
                }}
                options={meTypes.map(makeOption)}
                placeholder="Choose engine types"
                name={name}
                maxValuesLength={5}
              />
            )}
          />
        </Accordion>
      </div>
      <div className="FilterWrapper" id={Names.ship_kw}>
        <Accordion title="Engine Power (kw)" defaultOpened>
          <Controller
            name={Names.ship_kw}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Range
                minValue={0}
                maxValue={100000}
                maxLength={6}
                onChange={(from: number, to: number): void => {
                  onChangeField(name);
                  onChange({ from, to });
                }}
                value={value}
              />
            )}
          />
        </Accordion>
      </div>
      <div className="FilterWrapper" id={Names.ship_dwt}>
        <Accordion title="DWT" defaultOpened>
          <Controller
            name={Names.ship_dwt}
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Range
                minValue={50}
                maxValue={1000000}
                maxLength={7}
                onChange={(from: number, to: number): void => {
                  onChangeField(name);
                  onChange({ from, to });
                }}
                value={value}
              />
            )}
          />
        </Accordion>
      </div>
    </>
  );
});

export { Experience };
