import React from "react";

import * as Styled from "./FieldRow.styled";
import * as Typed from "./FieldRow.typed";

const FieldRow: React.FC<Typed.IFieldRow> = (props) => {
  const { children, label = "", required } = props;

  return (
    <Styled.Wrapper>
      <Styled.LabelContainer>
        {!!label && (
          <Styled.Label>
            {label}
            {required && <span>*</span>}
          </Styled.Label>
        )}
      </Styled.LabelContainer>
      <Styled.InputContainer>
        <Styled.Field>{children}</Styled.Field>
      </Styled.InputContainer>
    </Styled.Wrapper>
  );
};

export { FieldRow };
