import React, { useEffect, useState } from "react";
import { EmptyData } from "components/EmptyData/EmptyData";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import { fetchResponses } from "../../api/responses/responses";
import { IResponse } from "api/responses/types";
import Loader from "../../components/utils/Loader/Loader";
import PaginationControls from "../../components/PaginationControls/PaginationControls";
import { useApplicantsData, COLUMNS } from "./Applicants.utils";
import Table from "../../components/Table/Table";
import Filter from "../../components/Filter/Filter";
import { NotificationModal } from "../../modals/Notification/Notification";

export enum FiltersApplicants {
  pending = "Pending",
  declined = "Declined",
  validated = "Validated",
}

interface IItemsFilter {
  name: FiltersApplicants;
  amount: number;
}

const Applicants: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [responses, setResponses] = useState<IResponse[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [itemsFilter, setItemsFilter] = useState<IItemsFilter[]>([]);
  const [filter, setFilter] = useState(FiltersApplicants.pending);

  const [openNotifyId, setOpenNotifyId] = useState<string | null>(null);

  useEffect(() => {
    getResponses();
  }, [currentPage, filter]);

  const setActiveFilter = (val: FiltersApplicants) => {
    setFilter(val);
    setCurrentPage(0);
  };

  const getResponses = async () => {
    try {
      setLoading(true);
      const data = await fetchResponses(getQuery());
      setResponses(data.data.responses);
      const total = getTotal(data.data);
      setTotal(total);
      setItemsFilter([
        {
          name: FiltersApplicants.pending,
          amount: data.data.total_pending,
        },
        {
          name: FiltersApplicants.declined,
          amount: data.data.total_declined,
        },
        {
          name: FiltersApplicants.validated,
          amount: data.data.total_validated,
        },
      ]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const getQuery = (): string => {
    let query = `limit=${10}&skip=${currentPage * 10}`;
    query += getFilterQuery();
    return query;
  };

  const getFilterQuery = () => {
    switch (filter) {
      case FiltersApplicants.pending:
        return "&is_pending=true";
      case FiltersApplicants.declined:
        return "&is_declined=true";
      case FiltersApplicants.validated:
        return "&is_validated=true";
    }
  };

  const getTotal = (data: {
    total_pending: number;
    total_declined: number;
    total_validated: number;
  }) => {
    if (filter === FiltersApplicants.pending) return data.total_pending;
    if (filter === FiltersApplicants.declined) return data.total_declined;
    else return data.total_validated;
  };

  const data = useApplicantsData(responses, filter, setOpenNotifyId);

  if (!loading && !responses.length)
    return (
      <PageLayout>
        <Filter setFilter={setActiveFilter} items={itemsFilter} />
        <EmptyData />
      </PageLayout>
    );

  return (
    <PageLayout>
      {itemsFilter.length ? (
        <Filter setFilter={setActiveFilter} items={itemsFilter} />
      ) : null}
      {loading ? <Loader /> : <Table columns={COLUMNS} data={data} />}
      <PaginationControls totalElements={total} changePage={setCurrentPage} />
      <NotificationModal
        isOpen={!!openNotifyId}
        onClose={() => setOpenNotifyId(null)}
        userId={openNotifyId}
      />
    </PageLayout>
  );
};

export default Applicants;
