import React, { useEffect, useState, Fragment } from "react";
import * as Styled from "../../Support.styled";
import { fetchMatchingCvs } from "api/cvs/cvs";
import { ICvData } from "api/cvs/types";
import Loader from "components/utils/Loader/Loader";
import { COLUMNS, useSeafarers } from "./Seafarers.utils";
import PaginationControls from "components/PaginationControls/PaginationControls";
import { NotificationModal } from "modals/Notification/Notification";
import { UpdateRanks } from "components/TooltipEditCv/UpdateRanks/UpdateRanks";
import { UpdateStatusAvailable } from "components/TooltipEditCv/UpdateStatusAvailable/UpdateStatusAvailable";
import { IVacancy } from "api/vacancies/types";
import { fetchMatchingVacancies } from "api/vacancies/vacancies";
import { VacanciesTable } from "./Vacancies.utils";
import { Filters } from "../Filters/Filters";
import { EmptyData } from "../../../../components/EmptyData/EmptyData";

export enum TooltipType {
  ranks = "ranks",
  statusAvailable = "statusAvailable",
}

interface ITooltipOpen {
  type: null | TooltipType;
  cv: null | ICvData;
}

export interface ICvWithVacancies extends ICvData {
  vacancies?: IVacancy[];
}

const Seafarers = () => {
  const [loading, setLoading] = useState(true);
  const [cvs, setCvs] = useState<ICvWithVacancies[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [filter, setFilter] = useState<Record<string, string>>({
    rank: "",
    vacancy: "",
  });

  const [openNotifyId, setOpenNotifyId] = useState<string | null>(null);
  const [tooltip, setTooltip] = useState<ITooltipOpen>({
    type: null,
    cv: null,
  });

  useEffect(() => {
    const controller = new AbortController();
    getCvs(controller);
    return () => {
      controller.abort();
    };
  }, [currentPage, filter]);

  const changeFilter = (val: Record<string, string>) => {
    setFilter(val);
    setCurrentPage(0);
  };

  const getCvs = async (controller: AbortController) => {
    try {
      setLoading(true);
      const res = await fetchMatchingCvs(getQuery(), controller.signal);
      setTotal(res.data.total);
      getVacancies(res.data.cvs);
    } catch (e) {
      setLoading(false);
    }
  };

  const getVacancies = async (cvs: ICvData[]) => {
    const arrCvs: ICvWithVacancies[] = [...cvs];
    try {
      const res = await Promise.allSettled(
        cvs.map((item) => fetchMatchingVacancies(`cv_id=${item.cv_id}`))
      );
      res.forEach((item, index) => {
        if (item.status === "fulfilled") {
          arrCvs[index] = {
            ...arrCvs[index],
            vacancies: item.value.data.vacancies,
          };
        } else {
          arrCvs[index] = { ...arrCvs[index], vacancies: [] };
        }
      });
      setCvs(arrCvs);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const getQuery = () => {
    let query = `limit=10&skip=${currentPage * 10}`;
    if (!filter.vacancy) query += "&all_matching_seamen=true";
    if (filter.rank) query += `&rank=${filter.rank}`;
    if (filter.vacancy) query += `&vac_id=${filter.vacancy}`;
    return query;
  };

  const openTooltip = (type: TooltipType, cv: ICvData) => {
    setTooltip({ type: type, cv: cv });
  };

  const closeTooltip = () => {
    setTooltip({ type: null, cv: null });
  };

  const updateCv = (cv: ICvData) => {
    const arr = cvs.map((item) => {
      if (item.cv_id === cv.cv_id) {
        return cv;
      }
      return item;
    });
    setCvs(arr);
  };

  const data = useSeafarers(cvs, setOpenNotifyId, openTooltip);

  if (!loading && !cvs.length)
    return (
      <>
        <Filters changeQuery={changeFilter} />
        <EmptyData />
      </>
    );

  return (
    <>
      <Filters changeQuery={changeFilter} />
      {loading ? (
        <Loader />
      ) : (
        <Styled.Table>
          <Styled.THead>
            <Styled.TRow>
              {COLUMNS.map((item, index) => (
                <Styled.TH
                  key={index}
                  style={{
                    width: `${item.width}px`,
                    maxWidth: `${item.maxWidth}px`,
                    minWidth: `${item.minWidth}px`,
                  }}
                >
                  {item.Header}
                </Styled.TH>
              ))}
            </Styled.TRow>
          </Styled.THead>
          <Styled.TBody>
            {data.map((item, index) => (
              <Fragment key={index}>
                <Styled.TRow>
                  {Object.values(item).map((val, key) => (
                    <Styled.TD
                      key={key}
                      style={{
                        width: `${COLUMNS[key].width}px`,
                        maxWidth: `${COLUMNS[key].maxWidth}px`,
                        minWidth: `${COLUMNS[key].minWidth}px`,
                      }}
                    >
                      {val}
                    </Styled.TD>
                  ))}
                </Styled.TRow>
                {cvs[index].vacancies?.length ? (
                  <Styled.TRow>
                    <Styled.TD colSpan={6}>
                      <VacanciesTable cv={cvs[index]} />
                    </Styled.TD>
                  </Styled.TRow>
                ) : null}
              </Fragment>
            ))}
          </Styled.TBody>
        </Styled.Table>
      )}
      <PaginationControls totalElements={total} changePage={setCurrentPage} />
      <NotificationModal
        isOpen={!!openNotifyId}
        onClose={() => setOpenNotifyId(null)}
        userId={openNotifyId}
      />
      {tooltip.type === TooltipType.ranks && (
        <UpdateRanks
          cv={tooltip.cv}
          onClose={closeTooltip}
          updateCv={updateCv}
        />
      )}
      {tooltip.type === TooltipType.statusAvailable && (
        <UpdateStatusAvailable
          cv={tooltip.cv}
          onClose={closeTooltip}
          updateCv={updateCv}
        />
      )}
    </>
  );
};

export default Seafarers;
