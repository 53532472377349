import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import TextField from "../../components/ui/TextField";
import { PasswordInput } from "../../components/ui/PasswordInput/PasswordInput";
import * as yup from "yup";
import { FieldRow } from "../../components/FieldRow";
import Button from "../../components/ui/Button/Button";
import { ValidationError } from "yup";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../services/hooks";
import {
  selectIsAuthenticated,
  setIsAuthenticated,
} from "../../services/slices/auth";
import { getApiError } from "../../utils/helpers";
import { setCookie } from "utils/cookies";
import { AxiosResponse } from "axios";
import { auth } from "../../api/auth/auth";

const Root = styled.div`
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h1`
  margin-top: 150px;
  font-size: 32px;
`;

const Form = styled.form`
  margin-top: 50px;
  width: 100%;
  max-width: 500px;
`;

interface IError {
  email?: string;
  password?: string;
  api?: string;
}

const LoginPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory<{ prevPath: string }>();
  const [email, setEmail] = useState("");
  const [error, setError] = useState<IError>({});
  const [password, setPassword] = useState("");

  const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
  });

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const prevPath = useMemo(() => history.location.state?.prevPath, [history]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push(prevPath || "/");
    }
  }, [isAuthenticated]);

  const handleFormSubmit = async () => {
    setError({});
    const valid = await validation();
    if (valid) {
      try {
        const res = await auth(email, password);
        setCookie("auth-token", res.data.auth_token);
        dispatch(setIsAuthenticated());
        history.push("/");
      } catch (e) {
        const error = e as AxiosResponse;
        setError({ api: getApiError(error) });
      }
    }
  };

  const validation = async () => {
    const validResult = await schema
      .validate({ email: email, password: password }, { abortEarly: false })
      .then(() => false)
      .catch((err) => err);

    if (validResult) {
      const errors = {} as Record<string, string>;
      validResult.inner.forEach((val: ValidationError) => {
        if (val.path) errors[val.path] = val.message;
      });
      setError(errors);
      return false;
    }
    return true;
  };

  const onSubmit = (evt: React.SyntheticEvent<HTMLFormElement>) => {
    evt.preventDefault();
    handleFormSubmit();
  };

  return (
    <PageLayout>
      <Root>
        <Title>Log in admin panel</Title>
        <Form onSubmit={onSubmit}>
          <FieldRow label="Email">
            <TextField
              id="email"
              name="email"
              placeholder="Your e-mail"
              value={email}
              error={error["email"]}
              onChange={(evt) => setEmail(evt.target.value)}
            />
          </FieldRow>
          <FieldRow label="Password">
            <PasswordInput
              id="password"
              name="password"
              placeholder="Your password"
              value={password}
              error={error["password"]}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </FieldRow>
          <Button type="submit">Login</Button>
          {error["api"] && <ErrorMessageText text={error["api"]} />}
        </Form>
      </Root>
    </PageLayout>
  );
};

export default LoginPage;
