import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";
import { Wrapper as FieldRow } from "components/FieldRow/FieldRow.styled";

export const Wrapper = styled.div`
  width: 100%;
  padding: ${indents.indent5} 30px;
  background-color: ${Color.LIGHT00};
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: ${indents.indent5};

  & > ${FieldRow} {
    margin-bottom: 0;
  }
`;

export const Subtitle = styled.h3`
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: ${indents.indent5};
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK900};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${indents.indent5};
`;
