import React from "react";
import styled from "styled-components/macro";
import { Card } from "../Card/Card";
import { indents } from "styles/indents";
import { formatDate } from "utils/dateTime";
import { ICvData } from "api/cvs/types";
import dayjs from "dayjs";
import { Color } from "styles/color";

type Props = {
  cvData: ICvData | undefined;
};

const PhoneWrapper = styled.div`
  display: flex;
  gap: ${indents.indent4};
`;

const Icon = styled.img`
  display: block;
  width: 20px;
  height: auto;
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CheckIcon = styled.span`
  color: ${Color.GREEN300};
`;

export const BlueText = styled.span`
  color: #0065ff;
`;

export const SingularBlocks = ({ cvData }: Props) => {
  const getSeamansBookFooter = () => {
    if (cvData?.sb_status === "not_exist") {
      return (
        <>
          I will show during further <BlueText>interview</BlueText>
        </>
      );
    } else if (cvData?.sb_status === "later") {
      return (
        <>
          I will receive Seaman`&apos;`s book
          <BlueText>
            <br />
            on {dayjs(cvData?.sb_valid_until).format("YYYY MMMM D")}
          </BlueText>
        </>
      );
    }
    return null;
  };

  return (
    <Wrapper>
      <Card
        title="Contacts"
        entries={[
          {
            label: (
              <>
                Email
                {cvData?.email_is_verified && <CheckIcon> ✓</CheckIcon>}
              </>
            ),
            value: cvData?.email || "-",
          },
          {
            label: (
              <>
                Phone
                {cvData?.phone_is_verified && <CheckIcon> ✓</CheckIcon>}
              </>
            ),
            value: cvData?.phone ? (
              <PhoneWrapper>
                +{cvData?.phone}
                {cvData?.preferred_messenger && (
                  <Icon src={`/svg/${cvData?.preferred_messenger}.svg`} />
                )}
              </PhoneWrapper>
            ) : (
              "-"
            ),
          },
        ]}
      />
      <Card
        title="Seaman's document"
        entries={[
          {
            label: "Country of issue",
            value: cvData?.sb_country_of_issue || "-",
          },
          {
            label: "Book number",
            value: cvData?.sb_number || "-",
          },
          {
            label: "Valid until",
            value: cvData?.sb_is_perpetual ? (
              <>No expiration date</>
            ) : (
              (cvData?.sb_valid_until && formatDate(cvData?.sb_valid_until)) ||
              "-"
            ),
          },
        ]}
        footer={getSeamansBookFooter()}
        filePath={cvData?.sb_file_path}
      />
      <Card
        title="Biometrics"
        entries={[
          {
            label: "Height, cm",
            value: (cvData?.height && cvData?.height + " cm") || "-",
          },
          {
            label: "Weight, kg",
            value: (cvData?.weight && cvData?.weight + " kg") || "-",
          },
          {
            label: "Body Mass Index",
            value:
              (typeof cvData?.body_mass_index === "number" &&
                cvData?.body_mass_index.toFixed(1)) ||
              "-",
          },
          {
            label: "Overall size",
            value: cvData?.overall_size || "-",
          },
          {
            label: "Shoe size, EU",
            value: cvData?.shoe_size || "-",
          },
        ]}
      />
    </Wrapper>
  );
};
