import React, { useEffect, useMemo, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import TextField, { RegexPattern } from "components/ui/TextField";
import { Checkbox } from "components/ui/Checkbox";
import { IWorkProps } from "./WorkExperience.typed";
import * as Styled from "../forms.styled";
import { FieldRow } from "components/FieldRow";
import { Select } from "components/ui/Select/Select";
import { makeOption } from "components/ui/Select/utils";
import { CardRequirements } from "../../components/CardRequirements/CardRequirements";
import { FromTo } from "components/ui/FromTo/FromTo";
import { useAppSelector } from "services/hooks";
import { selectDC } from "services/slices/dictionaries";
import { TSelectProps } from "components/ui/Select/Select";

const getErrors = (errors: IWorkProps["errors"]) => {
  return {
    err: {
      total_contracts: errors?.total_contracts?.message,
      contracts_rank: errors?.contracts_rank?.message,
      contracts_ships: errors?.contracts_ships?.message,
      ship_types: errors?.ship_types?.message,
      dwt: errors?.dwt?.from?.message || errors?.dwt?.to?.message,
      kw: errors?.kw?.from?.message || errors?.kw?.to?.message,
      ship_me_types: errors?.ship_me_types?.message,
      fuel_types: errors?.fuel_types?.message,
    },
  };
};

const WorkExperience: React.FC<IWorkProps> = ({
  defaultValues,
  control,
  errors,
}) => {
  const { err } = getErrors(errors);
  const [disabled, setDisabled] = useState(false);
  const { shipTypes, fuelTypes, meTypes } = useAppSelector(selectDC);
  const valueShipTypes: Array<string> = useWatch({
    control,
    name: "work_experience.ship_types",
  });

  const isNoExperience: boolean = useWatch({
    control,
    name: "work_experience.no_experience_allowed",
  });

  const shipTypeGeneral = useWatch<any>({
    control,
    name: "general_info.ship_type",
  });

  // Дизейблим все поля, если поставлена галочка
  useEffect(() => {
    setDisabled(isNoExperience);
  }, [isNoExperience]);

  const shipTypesModify = useMemo(() => {
    const index = shipTypes.indexOf(shipTypeGeneral);
    if (index !== -1) {
      const shipTypesModify = [...shipTypes];
      shipTypesModify.unshift(...shipTypesModify.splice(index, 1));
      return shipTypesModify;
    }
    return shipTypes;
  }, [shipTypeGeneral, shipTypes]);

  return (
    <CardRequirements
      title="Work Experience Requirements"
      percent="+50%"
      fields="Total work experience, ship type experience, DWT...."
    >
      <div style={{ marginBottom: "10px" }}>
        <Controller
          name="work_experience.no_experience_allowed"
          control={control}
          defaultValue={defaultValues?.no_experience_allowed ?? false}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.checked);
              }}
            >
              No experience allowed
            </Checkbox>
          )}
        />
      </div>
      <div className="inputContainer" id="total_contracts">
        <FieldRow label="Total contracts" required>
          <Controller
            name="work_experience.total_contracts"
            control={control}
            defaultValue={defaultValues?.total_contracts ?? ""}
            render={({ field: { onChange, name, value, onBlur } }) => (
              <TextField
                disabled={disabled}
                maxLength={2}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder="Minimum number of contracts"
                pattern={RegexPattern.NUMBER}
                error={disabled ? false : err.total_contracts}
              />
            )}
          />
        </FieldRow>
      </div>
      <div className="inputContainer" id="contracts_rank">
        <FieldRow label="Contracts in rank" required>
          <Controller
            name="work_experience.contracts_rank"
            control={control}
            defaultValue={defaultValues?.contracts_rank ?? ""}
            render={({ field: { onChange, onBlur, name, value } }) => (
              <TextField
                disabled={disabled}
                maxLength={2}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder="Minimum number of contracts in rank"
                pattern={RegexPattern.NUMBER}
                error={disabled ? false : err.contracts_rank}
              />
            )}
          />
        </FieldRow>
      </div>
      <div className="inputContainer" id="ship_types">
        <FieldRow label="Ship types worked on" required>
          <Controller
            name="work_experience.ship_types"
            control={control}
            defaultValue={defaultValues?.ship_types ?? []}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <Select
                // @ts-ignore
                isMulti={true}
                isDisabled={disabled}
                value={value as TSelectProps["value"]}
                onChange={onChange}
                onBlur={onBlur}
                options={shipTypesModify.map(makeOption)}
                placeholder="Choose ship types"
                name={name}
                errors={disabled ? false : err.ship_types}
              />
            )}
          />
        </FieldRow>
      </div>
      {!!valueShipTypes?.length && (
        <Styled.SubField>
          <div className="inputContainer" id="contracts_ships">
            <FieldRow label="Contracts on the ships">
              <Controller
                name="work_experience.contracts_ships"
                control={control}
                defaultValue={defaultValues?.contracts_ships ?? ""}
                render={({ field: { onChange, onBlur, name, value } }) => (
                  <TextField
                    disabled={disabled}
                    maxLength={2}
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder="Minimum number of contracts"
                    pattern={RegexPattern.NUMBER}
                    error={err.contracts_ships}
                  />
                )}
              />
            </FieldRow>
          </div>
        </Styled.SubField>
      )}
      <div className="inputContainer" id="dwt">
        <FieldRow label="DWT">
          <Controller
            name="work_experience.dwt"
            control={control}
            defaultValue={defaultValues?.dwt ?? { from: "", to: "" }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FromTo
                disabled={disabled}
                maxLength={6}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholderFrom="50"
                placeholderTo="900 000"
                error={err.dwt}
              />
            )}
          />
        </FieldRow>
      </div>
      <div className="inputContainer" id="kw">
        <FieldRow label="kW">
          <Controller
            name="work_experience.kw"
            control={control}
            defaultValue={defaultValues?.kw ?? { from: "", to: "" }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <FromTo
                disabled={disabled}
                maxLength={6}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholderFrom="0"
                placeholderTo="100 000"
                error={err.kw}
              />
            )}
          />
        </FieldRow>
      </div>
      <FieldRow label="ME Type">
        <Controller
          name="work_experience.ship_me_types"
          control={control}
          defaultValue={defaultValues?.ship_me_types ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              isDisabled={disabled}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={meTypes.map(makeOption)}
              placeholder="Choose ME types"
              name={name}
              errors={err.ship_me_types}
            />
          )}
        />
      </FieldRow>
      <FieldRow label="Fuel Type">
        <Controller
          name="work_experience.fuel_types"
          control={control}
          defaultValue={defaultValues?.fuel_types ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              isDisabled={disabled}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={fuelTypes.map(makeOption)}
              placeholder="Choose fuel types"
              name={name}
              errors={err.fuel_types}
            />
          )}
        />
      </FieldRow>
    </CardRequirements>
  );
};

export { WorkExperience };
