import { useEffect, useState } from "react";

function createRootElement(id: string) {
  const rootContainer = document.createElement("div");
  rootContainer.setAttribute("id", id);
  return rootContainer;
}

function usePortal(id: string) {
  const [node, setNode] = useState<Element | null>(null);

  useEffect(() => {
    const container = document.querySelector(`#${id}`);
    const parentElem = container || createRootElement(id);
    if (!container) {
      document.body.appendChild(parentElem);
    }
    setNode(parentElem);

    return () => {
      setNode(null);
      if (!parentElem.childElementCount) {
        parentElem.remove();
      }
    };
  }, [id]);

  return node;
}

export { usePortal };
