import React from "react";
import styled from "styled-components/macro";

import { DiplomaType } from "api/cvs/types";
import { formatDate } from "utils/dateTime";

import { Card, Title } from "../Card/Card";
import { Date, StubText } from "../../Cv.styled";

type Props = {
  diplomas: DiplomaType[] | undefined;
  getLater: boolean | undefined;
  date: string | undefined;
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export enum typeDiploma {
  competency = "competency",
  proficiency = "proficiency",
  endorsement = "endorsement",
}

const getTitle = (type: string) => {
  if (type === typeDiploma.competency) return "Certificate of competency";
  if (type === typeDiploma.proficiency) return "Certificate of proficiency";
  return "Endorsement";
};

export const Diplomas = ({
  diplomas = [],
  getLater = false,
  date = "",
}: Props) => {
  if (!diplomas.length) {
    return (
      <>
        <Title>STCW Diplomas</Title>
        {getLater ? (
          <StubText>
            I will receive STCW Diplomas on{" "}
            <Date>{formatDate(date, "YYYY MMMM DD")}</Date>
          </StubText>
        ) : (
          <StubText>
            I will show during further <Date>interview</Date>
          </StubText>
        )}
      </>
    );
  }

  return (
    <>
      <Title>STCW Diplomas</Title>
      <Wrapper>
        {diplomas.map((diploma, index) => (
          <Card
            key={index}
            title={getTitle(diploma.cert_type)}
            entries={[
              {
                label: "Endorsement",
                value: diploma.endorsement,
              },
              {
                label: "Proficiency",
                value: diploma.proficiency,
              },
              {
                label: "Competency",
                value: diploma.competency,
              },
              {
                label: "License number",
                value: diploma.license_number || "",
              },
              {
                label: "Country of issue",
                value: diploma.country_of_issue || "",
              },
              {
                label: "Valid until",
                value: formatDate(diploma.valid_until) || "",
              },
            ]}
            filePath={diploma.file_path}
          />
        ))}
      </Wrapper>
    </>
  );
};
