import React, { useEffect, useState } from "react";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import Table from "../../components/Table/Table";
import { COLUMNS } from "./Companies.const";
import { useCompaniesData } from "./Companies.utils";
import Loader from "../../components/utils/Loader/Loader";
import CompaniesCard from "../../components/dashboard-cards/CompaniesCard/CompaniesCard";
import { CardsWrapper } from "../../components/dashboard-cards/dashboard-cards.styled";
import ManagersCard from "../../components/dashboard-cards/ManagersCard/ManagersCard";
import { fetchCompanies, getStatsCompanies } from "api/companies/companies";
import { ICompany } from "api/companies/types";
import TextField from "../../components/ui/TextField";
import { DeleteModal } from "../../modals/Delete/Delete";
import VerifyCompanyModal from "../../modals/VerifyCompanyModal/VerifyCompanyModal";

const Companies: React.FC = () => {
  const [openDeleteId, setOpenDeleteId] = useState<string | null>(null);
  const [openVerifyId, setOpenVerifyId] = useState<string | null>(null);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [allManagers, setAllManagers] = useState(0);
  const [sortCompanies, setSortCompanies] = useState<ICompany[]>([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");

  useEffect(() => {
    (async () => {
      const res = await fetchCompanies();
      const stats = await getStatsCompanies();
      setAllManagers(stats.data.managers);
      setCompanies(res.data);
      setSortCompanies(res.data);
      setLoading(false);
    })();
  }, []);

  const selectCompany = (id: string) => {
    return companies.find((item) => item.company_id === id);
  };

  const successVerify = () => {
    const changeValue = (item: ICompany) => {
      if (item.company_id === openVerifyId) {
        return { ...item, is_verified: true };
      }
      return item;
    };
    setCompanies(companies.map(changeValue));
    setSortCompanies(sortCompanies.map(changeValue));
  };

  const handleVerify = (id: string) => {
    setOpenVerifyId(id);
  };

  const handleDelete = (id: string) => {
    setOpenDeleteId(id);
  };

  const successDelete = () => {
    const deleteValue = (item: ICompany) => item.company_id !== openDeleteId;
    setCompanies(companies.filter(deleteValue));
    setSortCompanies(sortCompanies.filter(deleteValue));
    setOpenDeleteId(null);
  };

  const data = useCompaniesData(sortCompanies, handleVerify, handleDelete);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchStr = e.target.value;
    setSearch(searchStr);
    if (searchStr) {
      const lowerCaseSearch = searchStr.toLowerCase();
      const arr = companies.filter((item) => {
        return (
          (item.title && item.title.toLowerCase().includes(lowerCaseSearch)) ||
          (item.phone && item.phone.includes(lowerCaseSearch)) ||
          (item.email && item.email.toLowerCase().includes(lowerCaseSearch)) ||
          (item.office_location &&
            item.office_location.toLowerCase().includes(lowerCaseSearch))
        );
      });
      setSortCompanies(arr);
    } else {
      setSortCompanies(companies);
    }
  };

  return (
    <PageLayout>
      <>
        <CardsWrapper>
          <CompaniesCard
            small
            companiesLength={companies.length}
            loading={loading}
          />
          <ManagersCard loading={loading} managers={allManagers} />
        </CardsWrapper>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div style={{ marginBottom: "16px" }}>
              <TextField
                help="Search by company title, location, phone or email"
                id="search"
                name="search"
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </div>
            <Table columns={COLUMNS} data={data} />
          </>
        )}
      </>
      <DeleteModal
        open={!!openDeleteId}
        type={"company"}
        onClose={() => setOpenDeleteId(null)}
        payload={selectCompany(openDeleteId as string)}
        callback={successDelete}
      />
      <VerifyCompanyModal
        callback={successVerify}
        id={selectCompany(openVerifyId as string)?.company_id || ""}
        name={selectCompany(openVerifyId as string)?.title || ""}
        open={!!openVerifyId}
        onClose={() => setOpenVerifyId(null)}
      />
    </PageLayout>
  );
};

export default Companies;
