import React, { useMemo } from "react";
import * as Styled from "../../Card.styled";
import { Wrapper, Citizenship } from "./CitiAndLang.styled";
import ReactCountryFlag from "react-country-flag";
import { ICountries } from "api/dc/types";

interface IProps {
  citizenship?: string;
  englishLevel?: string;
  countries: ICountries;
}

const CitizenshipAndLanguages: React.FC<IProps> = ({
  citizenship,
  englishLevel,
  countries,
}) => {
  const code = useMemo(() => {
    if (citizenship) {
      return countries.find((item) => item.Name === citizenship)?.Code || "";
    }
    return "";
  }, [citizenship, countries]);

  return (
    <Wrapper>
      <div>
        <Styled.InfoTitle>Citizenship</Styled.InfoTitle>
        <Citizenship>
          {citizenship ? (
            <>
              <ReactCountryFlag
                countryCode={code}
                svg
                style={{
                  width: "20px",
                  marginRight: "4px",
                  position: "relative",
                  top: "-2px",
                }}
                title={citizenship}
              />
              {citizenship}
            </>
          ) : (
            "—"
          )}
        </Citizenship>
      </div>
      <div>
        <Styled.InfoTitle>language</Styled.InfoTitle>
        <div>
          {englishLevel && englishLevel !== "-"
            ? `English (${englishLevel})`
            : "—"}
        </div>
      </div>
    </Wrapper>
  );
};

export { CitizenshipAndLanguages };
