import React, { useState } from "react";
import * as Styled from "../CompanyPage.styled";
import { IOpeningCv } from "api/companies/types";
import { useOpeningData, COLUMNS } from "./Opening.utils";
import Table from "../../../components/Table/Table";

interface IProps {
  opening: Array<IOpeningCv>;
}
const OpeningsCv = ({ opening }: IProps) => {
  const [open, setOpen] = useState(true);
  const data = useOpeningData(opening);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Styled.Wrapper id={"opening"}>
      <Styled.HeaderWrapper>
        <Styled.Header>Opening ({opening.length})</Styled.Header>
        <Styled.Collapse onClick={handleClick}>
          {open ? "Collapse table" : "Expand table"}
        </Styled.Collapse>
      </Styled.HeaderWrapper>
      {open && <Table columns={COLUMNS} data={data} />}
    </Styled.Wrapper>
  );
};

export { OpeningsCv };
