import React from "react";
import dayjs from "dayjs";
import * as Styled from "./BaseInfo.styled";

interface IProps {
  birthday?: string;
  residence?: string;
}

const BaseInfo: React.FC<IProps> = ({ birthday, residence }) => {
  return (
    <Styled.BaseInfo>
      {birthday && (
        <Styled.Age>
          {dayjs().diff(dayjs(birthday), "year")} years old
        </Styled.Age>
      )}
      {residence && <Styled.Residence>lives in {residence}</Styled.Residence>}
    </Styled.BaseInfo>
  );
};

export { BaseInfo };
