import React from "react";
import { useTable } from "react-table";
import { count } from "../../utils/helpers";
import * as Styled from "./Table.styled";

const MAX_COUNT = 50;
const ROW_HEIGHT = 72; // для рендера пустых строк

const Table = (props: { columns: any; data: any }) => {
  const { columns, data } = props;

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Styled.TableWrapper>
      <Styled.Table {...getTableProps()}>
        <Styled.Thead>
          {headerGroups.map((headerGroup) => {
            const { key, ...rest } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...rest}>
                {headerGroup.headers.map((column) => {
                  const { key, ...rest } = column.getHeaderProps({
                    style: {
                      width: column.width,
                      maxWidth: column.maxWidth,
                      minWidth: column.minWidth,
                    },
                  });

                  return (
                    <Styled.Th key={key} {...rest}>
                      {column.render("Header")}
                    </Styled.Th>
                  );
                })}
              </tr>
            );
          })}
        </Styled.Thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, idx) => {
            prepareRow(row);
            // выяснить, последняя ли строка на странице
            const shouldAddEmptyRows = false;
            // idx === rows.length - 1 && rows.length < MAX_SEAMEN_COUNT; – условие для shouldAddEmptyRows
            const emptyRowsCount = MAX_COUNT - rows.length;

            return (
              <React.Fragment key={`main-row-${idx}`}>
                <Styled.Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const { key, ...rest } = cell.getCellProps();
                    return (
                      <Styled.Td key={key} {...rest}>
                        {cell.render("Cell")}
                      </Styled.Td>
                    );
                  })}
                </Styled.Tr>
                {/*
                    если строка последняя, вывести после неё пустые строки,
                    чтобы таблица всегда была одной высоты
                */}
                {shouldAddEmptyRows &&
                  count(emptyRowsCount).map((item, idx) => (
                    <Styled.Tr
                      key={`empty-row-${idx}`}
                      style={{ height: ROW_HEIGHT }}
                    />
                  ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </Styled.Table>
    </Styled.TableWrapper>
  );
};
export default Table;
