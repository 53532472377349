import { RefObject, useEffect } from "react";
import * as ReactDOM from "react-dom";

function useClickOutside(
  open: boolean,
  onClose: () => void,
  ref: RefObject<HTMLDivElement>
) {
  useEffect(() => {
    function handleClickOutside(e: any) {
      // eslint-disable-next-line react/no-find-dom-node
      const domNode = ReactDOM.findDOMNode(ref.current);

      if (!domNode || !domNode.contains(e.target)) {
        onClose();
      }
    }

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, ref, onClose]);
}

export default useClickOutside;
