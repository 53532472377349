import axios from "axios";
import { getCookie } from "utils/cookies";
const DEV_API_LINK = "https://api.krew.team";

const API_URL = process.env.REACT_APP_BASE_API_URL || DEV_API_LINK;

const api = axios.create({
  baseURL: API_URL,
});

api.interceptors.request.use((config) => {
  const authToken = getCookie("auth-token");
  if (authToken && config.headers) {
    config.headers["x-auth-token"] = authToken;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

export { api };
