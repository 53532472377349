import React, { useEffect, useRef, useState } from "react";

import * as Styled from "./TextArea.styled";
import * as Typed from "./TextArea.typed";
import { ErrorMessageText } from "../../ErrorMessage/ErrorMessage";

const TextArea: React.FC<Typed.IProps> = (props) => {
  const {
    defaultValue = "",
    disabled = false,
    maxLength,
    placeholder,
    readOnly = false,
    resizable = false,
    autofocus,
    errors,
    name,
  } = props;
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (ref && ref.current && autofocus) {
      ref.current.focus();
    }
  }, []);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    const { onChange = null } = props;

    // если первым символом пробел, прервать
    if (/^[\s]/gm.test(event.target.value)) {
      return;
    }

    const banSymbols = /[а-яё]/i;
    if (!banSymbols.test(event.target.value)) {
      setValue(event.target.value);
      if (onChange) {
        onChange(event);
      }
    }
  };

  const handleFocus = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { onFocus = null } = props;

    setFocused(true);

    if (onFocus !== null) {
      onFocus(event);
    }
  };

  const handleBlur = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { onBlur = null } = props;

    setFocused(false);

    if (onBlur !== null) {
      onBlur(event);
    }
  };

  return (
    <>
      <Styled.Wrapper
        disabled={disabled}
        focused={focused}
        resizable={resizable}
        filled={value?.length > 0}
        error={!!errors}
      >
        <Styled.NativeTextArea
          value={value}
          name={name}
          disabled={disabled}
          maxLength={maxLength}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          ref={ref}
        />
      </Styled.Wrapper>
      {!focused && errors && typeof errors === "string" ? (
        <ErrorMessageText text={errors} />
      ) : null}
    </>
  );
};

export { TextArea, Styled as TextAreaStyled, Typed as TextAreaTyped };
