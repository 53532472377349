import React, { useState } from "react";
import * as Styled from "./Notification.styled";
import Button from "../../components/ui/Button/Button";
import Modal from "../../components/ui/Modal";
import { generateAuthToken } from "../../api/users/users";
import { getApiError, getBaseUrl } from "../../utils/helpers";
import { AxiosResponse } from "axios";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";

interface IProps {
  userId: string | null;
  isOpen: boolean;
  onClose: () => void;
  redirect?: string;
}

const baseUrl = getBaseUrl();

const NotificationModal = ({ isOpen, onClose, userId, redirect }: IProps) => {
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState(null);

  const handleCopyToken = async () => {
    if (!userId) return;
    try {
      setError(null);
      setLoader(true);
      const res = await generateAuthToken(userId);
      let url = `${baseUrl}/auth?token=${res.data.token}`;
      if (redirect) url += `&redirect=${redirect}`;
      window.open(url, "_blank");
      onClose();
    } catch (e) {
      const error = e as AxiosResponse;
      setError(getApiError(error));
    }
    setLoader(false);
  };

  const handleClose = () => {
    setError(null);
    onClose();
  };

  return (
    <Modal
      title={"NOTICE"}
      open={isOpen}
      style={{ width: "100%", maxWidth: 575 }}
      onClose={handleClose}
    >
      <Styled.Text>
        You will be redirected to the sailor`s dashboard in the service, where
        you can go to his cv and edit it. After editing the cv, it is necessary
        to log out of the sailor`s account.
      </Styled.Text>
      <Button
        disabled={loader}
        style={{
          padding: "9px 36px",
          width: "100px",
        }}
        onClick={handleCopyToken}
      >
        Ok
      </Button>
      {error && <ErrorMessageText text={error} />}
    </Modal>
  );
};

export { NotificationModal };
