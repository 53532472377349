import styled, { css } from "styled-components/macro";
import { Card, List } from "../dashboard-cards.styled";
import bgDecorBig from "../../../images/bg-decor/bg-decor-big.svg";
import bgDecorDark from "../../../images/bg-decor/bg-decor-dark.svg";

export const CompaniesCard = styled(Card)<{ small?: boolean }>`
  background: url("${bgDecorBig}") bottom right no-repeat,
    linear-gradient(90deg, #ffffff 0%, rgba(39, 170, 225, 0.3) 75%), #ffffff;

  ${(props) =>
    props.small &&
    css`
      background: url("${bgDecorDark}") right bottom no-repeat,
        linear-gradient(90deg, #04265b 0%, #023788 100%),
        linear-gradient(90deg, #e65c00 0%, #f9d423 100%);
    `}
`;

export const CompaniesList = styled(List)`
  margin-bottom: 16px;
`;
