import dayjs from "dayjs";
import { IVacancy } from "../../../../api/vacancies/types";
import { IParams } from "./Filters.typed";

export enum Names {
  rank = "rank",
  experience_in_ranks = "experience_in_ranks",
  proficiencies = "proficiencies",
  total_work_experience = "total_work_experience",
  competencies = "competencies",
  ship_type = "ship_type",
  experience_ship_types = "experience_ship_types",
  ship_me_type = "ship_me_type",
  ship_kw = "ship_kw",
  ship_dwt = "ship_dwt",
  citizenship = "citizenship",
  country_current_residence = "country_current_residence",
  age = "age",
  net_salary = "net_salary",
  expected_duration = "expected_duration",
  doa = "doa",
  english_level = "english_level",
  langs = "langs",
  visas = "visas",
  certificates = "certificates",
  medical_certificates = "medical_certificates",
  seaman_documents = "documents_tooltip",
  travel_passport = "travel_tooltip",
  endorsements = "endorsements",
  endorsements_competency = "endorsements_competency",
  endorsements_issued = "endorsements_issued",
  time_at_sea = "time_at_sea",
  time_off_board_limit = "time_off_board_limit",
}

const getDefaultValue = (query: Record<string, string>, name: string) => {
  if (
    query[name] &&
    (name === Names.experience_in_ranks ||
      name === Names.total_work_experience ||
      name === Names.experience_ship_types)
  ) {
    return { from: 0, to: Number(query[name]) };
  }
  if (query[name] && (name === Names.ship_kw || name === Names.ship_dwt)) {
    const value = query[name].split(",");
    return { from: Number(value[0]), to: Number(value[1]) };
  }
  if (query[name] && name === Names.age) {
    return { from: 18, to: Number(query[name]) };
  }
  if (
    query[name] &&
    (name === Names.english_level ||
      name === Names.endorsements_competency ||
      name === Names.endorsements_issued)
  ) {
    return query[name];
  }
  if (query[name]) {
    return query[name].split(",");
  }
  return "";
};

export const adaptDataForFilter = (vacancy: IVacancy): IParams => {
  const adapter = {} as Record<string, string>;
  if (vacancy.rank) adapter.rank = vacancy.rank;
  if (vacancy.contracts_rank)
    adapter.experience_in_ranks = vacancy.contracts_rank.toString();
  if (vacancy.proficiency && vacancy.proficiency.length)
    adapter.proficiencies = vacancy.proficiency.join(",");
  if (vacancy.total_contracts)
    adapter.total_work_experience = vacancy.total_contracts.toString();
  if (vacancy.competency && vacancy.competency.length)
    adapter.competencies = vacancy.competency.join(",");
  if (vacancy.ship_types && vacancy.ship_types.length)
    adapter.ship_type = vacancy.ship_types.join(",");
  if (vacancy.contracts_ships)
    adapter.experience_ship_types = vacancy.contracts_ships.toString();
  if (vacancy.ship_me_types && vacancy.ship_me_types.length)
    adapter.ship_me_type = vacancy.ship_me_types.join(",");
  if (vacancy.kw_from && vacancy.kw_to)
    adapter.ship_kw = `${vacancy.kw_from},${vacancy.kw_to}`;
  if (vacancy.dwt_from && vacancy.dwt_to)
    adapter.ship_dwt = `${vacancy.dwt_from},${vacancy.dwt_to}`;
  if (vacancy.seaman_citizenship && vacancy.seaman_citizenship.length)
    adapter.citizenship = vacancy.seaman_citizenship.join(",");
  if (vacancy.age_limit) adapter.age = vacancy.age_limit;
  if (vacancy.english_level) adapter.english_level = vacancy.english_level;
  if (vacancy.visas && vacancy.visas.length)
    adapter.visas = vacancy.visas.join(",");
  if (vacancy.certificates && vacancy.certificates.length)
    adapter.certificates = vacancy.certificates.join(",");
  if (vacancy.medical_certificates && vacancy.medical_certificates.length)
    adapter.medical_certificates = vacancy.medical_certificates.join(",");
  if (vacancy.endorsement) adapter.endorsements = vacancy.endorsement;
  if (vacancy.endorsement_country)
    adapter.endorsements_issued = vacancy.endorsement_country;
  if (vacancy.endorsement_competency)
    adapter.endorsements_competency = vacancy.endorsement_competency;

  const result = {} as IParams;

  Object.keys(adapter).forEach((item) => {
    result[item] = getDefaultValue(adapter, item);
  });
  return result;
};
