import * as yup from "yup";

export const DocumentsScheme = yup.object().shape({
  visas: yup.array().max(20, "Maximum 20 values"),
  certificates: yup.array().max(20, "Maximum 20 values"),
  medical_certificates: yup.array().max(20, "Maximum 20 values"),
  seaman_document: yup.string(),
  travel_passport: yup.string(),
  competency: yup.array().max(5, "Maximum 5 values"),
  proficiency: yup.array().max(5, "Maximum 5 values"),
  endorsement: yup.string(),
  endorsement_country: yup.string(),
  endorsement_competency: yup.string(),
});
