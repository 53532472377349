import React from "react";
import styled from "styled-components/macro";

import { CertificatesType } from "api/cvs/types";
import { formatDate } from "utils/dateTime";

import { Card, Title } from "../Card/Card";

type Props = {
  certificates: CertificatesType[];
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Certificates = (props: Props) => {
  const { certificates } = props;

  return (
    <>
      <Title>Certificates</Title>
      <Wrapper>
        {certificates.map((cert, index) => (
          <Card
            key={index}
            title={cert.certificate_type}
            entries={[
              {
                label: "License number",
                value: cert.certificate_number,
              },
              {
                label: "Country of Issue",
                value: cert.country_of_issue,
              },
              {
                label: "City of issue",
                value: cert.place_of_issue,
              },
              {
                label: "Valid until",
                value: formatDate(cert.valid_until) || "",
              },
            ]}
            filePath={cert.file_path}
          />
        ))}
      </Wrapper>
    </>
  );
};
