import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { IPersonalProps } from "./PersonalInformation.typed";
import { FieldRow } from "components/FieldRow";
import { Checkbox } from "components/ui/Checkbox";
import { CardRequirements } from "../../components/CardRequirements/CardRequirements";
import { makeOption, makeOptionCountries } from "components/ui/Select/utils";
import { Select } from "components/ui/Select/Select";
import { useAppSelector } from "services/hooks";
import { selectDC } from "services/slices/dictionaries";
import TextField, { RegexPattern } from "components/ui/TextField";
import { TSelectProps } from "components/ui/Select/Select";

const getErrors = (errors: IPersonalProps["errors"]) => {
  return {
    err: {
      seaman_citizenship: errors?.seaman_citizenship?.message,
      age_limit: errors?.age_limit?.message,
    },
  };
};

const PersonalInformation: React.FC<IPersonalProps> = ({
  control,
  defaultValues,
  errors,
}) => {
  const { err } = getErrors(errors);
  const [disable, setDisable] = useState(false);
  const { countries, langLevels } = useAppSelector(selectDC);

  const isAnyCitizenship: boolean = useWatch({
    control,
    name: "personal_information.any_citizenship_allowed",
  });

  // Дизейблим citizenship, если поставлена галочка
  useEffect(() => {
    setDisable(isAnyCitizenship);
  }, [isAnyCitizenship]);

  return (
    <CardRequirements
      id="personalInformation"
      title="Personal Requirements"
      percent="+15%"
      fields="Seaman citizenship, age limit, english level"
    >
      <FieldRow label="Seaman citizenship" required>
        <Controller
          name="personal_information.seaman_citizenship"
          control={control}
          defaultValue={defaultValues?.seaman_citizenship ?? []}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              // @ts-ignore
              isMulti={true}
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={countries.map(makeOptionCountries)}
              placeholder="Choose countries"
              name={name}
              errors={disable ? false : err.seaman_citizenship}
            />
          )}
        />
        <div style={{ marginTop: "6px" }}>
          <Controller
            name="personal_information.any_citizenship_allowed"
            control={control}
            defaultValue={defaultValues?.any_citizenship_allowed ?? false}
            render={({ field: { onChange, value } }) => (
              <Checkbox
                checked={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChange(e.target.checked);
                }}
              >
                Any citizenship is allowed
              </Checkbox>
            )}
          />
        </div>
      </FieldRow>
      <FieldRow label="English level">
        <Controller
          name="personal_information.english_level"
          control={control}
          defaultValue={defaultValues?.english_level ?? ""}
          render={({ field: { onChange, onBlur, value, name } }) => (
            <Select
              value={value as TSelectProps["value"]}
              onChange={onChange}
              onBlur={onBlur}
              options={langLevels.map(makeOption)}
              placeholder="Choose level"
              name={name}
            />
          )}
        />
      </FieldRow>
      <div className="inputContainer" id="age_limit">
        <FieldRow label="Age limit">
          <Controller
            name="personal_information.age_limit"
            control={control}
            defaultValue={defaultValues?.age_limit ?? ""}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <TextField
                maxLength={2}
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder="Set upper age limit"
                pattern={RegexPattern.NUMBER}
                error={err.age_limit}
              />
            )}
          />
        </FieldRow>
      </div>
    </CardRequirements>
  );
};

export { PersonalInformation };
