import React, { useMemo } from "react";
import { Link, Subtext } from "../../Support.styled";
import { getApiError, getBaseAdminUrl } from "utils/helpers";
import { getTime } from "utils/dateTime";
import { IVacancy } from "api/vacancies/types";
import * as Styled from "../../Support.styled";
import Button from "components/ui/Button/Button";
import { Variant } from "components/ui/Button/Button.typed";
import { AxiosResponse } from "axios";
import { sendInvitation } from "api/vacancies/vacancies";
import { ICvWithVacancies } from "./Seafarers";

export const COLUMNS = [
  {
    Header: "Applied at",
    accessor: "col1",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "DOB",
    accessor: "col2",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
  },
  {
    Header: "Vacancy Name",
    accessor: "col3",
    minWidth: 130,
    maxWidth: 130,
    width: 130,
  },
  {
    Header: "Company Name",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Matching %",
    accessor: "col5",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "",
    accessor: "col6",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

const prepareRowData = (vacancy: IVacancy, cv: ICvWithVacancies) => {
  const matchingUrl = `${getBaseAdminUrl()}/support/matching/${vacancy.vac_id}`;

  const handleClickVacancy = () => {
    window.open(`${matchingUrl}`, "_blank");
  };

  const handleClickCompany = () => {
    window.open(`${getBaseAdminUrl()}/company/${vacancy.company_id}`, "_blank");
  };

  const handleSendInvitation = async () => {
    try {
      await sendInvitation(vacancy.vac_id, cv.user_id);
      alert(`Send invite to ${cv.first_name} ${cv.last_name}`);
    } catch (e) {
      alert(getApiError(e as AxiosResponse));
    }
  };

  return {
    col1: vacancy.responded_to_vacancy_at ? (
      getTime(vacancy.responded_to_vacancy_at, true)
    ) : (
      <Subtext>Didn’t apply</Subtext>
    ),
    col2: getTime(vacancy.date_of_boarding, true),
    col3: <Link onClick={handleClickVacancy}>{vacancy.title}</Link>,
    col4: <Link onClick={handleClickCompany}>{vacancy.company_title}</Link>,
    col5: `${vacancy.matching_percent_to_vacancy}%`,
    col6: (
      <Button variant={Variant.TEXT} onClick={handleSendInvitation}>
        Send Invitation
      </Button>
    ),
  };
};

export const useVacancies = (cv: ICvWithVacancies) => {
  const { vacancies = [] } = cv;
  return useMemo(() => {
    if (!vacancies || !vacancies.length) return [];

    return vacancies.map((item) => prepareRowData(item, cv));
  }, [cv]);
};

export const VacanciesTable = ({ cv }: { cv: ICvWithVacancies }) => {
  const data = useVacancies(cv);

  return (
    <Styled.Table isVacancy={true}>
      <Styled.THead>
        <Styled.TRow>
          {COLUMNS.map((item, index) => (
            <Styled.TH
              key={index}
              style={{
                width: `${item.width}px`,
                maxWidth: `${item.maxWidth}px`,
                minWidth: `${item.minWidth}px`,
              }}
            >
              {item.Header}
            </Styled.TH>
          ))}
        </Styled.TRow>
      </Styled.THead>
      <Styled.TBody>
        {data.map((item, index) => (
          <Styled.TRow key={index} isVacancy={true}>
            {Object.values(item).map((val, key) => (
              <Styled.TD key={key}>{val}</Styled.TD>
            ))}
          </Styled.TRow>
        ))}
      </Styled.TBody>
    </Styled.Table>
  );
};
