import React, { useState } from "react";
import * as Styled from "./AddManagerCompany.styled";
import PageLayout from "components/layout/PageLayout/PageLayout";
import Button from "components/ui/Button/Button";
import { Controller, useForm } from "react-hook-form";
import TextField from "components/ui/TextField";
import { FieldRow } from "components/FieldRow";
import { PhoneInput } from "components/ui/PhoneInput";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";
import { REG_PASSWORD } from "../CreateSeaman/CreateSeaman";
import { AxiosResponse } from "axios";
import { getApiError } from "../../utils/helpers";
import { useHistory, useParams } from "react-router-dom";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton";
import { addManagers } from "../../api/companies/companies";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { PasswordInput } from "../../components/ui/PasswordInput/PasswordInput";
import { ICreateUser, IUpdateUser, UserType } from "../../api/users/types";
import { createUser, editUser } from "../../api/users/users";

export interface IProps {
  id?: string;
  defaultValues?: {
    first_name: string;
    last_name: string;
    phone: string;
    email: string;
  };
}

const scheme = yup.object().shape({
  first_name: yup.string(),
  last_name: yup.string(),
  phone: yup.string(),
  email: yup.string().required().email(),
  password: yup
    .string()
    .required()
    .matches(
      REG_PASSWORD,
      "Incorrect password. Use 8 or more characters with a mix of letters, numbers & symbols"
    ),
});

const AddManagersCompany: React.FC<IProps> = ({ id, defaultValues }) => {
  const [error, setError] = useState("");
  const history = useHistory();
  const params = useParams() as { id: string };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(scheme),
    defaultValues: {
      first_name: defaultValues?.first_name || "",
      last_name: defaultValues?.last_name || "",
      phone: defaultValues?.phone || "",
      email: defaultValues?.email || "",
      password: "",
    },
  });

  const handleClick = async (data: Omit<ICreateUser, "user_type">) => {
    if (id) {
      await handleEdit(data);
    } else {
      await handleCreate(data);
    }
  };

  const handleEdit = async (data: IUpdateUser) => {
    setError("");
    try {
      const res = await editUser(id as string, data);
      if (res.status === 200) {
        alert(`Менеджер отредактирован`);
        history.push(`/company/${params.id}`);
      }
    } catch (e) {
      const error = e as AxiosResponse;
      setError(getApiError(error));
    }
  };

  const handleCreate = async (data: Omit<ICreateUser, "user_type">) => {
    let userId = "";
    setError("");
    try {
      const user = await createUser({
        ...data,
        user_type: UserType.CREW_OFFICER,
      });
      userId = user.data.user_id;
      const res = await addManagers(params.id, userId);
      if (res.status === 201) {
        alert(`Менеджер добавлен`);
        history.push(`/company/${params.id}`);
      }
    } catch (e) {
      const error = e as AxiosResponse;
      setError(getApiError(error));
    }
  };

  return (
    <PageLayout>
      <GoBackButton text="Back to company" link={`/company/${params.id}`} />
      <Styled.Wrapper>
        <Styled.Aside>
          <Styled.ButtonContainer>
            <Button onClick={handleSubmit(handleClick)}>Save</Button>
            {error && <ErrorMessageText text={error} />}
          </Styled.ButtonContainer>
        </Styled.Aside>
        <Styled.Main>
          <Styled.Title>{id ? "Edit manager" : "Add manager"}</Styled.Title>
          <FieldRow label="First name">
            <Controller
              name="first_name"
              control={control}
              defaultValue={defaultValues?.first_name ?? ""}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  name={name}
                  id={name}
                  value={value}
                  onChange={onChange}
                  placeholder="First name"
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Last name">
            <Controller
              name="last_name"
              control={control}
              defaultValue={defaultValues?.last_name ?? ""}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  name={name}
                  id={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Last name"
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Email" required>
            <Controller
              name="email"
              control={control}
              defaultValue={defaultValues?.email ?? ""}
              render={({ field: { onChange, name, value } }) => (
                <TextField
                  name={name}
                  id={name}
                  value={value}
                  onChange={onChange}
                  placeholder="Email"
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Password" required>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value, name } }) => (
                <PasswordInput
                  id="password"
                  name={name}
                  placeholder="Enter password"
                  value={value}
                  help="Use 8 or more characters with a mix of letters, numbers & symbols"
                  onChange={onChange}
                  error={errors[name]?.message || !!errors[name]}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Phone">
            <Controller
              name="phone"
              control={control}
              defaultValue={defaultValues?.phone ?? ""}
              render={({ field: { onChange, name, value } }) => (
                <PhoneInput
                  country="rus"
                  name={name}
                  value={value}
                  errors={errors[name]?.message || !!errors[name]}
                  onChange={onChange}
                />
              )}
            />
          </FieldRow>
        </Styled.Main>
      </Styled.Wrapper>
    </PageLayout>
  );
};

export { AddManagersCompany };
