import React from "react";
import * as Styled from "./GoBackButton.styled";

interface IProps {
  text: string;
  link: string;
}

const GoBackButton = ({ text, link }: IProps) => {
  return <Styled.GoBackButton to={link}>{text}</Styled.GoBackButton>;
};

export { GoBackButton };
