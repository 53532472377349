import React, { useState, useEffect } from "react";
import * as Styled from "./NetSalaryFromTo.styled";
import TextField, { RegexPattern } from "components/ui/TextField";
import { IFromToProps as INetSalaryFromToProps } from "components/ui/FromTo/FromTo.typed";
import { ErrorMessageText } from "components/ErrorMessage/ErrorMessage";
import { Checkbox } from "components/ui//Checkbox/Checkbox";
import { formatSalary } from "pages/AddVacancy/forms/GeneralInfo/GeneralInfo.utils";

const NetSalaryFromTo: React.FC<INetSalaryFromToProps> = ({
  name,
  maxLength,
  value,
  onChange,
  onBlur,
  disabled = false,
  placeholderFrom = "",
  placeholderTo = "",
  error,
}) => {
  const [currentValue, setCurrentValue] = useState({
    to: value.to,
    from: value.from,
  });
  const [isChecked, setIsChecked] = useState(!!currentValue.to);

  useEffect(() => {
    setCurrentValue({ to: value.to, from: value.from });
  }, [value]);

  useEffect(() => {
    if (!isChecked) {
      setCurrentValue({ ...value, to: "" });
    }
  }, [isChecked]);

  const handleChange = (e: any) => {
    const key = e.target.name.split("-")[1];
    setCurrentValue({ ...currentValue, [key]: e.target.value });
    onChange({ ...currentValue, [key]: e.target.value });
  };

  return (
    <>
      <Styled.Container isDisplayFlex={!isChecked}>
        <Styled.Wrapper>
          <Styled.InputWrapper
            value={(currentValue?.from).toString()}
            disabled={disabled}
            error={!!error}
            isPaddingLeft={!isChecked}
          >
            <Styled.Label isVisible={!isChecked}>From:</Styled.Label>
            <TextField
              id={`${name}-from`}
              name={`${name}-from`}
              maxLength={maxLength}
              value={formatSalary(currentValue?.from || "")}
              pattern={RegexPattern.NUMBER}
              returnEvent
              onChange={handleChange}
              onBlur={onBlur}
              disabled={disabled}
              placeholder={placeholderFrom}
              error={!!error}
              icon="/svg/dollar.svg"
            />
          </Styled.InputWrapper>
          {isChecked && (
            <Styled.InputWrapper
              value={(currentValue?.to).toString()}
              disabled={disabled}
              error={!!error}
            >
              <Styled.Label>To:</Styled.Label>
              <TextField
                id={`${name}-from`}
                name={`${name}-to`}
                maxLength={maxLength}
                value={formatSalary(currentValue?.to || "")}
                pattern={RegexPattern.NUMBER}
                returnEvent
                onChange={handleChange}
                onBlur={onBlur}
                disabled={disabled}
                placeholder={placeholderTo}
                error={!!error}
                icon="/svg/dollar.svg"
              />
            </Styled.InputWrapper>
          )}
        </Styled.Wrapper>
        {isChecked && !!error && <ErrorMessageText text={error} />}
        <Styled.CheckboxWrapper checked={isChecked}>
          <Checkbox
            onChange={() => setIsChecked(!isChecked)}
            checked={isChecked}
          >
            <span>Specify the range</span>
          </Checkbox>
        </Styled.CheckboxWrapper>
      </Styled.Container>
      {!isChecked && !!error && <ErrorMessageText text={error} />}
    </>
  );
};

export { NetSalaryFromTo };
