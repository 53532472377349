import React from "react";
import styled from "styled-components/macro";

import { VisaType } from "api/cvs/types";
import { Card, Title } from "../Card/Card";
import { formatDate } from "utils/dateTime";
import { firstChartToUpperCase } from "utils/firstChartToUppercase";

type Props = {
  visas: VisaType[];
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Visas = (props: Props) => {
  const { visas } = props;

  return (
    <>
      <Title>Visas</Title>
      <Wrapper>
        {visas.map((visa, index) => (
          <Card
            key={index}
            title={`Visa to ${visa.visa_to}`}
            entries={[
              {
                label: "Visa type",
                value: firstChartToUpperCase(visa.visa_type) || "-",
              },
              {
                label: "Valid until",
                value: formatDate(visa.valid_until) || "",
              },
            ]}
            filePath={visa.file_path}
          />
        ))}
      </Wrapper>
    </>
  );
};
