import React, { useState } from "react";
import styled from "styled-components/macro";
import { Color } from "../../../styles/color";
import TextField, { RegexPattern } from "../../../components/ui/TextField";
import Button from "../../../components/ui/Button/Button";
import { ErrorMessageText } from "../../../components/ErrorMessage/ErrorMessage";
import { editCost } from "../../../api/companies/companies";

const Content = styled.div`
  display: inline;
  position: relative;
`;

const Wrapper = styled.div<{ open: boolean }>`
  min-width: 240px;
  display: ${(props) => (props.open ? "display" : "none")};
  position: absolute;
  z-index: 99999;
  left: 0;
  background: ${Color.LIGHT00};
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 5px 10px 0 rgba(23, 43, 77, 0.25);
`;

export const Text = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${Color.BLUE300};
  cursor: pointer;
  padding-left: 8px;
`;

const EditCost = ({ callback, id }: { callback: () => void; id: string }) => {
  const [cost, setCost] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");

  const handleOpen = () => {
    setOpen(!open);
  };

  const save = async () => {
    setError("");
    try {
      const amount = Number(cost);
      if (!cost) {
        setError("Input field can not be empty");
        return;
      }
      if (Number.isNaN(amount) || amount < 0 || amount > 999) {
        setError("The value can be between 0 and 999");
        return;
      }
      await editCost(id, amount);
      callback();
      setOpen(false);
    } catch (e) {
      setError("Something went wrong");
    }
  };

  return (
    <Content>
      <Text onClick={handleOpen}>{open ? "Close" : "Edit"}</Text>
      <Wrapper open={open}>
        <TextField
          help="The value can be between 0 and 999"
          placeholder="Enter open contact cost"
          pattern={RegexPattern.NUMBER}
          id="cost"
          name="cost"
          value={cost}
          onChange={(e) => setCost(e.target.value)}
        />
        <Button onClick={save}>Save</Button>
        {error && <ErrorMessageText text={error} />}
      </Wrapper>
    </Content>
  );
};

export { EditCost };
