import React from "react";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import * as Styled from "./GeneralInfo.styled";
import { IVacancyName } from "./GeneralInfo.typed";
import { selectDC } from "services/slices/dictionaries";
import { Select } from "components/ui/Select/Select";
import { useAppSelector } from "services/hooks";
import { FieldRow } from "components/FieldRow";
import { Checkbox } from "components/ui/Checkbox";
import { makeOption, makeOptionCountries } from "components/ui/Select/utils";
import TextField, { RegexPattern } from "components/ui/TextField";
import { NetSalaryFromTo } from "components/ui/NetSalaryFromTo/NetSalaryFromTo";
import { CONTRACT_DURATION, PRIVATE_INFO_HELP } from "./GeneralInfo.const";
import { DatePicker } from "components/ui/DatePicker/DatePicker";
import { TextArea } from "components/ui/TextArea";
import { TSelectProps } from "components/ui/Select/Select";

const getErrors = (errors: IVacancyName["errors"]) => {
  return {
    err: {
      rank: errors?.rank?.message,
      ship_type: errors?.ship_type?.message,
      ship_dwt: errors?.ship_dwt?.message,
      net_salary:
        errors?.net_salary?.from?.message || errors?.net_salary?.to?.message,
      contract_duration: errors?.contract_duration?.message,
      has_dates_interval: errors?.has_dates_interval?.message,
      date_of_boarding: errors?.date_of_boarding?.message,
      crew_on_board: errors?.crew_on_board?.message,
      private_info: errors?.private_info?.message,
    },
  };
};

const GeneralInfo = ({ control, defaultValues, errors }: IVacancyName) => {
  const { err } = getErrors(errors);

  const { countries, ranks, shipTypes } = useAppSelector(selectDC);

  return (
    <>
      <Styled.FormWrapper>
        <Styled.Form id="generalInfo">
          <Styled.FormTitle>General Info About Vacancy</Styled.FormTitle>
          <FieldRow label="Rank" required>
            <Controller
              name="general_info.rank"
              control={control}
              defaultValue={defaultValues?.rank ?? ""}
              render={({ field: { onChange, name, value } }) => (
                <Select
                  name={name}
                  id={name}
                  value={value as TSelectProps["value"]}
                  onChange={onChange}
                  placeholder="Choose rank"
                  options={ranks.map(makeOption)}
                  required
                  errors={err.rank}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Ship type" required>
            <Controller
              name="general_info.ship_type"
              control={control}
              defaultValue={defaultValues?.ship_type ?? ""}
              render={({ field: { onChange, name, value } }) => (
                <Select
                  name={name}
                  id={name}
                  value={value as TSelectProps["value"]}
                  onChange={onChange}
                  placeholder="Choose ship type"
                  options={shipTypes.map(makeOption)}
                  required
                  errors={err.ship_type}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Ship DWT">
            <Controller
              name="general_info.ship_dwt"
              control={control}
              defaultValue={defaultValues?.ship_dwt ?? ""}
              render={({ field: { onChange, name, value, onBlur } }) => (
                <TextField
                  name={name}
                  id={name}
                  value={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  placeholder="Enter DWT of ship"
                  maxLength={7}
                  pattern={RegexPattern.NUMBER}
                  error={err.ship_dwt}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Net salary" required>
            <Controller
              name="general_info.net_salary"
              control={control}
              defaultValue={defaultValues?.net_salary ?? { from: "", to: "" }}
              render={({ field: { onChange, onBlur, name, value } }) => (
                <NetSalaryFromTo
                  name={name}
                  value={value}
                  maxLength={6}
                  placeholderFrom="3 500"
                  placeholderTo="4 500"
                  onChange={onChange}
                  onBlur={onBlur}
                  error={err.net_salary}
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Contract duration" required>
            <div style={{ display: "flex" }}>
              <div style={{ width: 160 }}>
                <Controller
                  name="general_info.contract_duration"
                  control={control}
                  defaultValue={defaultValues?.contract_duration ?? ""}
                  render={({ field: { onChange, name, value } }) => (
                    <Select
                      name={name}
                      id={name}
                      value={value as TSelectProps["value"]}
                      onChange={onChange}
                      placeholder="Choose months"
                      options={CONTRACT_DURATION.map(makeOption)}
                      required
                      errors={err.contract_duration}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginLeft: 16,
                  position: "relative",
                  top: "9px",
                }}
              >
                <Controller
                  name="general_info.has_dates_interval"
                  control={control}
                  defaultValue={defaultValues?.has_dates_interval ?? false}
                  render={({ field: { onChange, name, value } }) => (
                    <Checkbox
                      name={name}
                      onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                        onChange(evt.target.checked)
                      }
                      checked={value}
                    >
                      ±1 month
                    </Checkbox>
                  )}
                />
              </div>
            </div>
          </FieldRow>
          <FieldRow label="Date of boarding" required>
            <Controller
              name="general_info.date_of_boarding"
              control={control}
              defaultValue={defaultValues?.date_of_boarding ?? ""}
              render={({ field: { onChange, value, name } }) => (
                <>
                  <DatePicker
                    name={name}
                    value={value ? new Date(value) : undefined}
                    fromDate={dayjs().toString()}
                    toDate={dayjs().add(2, "year").endOf("year").toString()}
                    onDayChange={onChange}
                    errors={err.date_of_boarding}
                    startToday
                  />
                  <Styled.HelpNote>
                    Select an approximate date of boarding. Candidates will only
                    see the month and year you selected.
                  </Styled.HelpNote>
                </>
              )}
            />
          </FieldRow>
          <FieldRow label="Crew on board">
            <Controller
              name="general_info.crew_on_board"
              control={control}
              defaultValue={defaultValues?.crew_on_board ?? []}
              render={({ field: { onChange, name, value } }) => (
                <Select
                  // @ts-ignore
                  isMulti={true}
                  name={name}
                  id={name}
                  value={value as TSelectProps["value"]}
                  onChange={onChange}
                  options={[
                    { label: "Mixed", value: "Mixed" },
                    ...countries.map(makeOptionCountries),
                  ]}
                  placeholder="Choose countries"
                />
              )}
            />
          </FieldRow>
          <FieldRow label="Private information">
            <Controller
              name="general_info.private_info"
              control={control}
              defaultValue={defaultValues?.private_info ?? ""}
              render={({ field: { onChange, name, value } }) => (
                <>
                  <TextArea
                    name={name}
                    onChange={onChange}
                    placeholder="Add private information about vacancy"
                    defaultValue={value}
                  />
                  <Styled.HelpNote>{PRIVATE_INFO_HELP}</Styled.HelpNote>
                </>
              )}
            />
          </FieldRow>
        </Styled.Form>
      </Styled.FormWrapper>
    </>
  );
};

export default GeneralInfo;
