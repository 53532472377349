import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../services/hooks";
import { checkToken, selectTokenLoading } from "../services/slices/auth";
import { getCookie } from "utils/cookies";
import Loader from "./utils/Loader/Loader";
import ProtectedRoute from "./utils/ProtectedRoute/ProtectedRoute";
import { routes } from "../routes";

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const tokenLoading = useAppSelector(selectTokenLoading);

  useEffect(() => {
    const token = getCookie("auth-token");
    if (!token) {
      return;
    }
    dispatch(checkToken());
  }, []);

  if (tokenLoading) return <Loader size={50} />;

  return (
    <Switch>
      {routes.map((item, index) => {
        if (item.protect) {
          return (
            <ProtectedRoute key={index} exact={item.exact} path={item.path}>
              {item.render()}
            </ProtectedRoute>
          );
        } else {
          return (
            <Route key={index} exact={item.exact} path={item.path}>
              {item.render()}
            </Route>
          );
        }
      })}
    </Switch>
  );
};

export default App;
