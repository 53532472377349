import * as Styled from "./TextField.styled";
import { BgText } from "./TextField.styled";
import { ITextField } from "./TextField.typed";
import { isTooLong } from "./TextField.utils";
import React, { useEffect, useRef, useState } from "react";
import { RegexPattern } from "./TextField.const";

const TextField = (props: ITextField) => {
  const { textPosition = "left", type = "string" } = props;
  const shouldShowError = !!props.error && !!String(props.error).length;
  const shouldShowHelp = !shouldShowError && props.help;
  const hasLabel = !!props.label;
  const isSmallInput = props.vertical;

  const [currentValue, setCurrentValue] = useState(props.value);

  useEffect(() => {
    setCurrentValue(props.value);
  }, [props.value]);

  const pattern = props.pattern || RegexPattern.ENGLISH_STRING;

  const inputRef = props.inputRef || useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (props.autoFocus) {
      inputRef.current?.focus();
    }
  }, [props.autoFocus]);

  const onChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let value = evt.target.value;

    if (/^[\s]/gm.test(value)) {
      return value;
    }

    if (pattern) {
      value = value.replace(pattern, "");
    }
    if (props.maxLength && isTooLong(value, props.maxLength, props.type)) {
      return value;
    }

    setCurrentValue(value);

    props.onChange(evt);
  };

  return (
    <Styled.Container
      className={props.className}
      vertical={props.vertical}
      small={isSmallInput}
      style={props.style}
    >
      {hasLabel && (
        <Styled.Label htmlFor={props.id} vertical={props.vertical}>
          {props.label}
        </Styled.Label>
      )}
      <Styled.InputContainer>
        <Styled.InputWrapper>
          <Styled.Input
            value={currentValue || ""}
            name={props.name}
            onChange={onChange}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            error={shouldShowError}
            ref={inputRef}
            id={props.id}
            type={type}
            placeholder={props.placeholder || ""}
            disabled={props.disabled}
            width={props.width}
            icon={props.icon}
            min={props.min}
            max={props.max}
            style={props.inputStyle}
            textPosition={textPosition}
          />
          {props.bgText && <BgText>{props.bgText}</BgText>}
        </Styled.InputWrapper>
        {shouldShowError && typeof props.error !== "boolean" && (
          <Styled.Error>{String(props.error)}</Styled.Error>
        )}
        {shouldShowHelp && <Styled.Help>{props.help}</Styled.Help>}
        {props.children && props.children}
      </Styled.InputContainer>
    </Styled.Container>
  );
};

export default TextField;
