export interface ICreateUser {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  user_type: UserType;
  password: string;
}

export interface IUpdateUser {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  user_type?: UserType;
  password?: string;
  username?: string;
  messenger?: string;
}

export enum UserType {
  SEAMAN = "seaman",
  ADMIN = "admin",
  CREW_OFFICER = "crew_officer",
}
