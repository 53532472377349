import React from "react";
import { ICvData } from "api/cvs/types";
import * as Styled from "./PhotoWithAvailable.styled";
import { AvailabilityBlock } from "../Availability/Availability";

interface IProps {
  cvData?: ICvData;
}

const PhotoWithAvailable: React.FC<IProps> = ({ cvData }) => {
  return (
    <>
      <Styled.PicWrapper noPicture={!cvData?.photo}>
        <img src={cvData?.photo || "/empty-photo.png"} alt="candidate" />
      </Styled.PicWrapper>
      <AvailabilityBlock
        dateOfAvailability={cvData?.date_of_availability}
        status={cvData?.availability_status}
      />
    </>
  );
};

export { PhotoWithAvailable };
