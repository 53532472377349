import styled from "styled-components/macro";
import { Color } from "../../styles/color";
import { indents } from "styles/indents";

export const Wrapper = styled.div`
  position: relative;
`;

export const CopyLink = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? "block" : "none")};
  position: absolute;
  width: 110px;
  top: 35px;
  background: ${Color.BLUE50};
  border-radius: 10px;
  padding: ${indents.indent5};
`;
