import styled, { css } from "styled-components/macro";
import { Input as TextInput } from "components/ui/TextField/TextField.styled";
import { Color } from "styles/color";
import { indents } from "styles/indents";
import { Wrapper as ErrorComponent } from "components/ErrorMessage/ErrorMessage";
interface IInputWrapper {
  value: string;
  disabled: boolean;
  error: boolean;
  isPaddingLeft?: boolean;
}

export const Container = styled.div<{
  isDisplayFlex: boolean;
}>`
  display: ${(props) => (props.isDisplayFlex ? "flex" : "block")};
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  &:focus-within {
    ${ErrorComponent} {
      display: none;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
`;

export const Label = styled.label<{
  isVisible?: boolean;
}>`
  position: absolute;
  display: ${(props) => (props.isVisible ? "none" : "block")};
  left: 12px;
  top: 5px;
  z-index: 100;
  font-size: 14px;
  line-height: 20px;
  background: transparent;
  color: ${Color.DARK900};
`;

export const InputWrapper = styled.div<IInputWrapper>`
  display: block;
  position: relative;
  max-width: 164px;
  width: 100%;
  --padding-left: ${(props) => (props.isPaddingLeft ? "14" : "50")}px;

  ${(props) =>
    props.value &&
    css`
      ${Label} {
        color: ${Color.BLUE500};
      }
    `};

  ${(props) =>
    (props.disabled || props.error) &&
    css`
      ${Label} {
        color: ${Color.MID80};
      }
    `};

  &:last-child {
    ${TextInput} {
      padding-left: 32px;
    }
  }
  &:first-child {
    margin-right: ${indents.indent15};
    ${TextInput} {
      padding-left: var(--padding-left);
    }
  }
`;

export const CheckboxWrapper = styled.div<{
  checked: boolean;
}>`
  margin-top: ${(props) => (props.checked ? "8px" : "0")};
`;
