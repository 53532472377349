import styled, { css } from "styled-components/macro";
import * as Typed from "./Range.typed";

import * as DatePickerStyled from "../DatePicker/DatePicker.styled";
import {
  Container as TextContainer,
  Input as TextInput,
} from "../TextField/TextField.styled";
import { Color } from "../../../styles/color";
import { indents } from "styles/indents";

const Wrapper = styled.div<Typed.IWrapperProps>`
  .input-range {
    width: calc(100% - 15px);
    transform: translateX(7px);
  }

  .input-range__label-container {
    display: none;
  }

  .input-range__slider-container {
    transition-duration: 0.1s;

    .input-range__slider {
      margin-left: -7px;
    }
  }

  .input-range__slider {
    margin-top: -9px;
    width: 15px;
    height: 15px;
    border: 1px solid ${Color.BLUE300};
    background-color: ${({ touched }) =>
      touched ? Color.BLUE300 : Color.LIGHT00};
    border-radius: 50%;
    transition: background-color 0.3s;

    &:active {
      transform: none;
    }

    &:hover,
    &:active {
      background-color: ${Color.BLUE75};
    }
  }

  .input-range__track {
    height: 2px;

    &--background {
      background-color: ${Color.LIGHT30};
    }

    &--active {
      transition-duration: 0.1s;
      background-color: ${Color.BLUE300};
    }
  }

  ${DatePickerStyled.Wrapper}
  ${TextInput} {
    &:not(:focus):not(:hover) {
      ${({ touched }) =>
        !touched &&
        css`
          border-color: ${Color.LIGHT40};
          color: ${Color.MID300};
          background-color: ${Color.LIGHT10};
        `}
    }
    &:hover {
      background-color: ${Color.LIGHT10};
    }
  }
`;

const FieldsContainer = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;

  ${DatePickerStyled.Wrapper}:first-child {
    margin-right: ${indents.indent4};
  }

  ${DatePickerStyled.Wrapper} {
    width: auto;

    ${TextContainer},
    ${TextInput} {
      max-width: 124px;
      width: 100%;
    }

    ${TextInput} {
      text-align: center;
    }
  }

  ${DatePickerStyled.CalendarIconStyled} {
    display: none;
  }
`;

export { Wrapper, FieldsContainer };
