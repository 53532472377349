import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import * as Styled from "./Store.styled";
import { Filters } from "../Filters/Filters";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { getCountries, selectDC } from "services/slices/dictionaries";
import { ToggleCvs } from "../Filters/ToggleCvs/ToggleCvs";
import { ICvData } from "api/cvs/types";
import { IVacancy } from "api/vacancies/types";
import { IRefObject, TFormattedParams } from "../Filters/Filters.typed";
import { fetchCvs } from "api/cvs/cvs";
import { getSearchString } from "../../utils";
import { NotFound } from "../NotFound/NotFound";
import { Card } from "components/SeamenCard/Card";
import { SeamanCardSkeleton } from "components/SeamenCard/components/Scelet/Skelet";
import Loader, { Size } from "components/utils/Loader/Loader";
import { AddingVacancy } from "../AddingVacancy/AddingVacancy";
import { NotificationModal } from "modals/Notification/Notification";
import { AdditionalFilters } from "../Filters/AdditionalFilters/AdditionalFilters";
import { IOpenModeration } from "../../Seamen";

export const PAGE_SIZE = 20;

interface IProps {
  setOpenModeration: (value: IOpenModeration) => void;
}

const StoreSeamen = forwardRef((props: IProps, refModerate) => {
  const { setOpenModeration } = props;
  const dispatch = useAppDispatch();
  const { countries } = useAppSelector(selectDC);

  const ref = useRef<IRefObject>(null);
  const refAdditional = useRef<IRefObject>(null);
  const [loading, setLoading] = useState(true);
  const [filterParams, setFilterParams] = useState({});
  const [additionsFilterParams, setAdditionsFilterParams] = useState({});
  const [cvs, setCVs] = useState<ICvData[]>([]);
  const [vacancy, setVacancy] = useState<IVacancy | null>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [onlyGoodCvsFilter, setOnlyGoodCvsFilter] = useState(true);
  const [openModal, setOpenModal] = useState({ isOpen: false, id: "" });

  useEffect(() => {
    if (!countries?.length) dispatch(getCountries());
  }, []);

  useEffect(() => {
    getData();
  }, [filterParams, onlyGoodCvsFilter, additionsFilterParams]);

  useImperativeHandle(refModerate, () => ({
    updateCv(cv: ICvData) {
      updateCv(cv);
    },
  }));

  const getData = async () => {
    setLoading(true);
    const { data } = await fetchCvs(
      getSearchString(
        { ...additionsFilterParams, ...filterParams },
        onlyGoodCvsFilter,
        currentPage,
        PAGE_SIZE
      )
    );
    if (data?.cvs?.length > 0) {
      setCVs([...cvs, ...data.cvs]);
      setHasNextPage(data.cvs.length === PAGE_SIZE);
      setCurrentPage(currentPage + 1);
    }
    setLoading(false);
  };

  const updateCv = (cv: ICvData) => {
    const arr = cvs.map((item) => {
      if (item.cv_id === cv.cv_id) {
        return cv;
      }
      return item;
    });
    setCVs(arr);
  };

  const handleResetNotFound = () => {
    if (ref && ref.current) {
      ref.current.onReset();
    }
    if (refAdditional && refAdditional.current) {
      refAdditional.current.onReset();
    }
  };

  const handleFilterSubmit = (params: TFormattedParams) => {
    resetCvs();
    setFilterParams(params);
  };

  const handleAdditionalFilterSubmit = (params: TFormattedParams) => {
    resetCvs();
    setAdditionsFilterParams(params);
  };

  const onClickGoodCv = (value: boolean) => {
    resetCvs();
    setOnlyGoodCvsFilter(value);
  };

  const resetCvs = () => {
    setCurrentPage(0);
    setCVs([]);
  };

  const CardsLoader = <Loader size={Size.SMALL} />;

  return (
    <Styled.Wrapper>
      <Styled.Aside>
        <ToggleCvs
          onClick={onClickGoodCv}
          onlyGoodCvsFilter={onlyGoodCvsFilter}
        />
        <AdditionalFilters
          ref={refAdditional}
          handleAdditionalFilterSubmit={handleAdditionalFilterSubmit}
        />
        <Filters
          vacancy={vacancy}
          countries={countries}
          onSubmit={handleFilterSubmit}
          onlyGoodCvsFilter={onlyGoodCvsFilter}
          ref={ref}
        />
      </Styled.Aside>
      <Styled.Main>
        <AddingVacancy
          vacancy={vacancy}
          setVacancy={setVacancy}
          handleSubmit={handleFilterSubmit}
        />
        {loading && cvs.length === 0 ? (
          <Styled.CardsWrapper>
            <SeamanCardSkeleton />
            <SeamanCardSkeleton />
            <SeamanCardSkeleton />
            <SeamanCardSkeleton />
          </Styled.CardsWrapper>
        ) : cvs.length > 0 ? (
          <InfiniteScroll
            dataLength={cvs.length}
            next={getData}
            hasMore={hasNextPage}
            loader={CardsLoader}
          >
            <Styled.CardsWrapper>
              {cvs.map((cv) => (
                <Card
                  key={cv.cv_id}
                  cvData={cv}
                  vacancyId={vacancy?.vac_id}
                  countries={countries}
                  setOpenModal={setOpenModal}
                  setOpenModeration={setOpenModeration}
                />
              ))}
            </Styled.CardsWrapper>
          </InfiniteScroll>
        ) : (
          <NotFound handleReset={handleResetNotFound} />
        )}
      </Styled.Main>
      <NotificationModal
        isOpen={openModal.isOpen}
        onClose={() => setOpenModal({ isOpen: false, id: "" })}
        userId={openModal.id}
      />
    </Styled.Wrapper>
  );
});

export default StoreSeamen;
