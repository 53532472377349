import React from "react";
import styled from "styled-components/macro";
import { TravelPassportType } from "api/cvs/types";
import { formatDate } from "utils/dateTime";
import { Card, Title } from "../Card/Card";

type Props = {
  passports: TravelPassportType[];
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Passports = (props: Props) => {
  const { passports } = props;

  return (
    <>
      <Title>Travel Passports</Title>
      <Wrapper>
        {passports.map((passport, index) => (
          <Card
            title={passport.country_of_issue}
            key={index}
            entries={[
              {
                label: "Passport number",
                value: passport.passport_number || "-",
              },
              {
                label: "Valid until",
                value: passport.is_perpetual ? (
                  <>No expiration date</>
                ) : (
                  formatDate(passport.valid_until)
                ),
              },
            ]}
            filePath={passport.file_path}
          />
        ))}
      </Wrapper>
    </>
  );
};
