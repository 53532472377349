import React from "react";
import * as Styled from "./Entries.styled";

interface IProps {
  entries: Array<{
    label: string | number | React.ReactElement;
    value: string | number | React.ReactElement;
  }>;
}

export const Entries: React.FC<IProps> = ({ entries }) => {
  return (
    <Styled.EntriesStyled>
      <tbody>
        {entries
          .filter(({ value }) => value)
          .map(({ label, value }, index) => (
            <tr key={index}>
              <td>{label}:</td>
              <td>{value}</td>
            </tr>
          ))}
      </tbody>
    </Styled.EntriesStyled>
  );
};
