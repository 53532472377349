import { pluralizeMonth } from "utils/helpers";

export const getMonth = (
  contract_duration: number | string,
  has_dates_interval: boolean | undefined
) => {
  if (!has_dates_interval)
    return `${contract_duration} ${pluralizeMonth(contract_duration)}`;
  const from = Number(contract_duration) - 1;
  const to = Number(contract_duration) + 1;
  return `${from || 1} - ${to} months`;
};
