import styled from "styled-components/macro";
import { Color } from "styles/color";

const Element = styled.span`
  background-color: ${Color.LIGHT10};
  border: 1px solid ${Color.LIGHT50};
  border-radius: 50%;
  display: block;
  height: 16px;
  overflow: hidden;
  position: relative;
  transition: all 300ms;
  width: 16px;

  &::after {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;

    background-color: ${Color.LIGHT00};
    border-radius: 50%;
    content: "";
    height: 6px;
    opacity: 0;
    transition: opacity 150ms;
    width: 6px;
  }
`;

const NativeInput = styled.input.attrs({ type: "radio" })`
  height: 1px;
  opacity: 0;
  position: absolute;
  width: 1px;
  z-index: -1;
`;

const Text = styled.span`
  color: ${Color.DARK800};
  font-size: 14px;
  padding-left: 8px;
`;

const Wrapper = styled.label`
  align-items: center;
  display: flex;
  user-select: none;

  margin-bottom: 8px;

  &:hover ${NativeInput} {
    &:enabled {
      ~ ${Element} {
        box-shadow: 0 0 0 4px ${Color.LIGHT30} !important;
        cursor: pointer;
      }

      ~ ${Text} {
        cursor: pointer;
      }
    }
  }

  ${NativeInput} {
    &:focus {
      ~ ${Element} {
        background-color: ${Color.LIGHT00};
        border-color: ${Color.BLUE300};
        box-shadow: 0px 0px 4px rgba(0, 101, 255, 0.24),
          inset 0px 0px 4px rgba(23, 43, 77, 0.5);
      }
    }

    &:checked {
      ~ ${Element} {
        background-color: ${Color.BLUE200};
        border-color: ${Color.BLUE200};
        box-shadow: none;

        &::after {
          opacity: 1;
        }
      }
    }

    &:disabled {
      &:checked {
        ~ ${Element} {
          background-color: ${Color.MID60};
          border-color: ${Color.MID60};
        }
      }

      ~ ${Element} {
        background-color: ${Color.LIGHT30};
        border-color: ${Color.LIGHT50};
      }
    }
  }
`;

export { Element, NativeInput, Text, Wrapper };
