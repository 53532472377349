import React, { forwardRef, useEffect, useState } from "react";

import * as Styled from "./PhotoEdit.styled";
import * as Utils from "./PhotoEdit.utils";
import * as Typed from "./PhotoEdit.typed";
import { uploadFile } from "api/storage/storage";

export const PhotoEdit = forwardRef<Typed.IRefObject, Typed.IProps>(
  (
    {
      id = "userPhoto",
      onChange,
      defaultPhotoUrl = "",
      disabled = false,
      rules,
      width,
      placeholder,
    }: Typed.IProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ref
  ) => {
    const [loader, setLoader] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [preview, setPreview] = useState<string | null>(defaultPhotoUrl);

    useEffect(() => {
      if (defaultPhotoUrl) {
        setPreview(defaultPhotoUrl);
      }
    }, [defaultPhotoUrl]);

    const handleChangePhoto = async (
      e: React.ChangeEvent<HTMLInputElement>
    ) => {
      const { files } = e.target;

      if (!files || !files[0]) {
        return;
      }

      const file = files[0];

      if (file.size > 1024 * 1024 * 20) {
        setErrorText("Maximum file size is 20MB");
        return;
      }
      setLoader(true);
      const { image, error } = await Utils.cropPhoto(file);
      if (error) {
        setErrorText(error);
        return;
      }

      const formData = new FormData();

      formData.append("file", image as Blob);

      const { data } = await uploadFile(formData);

      const { file_path: filePath, success, msg } = data;

      if (success) {
        setPreview(filePath);
        setErrorText("");

        if (onChange) {
          onChange(filePath);
        }
      } else {
        setErrorText(msg);
      }
      setLoader(false);
    };

    return (
      <Styled.Wrapper>
        <Styled.NativeInput
          type="file"
          id={String(id)}
          disabled={disabled}
          accept=".jpg, .jpeg, .png"
          onChange={handleChangePhoto}
        />
        {loader ? (
          <Styled.Label
            htmlFor={String(id)}
            disabled={disabled}
            width={width}
            showPadding={!preview}
          >
            <Styled.Person />
            <p>{"Loading..."}</p>
            <Styled.Rules>{"Your picture will be uploaded, wait"}</Styled.Rules>
          </Styled.Label>
        ) : (
          <Styled.Label
            htmlFor={String(id)}
            disabled={disabled}
            width={width}
            showPadding={!preview}
          >
            {preview ? (
              <Styled.Preview src={preview} />
            ) : (
              <>
                <Styled.Person />
                <p>{placeholder || "Add your photo"}</p>
                <Styled.Rules>
                  {rules || "Jpg, jpeg, png. 20 Mb is maximum."}
                </Styled.Rules>
              </>
            )}
          </Styled.Label>
        )}
        <Styled.Limits isError>{errorText}</Styled.Limits>
      </Styled.Wrapper>
    );
  }
);
