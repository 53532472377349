import React from "react";
import { Title } from "../Card/Card";
import { WorkExperience } from "api/cvs/types";

import {
  Table,
  TableHead,
  TableBody,
  BlueText,
  Wrapper,
} from "./Experience.styled";
import { StubText } from "../../Cv.styled";
import { formatDate } from "utils/dateTime";

type Props = {
  experience: WorkExperience[] | undefined;
  total: string | undefined;
  noExperience: boolean | undefined;
};

export const Experience = ({ experience = [], total, noExperience }: Props) => {
  if (!experience.length && noExperience) {
    return (
      <>
        <Title>Work Experience</Title>
        <StubText>I do not have Work Experience</StubText>
      </>
    );
  }

  return (
    <>
      <Title>Work Experience</Title>
      <>
        <Title>
          <BlueText>Total experience at sea</BlueText> - {total}
        </Title>
        <Wrapper>
          <Table>
            <TableHead>
              <tr>
                <td>Rank</td>
                <td>
                  Vessel type,
                  <br />
                  DWT
                </td>
                <td>
                  Name of Ship,
                  <br />
                  IMO#
                </td>
                <td>
                  ME type,
                  <br />
                  KW
                </td>
                <td>Fuel type</td>
                <td>
                  From/To
                  <br />
                  Contract
                </td>
              </tr>
            </TableHead>
            <TableBody>
              {experience.map((job, index) => {
                return (
                  <tr key={index}>
                    <td>{job.rank}</td>
                    <td>
                      {job.ship_type}
                      <br />
                      <span>{job.ship_dwt ? job.ship_dwt : ""}</span>
                    </td>
                    <td>
                      {job.ship_name}
                      <br />
                      <span>{job.ship_imo}</span>
                    </td>
                    <td>
                      {job.ship_grt}
                      <br />
                      {job.ship_nrt}
                    </td>
                    <td>
                      {job.ship_me_type}
                      <br />
                      <span>{job.ship_kw ? job.ship_kw : ""}</span>
                    </td>
                    <td>{job.fuel_type ? job.fuel_type : ""}</td>
                    <td>
                      {formatDate(job.sign_on_date)}
                      <br />
                      {job.still_employed || !job.sign_off_date ? (
                        <>Still employed</>
                      ) : (
                        formatDate(job.sign_off_date)
                      )}
                      <br />
                      <span>{job.duration_human}</span>
                    </td>
                  </tr>
                );
              })}
            </TableBody>
          </Table>
        </Wrapper>
      </>
    </>
  );
};
