import styled from "styled-components/macro";
import { Button } from "../../components/ui/Button/Button.styled";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const Aside = styled.aside`
  margin-right: 18px;
  width: 315px;
`;

export const ButtonContainer = styled.div`
  width: 315px;
  padding: 16px;
  position: sticky;
  top: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
  ${Button} {
    width: 100%;
  }
`;

export const Main = styled.main`
  flex: 1;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #061938;
  font-family: Montserrat, sans-serif;
  margin-bottom: 16px;
`;
