import { useHistory, useParams } from "react-router-dom";
import { adaptDataToServer, IDataForServer } from "./AddVacancy.utils";
import { createVacancy, editVacancy } from "../../api/vacancies/vacancies";
import { IInitialValues } from "./forms/forms.const";
import { useState } from "react";
import { AxiosResponse } from "axios";
import { getApiError } from "../../utils/helpers";

interface IProps {
  vacancyId: string | undefined;
  titleCompany: string | undefined;
}

const useSubmit = ({ vacancyId, titleCompany }: IProps) => {
  const history = useHistory();
  const [error, setError] = useState("");
  const params = useParams() as { id: string; title: string };

  const dataForServer = (
    data: IInitialValues,
    draft: boolean
  ): IDataForServer => {
    const newData = adaptDataToServer(data);
    return {
      ...newData,
      company_id: params.id,
      company_title: vacancyId ? titleCompany : params.title,
      status: draft ? "draft" : "published",
      is_synt: false,
    };
  };

  const onSubmit = async (data: IInitialValues, draft: boolean) => {
    if (vacancyId) {
      try {
        const res = await editVacancy(vacancyId, dataForServer(data, draft));
        if (res.status === 200) {
          alert("Вакансия отредактирована");
          history.push(`/company/${params.id}`);
        }
        return;
      } catch (e) {
        const error = e as AxiosResponse;
        setError(getApiError(error));
      }
    }

    try {
      const res = await createVacancy(dataForServer(data, draft));
      if (res.status === 200) {
        alert("Вакансия создана");
        history.push(`/company/${params.id}`);
      }
    } catch (e) {
      const error = e as AxiosResponse;
      setError(getApiError(error));
    }
  };

  const onPublicClick = (data: IInitialValues) => {
    onSubmit(data, false);
  };

  const onDraftClick = (data: IInitialValues) => {
    onSubmit(data, true);
  };

  return {
    error,
    onPublicClick,
    onDraftClick,
  };
};

export { useSubmit };
