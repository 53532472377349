import React, { useState } from "react";
import * as Styled from "./PasswordInput.styled";
import TextField from "../TextField/TextField";
import { ITextField } from "../TextField/TextField.typed";
import { PasswordIcon } from "../../../images/svg/password";
import { PasswordShowIcon } from "../../../images/svg/passwordShow";

const PasswordInput = (props: ITextField) => {
  const [type, setType] = useState<"string" | "password">("password");

  const handleClick = () => {
    if (type === "password") {
      setType("string");
      return;
    }
    setType("password");
  };

  return (
    <Styled.Wrapper type={type}>
      <TextField {...props} type={type} />
      <span onClick={handleClick}>
        {type === "password" ? <PasswordIcon /> : <PasswordShowIcon />}
      </span>
    </Styled.Wrapper>
  );
};

export { PasswordInput };
