import { api } from "../axiosConfig";
import { IDataForServer } from "../../pages/AddVacancy/AddVacancy.utils";
import { IVacancy } from "api/vacancies/types";

export const fetchVacancies = (query: string, signal?: AbortSignal) => {
  const config = {} as { signal: AbortSignal };
  if (signal) config.signal = signal;
  return api.get<{ vacancies: IVacancy[]; total?: number }>(
    `/vacancies?${query}`,
    config
  );
};

export const getVacancy = (vacId: string) =>
  api.get<IVacancy>(`/vacancies/${vacId}`);

export const editVacancy = (vacId: string, data: IDataForServer) =>
  api.put(`/vacancies/${vacId}`, data);

export const createVacancy = (data: IDataForServer) =>
  api.post("/vacancies", data);

export const deleteVacancy = (vacId: string) =>
  api.delete(`/vacancies/${vacId}`);

export const sendInvitation = (vac_id: string, user_id: string) =>
  api.get(`/vacancies/${vac_id}/${user_id}/invite`);

export const fetchMatchingVacancies = (query: string, signal?: AbortSignal) => {
  const config = {} as { signal: AbortSignal };
  if (signal) config.signal = signal;
  return api.get<{ vacancies: IVacancy[] }>(
    `/matching/vacancies?${query}`,
    config
  );
};
