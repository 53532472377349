import React, { useMemo } from "react";
import * as Styled from "./Emails.styled";
import { IEmail, StatusEmail } from "api/emails/types";
import { getTime } from "../../utils/dateTime";
import { firstChartToUpperCase } from "../../utils/firstChartToUppercase";
import { Color as Colors } from "../../styles/color";

export const COLUMNS = [
  {
    Header: "Email id",
    accessor: "col1",
    minWidth: 160,
    maxWidth: 160,
    width: 160,
  },
  {
    Header: "User id\nE-mail",
    accessor: "col2",
    minWidth: 170,
    maxWidth: 170,
    width: 170,
  },
  {
    Header: "Category",
    accessor: "col3",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Status",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Comment",
    accessor: "col5",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Created at",
    accessor: "col6",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Updated at",
    accessor: "col7",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Expired at",
    accessor: "col8",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Sent at",
    accessor: "col9",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

export const getSearchString = (
  params: Record<string, string | Array<string>>,
  currentPage = 0
) => {
  return `limit=${10}&skip=${currentPage * 10}&${
    Object.entries(params)
      .map(
        ([key, entry]) =>
          `${key}=${Array.isArray(entry) ? entry.join(",") : entry}`
      )
      .join("&") || ""
  }`;
};

const getColorStatus = (status: StatusEmail) => {
  let color = Colors.DARK900;
  if (status === StatusEmail.sent) color = Colors.GREEN400;
  if (status === StatusEmail.pending) color = Colors.YELLOW300;
  if (status === StatusEmail.canceled) color = Colors.RED300;
  return <span style={{ color: color }}>{firstChartToUpperCase(status)}</span>;
};

const prepareRowData = (email: IEmail) => {
  const createdAt = getTime(email.created_at);
  const updateAt = getTime(email.updated_at);
  const expiredAt = getTime(email.expired_at);
  const sentAt = getTime(email.send_at);

  return {
    col1: email.email_id,
    col2: (
      <>
        <Styled.User>{email.user_id} </Styled.User> {email.email}
      </>
    ),
    col3: email.category,
    col4: getColorStatus(email.status),
    col5: email.comment || "-",
    col6: createdAt,
    col7: updateAt,
    col8: expiredAt,
    col9: sentAt,
  };
};

export const useEmailsData = (emails: IEmail[]) => {
  return useMemo(() => {
    if (!emails || !emails.length) return [];

    return emails.map((item) => prepareRowData(item));
  }, [emails]);
};
