import React, { useEffect, useRef, useState } from "react";
import * as Styled from "../Filters.styled";
import useClickOutside from "hooks/useClickOutside";
import _ from "lodash";
import { fetchCvs } from "api/cvs/cvs";
import { Portal } from "components/Portal/Portal";
import Button from "components/ui/Button/Button";
import Loader from "components/utils/Loader/Loader";
import { getFormattedParams, getSearchString } from "../../../utils";
import { Color } from "components/ui/Button/Button.typed";
import { IParams } from "../Filters.typed";
import { usePrevious } from "../../../../../hooks/usePrevious";

interface IProps {
  watchFields: IParams;
  onSubmit: () => void;
  onReset: (val: string) => void;
  popupContainer: string | null;
  setPopupContainer: (val: string | null) => void;
  onlyGoodCvsFilter: boolean;
}

const Component: React.FC<IProps> = ({
  watchFields,
  onSubmit,
  popupContainer,
  setPopupContainer,
  onReset,
  onlyGoodCvsFilter,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [seamenCount, setSeamenCount] = useState(0);
  const [isReset, setIsReset] = useState(false);
  const [loading, setLoading] = useState(false);

  useClickOutside(!!popupContainer, () => setPopupContainer(null), ref);
  const previous = usePrevious(watchFields);

  const getSeamenCount = async (params: IParams) => {
    setLoading(true);
    setIsReset(false);
    const { data } = await fetchCvs(
      getSearchString(getFormattedParams(params), onlyGoodCvsFilter)
    );
    setSeamenCount(data.total);
    setIsReset(data.total === 0);
    setLoading(false);
  };

  const handleClick = () => {
    if (isReset && popupContainer) {
      onReset(popupContainer);
      return;
    }
    onSubmit();
  };

  useEffect(() => {
    if (!_.isEqual(previous, watchFields)) {
      getSeamenCount(watchFields);
    }
  }, [watchFields]);

  if (!popupContainer) {
    return null;
  }

  return (
    <Portal id={popupContainer}>
      <Styled.Popup ref={ref} isReset={isReset}>
        {loading ? (
          <>
            <Styled.PopupInfo>
              We are looking for seamen for you...
            </Styled.PopupInfo>
            <Loader />
          </>
        ) : (
          <>
            <Styled.PopupInfo>
              Found{" "}
              <Styled.Highlght isReset={isReset}>
                {`${seamenCount} seam${seamenCount === 1 ? "a" : "e"}n`}
              </Styled.Highlght>{" "}
              for you
            </Styled.PopupInfo>
            <Button
              color={isReset ? Color.YELLOW : Color.PRIMARY}
              onClick={handleClick}
              style={{ width: "130px" }}
            >
              {isReset ? "Reset last filter" : "Show results"}
            </Button>
          </>
        )}
      </Styled.Popup>
    </Portal>
  );
};

const areEqual = (prevProps: IProps, nextProps: IProps) => {
  return (
    _.isEqual(prevProps.watchFields, nextProps.watchFields) &&
    prevProps.popupContainer === nextProps.popupContainer
  );
};

const SearchTooltip = React.memo(Component, areEqual);

export { SearchTooltip };
