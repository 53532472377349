import React from "react";
import { Blocks, Wrapper } from "./GeneralInfo.styled";
import { ICvData } from "api/cvs/types";
import { RankAndName } from "../GeneralInfo/RankAndName";
import { GenderAndBirthday } from "../GeneralInfo/GenderAndBirthday";
import { Location } from "../GeneralInfo/Location";
import { Languages } from "../GeneralInfo/Languages";
import { Expactations } from "../GeneralInfo/Expactations";

interface IProps {
  cvData: ICvData | undefined;
}

export const GeneralInfo: React.FC<IProps> = ({ cvData }) => {
  return (
    <Wrapper>
      <RankAndName cvData={cvData} />
      <GenderAndBirthday cvData={cvData} />
      {cvData && (
        <Blocks>
          <Location cvData={cvData} />
          <Languages cvData={cvData} />
          <Expactations cvData={cvData} />
        </Blocks>
      )}
    </Wrapper>
  );
};
