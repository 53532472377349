import styled from "styled-components/macro";
import { indents } from "styles/indents";

export const Wrapper = styled.div`
  display: flex;
  margin-top: ${indents.indent5};
  margin-bottom: ${indents.indent4};

  & > div {
    margin-right: ${indents.indent6};
  }
`;

export const Citizenship = styled.div`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
`;
