import styled, { css } from "styled-components/macro";
import * as Typed from "./Accordion.typed";
import { Color } from "styles/color";
import { indents } from "styles/indents";
import iconArrow from "images/accordion-arrow.svg";

export const Wrapper = styled.div<Typed.IStyledWrapper>`
  ${(props) =>
    props.opened &&
    css`
      ${Title}::after {
        right: 0;
        transform: rotate(0);
      }

      ${Content} {
        display: block;
      }
    `}
`;

export const Title = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${Color.DARK900};
  & > div {
    display: flex;
    align-items: center;
    & > img {
      width: 16px;
      height: 16px;
      margin-right: ${indents.indent3};
    }
  }

  &::after {
    content: "";
    position: relative;
    right: -2px;
    width: 10px;
    height: 5px;
    transform: rotate(90deg);
    transition: all 0.2s;
    background-image: url(${iconArrow});
    background-repeat: no-repeat;
    background-size: contain;
  }
`;

export const Content = styled.div`
  padding-top: ${indents.indent4};
  display: none;
`;
