import React from "react";
import * as Styled from "./Filter.styled";
import { useState } from "react";

interface Props<T> {
  active?: T;
  items: Array<{ name: T; amount: number }>;
  setFilter: (val: T) => void;
  disabled?: boolean;
}

const FilterControl = <T,>({
  active,
  setFilter,
  items,
  disabled = false,
}: Props<T>) => {
  const [value, setValue] = useState(active || items[0].name);

  const handleClick = (name: T) => {
    if (value !== name && !disabled) {
      setValue(name);
      setFilter(name);
    }
  };

  return (
    <Styled.Wrapper>
      {items.map((item, index) => (
        <Styled.Item
          key={index}
          isActive={value === item.name}
          onClick={() => handleClick(item.name)}
        >
          <Styled.Name>{item.name}</Styled.Name>
          {item.amount ? <Styled.Count>{item.amount}</Styled.Count> : null}
        </Styled.Item>
      ))}
    </Styled.Wrapper>
  );
};

export default FilterControl;
