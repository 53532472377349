import React from "react";
import styled from "styled-components/macro";
import { indents } from "styles/indents";

export const Wrapper = styled.div`
  font-family: Lato, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #de350b;
  text-transform: lowercase;
  margin-top: ${indents.indent1};

  &:first-letter {
    text-transform: uppercase;
  }
`;

type propsText = {
  text: string;
};

export const ErrorMessageText = (props: propsText): JSX.Element => {
  return <Wrapper>{props.text}</Wrapper>;
};
