import React from "react";
import { Block, BlockTitle } from "./GeneralInfo.styled";
import { Entries } from "../Entries/Entries";
import { ICvData, LanguageType } from "api/cvs/types";

interface IProps {
  cvData: ICvData;
}

const Languages: React.FC<IProps> = ({ cvData }) => {
  const { foreign_languages: languages } = cvData;

  const languagesEntries = languages.map((lang: LanguageType) => ({
    label: lang.language,
    value: lang.level,
  }));
  if (!languages.find((lang: LanguageType) => lang.language === "English")) {
    languagesEntries.unshift({ label: "English", value: "-" });
  }
  languagesEntries.sort((a, b) => {
    return a.label === "English" ? -1 : b.label === "English" ? 1 : 0;
  });

  return (
    <Block>
      <BlockTitle>Languages</BlockTitle>
      <Entries entries={[...languagesEntries]} />
    </Block>
  );
};

export { Languages };
