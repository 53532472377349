import dayjs from "dayjs";

export const formatDate = (
  date: Date | string | number,
  format = "YYYY/MM/DD"
): string => {
  const d = dayjs(date);

  if (!date || !d.isValid()) {
    return "";
  }

  return dayjs(date).format(format);
};

export const formatDateAPI = (date: Date | string | number): string =>
  dayjs(date).format("YYYY-MM-DD");

export const formatBoardingDate = (
  boardingDate: string | undefined
): string => {
  if (!boardingDate) return "";

  const day = dayjs(boardingDate).get("date");
  let prefix = "";
  switch (true) {
    case day <= 10:
      prefix = "Start of";
      break;
    case day > 10 && day <= 20:
      prefix = "Middle of";
      break;
    default:
      prefix = "End of";
  }
  const formattedDate = dayjs(boardingDate).format("MMMM YYYY");
  return `${prefix} ${formattedDate}`;
};

export const getTime = (time: string, onlyDate?: boolean): string => {
  if (!time) return "-";
  const TimeUTC = convertUTCDateToLocalDate(time);
  const date = `${dayjs(TimeUTC).format("YYYY / MM / D")}`;
  if (onlyDate) return date;
  return `${dayjs(TimeUTC).format("HH:mm")}\n${date}`;
};

export const convertUTCDateToLocalDate = (value?: string) => {
  if (!value) return "";
  const date = new Date(value);
  const newDate = new Date(
    date.getTime() + date.getTimezoneOffset() * 60 * 1000
  );

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
};
