import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { EmptyData } from "components/EmptyData/EmptyData";
import Loader from "components/utils/Loader/Loader";
import { fetchCvs } from "api/cvs/cvs";
import Table from "components/Table/Table";
import { COLUMNS, useCVsData } from "./NewCvs.utils";
import { ICvData } from "api/cvs/types";
import { UpdateRanks } from "components/TooltipEditCv/UpdateRanks/UpdateRanks";
import { UpdateStatusAvailable } from "components/TooltipEditCv/UpdateStatusAvailable/UpdateStatusAvailable";
import PaginationControls from "components/PaginationControls/PaginationControls";
import { IOpenModeration } from "../../Seamen";

export enum TooltipType {
  ranks = "ranks",
  statusAvailable = "statusAvailable",
}

interface ITooltipOpen {
  type: null | TooltipType;
  cv: null | ICvData;
}

interface IProps {
  setOpenModeration: (value: IOpenModeration) => void;
}

const Moderation = forwardRef((props: IProps, ref) => {
  const { setOpenModeration } = props;
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [cvs, setCvs] = useState<ICvData[]>([]);
  const [total, setTotal] = useState<number>(0);

  const [tooltip, setTooltip] = useState<ITooltipOpen>({
    type: null,
    cv: null,
  });

  useEffect(() => {
    const controller = new AbortController();
    getCVs(controller);
    return () => {
      controller.abort();
    };
  }, [currentPage]);

  useImperativeHandle(ref, () => ({
    updateCv(cv: ICvData) {
      updateCv(cv);
    },
  }));

  const getCVs = async (controller: AbortController) => {
    try {
      setLoading(true);
      const data = await fetchCvs(
        `limit=20&skip=${currentPage * 10}&new_cvs_to_moderate=true`,
        controller.signal
      );
      setCvs(data.data.cvs);
      setTotal(data.data.total);
      setLoading(false);
    } catch (e) {
      if (e) setLoading(false);
    }
  };

  const openTooltip = (type: TooltipType, cv: ICvData) => {
    setTooltip({ type: type, cv: cv });
  };

  const closeTooltip = () => {
    setTooltip({ type: null, cv: null });
  };

  const updateCv = (cv: ICvData) => {
    const arr = cvs.map((item) => {
      if (item.cv_id === cv.cv_id) {
        return cv;
      }
      return item;
    });
    setCvs(arr);
  };

  const data = useCVsData(cvs, setOpenModeration, openTooltip);

  const getRenderContent = () => {
    if (!loading && !cvs.length) {
      return <EmptyData />;
    }
    return (
      <>
        {loading ? <Loader /> : <Table columns={COLUMNS} data={data} />}
        <PaginationControls totalElements={total} changePage={setCurrentPage} />
      </>
    );
  };

  return (
    <>
      {getRenderContent()}
      {tooltip.type === TooltipType.ranks && (
        <UpdateRanks
          cv={tooltip.cv}
          onClose={closeTooltip}
          updateCv={updateCv}
        />
      )}
      {tooltip.type === TooltipType.statusAvailable && (
        <UpdateStatusAvailable
          cv={tooltip.cv}
          onClose={closeTooltip}
          updateCv={updateCv}
        />
      )}
    </>
  );
});

export default Moderation;
