import React from "react";
import * as Styled from "./Moderation.styled";
import { ModerationStatus } from "api/cvs/types";

interface IProps {
  status?: ModerationStatus;
}

const Moderation = ({ status }: IProps) => {
  if (status !== ModerationStatus.banned) return null;

  return (
    <Styled.Wrapper>
      <Styled.Text>Blocked</Styled.Text>
    </Styled.Wrapper>
  );
};

export { Moderation };
