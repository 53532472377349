import { AxiosResponse } from "axios";

export const getBaseUrl = () => {
  const isDev = process.env.NODE_ENV === "development";
  return isDev ? "https://app.krew.team" : "https://app.krew.guru";
};

export const getBaseAdminUrl = () => {
  const isDev = process.env.NODE_ENV === "development";
  return isDev
    ? `http://localhost:${window.location.port}`
    : "https://cp.krew.team";
};

export const count = (max: number, startsWith = 0) => {
  const res = [];
  for (let i = startsWith; i < max + startsWith; i++) {
    res.push(i);
  }
  return res;
};

export const getApiError = (error: AxiosResponse) => {
  if (error?.data?.err) {
    if (typeof error.data.err === "string") {
      return error.data.err;
    } else {
      const key = Object.keys(error.data.err)[0];
      return error.data.err[key][0];
    }
  }
  if (error?.data?.msg) {
    return error.data.msg;
  }
  return "Something went wrong";
};

export const joinArray = (val: Array<string | undefined> | undefined) => {
  let result = "";
  if (Array.isArray(val)) {
    result = val.filter((str) => str).join(" / ");
  }
  return result;
};

export const pluralizeMonth = (value: number | string) =>
  Number(value) === 1 ? "month" : "months";
