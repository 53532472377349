import * as yup from "yup";
import { IValuesPerson } from "./PersonalInformation/PersonalInformation.typed";
import { IValuesDocuments } from "./Documents/Documents.typed";
import { DocumentsScheme } from "./Documents/schema";
import { IValuesWork } from "./WorkExperience/WorkExperience.typed";
import { WorkExperienceScheme } from "./WorkExperience/scheme";
import { IGeneralInfoValues } from "./GeneralInfo/GeneralInfo.typed";
import { GENERAL_INFO_SCHEMA } from "./GeneralInfo/GeneralInfo.const";
import { CONFIG_INFO_SCHEMA } from "./ConfigInfo/ConfigInfo.const";

export interface IInitialValues {
  config_info: { is_synt: boolean };
  general_info: IGeneralInfoValues;
  personal_information: IValuesPerson;
  work_experience: IValuesWork;
  documents: IValuesDocuments;
}

export const initialValues: IInitialValues = {
  config_info: {
    is_synt: false,
  },
  general_info: {
    rank: "",
    ship_type: "",
    ship_dwt: "",
    net_salary: { from: "", to: "" },
    contract_duration: "",
    has_dates_interval: false,
    date_of_boarding: "",
    crew_on_board: [],
    private_info: "",
  },
  personal_information: {
    seaman_citizenship: [],
    any_citizenship_allowed: false,
    english_level: "",
    age_limit: "",
  },
  work_experience: {
    total_contracts: "",
    contracts_rank: "",
    contracts_ships: "",
    ship_types: [],
    dwt: { from: "", to: "" },
    kw: { from: "", to: "" },
    fuel_types: [],
    ship_me_types: [],
    no_experience_allowed: false,
  },
  documents: {
    visas: [],
    certificates: [],
    medical_certificates: [],
    seaman_document: "",
    travel_passport: "",
    competency: [],
    proficiency: [],
    endorsement: "",
    endorsement_country: "",
    endorsement_competency: "",
  },
};

const schemeCitizenship = yup.lazy(() =>
  yup
    .array()
    .max(20, "Maximum 20 values")
    .when("any_citizenship_allowed", {
      is: false,
      then: yup
        .array()
        .max(20, "Maximum 20 values")
        .min(1, "Fill in the required field"),
    })
);

const schemeAgeLimit = yup.lazy((value) =>
  value === ""
    ? yup.string()
    : yup
        .number()
        .min(18, `The value can be between 18 and 99`)
        .max(99, `The value can be between 18 and 99`)
);

export const scheme = yup.object().shape({
  config_info: CONFIG_INFO_SCHEMA,
  general_info: GENERAL_INFO_SCHEMA,
  personal_information: yup.object().shape({
    seaman_citizenship: schemeCitizenship,
    any_citizenship_allowed: yup.boolean(),
    english_level: yup.string(),
    age_limit: schemeAgeLimit,
  }),
  work_experience: WorkExperienceScheme,
  documents: DocumentsScheme,
});
