import React, { useEffect, useState } from "react";
import * as Styled from "../CompanyPage.styled";
import { useOpeningData, COLUMNS } from "./Applicants.utils";
import Table from "../../../components/Table/Table";
import { IResponse, StatusResponse } from "api/responses/types";
import { Select } from "../../../components/ui/Select/Select";
import { makeOption } from "../../../components/ui/Select/utils";

interface IProps {
  applicants: Array<IResponse>;
}

const Applicants = ({ applicants = [] }: IProps) => {
  const [open, setOpen] = useState(true);
  const [sortApplicants, setSortApplicants] = useState<IResponse[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const data = useOpeningData(sortApplicants);

  useEffect(() => {
    setSortApplicants(applicants);
  }, [applicants]);

  const handleClick = () => {
    setOpen(!open);
  };

  const onChange = (value: Array<string>) => {
    setStatuses(value as string[]);
    const arr = !value.length
      ? applicants
      : applicants.filter((item) => {
          return value.includes(item.fr_status as string);
        });
    setSortApplicants(arr);
  };

  return (
    <Styled.Wrapper id={"opening"}>
      <Styled.HeaderWrapper>
        <Styled.Header>Applicants ({applicants.length})</Styled.Header>
        <Styled.Collapse onClick={handleClick}>
          {open ? "Collapse table" : "Expand table"}
        </Styled.Collapse>
      </Styled.HeaderWrapper>
      <div style={{ marginBottom: "16px" }}>
        <Select
          // @ts-ignore
          isMulti={true}
          value={statuses as any}
          onChange={onChange as any}
          options={Object.values(StatusResponse).map(makeOption)}
          placeholder="Choose the status"
          name="status"
        />
      </div>
      {open && <Table columns={COLUMNS} data={data} />}
    </Styled.Wrapper>
  );
};

export { Applicants };
