import React, { useState } from "react";
import * as Styled from "../CompanyPage.styled";
import { IVacancy } from "api/vacancies/types";
import Table from "../../../components/Table/Table";
import { COLUMNS, useVacanciesData } from "./Vacancies.utils";
import { DeleteModal } from "../../../modals/Delete/Delete";

interface IProps {
  vacancies: IVacancy[];
  setVacancies: (vacancies: Array<IVacancy>) => void;
}

const Vacancies: React.FC<IProps> = ({ vacancies, setVacancies }) => {
  const [open, setOpen] = useState(true);
  const [openDeleteId, setOpenDeleteId] = useState<string | null>(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const successDelete = () => {
    const changeValue = (item: IVacancy) => {
      if (item.vac_id === openDeleteId) {
        return { ...item, status: "archived" };
      }
      return item;
    };
    setVacancies(vacancies.map(changeValue));
    setOpenDeleteId(null);
  };

  const handleDelete = (vacId: string) => {
    setOpenDeleteId(vacId);
  };

  const data = useVacanciesData(vacancies, handleDelete);

  return (
    <>
      <Styled.Wrapper>
        <Styled.HeaderWrapper>
          <Styled.Header>Vacancies ({vacancies.length})</Styled.Header>
          <Styled.Collapse onClick={handleClick}>
            {open ? "Collapse table" : "Expand table"}
          </Styled.Collapse>
        </Styled.HeaderWrapper>
        {open && <Table columns={COLUMNS} data={data} />}
      </Styled.Wrapper>
      <DeleteModal
        open={!!openDeleteId}
        type={"vacancy"}
        payload={vacancies.find((item) => item.vac_id === openDeleteId)}
        onClose={() => setOpenDeleteId(null)}
        callback={successDelete}
      />
    </>
  );
};

export { Vacancies };
