import React, { useMemo } from "react";
import * as Styled from "../../Card.styled";
import { Wrapper, InfoSubtitle } from "./Expectations.styled";
import { joinArray, pluralizeMonth } from "utils/helpers";

interface IProps {
  netSalaryFrom?: string;
  netSalaryTo?: string;
  contractDuration?: number;
  shipTypes?: Array<string>;
}

const Expectations: React.FC<IProps> = ({
  netSalaryFrom,
  netSalaryTo,
  contractDuration,
  shipTypes,
}) => {
  const regExp = /\B(?=(\d{3})+(?!\d))/g;

  const netSalary = useMemo(() => {
    let res = netSalaryFrom ? `$${netSalaryFrom}`.replace(regExp, " ") : "—";
    if (netSalaryTo) {
      res += ` - $${netSalaryTo}`.replace(regExp, " ");
    }
    return res;
  }, [netSalaryFrom, netSalaryTo]);

  return (
    <div>
      <Styled.InfoTitle>Expectations</Styled.InfoTitle>
      <Wrapper>
        <div>
          <InfoSubtitle>Ship type:</InfoSubtitle>
          {joinArray(shipTypes) || "—"}
        </div>
        <div>
          <InfoSubtitle>Net salary:</InfoSubtitle>
          <>{netSalary}</>
        </div>
        <div>
          <InfoSubtitle>Contract duration:</InfoSubtitle>
          {contractDuration
            ? `${contractDuration} ${pluralizeMonth(Number(contractDuration))}`
            : "—"}
        </div>
      </Wrapper>
    </div>
  );
};

export { Expectations };
