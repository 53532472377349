import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";
import { Button } from "../../components/ui/Button/Button.styled";

export const WrapperPage = styled.div`
  margin-bottom: ${indents.indent10};
`;

export const Information = styled.div`
  background: ${Color.BLUE50};
  padding: 24px 32px;
  margin-top: 16px;
`;

export const Buttons = styled.div`
  display: flex;
  ${Button} {
    min-width: 109px;
  }
`;

export const Lists = styled.div`
  display: flex;
  margin: -${indents.indent4} 0 ${indents.indent5} 0;
  gap: 56px;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  color: ${Color.DARK900};
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  padding-bottom: ${indents.indent2};
`;

export const NotSpecified = styled.span`
  color: ${Color.RED300};
`;

export const TypeCompany = styled.span`
  color: ${Color.BLUE500};
`;

export const StatusCompany = styled.span<{ isVerified: boolean }>`
  color: ${(props) => (props.isVerified ? Color.GREEN300 : Color.RED300)};
`;

export const Wrapper = styled.div`
  margin-top: ${indents.indent6};
`;

export const Header = styled.h2`
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  color: ${Color.DARK900};
  font-size: 24px;
  line-height: 26px;
  margin-bottom: ${indents.indent6};
`;

export const HeaderWrapper = styled.div`
  display: flex;
`;

export const Collapse = styled.span`
  display: inline-block;
  padding-left: 8px;
  padding-top: 5px;
  color: ${Color.BLUE300};
  cursor: pointer;
`;

export const AccessList = styled.div`
  margin-top: ${indents.indent5};
  color: ${Color.DARK900};
  font-weight: bold;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Link = styled.span`
  color: ${Color.BLUE300};
  cursor: pointer;
`;

export const ButtonsRow = styled.div`
  display: flex;
`;
