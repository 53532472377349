import styled, { css, keyframes } from "styled-components/macro";
import { Color } from "../../../styles/color";
import iconClose from "./assets/icon-close-popup.svg";

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

export const Wrapper = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  overflow-x: hidden;
  overflow-y: auto;

  z-index: 1000;

  visibility: hidden;
  opacity: 0;
  transition: opacity 300ms ease-in-out, visibility 300ms ease-in-out;

  // если open = true
  ${(props) =>
    props.open &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;

export const Layout = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(90, 90, 90, 0.8);
`;

export const ContentWrapper = styled.div`
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div<{ error?: boolean }>`
  padding: 24px 32px;
  min-width: 300px;
  max-width: 80vw;
  border-radius: 20px;
  background: white;
  position: relative;
  margin: 20px;

  ${(props) =>
    props.error &&
    css`
      animation: ${shake} 800ms cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    `};
`;

export const Title = styled.h2`
  font-family: Montserrat, Vardana, sans-serif;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 32px;
  color: ${Color.DARK900};
`;

export const CloseButton = styled.button`
  position: absolute;
  z-index: 1;
  padding: 0;
  top: 24px;
  right: 24px;
  width: 20px;
  height: 20px;
  background: transparent;
  border: none;
  background: url(${iconClose}) no-repeat center;
  cursor: pointer;

  transition: 200ms ease-in-out;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.3;
  }
`;
