import React, { useState, useEffect } from "react";
import * as Styled from "./Cv.styled";
import { Title } from "./components/Card/Card";
import PageLayout from "components/layout/PageLayout/PageLayout";
import { PhotoWithAvailable } from "./components/PhotoWithAvailable/PhotoWithAvailable";
import { GeneralInfo } from "./components/GeneralInfo/GeneralInfo";
import { SingularBlocks } from "./components/SingularBlocks/SingularBlocks";
import { Visas } from "./components/Visas/Visas";
import { Experience } from "./components/Experience/Experience";
import { Diplomas } from "./components/Diplomas/Diplomas";
import { Certificates } from "./components/Certificates/Certificates";
import { Passports } from "./components/Passports/Passports";
import { MedicalCertificates } from "./components/MedicalCertificates/MedicalCertificates";
import Loader from "components/utils/Loader/Loader";
import { useParams } from "react-router-dom";
import { ICvData } from "api/cvs/types";
import { getCvById } from "api/cvs/cvs";
import isEmpty from "lodash/fp/isEmpty";

const Cv = () => {
  const params = useParams() as { id: string };
  const [cvData, setCvData] = useState<ICvData | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const handleFetchCvData = async () => {
    const cvid = params.id;

    if (cvid) {
      const data = await getCvById(cvid as string);
      setCvData(data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchCvData();
  }, []);

  return (
    <PageLayout>
      {loading ? (
        <Loader />
      ) : (
        <Styled.CVPageLayout>
          <Styled.ContentWrapper>
            <Styled.Profile>
              <PhotoWithAvailable cvData={cvData} />
            </Styled.Profile>
            <GeneralInfo cvData={cvData} />
            <Styled.Rest>
              <SingularBlocks cvData={cvData} />
              <>
                {cvData?.visas && (
                  <>
                    <Styled.Divider />
                    <Visas visas={cvData.visas} />
                  </>
                )}
                {cvData?.work_experience && (
                  <>
                    <Styled.Divider />
                    <Experience
                      noExperience={cvData.no_experience}
                      total={cvData.total_work_experience}
                      experience={cvData.work_experience}
                    />
                  </>
                )}
                {cvData?.diplomas && (
                  <>
                    <Styled.Divider />
                    <Diplomas
                      getLater={cvData.dip_get_later}
                      date={cvData.dip_later_date}
                      diplomas={cvData.diplomas}
                    />
                  </>
                )}
                {cvData?.certificates && (
                  <>
                    <Styled.Divider />
                    <Certificates certificates={cvData.certificates} />
                  </>
                )}
                {cvData?.travel_passports && (
                  <>
                    <Styled.Divider />
                    <Passports passports={cvData.travel_passports} />
                  </>
                )}
                {cvData?.medical_certificates && (
                  <>
                    <Styled.Divider />
                    <MedicalCertificates
                      certificates={cvData.medical_certificates}
                    />
                  </>
                )}
                <Styled.Divider />
                {!isEmpty(cvData?.general_remarks) && (
                  <>
                    <Title>General Remarks</Title>
                    <Styled.GeneralRemarks>
                      {cvData?.general_remarks}
                    </Styled.GeneralRemarks>
                  </>
                )}
              </>
            </Styled.Rest>
          </Styled.ContentWrapper>
        </Styled.CVPageLayout>
      )}
    </PageLayout>
  );
};

export { Cv };
