import styled from "styled-components/macro";

export const PicWrapper = styled.div<{ noPicture?: boolean }>`
  min-width: 205px;
  width: 205px;
  border-radius: 8px 8px 0 0;
  ${({ noPicture }) => (noPicture ? "background: #ececec;" : "")}

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px 8px 0 0;
  }
`;
