import { IInitialValues } from "./forms/forms.const";

export interface IDataForServer {
  is_synt: boolean;
  company_id?: string;
  company_title?: string;
  status?: string;
  rank: string;
  ship_type: string;
  ship_dwt: number | "";
  net_salary_from: string | number;
  net_salary_to: string | number;
  contract_duration: string | number;
  has_dates_interval: boolean;
  date_of_boarding: string;
  crew_on_board: string[];
  private_info: string;
  seaman_citizenship: Array<string>;
  english_level: string;
  total_contracts: string | number;
  contracts_rank: string | number;
  contracts_ships: string | number;
  ship_types: Array<string>;
  fuel_types: Array<string>;
  ship_me_types: Array<string>;
  dwt_from: string | number;
  dwt_to: string | number;
  kw_from: string | number;
  kw_to: string | number;
  visas: Array<string>;
  certificates: Array<string>;
  medical_certificates: Array<string>;
  seaman_document: string;
  travel_passport: string;
  competency: Array<string>;
  proficiency: Array<string>;
  endorsement: string;
  endorsement_country: string;
  endorsement_competency: string;
  any_citizenship_allowed: boolean;
  no_experience_allowed: boolean;
}

type TFromTo = {
  [key: string]: {
    from: number | "";
    to: number | "";
  };
};

const isFromToEmpty = (formValue: TFromTo): boolean => {
  if (formValue?.from === undefined || formValue?.to === undefined) return true;

  return !formValue.from && !formValue.to;
};

const isEmptyValue = (val: number | string | string[] | TFromTo): boolean => {
  if (typeof val === "number") {
    return !val;
  }
  if (typeof val === "string" || Array.isArray(val)) {
    return !val.length;
  }
  if (typeof val === "boolean") return !val;

  return isFromToEmpty(val) || !val;
};

export const countFulfilledValues = (formValues: {
  [key: string]: any;
}): number | undefined => {
  if (!formValues) return;
  return Object.values(formValues).reduce((acc, val) => {
    if (isEmptyValue(val)) {
      return acc;
    }
    return acc + 1;
  }, 0);
};

const stringToNumber = (value: string | number): string | number => {
  if (value && typeof value === "string") {
    value = Number(value.replace(/\s/, ""));
  }
  return value;
};

export const adaptDataToServer = (data: IInitialValues): IDataForServer => {
  const salary_from: number | string = stringToNumber(
    data.general_info.net_salary.from || ""
  );
  const salary_to: number | string = stringToNumber(
    data.general_info.net_salary.to || ""
  );

  return {
    is_synt: data.config_info.is_synt,
    ...data.general_info,
    net_salary_from: salary_from,
    net_salary_to: salary_to,
    contract_duration: data.general_info.contract_duration
      ? Number(data.general_info.contract_duration.split(" ")[0].trim())
      : "",
    ...data.documents,
    ...data.personal_information,
    total_contracts: data.work_experience.total_contracts,
    contracts_rank: data.work_experience.contracts_rank,
    contracts_ships: data.work_experience.contracts_ships,
    ship_types: data.work_experience.ship_types,
    fuel_types: data.work_experience.fuel_types,
    ship_me_types: data.work_experience.ship_me_types,
    dwt_from: data.work_experience?.dwt.from,
    dwt_to: data.work_experience?.dwt.to,
    kw_from: data.work_experience?.kw.from,
    kw_to: data.work_experience?.kw.to,
    no_experience_allowed: data.work_experience?.no_experience_allowed,
  };
};
