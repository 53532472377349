import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const EntriesStyled = styled.table`
  font-size: 14px;
  line-height: 20px;
  border-collapse: collapse;

  td {
    padding: 0;
    padding-bottom: 8px;
    position: relative;
  }

  td:first-child {
    color: ${Color.MID100};
    padding-right: ${indents.indent4};
  }
`;
