import { ICompanyManager } from "api/companies/types";
import * as Styled from "../CompanyPage.styled";
import React, { useMemo } from "react";
import Button from "components/ui/Button/Button";
import { Color, Variant } from "components/ui/Button/Button.typed";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router-dom";
import { CopyLinkWithToken } from "../../../components/CopyLinkWithToken/CopyLinkWithToken";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "col1",
    minWidth: 200,
    maxWidth: 200,
    width: 200,
  },
  {
    Header: "Phone number, E-mail",
    accessor: "col2",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Date of registration",
    accessor: "col3",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Date of last entry",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "",
    accessor: "col5",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

const RowControls = ({
  manager,
  handleDelete,
}: {
  manager: ICompanyManager;
  handleDelete: (userId: string) => void;
}) => {
  const history = useHistory();
  const params = useParams() as { id: string };

  const handleEdit = () => {
    history.push(`/company/${params.id}/manager/edit/${manager.user_id}`);
  };

  const handleArchive = () => {
    handleDelete(manager.user_id);
  };

  return (
    <Styled.ButtonsRow>
      <CopyLinkWithToken userId={manager.user_id} textButton="Copy token" />
      <Button variant={Variant.TEXT} onClick={handleEdit}>
        Edit
      </Button>
      <Button
        color={Color.SECONDARY}
        variant={Variant.TEXT}
        onClick={handleArchive}
      >
        Archive
      </Button>
    </Styled.ButtonsRow>
  );
};

const prepareRowData = (
  manager: ICompanyManager,
  handleDelete: (userId: string) => void
) => {
  return {
    col1: `${manager.first_name || "-"} ${manager.last_name || "-"}`,
    col2: `${manager.phone || "–"}\n${manager.email || "–"}`,
    col3: dayjs(manager.created_at).format("YYYY/MM/DD"),
    col4: manager.last_activity_at
      ? dayjs(manager.last_activity_at).format("YYYY/MM/DD")
      : "-",
    col5: <RowControls manager={manager} handleDelete={handleDelete} />,
  };
};

export const useManagersData = (
  managers: ICompanyManager[],
  handleDelete: (userId: string) => void
) => {
  return useMemo(() => {
    if (!managers || !managers.length) return [];

    return managers.map((item) => prepareRowData(item, handleDelete));
  }, [managers]);
};
