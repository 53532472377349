import React, { useState } from "react";
import TextField from "components/ui/TextField";
import Button from "components/ui/Button/Button";
import styled from "styled-components/macro";
import Loader from "components/utils/Loader/Loader";
import { indents } from "styles/indents";
import { VacancyCard } from "../VacancyCard/VacancyCard";
import { getVacancy } from "api/vacancies/vacancies";
import { IVacancy } from "api/vacancies/types";
import { ErrorMessageText } from "components/ErrorMessage/ErrorMessage";
import { getApiError } from "utils/helpers";
import { AxiosResponse } from "axios";
import { TFormattedParams } from "../Filters/Filters.typed";
import { getFormattedParams } from "../../utils";
import { adaptDataForFilter } from "../Filters/Filter.utils";

const Container = styled.div`
  margin-bottom: ${indents.indent5};
`;

const Wrapper = styled.div`
  display: flex;
  gap: ${indents.indent5};
`;

const Input = styled(TextField)`
  & > :last-child {
    max-width: 100%;
  }
`;

interface IProps {
  handleSubmit: (val: TFormattedParams) => void;
  vacancy: IVacancy | null;
  setVacancy: (val: IVacancy | null) => void;
}

const AddingVacancy = ({ vacancy, setVacancy, handleSubmit }: IProps) => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const handleLink = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setLink(event.target.value);
  };

  const handleAddVacancy = async () => {
    setError("");
    const vacancyId = getVacancyId(link);
    if (vacancyId) {
      setLoading(true);
      try {
        const res = await getVacancy(vacancyId);
        setVacancy(res.data);
        handleSubmit(getFormattedParams(adaptDataForFilter(res.data)));
      } catch (e) {
        const error = e as AxiosResponse;
        setError(getApiError(error));
      }
      setLoading(false);
    }
  };

  const getVacancyId = (link: string) => {
    const splitPosition = link.indexOf("vacancy/");
    if (splitPosition === -1) {
      setError("Can't find id");
      return;
    }
    const slicedLink = link.slice(splitPosition + 8);
    const query = link.indexOf("?");
    if (query === -1) return slicedLink;
    else return slicedLink.slice(0, query);
  };

  const clearVacancy = () => {
    setVacancy(null);
    setLink("");
    handleSubmit({});
  };

  return (
    <Container>
      <Wrapper>
        {vacancy ? (
          <VacancyCard vacancy={vacancy} clearVacancy={clearVacancy} />
        ) : (
          <>
            <Input
              id="addingVacancy"
              name="addingVacancy"
              placeholder="Enter vacancy link"
              help={"Correct link: https://app.krew.guru/vacancy/..."}
              value={link}
              onChange={handleLink}
              style={{
                width: "100%",
                maxWidth: "100%",
                marginBottom: "-4px",
              }}
              inputStyle={{ padding: "9px 12px" }}
            />
            {loading ? (
              <Loader size={24} style={{ maxWidth: "135px" }} />
            ) : (
              <Button
                disabled={!link}
                onClick={handleAddVacancy}
                style={{ minWidth: "135px", maxHeight: "40px" }}
              >
                Enter vacancy
              </Button>
            )}
          </>
        )}
      </Wrapper>
      <ErrorMessageText text={error} />
    </Container>
  );
};

export { AddingVacancy };
