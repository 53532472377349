import React from "react";
import * as Styled from "./Skeleton.styled";

interface IProps {
  height: number;
  bottom?: number;
  maxWidth?: number;
}

const Skeleton: React.FC<IProps> = ({ height, bottom, maxWidth }) => {
  return (
    <Styled.Skeleton height={height} bottom={bottom} maxWidth={maxWidth} />
  );
};

export { Skeleton };
