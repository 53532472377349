import React, { useEffect, useState } from "react";
import { Tooltip } from "../TooltipEditCv.styled";
import { Select, TSelectProps } from "../../ui/Select/Select";
import { makeOption } from "../../ui/Select/utils";
import Button from "../../ui/Button/Button";
import { Variant } from "../../ui/Button/Button.typed";
import { FieldRow } from "../../FieldRow";
import { ICvData } from "../../../api/cvs/types";
import { Portal } from "../../Portal/Portal";
import { changeCv } from "../../../api/cvs/cvs";
import { AxiosResponse } from "axios";
import { getApiError } from "../../../utils/helpers";
import { ErrorMessageText } from "../../ErrorMessage/ErrorMessage";
import { useAppDispatch, useAppSelector } from "services/hooks";
import { getRanks, selectDC } from "services/slices/dictionaries";

interface IProps {
  cv: ICvData | null;
  onClose: () => void;
  updateCv: (cv: ICvData) => void;
}

const UpdateRanks = ({ cv, onClose, updateCv }: IProps) => {
  const dispatch = useAppDispatch();
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [error, setError] = useState("");

  const { ranks } = useAppSelector(selectDC);

  useEffect(() => {
    if (!ranks.length) dispatch(getRanks());
  }, []);

  useEffect(() => {
    if (cv) {
      setFirst(cv.first_rank || "");
      setSecond(cv.secondary_rank || "");
    }
  }, [cv]);

  const handleSave = async () => {
    if (cv) {
      try {
        const data = await changeCv(cv?.cv_id, {
          first_rank: first,
          secondary_rank: second,
        });
        updateCv({
          ...cv,
          first_rank: first,
          secondary_rank: second,
          last_admin_editor: data.data.last_edited_by,
        });
        onClose();
      } catch (e) {
        const error = e as AxiosResponse;
        setError(getApiError(error));
      }
    }
  };

  return (
    <Portal id={`ranks_${cv?.cv_id}`}>
      <Tooltip>
        <FieldRow label="First rank">
          <Select
            name="first_ranks"
            id="first_ranks"
            value={first as TSelectProps["value"]}
            onChange={(val) => setFirst(val as string)}
            placeholder="Choose rank"
            options={ranks.map(makeOption)}
          />
        </FieldRow>
        <FieldRow label="Second rank">
          <Select
            name="secondary_rank"
            id="secondary_rank"
            value={second as TSelectProps["value"]}
            onChange={(val) => setSecond(val as string)}
            placeholder="Choose rank"
            options={ranks.map(makeOption)}
          />
        </FieldRow>
        <Button onClick={handleSave}>Save</Button>
        <Button variant={Variant.TEXT} onClick={onClose}>
          Close
        </Button>
        {error && <ErrorMessageText text={error} />}
      </Tooltip>
    </Portal>
  );
};

export { UpdateRanks };
