import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "services/hooks";
import {
  getCertificates,
  getCoc,
  getCop,
  getCountries,
  getEndorsement,
  getFuelTypes,
  getLangLevels,
  getMedicalCertificates,
  getMETypes,
  getRanks,
  getShipTypes,
  selectDC,
} from "services/slices/dictionaries";

export const documents = [
  "Only credentials",
  "Credentials + uploaded document",
];

const useGetDictionary = () => {
  const dispatch = useAppDispatch();

  const {
    countries,
    langLevels,
    endorsement,
    coc,
    cop,
    certificates,
    medicalCertificates,
    ranks,
    shipTypes,
    fuelTypes,
    meTypes,
  } = useAppSelector(selectDC);

  useEffect(() => {
    if (!countries.length) dispatch(getCountries());
    if (!langLevels.length) dispatch(getLangLevels());
    if (!certificates.length) dispatch(getCertificates());
    if (!medicalCertificates.length) dispatch(getMedicalCertificates());
    if (!ranks.length) dispatch(getRanks());
    if (!shipTypes.length) dispatch(getShipTypes());
    if (!meTypes.length) dispatch(getMETypes());
    if (!fuelTypes.length) dispatch(getFuelTypes());
    if (!endorsement.length) dispatch(getEndorsement());
    if (!coc.length) dispatch(getCoc());
    if (!cop.length) dispatch(getCop());
  }, []);
};

export { useGetDictionary };
