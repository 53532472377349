import React, { useEffect, useState } from "react";
import * as Styled from "./AddCompany.styled";
import { useHistory, useParams } from "react-router-dom";
import PageLayout from "../../components/layout/PageLayout/PageLayout";
import Button from "../../components/ui/Button/Button";
import { ErrorMessageText } from "../../components/ErrorMessage/ErrorMessage";
import { Controller, useForm } from "react-hook-form";
import { GoBackButton } from "../../components/GoBackButton/GoBackButton";
import TextField, { RegexPattern } from "../../components/ui/TextField";
import { FieldRow } from "../../components/FieldRow";
import { PhoneInput } from "../../components/ui/PhoneInput";
import { TextArea } from "../../components/ui/TextArea";
import { PhotoEdit } from "../../components/PhotoEdit";
import { AxiosResponse } from "axios";
import { getApiError } from "../../utils/helpers";
import { editCompany, fetchCompanyInfo } from "../../api/companies/companies";
import Loader from "../../components/utils/Loader/Loader";
import { ICompany } from "api/companies/types";

export interface ICompanyEditData {
  title: string;
  office_location: string;
  email: string;
  phone: string;
  description: string;
  logotype: string;
  cv_search_enabled: boolean;
  vacancies_enabled: boolean;
}

const EditCompany = () => {
  const history = useHistory();
  const params = useParams() as { id: string };
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState<ICompany | null>(null);
  const [error, setError] = useState("");

  const { handleSubmit, control, reset } = useForm<ICompanyEditData>({
    mode: "onBlur",
  });

  useEffect(() => {
    (async () => {
      const res = await fetchCompanyInfo(params.id);
      reset({
        email: res.data.email || "",
        description: res.data.description || "",
        title: res.data.title || "",
        office_location: res.data.office_location || "",
        phone: res.data.phone || "",
        logotype: res.data.logotype || "",
      });
      setCompany(res.data);
      setLoading(false);
    })();
  }, []);

  const handleClick = async (data: ICompanyEditData) => {
    setError("");
    try {
      const payload = {
        ...data,
        cv_search_enabled: company?.cv_search_enabled || false,
        vacancies_enabled: company?.vacancies_enabled || false,
      };
      const res = await editCompany(params.id, payload);
      if (res.status === 200) {
        alert("Успешно сохранено");
        history.push(`/company/${params.id}`);
      }
    } catch (e) {
      const error = e as AxiosResponse;
      setError(getApiError(error));
    }
  };

  if (loading) {
    return (
      <PageLayout>
        <Loader />
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      <GoBackButton text="Back to company" link={`/company/${params.id}`} />
      <Styled.Wrapper>
        <Styled.Aside>
          <Styled.ButtonContainer>
            <Button onClick={handleSubmit(handleClick)}>Save</Button>
            {error && <ErrorMessageText text={error} />}
          </Styled.ButtonContainer>
        </Styled.Aside>
        <Styled.Main>
          <Styled.Title>Edit company</Styled.Title>
          <form>
            <FieldRow label="Title">
              <Controller
                name="title"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, name, value } }) => (
                  <TextField
                    help="Если заполнить это поле, то в сервисе страница регистрации компании отображаться не будет"
                    name={name}
                    id={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Title company"
                  />
                )}
              />
            </FieldRow>
            <FieldRow label="Office location">
              <Controller
                name="office_location"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, name, value } }) => (
                  <TextField
                    name={name}
                    id={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Office location"
                  />
                )}
              />
            </FieldRow>
            <FieldRow label="Email">
              <Controller
                name="email"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, name, value } }) => (
                  <TextField
                    pattern={RegexPattern.EMAIL_ALLOWED_CHARS}
                    name={name}
                    id={name}
                    value={value}
                    onChange={onChange}
                    placeholder="Email"
                  />
                )}
              />
            </FieldRow>
            <FieldRow label="Phone">
              <Controller
                name="phone"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, name, value } }) => (
                  <PhoneInput
                    country="rus"
                    name={name}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </FieldRow>
            <FieldRow label="Description">
              <Controller
                name="description"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, name, value } }) => (
                  <TextArea
                    name={name}
                    defaultValue={value}
                    placeholder="Description"
                    onChange={onChange}
                  />
                )}
              />
            </FieldRow>
            <FieldRow label="Logotype">
              <Controller
                name="logotype"
                control={control}
                defaultValue={""}
                render={({ field: { onChange, value } }) => (
                  <PhotoEdit defaultPhotoUrl={value} onChange={onChange} />
                )}
              />
            </FieldRow>
          </form>
        </Styled.Main>
      </Styled.Wrapper>
    </PageLayout>
  );
};

export { EditCompany };
