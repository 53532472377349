import React from "react";

import * as Dashboard from "pages/Dashboard/Dashboard.styled";
import Loader from "../../utils/Loader/Loader";
import * as Styled from "./CompaniesCard.styled";
import { useHistory } from "react-router-dom";
import Button from "components/ui/Button/Button";
import { indents } from "styles/indents";
import { Color } from "../../ui/Button/Button.typed";
import { ICompany } from "api/companies/types";

interface ICompaniesCard {
  small?: boolean;
  companiesLength?: number;
  loading: boolean;
  companies?: ICompany[];
}

const CompaniesCard = ({
  small,
  companiesLength,
  loading,
  companies,
}: ICompaniesCard) => {
  const history = useHistory();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    history.push(`/company/add`);
  };

  const handleClickCard = () => {
    history.push(`/companies`);
  };

  const titleJsx = (
    <Dashboard.Title white={small}>
      <Dashboard.TitleText>Total companies: </Dashboard.TitleText>
      {loading ? <Loader inline /> : companiesLength}
    </Dashboard.Title>
  );

  if (small) {
    return (
      <Styled.CompaniesCard small={small}>
        <span>{titleJsx}</span>
        <Button
          color={Color.PRIMARY}
          onClick={handleClick}
          style={{ marginTop: indents.indent5 }}
        >
          Add company
        </Button>
      </Styled.CompaniesCard>
    );
  }

  return (
    <div onClick={handleClickCard}>
      <Styled.CompaniesCard hover>
        {titleJsx}
        {companies && (
          <Styled.CompaniesList>
            {companies.map((c) => (
              <Dashboard.ListItem key={c.company_id}>
                <Dashboard.Text>{c.title || "No title"}</Dashboard.Text>
              </Dashboard.ListItem>
            ))}
          </Styled.CompaniesList>
        )}
        <Button color={Color.PRIMARY} onClick={handleClick}>
          Add company
        </Button>
      </Styled.CompaniesCard>
    </div>
  );
};

export default CompaniesCard;
