export const indents = {
  indent1: "2px" as const,
  indent2: "4px" as const,
  indent3: "6px" as const,
  indent4: "8px" as const,
  indent5: "16px" as const,
  indent6: "24px" as const,
  indent7: "32px" as const,
  indent8: "40px" as const,
  indent9: "48px" as const,
  indent10: "56px" as const,
  indent11: "64px" as const,
  indent12: "80px" as const,
  indent13: "96px" as const,
  indent14: "140px" as const,

  indent15: "12px" as const,
  indent16: "10px" as const,
};
