import React from "react";

export interface IButton extends React.ComponentPropsWithRef<"button"> {
  /** ссылка, если необходима ссылка в виде кнопки */
  href?: string;
  /** заголовок кнопки */
  children: string;
  /** для наследования стилей через styled-components */
  className?: string;
  /** внешний вид кнопки */
  variant?: Variant;
  /** цвет кнопки */
  color?: Color;
}

export type TColorSet = {
  default: string;
  hover: string;
  focus: string;
  pale: string;
};

export enum Variant {
  TEXT = "text",
  OUTLINED = "outlined",
  CONTAINED = "contained",
}

export enum Color {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  YELLOW = "yellow",
  GREEN = "green",
}
