import React, { useRef, useState } from "react";
import PageLayout from "components/layout/PageLayout/PageLayout";
import Store from "./components/Store/Store";
import Filter from "components/Filter/Filter";
import NewCvs from "./components/NewCvs/NewCvs";
import { NotificationModerationModal } from "modals/ModerationCV/NotificationModeration";
import { ICvData, ModerationStatus } from "api/cvs/types";

export enum Tabs {
  full_base = "Full Base",
  latest_cvs = "Latest CVs",
}

export interface IOpenModeration {
  type: ModerationStatus | null;
  cv: ICvData | null;
}

export interface IRefUpdateCv {
  updateCv: (cv: ICvData) => void;
}

const defaultTabs = [
  {
    name: Tabs.full_base,
    amount: 0,
  },
  {
    name: Tabs.latest_cvs,
    amount: 0,
  },
];

const SeamenPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState(Tabs.full_base);
  const ref = useRef<IRefUpdateCv>(null);

  const [openModeration, setOpenModeration] = useState<IOpenModeration>({
    type: null,
    cv: null,
  });

  const updateCv = (cv: ICvData) => {
    if (ref && ref.current) {
      ref.current.updateCv(cv);
    }
  };

  return (
    <PageLayout>
      <Filter items={defaultTabs} setFilter={setActiveTab} />
      {activeTab === Tabs.full_base && (
        <Store setOpenModeration={setOpenModeration} ref={ref} />
      )}
      {activeTab === Tabs.latest_cvs && (
        <NewCvs setOpenModeration={setOpenModeration} ref={ref} />
      )}
      <NotificationModerationModal
        isOpen={!!openModeration.type}
        onClose={() => setOpenModeration({ type: null, cv: null })}
        payload={openModeration}
        updateCv={updateCv}
      />
    </PageLayout>
  );
};

export default SeamenPage;
