// @ts-nocheck
import * as yup from "yup";

const isNumber = (val: string | number) => typeof val === "number";
const numberContract = yup.lazy((value) =>
  value === ""
    ? yup.string().when("no_experience_allowed", {
        is: false,
        then: yup.string().required("Fill in the required field"),
      })
    : yup
        .number()
        .min(0, "The value can be between 0 and 99")
        .max(99, "The value can be between 0 and 99")
        .when("contracts_rank", (contract_rank) => {
          if (isNumber(contract_rank)) {
            return yup
              .number()
              .min(
                contract_rank,
                "The value cannot be less than the value in the Contracts in rank field"
              )
              .max(99, "The value can be between 0 and 99");
          }
        })
);

const schemeRank = yup.lazy((value) =>
  value === ""
    ? yup.string().when("no_experience_allowed", {
        is: false,
        then: yup.string().required("Fill in the required field"),
      })
    : yup.number().when(`total_contracts`, (val) => {
        if (isNumber(val)) {
          return yup
            .number()
            .max(
              val,
              "The value cannot be greater than the value in the Total contracts field"
            );
        }
      })
);

const schemeShips = yup.lazy(() =>
  yup
    .array()
    .max(5, "Maximum 5 values")
    .when("no_experience_allowed", {
      is: false,
      then: yup
        .array()
        .max(5, "Maximum 5 values")
        .min(1, "Fill in the required field"),
    })
);

const schemeContractsShips = yup.lazy((value) =>
  value === ""
    ? yup.string()
    : yup
        .number()
        .min(1, "The value can be between 1 and 99")
        .when(`total_contracts`, (val) => {
          if (isNumber(val)) {
            return yup
              .number()
              .min(1, "The value can be between 1 and 99")
              .max(
                val,
                "The value cannot be greater than the value in the Total contracts field"
              );
          }
        })
);

const dwFromTo = yup.lazy((value) =>
  value === ""
    ? yup.string()
    : yup
        .number()
        .min(50, `The value can be between 50 and 900 000`)
        .max(900000, `The value can be between 50 and 900 000`)
        .when(["from", "to"], (from, to) => {
          if (isNumber(from) && isNumber(to)) {
            return yup
              .number()
              .min(50, "The value can be between 50 and 900 000")
              .max(900000, "The value can be between 50 and 900 000")
              .test(
                "dwt",
                "The value From cannot be greater than the value To",
                () => from <= to
              );
          }
        })
);

const kwFromTo = yup.lazy((value) =>
  value === ""
    ? yup.string()
    : yup
        .number()
        .min(0, `The value can be between 0 and 100 000`)
        .max(100000, `The value can be between 0 and 100 000`)
        .when(["from", "to"], (from, to) => {
          if (isNumber(from) && isNumber(to)) {
            return yup
              .number()
              .min(0, `The value can be between 0 and 100 000`)
              .max(100000, `The value can be between 0 and 100 000`)
              .test(
                "kw",
                "The value From cannot be greater than the value To",
                () => from <= to
              );
          }
        })
);

export const WorkExperienceScheme = yup.object().shape({
  no_experience_allowed: yup.boolean(),
  total_contracts: numberContract,
  contracts_rank: schemeRank,
  contracts_ships: schemeContractsShips,
  ship_types: schemeShips,
  ship_me_types: yup.array().max(5, "Maximum 5 values"),
  fuel_types: yup.array().max(5, "Maximum 5 values"),
  dwt: yup.object().shape({
    from: dwFromTo,
    to: dwFromTo,
  }),
  kw: yup.object().shape({
    from: kwFromTo,
    to: kwFromTo,
  }),
});
