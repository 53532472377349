import React, { useEffect, useMemo, useState } from "react";
import ReactSelect, { Props as SelectProps } from "react-select";
import AsyncReactSelect from "react-select/async";
import { DebouncedFunc } from "lodash";
import get from "lodash/fp/get";

import { DropdownIndicator } from "./components/DropdownIndicator";
import { selectStyles } from "./styles";
import { makeOption } from "./utils";
import { ErrorMessageText } from "../../ErrorMessage/ErrorMessage";

type LoadOptionsFunc = (inputValue: string) => void | Promise<readonly any[]>;
type OptionType = { label: string; value: string };

export type TSelectProps = SelectProps & {
  onChange: (value: string | string[]) => void;
  groupLeft?: boolean;
  groupRight?: boolean;
  groupTop?: boolean;
  groupBottom?: boolean;
  width?: number;
  async?: boolean;
  loadOptions?: LoadOptionsFunc | DebouncedFunc<LoadOptionsFunc>;
  value?: string | Array<string> | OptionType;
  isClearable?: boolean;
  maxValuesLength?: number;
  errors?: string | boolean;
  filterOptionValues?: Array<string>;
};

export const getCurrentValue = (
  value?: string | Array<string> | OptionType
) => {
  if (value && typeof value === "string") {
    return makeOption(value);
  }
  if (Array.isArray(value)) {
    return value.map((item) => makeOption(item));
  }
  return value;
};

export const Select = (props: TSelectProps): JSX.Element => {
  const {
    options: optionsProps = [],
    placeholder,
    width = 340,
    groupLeft,
    groupRight,
    groupTop,
    groupBottom,
    async = false,
    loadOptions,
    onChange,
    value,
    isClearable = true,
    errors,
    isMulti = false,
    filterOptionValues,
    maxValuesLength = 20,
  } = props;

  const shouldShowError = props.errors && !!String(props.error).length;
  const [options, setOptions] = useState<Array<OptionType>>([]);
  const [warning, setWarning] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [focus, setFocus] = useState(false);
  const currentValue = getCurrentValue(value);

  const handleChange = (option: OptionType[] | OptionType | null) => {
    if (isMulti && Array.isArray(option)) {
      const error = option.length > maxValuesLength;
      if (error) {
        setWarning(`Maximum ${maxValuesLength} filters per category`);
        setTimeout(() => {
          setWarning("");
        }, 3000);
        return;
      }
      onChange(option.map((item) => item.value));
    } else {
      onChange(get(["value"], option));
    }
  };

  // Устанавливаем options. Если есть условия фильтра, то применяем их
  useEffect(() => {
    if (filterOptionValues && filterOptionValues.length) {
      const filter = optionsProps.filter((item) => {
        return !filterOptionValues.includes(item.label);
      });
      setOptions(filter as Array<OptionType>);
    } else if (Array.isArray(optionsProps)) {
      setOptions([...optionsProps]);
    }
  }, [filterOptionValues, optionsProps]);

  // Блокируем кирилицу для ввода
  const onInputChange = (inputValue: string) => {
    const value = inputValue.replace(/^[а-яё]/i, "");
    setInputValue(value);
    return value;
  };

  const showError = useMemo(() => {
    if (!focus || warning) {
      return (shouldShowError && typeof errors !== "boolean") || warning;
    }
    return false;
  }, [shouldShowError, warning, errors, focus]);

  return (
    <>
      {async ? (
        <AsyncReactSelect
          {...props}
          onInputChange={onInputChange}
          inputValue={inputValue}
          value={currentValue as OptionType}
          defaultOptions={[]}
          loadOptions={loadOptions}
          placeholder={placeholder}
          styles={selectStyles}
          components={{ DropdownIndicator } as any}
          width={width}
          groupLeft={groupLeft}
          groupRight={groupRight}
          groupTop={groupTop}
          groupBottom={groupBottom}
          onChange={handleChange}
          isClearable={isClearable}
          maxMenuHeight={184}
          noOptionsMessage={() => null}
          error={shouldShowError}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      ) : (
        <ReactSelect
          {...props}
          onInputChange={onInputChange}
          inputValue={inputValue}
          value={(currentValue || "") as OptionType}
          options={options}
          placeholder={placeholder}
          styles={selectStyles}
          classNamePrefix="custom"
          components={{ DropdownIndicator } as any}
          width={width}
          groupLeft={groupLeft}
          groupRight={groupRight}
          groupTop={groupTop}
          groupBottom={groupBottom}
          onChange={handleChange}
          isClearable={isClearable}
          error={shouldShowError}
          noOptionsMessage={() => null}
          maxMenuHeight={184}
          isMulti={isMulti}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
        />
      )}
      {showError && <ErrorMessageText text={String(errors || warning)} />}
    </>
  );
};
