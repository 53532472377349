import React, { useMemo, useState } from "react";
import * as Styled from "../CompanyPage.styled";
import { ICompanyManager } from "api/companies/types";
import Table from "components/Table/Table";
import { COLUMNS, useManagersData } from "./Managers.utils";
import { useParams } from "react-router-dom";
import { DeleteModal } from "../../../modals/Delete/Delete";

interface IProps {
  managers: Array<ICompanyManager>;
  setManagers: (managers: Array<ICompanyManager>) => void;
}

const Managers: React.FC<IProps> = ({ managers, setManagers }) => {
  const params = useParams() as { id: string };

  const [open, setOpen] = useState(true);
  const [openDeleteId, setOpenDeleteId] = useState<string | null>(null);

  const manager = useMemo(() => {
    const item = managers.find((item) => item.user_id === openDeleteId);
    if (item) item.company_id = params.id;
    return item;
  }, [openDeleteId, managers]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleDelete = (userId: string) => {
    setOpenDeleteId(userId);
  };

  const successDelete = () => {
    const deleteValue = (item: ICompanyManager) =>
      item.user_id !== openDeleteId;
    setManagers(managers.filter(deleteValue));
    setOpenDeleteId(null);
  };

  const data = useManagersData(managers, handleDelete);

  return (
    <Styled.Wrapper>
      <Styled.HeaderWrapper>
        <Styled.Header>Managers ({managers.length})</Styled.Header>
        <Styled.Collapse onClick={handleClick}>
          {open ? "Collapse table" : "Expand table"}
        </Styled.Collapse>
      </Styled.HeaderWrapper>
      {open && <Table columns={COLUMNS} data={data} />}
      <DeleteModal
        open={!!openDeleteId}
        type={"managers"}
        payload={manager}
        callback={successDelete}
        onClose={() => setOpenDeleteId(null)}
      />
    </Styled.Wrapper>
  );
};

export { Managers };
