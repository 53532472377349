import { api } from "../axiosConfig";
import { ICompany, ICompanyManager, IOpeningCv } from "./types";
import { ICompanyEditData } from "../../pages/AddCompany/EditCompany";

export const verifyCompany = (id: string) => api.get(`/companies/${id}/verify`);

export const fetchCompanies = () => api.get<ICompany[]>("/companies");

export const fetchCompanyInfo = (id: string) =>
  api.get<ICompany>(`/companies/${id}`);

export const fetchCompanyManagers = (id: string) =>
  api.get<ICompanyManager[]>(`/companies/${id}/members`);

export const fetchCompanyOpeningCv = (id: string) =>
  api.get<IOpeningCv[]>(`/companies/${id}/contacts-history`);

export const createNewCompany = (
  payload: null | {
    user_id?: string;
    title?: string;
  }
) => api.post("/companies", payload);

export const editCompany = (id: string, payload: ICompanyEditData) =>
  api.put(`/companies/${id}`, payload);

export const getStatsCompanies = () => api.get("/companies/stats");

export const editCost = (id: string, cost: number) =>
  api.put(`/companies/${id}/contact-cost`, { cost: cost });

export const editBalance = (id: string, balance: number) =>
  api.put(`/companies/${id}/balance`, { balance: balance });

export const deleteCompany = (id: string) => api.delete(`/companies/${id}`);

export const addManagers = (id: string, userId: string) =>
  api.post(`/companies/${id}/members`, { user_id: userId });

export const getManagerInformation = (companyId: string, userId: string) =>
  api.get(`/companies/${companyId}/members/${userId}`);

export const deleteManager = (companyId: string, userId: string) =>
  api.delete(`/companies/${companyId}/members/${userId}`);
