import styled from "styled-components/macro";
import { Color } from "styles/color";
import { indents } from "styles/indents";

export const BaseInfo = styled.div`
  min-height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  color: ${Color.MID400};

  & > div {
    &:not(:first-child):not(:empty) {
      &::before {
        content: "·";
        margin: 0 ${indents.indent4};
        color: ${Color.DARK900};
      }
    }

    &:last-child {
      &::after {
        content: none;
      }
    }
  }
`;

export const Age = styled.div`
  max-width: 75px;
  white-space: nowrap;
`;

export const Residence = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
