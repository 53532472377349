import React from "react";
import * as Styled from "./NameAndRank.styled";
import { joinArray } from "utils/helpers";

interface IProps {
  firstName?: string;
  lastName?: string;
  ranks?: Array<string | undefined>;
}

const NameAndRank: React.FC<IProps> = ({ firstName, lastName, ranks }) => {
  return (
    <>
      <Styled.Name>
        {firstName || lastName ? (
          <>
            {firstName} {lastName}
          </>
        ) : (
          "—"
        )}
      </Styled.Name>
      <Styled.Position>
        {joinArray(ranks) || "The rank is not specified"}
      </Styled.Position>
    </>
  );
};

export { NameAndRank };
