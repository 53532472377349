import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as Styled from "../CompanyPage.styled";
import Button from "components/ui/Button/Button";
import { Color, Variant } from "components/ui/Button/Button.typed";
import VerifyCompanyModal from "modals/VerifyCompanyModal/VerifyCompanyModal";
import { ICompany } from "api/companies/types";
import Loader from "components/utils/Loader/Loader";
import { EditBalance } from "./EditBalance";
import { EditCost } from "./EditCost";
import { Checkbox } from "../../../components/ui/Checkbox";
import { editCompany } from "../../../api/companies/companies";
import { DeleteModal } from "../../../modals/Delete/Delete";
import { getBaseUrl } from "utils/helpers";

interface IProps {
  info: ICompany | null;
  managers: number;
  loader: boolean;
  fetchCompany: () => void;
}

const Information: React.FC<IProps> = ({
  info,
  managers,
  fetchCompany,
  loader,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const history = useHistory();
  const params = useParams() as { id: string };

  const handleClickAddVacancy = () => {
    if (info) {
      history.push(`/company/${params.id}/${info.title}/add_vacancy`);
    }
  };

  const handleClickOpenHistory = () => {
    if (info) {
      const el = document.getElementById("opening");
      if (el) el.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEditCompany = () => {
    history.push(`/company/edit/${params.id}`);
  };

  const handleClickAddManager = () => {
    history.push(`/company/${params.id}/manager/add`);
  };

  const handleOpenCompanyPage = () => {
    const baseUrl = getBaseUrl();
    window.open(`${baseUrl}/companies/${params.id}`, "_blank");
  };

  const onChangeAccess = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = {
      email: info?.email || "",
      description: info?.description || "",
      title: info?.title || "",
      office_location: info?.office_location || "",
      phone: info?.phone || "",
      logotype: info?.logotype || "",
      cv_search_enabled: info?.cv_search_enabled || false,
      vacancies_enabled: info?.vacancies_enabled || false,
    };
    // @ts-ignore
    data[e.target.name] = e.target.checked;

    try {
      const res = await editCompany(params.id, data);
      fetchCompany();
      if (res.status === 200) {
        alert("Успешно сохранено");
      }
    } catch (e) {
      alert("Ошибка сохранения");
    }
  };

  if (!info)
    return (
      <Styled.Information>
        <Loader />
      </Styled.Information>
    );

  return (
    <Styled.Information>
      <Styled.Header>{info.title}</Styled.Header>
      <Styled.Lists>
        <Styled.List>
          <Styled.ListItem>
            Type: <Styled.TypeCompany>Company</Styled.TypeCompany>
          </Styled.ListItem>
          <Styled.ListItem>
            Balance: {loader ? "load..." : info.balance}
            <EditBalance callback={fetchCompany} id={info.company_id} />
          </Styled.ListItem>
          <Styled.ListItem>
            Open contact price: {loader ? "load..." : info.contact_opening_cost}
            <EditCost callback={fetchCompany} id={info.company_id} />
          </Styled.ListItem>
          <Styled.ListItem>
            Status:{" "}
            <Styled.StatusCompany isVerified={info.is_verified}>
              {info.is_verified ? "Verified" : "Not verified"}
            </Styled.StatusCompany>{" "}
          </Styled.ListItem>
          <Styled.ListItem>Managers: {managers}</Styled.ListItem>
          <Styled.ListItem>
            Vacancies published: {info.total_vacancies_published}
          </Styled.ListItem>
          <Styled.ListItem>
            CV opened: {info.total_contact_openings}
          </Styled.ListItem>
        </Styled.List>
        <Styled.List>
          <Styled.ListItem>
            E-mail:{" "}
            {info.email || (
              <Styled.NotSpecified>not specified</Styled.NotSpecified>
            )}
          </Styled.ListItem>
          <Styled.ListItem>
            Phone:{" "}
            {info.phone || (
              <Styled.NotSpecified>not specified</Styled.NotSpecified>
            )}
          </Styled.ListItem>
          <Styled.ListItem>
            Location:{" "}
            {info.office_location || (
              <Styled.NotSpecified>not specified</Styled.NotSpecified>
            )}
          </Styled.ListItem>
          <Styled.ListItem>
            Working Area:{" "}
            <Styled.NotSpecified>not specified</Styled.NotSpecified>
          </Styled.ListItem>
          <Styled.AccessList>
            Grant access to:
            <Checkbox
              name="cv_search_enabled"
              checked={info?.cv_search_enabled}
              onChange={onChangeAccess}
            >
              CV Database
            </Checkbox>
            <Checkbox
              name="vacancies_enabled"
              checked={info?.vacancies_enabled}
              onChange={onChangeAccess}
            >
              Vacancies
            </Checkbox>
          </Styled.AccessList>
        </Styled.List>
      </Styled.Lists>
      <Styled.Buttons>
        <Button onClick={handleOpenCompanyPage}>Open company page</Button>
        <Button onClick={handleEditCompany}>Edit</Button>
        <Button onClick={handleClickAddManager}>Add manager</Button>
        <Button onClick={handleClickAddVacancy}>Add vacancy</Button>
        <Button onClick={handleClickOpenHistory} variant={Variant.OUTLINED}>
          History of opening
        </Button>
        <Button color={Color.GREEN} onClick={() => setIsOpen(true)}>
          Verify
        </Button>
        <Button color={Color.SECONDARY} onClick={() => setIsOpenDelete(true)}>
          Archive
        </Button>
      </Styled.Buttons>
      <VerifyCompanyModal
        callback={fetchCompany}
        open={isOpen}
        id={info.company_id}
        name={info.title || ""}
        onClose={() => setIsOpen(false)}
      />
      <DeleteModal
        open={isOpenDelete}
        type="company"
        payload={info}
        onClose={() => setIsOpenDelete(false)}
        callback={() => history.push("/companies")}
      />
    </Styled.Information>
  );
};

export { Information };
