import { PAGE_SIZE } from "./components/Store/Store";
import { IParams, TFormattedParams } from "./components/Filters/Filters.typed";

export const getSearchString = (
  params: TFormattedParams,
  onlyGoodCvsFilter: boolean,
  currentPage = 0,
  pageSize = PAGE_SIZE
) => {
  return `limit=${pageSize}&skip=${
    currentPage * pageSize
  }&only_good_cvs=${onlyGoodCvsFilter}&${
    Object.entries(params)
      .map(
        ([key, entry]) =>
          `${key}=${Array.isArray(entry) ? entry.join(",") : entry}`
      )
      .join("&") || ""
  }`;
};

export const getFormattedParams = (params: IParams): TFormattedParams => {
  const result = {} as TFormattedParams;
  Object.keys(params).forEach((key) => {
    if (params[key]) {
      if (Array.isArray(params[key]) && params[key].length) {
        result[key] = params[key] as string[];
      }
      if (typeof params[key] === "object" && !Array.isArray(params[key])) {
        result[key] = Object.values(params[key]).map((item) => item.toString());
      }
      if (typeof params[key] === "string" || typeof params[key] === "boolean") {
        result[key] = params[key].toString();
      }
    }
  });
  return result;
};
