export const Color = {
  DARK900: "#061938",
  DARK800: "#172b4d",
  DARK700: "#253858",
  DARK600: "#344563",
  DARK500: "#42526e",

  MID400: "#505f79",
  MID300: "#5e6c84",
  MID200: "#6b778c",
  MID100: "#7a869a",
  MID90: "#8993a4",
  MID80: "#97a0af",
  MID70: "#a5adba",
  MID60: "#b3bac5",

  LIGHT50: "#c1c7d0",
  LIGHT40: "#dfe1e6",
  LIGHT30: "#ebecf0",
  LIGHT20: "#f4f5f7",
  LIGHT10: "#fafbfc",
  LIGHT00: "#ffffff",

  RED500: "#b92500",
  RED400: "#de350b",
  RED300: "#ff5630",
  RED200: "#ff7452",
  RED100: "#ff8f73",
  RED75: "#ffbdad",
  RED50: "#ffebe6",

  YELLOW500: "#ff8b00",
  YELLOW400: "#ff991f",
  YELLOW300: "#ffab00",
  YELLOW200: "#ffc400",
  YELLOW100: "#ffe380",
  YELLOW75: "#fff0b3",
  YELLOW50: "#fffae6",

  GREEN500: "#006644",
  GREEN400: "#00875a",
  GREEN300: "#36b37e",
  GREEN200: "#57d9a3",
  GREEN100: "#79f2c0",
  GREEN75: "#abf5d1",
  GREEN50: "#e3fcef",

  TEAL500: "#008da6",
  TEAL400: "#00a3bf",
  TEAL300: "#00b8d9",
  TEAL200: "#00c7e6",
  TEAL100: "#79e2f2",
  TEAL75: "#b3f5ff",
  TEAL50: "#e6fcff",

  BLUE500: "#0747a6",
  BLUE400: "#0052cc",
  BLUE300: "#0065ff",
  BLUE200: "#2684ff",
  BLUE100: "#4c9aff",
  BLUE75: "#b3d4ff",
  BLUE50: "#f2f7ff",

  PURPLE500: "#403294",
  PURPLE400: "#5243aa",
  PURPLE300: "#6554c0",
  PURPLE200: "#8777d9",
  PURPLE100: "#998dd9",
  PURPLE75: "#c0b6f2",
  PURPLE50: "#eae6ff",
};
