import React, { ChangeEvent, useEffect, useState } from "react";
import { Tooltip } from "../TooltipEditCv.styled";
import Button from "../../ui/Button/Button";
import { Variant } from "../../ui/Button/Button.typed";
import { ICvData, StatusAvailable } from "../../../api/cvs/types";
import { Portal } from "../../Portal/Portal";
import { Radio } from "../../ui/Radio/Radio";
import { DatePicker } from "../../ui/DatePicker/DatePicker";
import dayjs from "dayjs";
import { changeCv } from "../../../api/cvs/cvs";
import { formatDateAPI } from "../../../utils/dateTime";
import { AxiosResponse } from "axios";
import { getApiError } from "../../../utils/helpers";
import { ErrorMessageText } from "../../ErrorMessage/ErrorMessage";

interface IProps {
  cv: ICvData | null;
  onClose: () => void;
  updateCv: (cv: ICvData) => void;
}

interface IPayload {
  availability_status: StatusAvailable;
  available_from?: string;
}

const UpdateStatusAvailable = ({ cv, onClose, updateCv }: IProps) => {
  const [status, setStatus] = useState(
    cv?.availability_status || StatusAvailable.available
  );
  const [date, setDate] = useState("");
  const [errorDate, setErrorDate] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (cv) {
      setStatus(cv.availability_status);
      if (cv.availability_status === StatusAvailable.postponed) {
        setDate(cv.date_of_availability);
      } else {
        setDate("");
      }
    }
  }, [cv]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setStatus(e.target.value as StatusAvailable);
  };

  const handleChangeDate = (val: string | Date) => {
    setDate(val as string);
  };

  const handleSave = async () => {
    if (status === StatusAvailable.postponed && !date) {
      setErrorDate(true);
      return;
    }
    if (cv && status) {
      const payload: IPayload = { availability_status: status };
      if (status === StatusAvailable.postponed) {
        payload.available_from = date;
      } else if (status === StatusAvailable.available) {
        payload.available_from = formatDateAPI(new Date());
      }
      try {
        const data = await changeCv(cv.cv_id, payload);
        updateCv({
          ...cv,
          availability_status: status,
          date_of_availability: date,
          last_admin_editor: data.data.last_edited_by,
        });
        onClose();
      } catch (e) {
        const error = e as AxiosResponse;
        setError(getApiError(error));
      }
    }
  };

  return (
    <Portal id={`available_${cv?.cv_id}`}>
      <Tooltip>
        <Radio
          name="status"
          value={StatusAvailable.available}
          checked={status === StatusAvailable.available}
          onChange={handleChange}
        >
          Immediately available
        </Radio>
        <Radio
          name="status"
          value={StatusAvailable.postponed}
          checked={status === StatusAvailable.postponed}
          onChange={handleChange}
        >
          Available from ...
        </Radio>
        {status === StatusAvailable.postponed && (
          <DatePicker
            errors={errorDate}
            name={"date"}
            value={date ? new Date(date) : undefined}
            fromDate={dayjs().toString()}
            toDate={dayjs().add(1, "year").endOf("year").toString()}
            onDayChange={handleChangeDate}
            reverseYears
            startToday
            withoutValidateInterval
          />
        )}
        <Radio
          name="status"
          value={StatusAvailable.unavailable}
          checked={status === StatusAvailable.unavailable}
          onChange={handleChange}
        >
          Unavailable
        </Radio>
        <Button onClick={handleSave}>Save</Button>
        <Button variant={Variant.TEXT} onClick={onClose}>
          Close
        </Button>
        {error && <ErrorMessageText text={error} />}
      </Tooltip>
    </Portal>
  );
};

export { UpdateStatusAvailable };
