import React, { useMemo } from "react";
import * as Styled from "./VacancyCard.styled";
import { formatBoardingDate } from "utils/dateTime";
import { getMonth } from "./VacancyCard.utils";
import Button from "components/ui/Button/Button";
import { CalendarBlue } from "images/svg/calendarBlue";
import { Month } from "images/svg/month";
import { MiniLogo } from "images/svg/mini-logo";
import { IVacancy } from "api/vacancies/types";

export interface IVacancyProps {
  vacancy: IVacancy;
  clearVacancy: () => void;
}

const VacancyCard: React.FC<IVacancyProps> = ({ vacancy, clearVacancy }) => {
  const {
    title,
    contract_duration,
    net_salary_from,
    net_salary_to,
    date_of_boarding,
    company_title,
    has_dates_interval,
    dwt_from,
    dwt_to,
    ship_me_types,
  } = vacancy;

  const dateOfBoarding = useMemo(() => {
    return formatBoardingDate(date_of_boarding);
  }, [date_of_boarding]);

  const changeVacancy = () => {
    clearVacancy();
  };

  return (
    <Styled.Wrapper>
      <Styled.Content>
        <MiniLogo />
        <div>
          {company_title && (
            <Styled.CompanyName>{company_title}</Styled.CompanyName>
          )}
          <Styled.Name>{title}</Styled.Name>
          <Styled.BoardingDataWrapper>
            {date_of_boarding && (
              <Styled.BoardingDate>
                <CalendarBlue />
                {dateOfBoarding}
              </Styled.BoardingDate>
            )}
            {contract_duration && (
              <Styled.BoardingDate>
                <Month />
                {getMonth(contract_duration, has_dates_interval)}
              </Styled.BoardingDate>
            )}
          </Styled.BoardingDataWrapper>
          <Styled.BoardingDataWrapper>
            {dwt_from ||
              (dwt_to && (
                <Styled.BoardingDataItem>
                  <Styled.BoardingDataTitle>
                    DWT:
                    <Styled.BoardingData>
                      {" "}
                      {dwt_from ? dwt_from : `/`}
                      {dwt_to && ` - ${dwt_to}`}
                    </Styled.BoardingData>
                  </Styled.BoardingDataTitle>
                </Styled.BoardingDataItem>
              ))}
            {ship_me_types?.length !== 0 && (
              <Styled.BoardingDataItem>
                <Styled.BoardingDataTitle>
                  ME Type:
                  <Styled.BoardingData> {ship_me_types}</Styled.BoardingData>
                </Styled.BoardingDataTitle>
              </Styled.BoardingDataItem>
            )}
          </Styled.BoardingDataWrapper>
          <Button onClick={changeVacancy}>Change vacancy</Button>
        </div>
      </Styled.Content>
      <Styled.SalaryWrapper>
        {net_salary_from ? (
          <Styled.Salary>
            $ {net_salary_from} {net_salary_to && "- $ " + net_salary_to}
          </Styled.Salary>
        ) : null}
      </Styled.SalaryWrapper>
    </Styled.Wrapper>
  );
};

export { VacancyCard };
