import React from "react";
import { Link } from "react-router-dom";
import PageLayout from "../../components/layout/PageLayout/PageLayout";

import * as Styled from "./NotFound.styled";

const NotFound: React.FC = () => {
  return (
    <PageLayout>
      <Styled.Title>404 ERROR</Styled.Title>
      <Link to="/">
        <Styled.BackToMain>Back to main page</Styled.BackToMain>
      </Link>
    </PageLayout>
  );
};

export default NotFound;
