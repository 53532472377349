import { useMemo, useState } from "react";
import { deleteVacancy } from "../../api/vacancies/vacancies";
import { IProps } from "./Delete.types";
import { AxiosResponse } from "axios";
import { deleteCompany, deleteManager } from "../../api/companies/companies";
import { usePrevious } from "../../hooks/usePrevious";
import { IVacancy } from "api/vacancies/types";
import { ICompany, ICompanyManager } from "api/companies/types";

export const useClick = (
  type: IProps["type"],
  payload: IProps["payload"],
  callback: IProps["callback"],
  onClose: IProps["onClose"]
) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const name = useMemo(() => {
    return getName(type, payload);
  }, [type, payload]);

  const prevName = usePrevious(name);

  const handleClick = async () => {
    setLoading(true);
    setError(false);
    const id = getId(type, payload) as string;
    try {
      const res = (await apiDelete(id)) as AxiosResponse;
      if (res.status === 200) {
        if (callback) callback();
        onClose();
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  };

  const apiDelete = async (id: string) => {
    switch (type) {
      case "company":
        return await deleteCompany(id);
      case "vacancy":
        return await deleteVacancy(id);
      case "managers":
        return await deleteManager(payload?.company_id as string, id);
    }
  };

  return {
    loading,
    error,
    handleClick,
    name: prevName || name,
  };
};

const getId = (type: IProps["type"], payload: IProps["payload"]) => {
  if (!payload) return "";
  switch (type) {
    case "company": {
      const obj = { ...payload } as ICompany;
      return obj.company_id;
    }
    case "vacancy": {
      const obj = { ...payload } as IVacancy;
      return obj.vac_id;
    }
    case "managers": {
      const obj = { ...payload } as ICompanyManager;
      return obj.user_id;
    }
    default:
      return "";
  }
};

const getName = (type: IProps["type"], payload: IProps["payload"]) => {
  if (!payload) return "";
  switch (type) {
    case "company": {
      const obj = { ...payload } as ICompany;
      return obj.title || "";
    }
    case "vacancy": {
      const obj = { ...payload } as IVacancy;
      return obj.title || "";
    }
    case "managers": {
      const obj = { ...payload } as ICompanyManager;
      return `${obj.first_name || "-"} ${obj.last_name || "-"}`;
    }
    default:
      return "";
  }
};
