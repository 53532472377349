import React from "react";
import { formatDate } from "utils/dateTime";
import * as Styled from "./Availability.styled";
import { StatusAvailable } from "api/cvs/types";

const getText = (status: StatusAvailable, date: string) => {
  if (status === "available") return "Immediately available";
  if (status === "postponed") return `Available from ${formatDate(date)}`;
  return "Unavailable now";
};

const Availability = (props: any) => {
  const { dateOfAvailability = null, status } = props;

  return (
    <Styled.AvailabilityText>
      <Styled.Status status={status} />
      <span>{getText(status, dateOfAvailability)}</span>
    </Styled.AvailabilityText>
  );
};

interface IProps {
  status?: StatusAvailable;
  dateOfAvailability?: string;
}

export const AvailabilityBlock: React.FC<IProps> = ({
  dateOfAvailability,
  status,
}) => {
  return (
    <Styled.Wrapper>
      <Availability dateOfAvailability={dateOfAvailability} status={status} />
    </Styled.Wrapper>
  );
};
