import React, { useMemo } from "react";
import { Links, ButtonWrapper, WrapperTooltip, Link } from "./NewCvs.styled";
import { getTime } from "utils/dateTime";
import { getBaseUrl } from "utils/helpers";
import { ICvData, ModerationStatus } from "api/cvs/types";
import { getRenderStatus } from "utils/getRenderStatusSeaman";
import Button from "components/ui/Button/Button";
import { Variant, Color } from "components/ui/Button/Button.typed";
import { Color as Colors } from "styles/color";
import { IOpenModeration } from "../../Seamen";
import { TooltipType } from "./NewCvs";

export const COLUMNS = [
  {
    Header: "Creation date",
    accessor: "col1",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Ranks",
    accessor: "col2",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Name",
    accessor: "col3",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Citizenship",
    accessor: "col4",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Phone / E-mail",
    accessor: "col5",
    minWidth: 170,
    maxWidth: 170,
    width: 170,
  },
  {
    Header: "Availability",
    accessor: "col6",
    minWidth: 125,
    maxWidth: 125,
    width: 125,
  },
  {
    Header: "CV Step",
    accessor: "col7",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "UTM",
    accessor: "col8",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Status",
    accessor: "col9",
    minWidth: 110,
    maxWidth: 110,
    width: 110,
  },
  {
    Header: "",
    accessor: "col10",
    minWidth: 100,
    maxWidth: 250,
    width: 100,
  },
];

const baseUrl = getBaseUrl();

export const getModerationStatus = (status: ModerationStatus) => {
  if (!status) return;
  const obj = {
    color: Colors.BLUE500,
    name: `Moderated`,
  };
  if (status === ModerationStatus.banned) {
    obj.color = Colors.RED500;
    obj.name = "Blocked";
  }
  return <span style={{ color: obj.color }}>{obj.name}</span>;
};

const prepareRowData = (
  cv: ICvData,
  setOpenModeration: (value: IOpenModeration) => void,
  openTooltip: (type: TooltipType, cv: ICvData) => void
) => {
  const creationDate = getTime(cv.created_at);
  const moderationStatus = getModerationStatus(cv.moderation_status);
  const availabilityStatus = getRenderStatus(
    cv.availability_status,
    cv.date_of_availability
  );

  const handleClickCV = () => {
    window.open(`${baseUrl}/cv/${cv.cv_id}`, "_blank");
  };
  const handleClickSetApprove = () => {
    setOpenModeration({ type: ModerationStatus.moderated, cv: cv });
  };
  const handleClickSetBlock = () => {
    setOpenModeration({ type: ModerationStatus.banned, cv: cv });
  };
  const onChangeRanks = () => {
    openTooltip(TooltipType.ranks, cv);
  };
  const onChangeStatus = () => {
    openTooltip(TooltipType.statusAvailable, cv);
  };

  return {
    col1: creationDate,
    col2: (
      <WrapperTooltip id={`ranks_${cv.cv_id}`}>
        <Links onClick={handleClickCV}>
          {cv.first_rank || "-"} / {cv.secondary_rank || "-"}
        </Links>
        <Link onClick={onChangeRanks}>Change</Link>
      </WrapperTooltip>
    ),
    col3: (
      <Links onClick={handleClickCV}>
        {cv.first_name || ""} {cv.middle_name || ""} {cv.last_name || ""}
      </Links>
    ),
    col4: `${cv.citizenship || "-"}/${cv.second_citizenship || "-"}`,
    col5: `${cv.phone || "-"}\n${cv.email}`,
    col6: (
      <WrapperTooltip id={`available_${cv.cv_id}`}>
        {availabilityStatus}
        <Link onClick={onChangeStatus}>Change</Link>
      </WrapperTooltip>
    ),
    col7: cv.fill_step,
    col8: `${cv.utm_medium || "-"}\n${cv.utm_source || "-"}\n${
      cv.utm_compaign || "-"
    }`,
    col9: moderationStatus,
    col10: (
      <ButtonWrapper>
        {cv.moderation_status === ModerationStatus.banned && (
          <Button onClick={handleClickSetApprove} variant={Variant.TEXT}>
            Approve
          </Button>
        )}
        {cv.moderation_status === ModerationStatus.moderated && (
          <Button
            onClick={handleClickSetBlock}
            variant={Variant.TEXT}
            color={Color.SECONDARY}
          >
            Block
          </Button>
        )}
      </ButtonWrapper>
    ),
  };
};

export const useCVsData = (
  CVs: ICvData[],
  setOpenModeration: (value: IOpenModeration) => void,
  openTooltip: (type: TooltipType, cv: ICvData) => void
) => {
  return useMemo(() => {
    if (!CVs || !CVs.length) return [];

    return CVs.map((cv) => prepareRowData(cv, setOpenModeration, openTooltip));
  }, [CVs]);
};
