import React, { useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import DayPickerInput from "react-day-picker/DayPickerInput";

import { YearMonthForm } from "./components/YearMonthForm";
import { CalendarIconStyled, Wrapper } from "./DatePicker.styled";
import styles from "./DatePicker.module.css";
import { formatDateAPI } from "utils/dateTime";
import TextField, { RegexPattern } from "../TextField";
import { DayModifiers } from "react-day-picker";

dayjs.extend(customParseFormat);

const FORMAT = "YYYY/MM/DD";
// класс option'а React Select'а с кастомным префиксом
const CUSTOM_OPTION_CLASSNAME = "custom__option";

type Props = {
  disabled?: boolean;
  value?: Date;
  showOverlay?: boolean;
  onDayChange: (
    day: Date | string,
    modifiers?: Record<string, unknown>,
    dayPickerInput?: DayPickerInput
  ) => void;
  fromDate?: string;
  toDate?: string;
  required?: boolean;
  errors?: string | boolean;
  name: string;
  reverseYears?: boolean;
  startToday?: boolean;
  center?: boolean;
  withoutValidateInterval?: boolean;
};

type IRefInput = {
  hideDayPicker: () => void;
};

type IPropsComponent = {
  onBlur: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: (e: React.FocusEvent<HTMLDivElement>) => void;
  onFocus: () => void;
  onKeyDown: (e: React.FocusEvent<HTMLDivElement>) => void;
  onKeyUp: (e: React.FocusEvent<HTMLDivElement>) => void;
  placeholder: string;
  value: string;
};

export const CalendarIcon = () => (
  <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.857 0C11.041 0 12 .96 12 2.143v.428H0v-.428C0 .959.96 0 2.143 0h7.714zM0 3.429h12v6.428C12 11.041 11.04 12 9.857 12H2.143A2.143 2.143 0 010 9.857V3.43zM7.714 6A.857.857 0 109.43 6a.857.857 0 00-1.715 0zm.857 3.429a.857.857 0 100-1.715.857.857 0 000 1.715zM5.143 6a.857.857 0 101.714 0 .857.857 0 00-1.714 0zM6 9.429a.857.857 0 100-1.715.857.857 0 000 1.715zM2.571 6a.857.857 0 101.715 0A.857.857 0 002.57 6z"
      fill="#C1C7D0"
    />
  </svg>
);

const maskValue = (value: string) => {
  return value.replace(
    /^(\d{4})+?\/?(\d{1,2})+?(\d{1,2})?$/,
    (match, p1, p2, p3) => [p1, p2, p3].filter(Boolean).join("/")
  );
};

const DatePicker: React.FC<Props> = (props) => {
  const inputRef = useRef<IRefInput>(null);
  const {
    disabled,
    fromDate = "1970-01-01",
    toDate = "2030-01-01",
    value,
    onDayChange,
    errors = false,
    name,
    reverseYears,
    startToday,
    center = false,
    withoutValidateInterval = false,
  } = props;
  const initialMonth = useMemo(
    () => (startToday ? new Date() : new Date(toDate)),
    [toDate, startToday]
  );

  useEffect(() => {
    if (disabled) {
      onDayChange("");
    }
  }, [disabled]);

  const [month, setMonth] = useState(initialMonth);
  const {
    inputContainer,
    inputOverlayWrapper,
    inputOverlay,
    inputOverlayCenter,
    ...dayPickerClassNames
  } = styles;

  const { fromYear, toYear } = useMemo(
    () => ({
      fromYear: dayjs(fromDate).year(),
      toYear: dayjs(toDate).year(),
    }),
    [fromDate, toDate]
  );

  const parseDate = (str: string, format: string) => {
    const parsed = dayjs(str, format);

    if (
      parsed.isBefore(fromDate) ||
      parsed.isAfter(toDate) ||
      !parsed.isValid()
    ) {
      return undefined;
    }

    return parsed.toDate();
  };

  const formatDate = (date: Date, format: string) => {
    return dayjs(date).format(format);
  };

  const handleDayChange = (
    date: Date,
    { disabled }: DayModifiers,
    dayPickerInput: DayPickerInput
  ) => {
    if (date != null && !disabled) {
      dayPickerInput.hideDayPicker();
      onDayChange(formatDateAPI(date));
    }
  };

  const dateInInterval = (value: string): boolean => {
    if (value === "" || withoutValidateInterval) {
      return true;
    }
    const date = dayjs(value, FORMAT);

    return (
      (date.isAfter(dayjs(fromDate)) && date.isBefore(dayjs(toDate))) ||
      date.isSame(dayjs(fromDate), "day") ||
      date.isSame(dayjs(toDate), "day")
    );
  };

  // реф инпута, по клику на который вызывается пикер
  const containerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClick = (evt: MouseEvent) => {
      const target = evt.target as HTMLElement;
      const container = document.querySelector(`.${inputOverlayWrapper}`);

      // если клик по самому инпуту, выходим
      if (
        containerRef &&
        containerRef.current &&
        containerRef.current.contains(target)
      )
        return;

      // клик за пределами пикера
      const isClickOutsidePicker = !container?.contains(target);
      // клик НЕ по опшену (название месяца из селекта)
      const isOptionClicked = !target.classList.contains(
        CUSTOM_OPTION_CLASSNAME
      );

      if (
        inputRef &&
        inputRef.current &&
        isClickOutsidePicker &&
        isOptionClicked
      ) {
        inputRef.current.hideDayPicker();
      }
    };

    document.addEventListener("click", handleClick);

    return () => document.removeEventListener("click", handleClick);
  }, []);

  const getError = (): boolean | string => {
    if (props.value) {
      return !dateInInterval(formatDate(props.value, FORMAT));
    }

    return errors;
  };

  return (
    <Wrapper>
      <div ref={containerRef}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore TS cant infer keys from styles object */}
        <DayPickerInput
          {...props}
          ref={inputRef}
          value={value}
          format={FORMAT}
          parseDate={parseDate}
          formatDate={formatDate}
          classNames={{
            container: inputContainer,
            overlayWrapper: inputOverlayWrapper,
            overlay: `${inputOverlay} ${center ? inputOverlayCenter : ""}`,
          }}
          inputProps={{
            ref: null,
          }}
          component={(props: IPropsComponent) => {
            return (
              <TextField
                {...props}
                id={name}
                onChange={(evt) => {
                  const event = evt as React.ChangeEvent<HTMLInputElement>;
                  if (!event.target.value) {
                    onDayChange("");
                    if (inputRef && inputRef.current) {
                      inputRef.current.hideDayPicker();
                    }
                  }
                  props.onChange(evt);
                }}
                name={name}
                disabled={disabled}
                returnEvent={true}
                maxLength={10}
                pattern={RegexPattern.DATE}
                value={maskValue(props.value)}
                error={getError()}
              />
            );
          }}
          onDayChange={handleDayChange}
          dayPickerProps={{
            month,
            initialMonth,
            selectedDays: value,
            classNames: dayPickerClassNames,
            captionElement: ({ date }) => (
              <YearMonthForm
                date={date}
                onChange={setMonth}
                fromYear={fromYear}
                toYear={toYear}
                reverseYears={reverseYears}
              />
            ),
            showOutsideDays: true,
            disabledDays: {
              before: dayjs(fromDate).toDate(),
              after: dayjs(toDate).toDate(),
            },
          }}
          placeholder="YYYY/MM/DD"
        />
      </div>
      <CalendarIconStyled filled={!!value}>
        <CalendarIcon />
      </CalendarIconStyled>
    </Wrapper>
  );
};

export { DatePicker };
