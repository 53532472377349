import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${indents.indent5};
  margin-bottom: ${indents.indent5};
`;

export const Text = styled.span`
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
`;

export const Link = styled(Text)`
  color: ${Color.BLUE300};
  text-decoration: underline;
  cursor: pointer;
`;
