import React from "react";
import * as Styled from "./ManagersCard.styled";
import * as Dashboard from "../../../pages/Dashboard/Dashboard.styled";
import Loader from "../../utils/Loader/Loader";

interface IProps {
  managers: number | string;
  loading: boolean;
}

const ManagersCard = ({ managers, loading }: IProps) => {
  const titleJsx = (
    <Dashboard.Title>
      <Dashboard.TitleText>Total managers: </Dashboard.TitleText>
      {loading ? <Loader inline /> : managers}
    </Dashboard.Title>
  );

  return <Styled.ManagersCard>{titleJsx}</Styled.ManagersCard>;
};

export default ManagersCard;
