import { Color } from "styles/color";

type TState = any;
type TBase = any;

const borderColor = (state: TState) => {
  if (state.isFocused || state.hasValue) {
    return Color.BLUE300;
  }

  if (state.isDisabled) {
    return Color.MID60;
  }

  return Color.LIGHT40;
};

const borderRadius = (state: TState) => {
  const { selectProps } = state;
  const { groupRight, groupLeft, groupTop, groupBottom } = selectProps;

  return (
    (groupLeft && "4px 0 0 4px") ||
    (groupRight && "0 4px 4px 0") ||
    (groupTop && "4px 4px 0 0") ||
    (groupBottom && "0 0 4px 4px") ||
    "4px"
  );
};

const backgroundColor = (state: TState) => {
  if (state.isFocused || state.selectProps.error) {
    return "#fff";
  }

  if (state.hasValue) {
    return Color.BLUE50;
  }

  if (state.isDisabled) {
    return Color.LIGHT20;
  }

  return Color.LIGHT10;
};

const fontColor = (state: TState) => {
  if (state.isFocused || state.selectProps.error) {
    return Color.MID80;
  }

  if (state.hasValue) {
    return Color.BLUE500;
  }

  if (state.isDisabled) {
    return Color.LIGHT50;
  }

  return Color.MID300;
};

export const selectStyles = {
  container: (base: TBase, s: TState) => ({
    ...base,

    fontFamily: "Lato",
    fontSize: "14px",
    lineHeight: "20px",

    maxWidth: `${s.selectProps.width}px`,
    width: "100%",
  }),
  control: (base: TBase, s: TState) => {
    const { error } = s.selectProps;

    return {
      ...base,
      minHeight: "32px",
      maxHeight: s.isMulti ? "auto" : "32px",
      boxShadow: s.isFocused
        ? "0px 0px 4px rgba(0, 101, 255, 0.24), inset 0px 0px 4px rgba(23, 43, 77, 0.5)"
        : "none",
      borderWidth: "1px",
      borderColor: error && !s.isFocused ? Color.RED400 : borderColor(s),
      borderRadius: borderRadius(s),
      backgroundColor: backgroundColor(s),
      borderRight: s.selectProps.groupLeft && "none",
      borderTop: s.selectProps.groupBottom && "none",

      ":hover": {
        borderColor: !s.isFocused && Color.BLUE100,
      },
    };
  },
  indicatorsContainer: (base: TBase) => ({
    ...base,
    minHeight: "32px",
    fill: Color.BLUE300,
  }),
  input: (base: TBase) => ({
    ...base,
    margin: "0",
    padding: "0",
  }),
  menu: (base: TBase) => ({
    ...base,
    zIndex: 600,
  }),
  indicatorSeparator: () => ({ display: "none" }),
  clearIndicator: (base: TBase) => ({
    ...base,
    padding: "0 8px",
  }),
  option: (base: TBase) => ({
    ...base,
    color: "172b4d",
  }),
  placeholder: (base: TBase, s: TState) => ({
    ...base,
    color: fontColor(s),
  }),
  singleValue: (base: TBase, s: TState) => ({
    ...base,
    top: "calc(50% - 1px)",
    color: fontColor(s),
  }),
  valueContainer: (base: TBase) => ({
    ...base,
    padding: "6px 0 6px 12px",
    minHeight: "32px",
    gap: "4px 8px",
  }),
  multiValue: (base: TBase) => {
    return {
      ...base,
      backgroundColor: `${Color.BLUE300}`,
      maxWidth: "calc(50% - 8px)",
      height: "20px",
      display: "flex",
      alignItems: "center",
      margin: "0",
    };
  },
  multiValueLabel: (base: TBase) => ({
    ...base,
    color: `${Color.LIGHT00}`,
    fontSize: "14px",
    lineHeight: "20px",
  }),
  multiValueRemove: (base: TBase) => ({
    ...base,
    color: Color.BLUE75,
    marginTop: "1px",
    background: "transparent",
    ":hover": {
      backgroundColor: "transparent",
      color: Color.RED75,
    },
    ":active": {
      color: Color.RED300,
    },
  }),
};
