import React from "react";
import Header from "../Header/Header";

import * as Styled from "./PageLayout.styled";

interface IPageLayout {
  children: React.ReactNode;
  backgroundImage?: string;
}

const PageLayout = (props: IPageLayout) => {
  return (
    <Styled.Wrapper backgroundImage={props.backgroundImage}>
      <Header />
      <Styled.Main>{props.children}</Styled.Main>
    </Styled.Wrapper>
  );
};

export default PageLayout;
