import styled, { keyframes } from "styled-components/macro";
import { Color } from "../../styles/color";

const pulse = keyframes`
    0% {
        background-position: 0 0
    }
    100% {
        background-position: -135% 0
    }
`;

export const Skeleton = styled.div<{
  height: number;
  bottom?: number;
  maxWidth?: number;
}>`
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : "100%")};
  border-radius: 4px;
  background: linear-gradient(
    -90deg,
    ${Color.LIGHT30} 0%,
    ${Color.LIGHT20} 50%,
    ${Color.LIGHT30} 100%
  );
  background-size: 400% 400%;
  animation: ${pulse} 1.2s ease-in-out infinite;
  height: ${(props) => `${props.height}px`};
  margin-bottom: ${(props) =>
    props.bottom !== undefined ? `${props.bottom}px` : "16px"};
`;
