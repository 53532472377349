import { ICountries } from "../api/dc/types";

export const findItemAndUnshiftCountries = (arr: ICountries, name: string) => {
  const index = arr.findIndex((item) => item.Name === name);
  const countriesModify = [...arr];
  const el = countriesModify.splice(index, 1);
  countriesModify.unshift(...el);
  return countriesModify;
};

export const findItemsAndUnshiftCountries = (
  arr: ICountries,
  names: Array<string>
) => {
  const start = [] as ICountries;
  const end = [] as ICountries;
  arr.forEach((item) => {
    if (names.includes(item.Name)) {
      start.push(item);
    } else {
      end.push(item);
    }
  });

  return [...start, ...end];
};
