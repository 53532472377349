import React from "react";
import * as Styled from "../../Card.styled";
import * as StyledExperience from "../WorkExperience/WorkExperience.styled";
import { Skeleton } from "components/Skeleton/Skeleton";
import styled from "styled-components/macro";
import { indents } from "styles/indents";

const Avatar = styled.div`
  margin-left: ${indents.indent6};
  margin-right: ${indents.indent5};
  width: 104px;
  height: 104px;
`;

const Experience = styled.div`
  margin: ${indents.indent4};
  width: 100%;
  height: inherit;
`;

const SeamanCardSkeleton = () => {
  return (
    <Styled.MainWrapper>
      <Styled.Wrapper fill_step={0}>
        <Avatar>
          <Skeleton height={104} maxWidth={104} />
        </Avatar>
        <Styled.PersonalInfo>
          <Skeleton height={20} maxWidth={100} bottom={4} />
          <Skeleton height={25} maxWidth={150} bottom={4} />
          <Skeleton height={20} maxWidth={90} bottom={10} />
          <Skeleton height={20} maxWidth={160} bottom={4} />
          <Skeleton height={20} maxWidth={250} bottom={8} />
          <Skeleton height={20} maxWidth={90} bottom={4} />
          <Skeleton height={20} maxWidth={260} bottom={4} />
          <Skeleton height={20} maxWidth={110} bottom={24} />
          <div style={{ display: "flex", width: "100%", gap: "16px" }}>
            <Skeleton height={40} maxWidth={90} bottom={0} />
            <Skeleton height={40} maxWidth={160} bottom={0} />
          </div>
        </Styled.PersonalInfo>
        <StyledExperience.Experience>
          <Experience>
            <Skeleton height={20} maxWidth={80} bottom={4} />
            <Skeleton height={20} maxWidth={200} bottom={4} />
            <Skeleton height={20} maxWidth={240} bottom={16} />
            <Skeleton height={20} maxWidth={80} bottom={4} />
            <Skeleton height={20} maxWidth={200} bottom={4} />
            <Skeleton height={20} maxWidth={240} bottom={16} />
            <Skeleton height={32} maxWidth={210} bottom={16} />
          </Experience>
        </StyledExperience.Experience>
      </Styled.Wrapper>
    </Styled.MainWrapper>
  );
};

export { SeamanCardSkeleton };
