import { IResponse, StatusResponse } from "api/responses/types";
import React, { useMemo } from "react";
import * as Styled from "../CompanyPage.styled";
import { Color as Colors } from "../../../styles/color";
import { firstChartToUpperCase } from "../../../utils/firstChartToUppercase";
import { getTime } from "../../../utils/dateTime";
import { getBaseUrl } from "utils/helpers";

export const COLUMNS = [
  {
    Header: "Name",
    accessor: "col1",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Phone number, email",
    accessor: "col2",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Vacancy",
    accessor: "col3",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "Status",
    accessor: "col4",
    minWidth: 100,
    maxWidth: 100,
    width: 100,
  },
  {
    Header: "Date of apply",
    accessor: "col5",
    minWidth: 120,
    maxWidth: 120,
    width: 120,
  },
  {
    Header: "Last visited",
    accessor: "col6",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
  {
    Header: "User ID",
    accessor: "col7",
    minWidth: 150,
    maxWidth: 150,
    width: 150,
  },
];

const baseUrl = getBaseUrl();

const getColorStatus = (status: StatusResponse) => {
  let color = Colors.DARK900;
  if (status === StatusResponse.sent) color = Colors.MID80;
  if (status === StatusResponse.viewed) color = Colors.BLUE100;
  if (status === StatusResponse.contacted) color = Colors.BLUE300;
  if (status === StatusResponse.selected) color = Colors.YELLOW300;
  if (
    status === StatusResponse.hired ||
    status === StatusResponse.hiring_confirmed
  ) {
    color = Colors.GREEN300;
  }

  if (status === StatusResponse.rejected) color = Colors.RED300;
  if (status === StatusResponse.archived) color = Colors.RED100;
  return <span style={{ color: color }}>{firstChartToUpperCase(status)}</span>;
};

const prepareRowData = (response: IResponse) => {
  const handleClick = () => {
    window.open(`${baseUrl}/cv/${response.cv_id}`, "_blank");
  };

  const handleClickVacancy = () => {
    window.open(`${baseUrl}/vacancies/${response.vac_id}`, "_blank");
  };

  const createdAt = getTime(response.created_at);
  const lastVisited = getTime(response.last_activity_at);

  return {
    col1: (
      <Styled.Link onClick={handleClick}>{response.seaman_name}</Styled.Link>
    ),
    col2: `${response.seaman_email || "–"}\n${response.seaman_phone || "–"}`,
    col3:
      (
        <Styled.Link onClick={handleClickVacancy}>{response.title}</Styled.Link>
      ) || "-",
    col4: getColorStatus(response.fr_status || StatusResponse.sent),
    col5: createdAt,
    col6: lastVisited,
    col7: <Styled.Link onClick={handleClick}>{response.user_id}</Styled.Link>,
  };
};

export const useOpeningData = (responses: IResponse[]) => {
  return useMemo(() => {
    if (!responses || !responses.length) return [];

    return responses.map((item) => prepareRowData(item));
  }, [responses]);
};
