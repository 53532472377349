import styled from "styled-components/macro";
import Button from "../../components/ui/Button/Button";

export const Root = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const Title = styled.h1`
  margin-top: 20px;
`;
export const Form = styled.form`
  margin-top: 70px;
  width: 100%;
  max-width: 500px;
`;

export const SubmitButton = styled(Button)`
  margin-top: 42px;
`;
