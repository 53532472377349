import styled from "styled-components/macro";

export const Wrapper = styled.div`
  display: flex;
  margin-top: 16px;
`;

export const Aside = styled.aside`
  margin-right: 18px;
  width: 315px;
`;

export const Main = styled.main`
  flex: 1;
`;

export const Title = styled.div`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: #061938;
  font-family: Montserrat, sans-serif;
  margin-bottom: 16px;
`;

export const Requirements = styled.div`
  max-width: 540px;
`;
