import styled from "styled-components/macro";
import { Color } from "styles/color";

export const NotFoundWrapper = styled.div`
  padding: 42px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: ${Color.LIGHT00};
  box-shadow: 0 4px 20px rgba(23, 43, 77, 0.08);
`;

export const NotFoundTitle = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: ${Color.DARK900};
  font-family: Montserrat, sans-serif;
  margin-bottom: 4px;
`;

export const NotFoundText = styled.div`
  margin-bottom: 16px;
  max-width: 340px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: ${Color.MID400};
`;
