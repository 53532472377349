import React, { useEffect, useState } from "react";
import * as Styled from "./DateWithMonth.styled";
import { Select } from "components/ui/Select/Select";
import { DatePicker } from "components/ui/DatePicker/DatePicker";
import dayjs from "dayjs";
import { DURATION_OPTIONS, getDiffMonth, getToDate } from "./utils";

interface IValue {
  from: string;
  to: string;
}

interface IProps {
  value: IValue;
  name: string;
  onChange: (val: IValue) => void;
}

const DateWithMonth: React.FC<IProps> = ({ value, onChange, name }) => {
  const [month, setMonths] = useState("");
  const [date, setDate] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    setDisabled(!date);
  }, [date]);

  useEffect(() => {
    setDate(value?.from || "");
    setMonths(
      value?.to && value.to !== value.from
        ? getDiffMonth(value?.from, value?.to)
        : ""
    );
  }, [value]);

  const handleChangeMonth = (data: string) => {
    setMonths(data);
    changeData("month", data);
  };

  const handleChangeDate = (date: string) => {
    setDate(date);
    changeData("date", date);
  };

  const changeData = (type: string, payload: string) => {
    const monthData = type === "month" ? payload : month;
    const dateData = type === "date" ? payload : date;
    let data;
    if (monthData && dateData) {
      data = {
        from: dateData,
        to: getToDate(dateData, monthData),
      };
    }
    if (dateData && !monthData) {
      data = {
        from: dateData,
        to: getToDate(dateData, "0"),
      };
    }
    if (data) onChange(data);
  };

  const pluralizeMonth = (value: number | string) =>
    Number(value) === 1 ? "month" : "months";

  return (
    <Styled.Wrapper>
      <DatePicker
        name={name}
        value={date ? new Date(date) : undefined}
        onDayChange={(val) => handleChangeDate(val as string)}
        fromDate={dayjs().toString()}
        startToday={true}
        toDate={dayjs().add(1, "year").endOf("year").toString()}
      />
      <span>+</span>
      <Select
        isDisabled={disabled}
        value={
          month
            ? {
                label: `${month} ${pluralizeMonth(month)}`,
                value: month,
              }
            : undefined
        }
        onChange={(val) => handleChangeMonth(val as string)}
        options={DURATION_OPTIONS}
        placeholder="Months"
      />
    </Styled.Wrapper>
  );
};

export { DateWithMonth };
