import React, { useCallback, useEffect, useReducer, useState } from "react";
import _debounce from "lodash/debounce";
import * as Styled from "./Filters.styled";
import { makeOption } from "../../../components/ui/Select/utils";
import { Select, TSelectProps } from "../../../components/ui/Select/Select";
import { StatusEmail } from "api/emails/types";
import TextField from "../../../components/ui/TextField";
import dayjs from "dayjs";
import { DatePicker } from "../../../components/ui/DatePicker/DatePicker";
import { getEmailCategories } from "../../../api/emails/emails";
import { useIsMount } from "../../../hooks/useIsMount";

interface IState {
  category: string;
  status: string;
  from: string;
  to: string;
  email: string;
}

export enum ActionType {
  setPayload = "setPayload",
}

const initialState: IState = {
  category: "",
  status: "",
  from: "",
  to: "",
  email: "",
};

export type Actions = {
  type: ActionType.setPayload;
  payload: { name: keyof IState; value: string };
};

function reducer(state: IState, action: Actions): IState {
  switch (action.type) {
    case ActionType.setPayload:
      return { ...state, [action.payload.name]: action.payload.value };
  }
}

interface IProps {
  getApiData: (val: Record<string, string | Array<string>>) => void;
}

const Filters = ({ getApiData }: IProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [categories, setCategories] = useState<string[]>([]);

  const isMount = useIsMount();

  const handleDebounceFn = (values: IState) => {
    const result: Record<string, string | Array<string>> = {};
    const created = [values.from, values.to];

    Object.keys(values).forEach((val) => {
      const key = val as keyof IState;
      if (values[key] && key !== "from" && key !== "to") {
        result[key] = values[key];
      }
    });
    if (created.some((item) => !!item)) {
      result.created = created;
    }
    getApiData(result);
  };

  const debounceFn = useCallback(_debounce(handleDebounceFn, 1000), []);

  useEffect(() => {
    (async () => {
      const data = await getEmailCategories();
      setCategories(data.data);
    })();
  }, []);

  useEffect(() => {
    if (isMount) {
      debounceFn(state);
    }
  }, [state]);

  const handleChange = (
    name: keyof IState,
    value: string | { label: string; value: string } | string[] | null = ""
  ) => {
    dispatch({
      type: ActionType.setPayload,
      payload: { name, value: value as string },
    });
  };

  return (
    <Styled.Wrapper>
      <Styled.Left>
        <Styled.Selects>
          <Select
            width={300}
            value={state.category as TSelectProps["value"]}
            onChange={(val) => handleChange("category", val)}
            options={categories.map(makeOption)}
            placeholder="Category"
            name="category"
          />
          <Select
            width={150}
            value={state.status as TSelectProps["value"]}
            onChange={(val) => handleChange("status", val)}
            options={Object.values(StatusEmail).map(makeOption)}
            placeholder="Status"
            name="status"
          />
        </Styled.Selects>
        <Styled.Dates>
          <DatePicker
            name="from"
            value={state.from ? new Date(state.from) : undefined}
            fromDate={dayjs().subtract(2, "year").startOf("year").toString()}
            toDate={dayjs().toString()}
            onDayChange={(val) => handleChange("from", val as string)}
            startToday
          />
          <DatePicker
            name="to"
            value={state.to ? new Date(state.to) : undefined}
            fromDate={dayjs().subtract(2, "year").startOf("year").toString()}
            toDate={dayjs().toString()}
            onDayChange={(val) => handleChange("to", val as string)}
            startToday
          />
        </Styled.Dates>
      </Styled.Left>
      <TextField
        width={300}
        name="email"
        id="email"
        placeholder="Search by e-mail"
        value={state.email}
        onChange={(e) => handleChange("email", e.target.value)}
      />
    </Styled.Wrapper>
  );
};

export { Filters };
