import React from "react";

const CalendarBlue = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 0C1.11929 0 0 1.11929 0 2.5V3H14V2.5C14 1.11929 12.8807 0 11.5 0H2.5ZM14 4H0V11.5C0 12.8807 1.11929 14 2.5 14H11.5C12.8807 14 14 12.8807 14 11.5V4ZM5 7C5 7.55229 4.55228 8 4 8C3.44772 8 3 7.55229 3 7C3 6.44771 3.44772 6 4 6C4.55228 6 5 6.44771 5 7ZM4 11C3.44772 11 3 10.5523 3 10C3 9.44771 3.44772 9 4 9C4.55228 9 5 9.44771 5 10C5 10.5523 4.55228 11 4 11ZM8 7C8 7.55229 7.55229 8 7 8C6.44771 8 6 7.55229 6 7C6 6.44771 6.44771 6 7 6C7.55229 6 8 6.44771 8 7ZM7 11C6.44771 11 6 10.5523 6 10C6 9.44771 6.44771 9 7 9C7.55229 9 8 9.44771 8 10C8 10.5523 7.55229 11 7 11ZM11 7C11 7.55229 10.5523 8 10 8C9.44771 8 9 7.55229 9 7C9 6.44771 9.44771 6 10 6C10.5523 6 11 6.44771 11 7Z"
        fill="#0065FF"
      />
    </svg>
  );
};
export { CalendarBlue };
