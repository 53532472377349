import styled, { css } from "styled-components/macro";
import * as Typed from "./TextArea.typed";
import { Color } from "styles/color";

const NativeTextArea = styled.textarea`
  background: none;
  border: none;
  color: inherit;
  display: block;
  font-family: inherit;
  font-size: inherit;
  min-height: inherit;
  outline: none !important;
  padding: 10px 12px;
  resize: none;
  width: 100%;

  &::placeholder {
    color: #b8becc;
  }
`;

const Wrapper = styled.div<Typed.IStyledProps>`
  background-color: ${Color.LIGHT10};
  border: 1px solid #e6eaf2;
  border-radius: 4px;
  display: block;
  color: ${Color.DARK800};
  font-size: 14px;
  line-height: 24px;
  min-height: 120px;

  :hover:not([disabled]) {
    border-color: ${Color.BLUE100};
  }

  ${({ disabled, focused, filled, error }) => css`
    ${disabled &&
    css`
      border-color: ${Color.LIGHT50};
      background-color: ${Color.LIGHT20};
      color: ${Color.LIGHT50};
    `}

    ${error &&
    css`
      background-color: ${Color.LIGHT00};
      border-color: ${Color.RED300};
      color: ${Color.DARK900};
    `}

        ${filled &&
    !error &&
    css`
      background-color: ${Color.BLUE50};
      border-color: ${Color.BLUE300};
      color: ${Color.BLUE500};
    `}

        ${focused &&
    css`
      color: ${Color.DARK900};
      background: ${Color.LIGHT00};
      border-color: ${Color.BLUE300};
      box-shadow: 0 0 4px rgba(0, 101, 255, 0.24),
        inset 0 0 4px rgba(23, 43, 77, 0.5);
    `}
  `}
  ${NativeTextArea} {
    ${(props) =>
      props.resizable &&
      css`
        resize: vertical;
      `}
  }
`;

export { NativeTextArea, Wrapper };
