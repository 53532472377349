import React from "react";
import * as Styled from "./GoodPerfect.styled";

interface IProps {
  fill_step?: number;
}

const GoodPerfect: React.FC<IProps> = ({ fill_step }) => {
  function getCvGrade() {
    if (fill_step === 3) {
      return "Perfect CV";
    }
    if (fill_step === 2) {
      return "Good CV";
    }
    return null;
  }
  if (fill_step && fill_step < 2) {
    return null;
  }
  return (
    <Styled.Container fill_step={fill_step}>
      <Styled.Title fill_step={fill_step}>{getCvGrade()}</Styled.Title>
    </Styled.Container>
  );
};

export { GoodPerfect };
