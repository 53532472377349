import styled from "styled-components/macro";
import { Color } from "../../styles/color";
import { ModerationStatus } from "api/cvs/types";

export const Text = styled.p`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK800};

  margin-bottom: 32px;
`;

export const Status = styled.span<{ status: ModerationStatus | null }>`
  color: ${(props) =>
    props.status === ModerationStatus.banned ? Color.RED300 : Color.BLUE300};
`;
