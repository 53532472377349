import styled from "styled-components/macro";
import { indents } from "styles/indents";
import { Color } from "styles/color";

export const Wrapper = styled.div`
  padding: ${indents.indent5} 20px;
  background-color: ${Color.LIGHT10};
  border-radius: ${indents.indent4};
  align-self: start;
  margin-bottom: ${indents.indent7};

  grid-column: 2 / 3;
  grid-row: 1 / 2;
  justify-self: start;

  @media (max-width: 580px) {
    margin-left: 0;
    justify-self: unset;
  }
`;

export const Name = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: ${Color.DARK900};
  margin-bottom: ${indents.indent2};

  &:empty {
    &::after {
      content: "—";
      display: inline-block;
    }
  }
`;

export const Rank = styled.p`
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
  color: ${Color.BLUE300};
  margin-bottom: ${indents.indent4};
`;

export const Bio = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${Color.DARK900};
`;

export const Separate = styled.span`
  color: #e6eaf2;
`;

export const Gender = styled.span`
  text-transform: capitalize;
`;

export const BlockTitle = styled.p`
  font-size: 12px;
  line-height: 16px;
  color: ${Color.MID400};
  margin-bottom: ${indents.indent4};
`;

export const Blocks = styled.div`
  display: flex;

  @media (max-width: 1260px) {
    flex-wrap: wrap;
  }
`;

export const Block = styled.div`
  min-width: 250px;
  margin-top: ${indents.indent5};

  :not(:last-child) {
    margin-right: ${indents.indent11};
  }
`;
